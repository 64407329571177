import {
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  VStack,
} from '@chakra-ui/react';
import { FiMoreVertical } from 'react-icons/fi';
import { CellProps } from 'react-table';
import { PartnerModel } from '../../../app/services/partner/api/types';
import EditPartnerInfoBUtton from './EditPartnerInfo';
import DisablePartner from './DisablePartner';

const OptionsButton = ({ row: { original } }: CellProps<PartnerModel>) => {
  return (
    <>
      <Popover placement="bottom-end">
        <PopoverTrigger>
          <IconButton aria-label="Options" icon={<FiMoreVertical />} variant="ghost" colorScheme="brand.main" h="5" />
        </PopoverTrigger>
        <Portal>
          <PopoverContent w="fit-content">
            <PopoverArrow />
            <PopoverBody>
              <VStack>
                <EditPartnerInfoBUtton original={original} />
                <DisablePartner original={original} />
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  );
};

export default OptionsButton;
