import { partnerApi } from '.';
import { PaginatedApiResult, PaginatedQueryParams } from '../../types';
import { RefUserModel, AddExistingSsoUserModel } from './types';

const baseUrl = '/user';

const extendedApi = partnerApi.injectEndpoints({
  endpoints: builder => ({
    getUserByEmail: builder.query<RefUserModel, string>({
      //! DO NOT ADD providesTags, not sure but with it, will fetch the previous value only
      query: email => baseUrl + `/email/${email}`,
    }),

    getUserList: builder.query<
      PaginatedApiResult<RefUserModel>,
      PaginatedQueryParams<RefUserModel> & { partner_product_id: number }
    >({
      query: params => ({
        url: baseUrl + `/list`,
        params,
      }),
      providesTags: ['User', 'UserDemographic'],
    }),

    deleteUserById: builder.mutation<void, number>({
      query: refUserId => ({
        url: baseUrl + `/${refUserId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),

    addExistingSsoUser: builder.mutation<void, AddExistingSsoUserModel>({
      query: body => ({
        url: baseUrl + '/existing/ssouser',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['User'],
    }),

    updateUserStatus: builder.mutation<void, { refUserId: number; isActive: boolean }>({
      query: ({ refUserId, isActive }) => ({
        url: baseUrl + `/${refUserId}/is_active/${isActive}`,
        method: 'PUT',
      }),
      invalidatesTags: ['User'],
    }),
  }),
});

export const {
  useLazyGetUserByEmailQuery,
  useGetUserListQuery,
  useDeleteUserByIdMutation,
  useUpdateUserStatusMutation,
  useAddExistingSsoUserMutation,
} = extendedApi;
