import { partnerApi } from '.';
import { PaginatedApiResult, PaginatedQueryParams } from '../../types';
import { AddRefProductTypeModel, RefProductTypeModel } from './types';

const baseUrl = '/product-type';

const extendedApi = partnerApi.injectEndpoints({
  endpoints: build => ({
    getProductTypeList: build.query<PaginatedApiResult<RefProductTypeModel>, PaginatedQueryParams<RefProductTypeModel>>(
      {
        query: params => ({
          url: baseUrl + `/list`,
          params,
        }),
        providesTags: ['ProductType'],
      },
    ),
    getAllProductType: build.query<RefProductTypeModel[], void>({
      query: () => baseUrl,
      providesTags: ['ProductType'],
    }),
    addProductType: build.mutation<void, AddRefProductTypeModel>({
      query: body => ({
        url: baseUrl,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ProductType'],
    }),
  }),
});

export const {
  useGetProductTypeListQuery,
  useGetAllProductTypeQuery,
  useAddProductTypeMutation,
  useLazyGetProductTypeListQuery,
  useLazyGetAllProductTypeQuery,
} = extendedApi;
