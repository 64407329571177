import {
  Box,
  Heading,
  Flex,
  Input,
  FormLabel,
  VStack,
  FormControl,
  Textarea,
  FormErrorMessage,
  IconButton,
  Button,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { AddReportModel } from '../../../app/services/partner/api/types';
import { isValidGuid } from '../../../app/helpers/stringHelper';
import HorizontalLine from '../../HorizontalLine';
import { CloseIcon } from '@chakra-ui/icons';
import RedAsterisk from '../../RedAsterisk';

const defaultFormDataErrors = {
  report_name: '',
  report_desc: '',
  report_url: '',
  looker_report_guid: '',
};

interface ReportDetailsProps {
  report: AddReportModel;
  onClose: () => void;
  handleCancel: () => void;
  updateReport: (newValue: AddReportModel) => void;
  handleAddReportStageChange: (stage: number) => void;
}

const ReportDetails = ({
  handleCancel,
  handleAddReportStageChange,
  updateReport,
  onClose,
  report,
}: ReportDetailsProps) => {
  const [errors, setErrors] = useState(defaultFormDataErrors);
  const [localReport, setLocalReport] = useState<AddReportModel>(report);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrors(defaultFormDataErrors);

    if (validate()) {
      handleAddReportStageChange(2);
    } else {
      console.log('INPUTS NOT VALID!!!');
    }
  };

  const validate = () => {
    let newErrors = { ...errors };

    if (!report.report_name.trim()) {
      newErrors.report_name = 'Report Name is required';
    } else {
      newErrors.report_name = '';
    }

    if (!isValidGuid(report.looker_report_guid)) {
      newErrors.looker_report_guid = 'A valid guid is required';
    } else {
      newErrors.looker_report_guid = '';
    }

    if (!report.report_desc.trim()) {
      newErrors.report_desc = 'Description is required';
    } else {
      newErrors.report_desc = '';
    }

    if (!report.report_url.toString()) {
      newErrors.report_url = `A valid url is required`;
    } else {
      newErrors.report_url = '';
    }

    setErrors(newErrors);
    console.log(`ERRORS: ${JSON.stringify(newErrors)}`);

    return Object.values(newErrors).every(error => error === '');
  };

  const handleOnStringPropertyChange = (prop_value: string, prop_name: string) => {
    setLocalReport(prevState => ({ ...prevState, [prop_name]: prop_value }));
  };

  useEffect(() => {
    updateReport(localReport);
  }, [localReport]);

  return (
    <>
      <Box>
        <Flex justifyContent={'space-between'}>
          <Box pt={'1.5'} pb={'1.5'}>
            <Heading as="h3" size="sm">
              Add Report
            </Heading>
          </Box>

          <Box>
            <IconButton
              sx={{ verticalAlign: 'middle' }}
              variant="link"
              color="black"
              size="xxs"
              aria-label="close modal"
              onClick={onClose}
              icon={<CloseIcon fontSize={'sm'} />}
            />
          </Box>
        </Flex>

        <Box>
          <form onSubmit={handleSubmit}>
            <VStack>
              <Box>
                <FormControl isInvalid={!!errors.report_name}>
                  <FormLabel fontSize={'smaller'} mb="-0.2" htmlFor="report-name">
                    Name
                    <RedAsterisk />
                  </FormLabel>
                  <Input
                    id="report-name"
                    name="report_name"
                    value={report.report_name}
                    onChange={e => handleOnStringPropertyChange(e.target.value, e.target.name)}
                  />
                  <FormErrorMessage>{errors.report_name}</FormErrorMessage>
                </FormControl>
              </Box>

              <Box>
                <FormControl isInvalid={!!errors.looker_report_guid}>
                  <FormLabel fontSize={'smaller'} mb="-0.2" htmlFor="looker-report-guid">
                    Looker Report GUID
                    <RedAsterisk />
                  </FormLabel>
                  <Input
                    id="looker-report-guid"
                    name="looker_report_guid"
                    value={report.looker_report_guid}
                    onChange={e => handleOnStringPropertyChange(e.target.value, e.target.name)}
                  />
                  <FormErrorMessage>{errors.looker_report_guid}</FormErrorMessage>
                </FormControl>
              </Box>

              <Box>
                <FormControl isInvalid={!!errors.report_url}>
                  <FormLabel fontSize={'smaller'} mb="-0.2" htmlFor="report-url">
                    Url
                    <RedAsterisk />
                  </FormLabel>
                  <Input
                    id="report-url"
                    name="report_url"
                    value={report.report_url}
                    onChange={e => handleOnStringPropertyChange(e.target.value, e.target.name)}
                  />
                  <FormErrorMessage>{errors.report_url}</FormErrorMessage>
                </FormControl>
              </Box>

              <Box>
                <FormControl isInvalid={!!errors.report_desc}>
                  <FormLabel fontSize={'smaller'} mb="-0.2" htmlFor="report-desc">
                    Description
                    <RedAsterisk />
                  </FormLabel>
                  <Textarea
                    sx={{ height: '100px' }}
                    id="report-desc"
                    name="report_desc"
                    value={report.report_desc}
                    onChange={e => handleOnStringPropertyChange(e.target.value, e.target.name)}
                  />
                  <FormErrorMessage>{errors.report_desc}</FormErrorMessage>
                </FormControl>
              </Box>
            </VStack>

            <HorizontalLine marginTop={'3vh'} />
            <Box display="flex" justifyContent={'flex-end'}>
              <Box display="flex" gap={2}>
                <Button size="sm" onClick={handleCancel}>
                  Cancel
                </Button>

                <Button size="sm" colorScheme="brand.main" gap="2" px="5" type="submit">
                  Next
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default ReportDetails;
