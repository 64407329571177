import { Flex, IconButton, VStack } from '@chakra-ui/react';
import { Fragment } from 'react';
import { HiOutlineChevronDoubleLeft, HiOutlineChevronDoubleRight } from 'react-icons/hi';
import { useLocation } from 'react-router-dom';
import NavItem from './NavItem';
import navItems from './navItems';
// import AppAuth from "../../features/AppAuth";
import { useDispatch } from 'react-redux';
import { AppSize } from '../../app/constants';
import { setIsSideNavOpen } from '../../app/slices/appSlice';
import { useAppSelector } from '../../app/state/hooks';
import AppAuth from '../../features/AppAuth';
// import PageLoading from "../PageLoading";

type Props = {};

const SideNav = (props: Props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { hasMultiplePartnerProducts } = useAppSelector(s => s.user);
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const { isSideNavOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const { isSideNavOpen } = useAppSelector(s => s.app);

  return (
    <>
      {['/claims', '/unauthorized'].includes(location.pathname) ? null : (
        <Flex
          id="SideNav"
          direction="column"
          minH="100vh"
          px={2}
          py={0}
          overflowX="hidden"
          style={{
            WebkitTransition: 'width 100ms ease-in-out',
            MozTransition: 'width 100ms ease-in-out',
            OTransition: 'width 100ms ease-in-out',
            transition: 'width 100ms ease-in-out',
          }}
          w={isSideNavOpen ? AppSize.sideNav.width.open : AppSize.sideNav.width.close}
        >
          {isSideNavOpen ? (
            <IconButton
              maxW="30px"
              ml={isSideNavOpen ? 'auto' : undefined}
              mx={isSideNavOpen ? undefined : 'auto'}
              my="2"
              aria-label="Hide"
              fontSize="2xl"
              onClick={() => dispatch(setIsSideNavOpen(false))}
              icon={<HiOutlineChevronDoubleLeft />}
              color="black"
              bg="white"
            />
          ) : (
            <IconButton
              maxW="30px"
              ml={isSideNavOpen ? 'auto' : undefined}
              mx={isSideNavOpen ? undefined : 'auto'}
              my="2"
              aria-label="Show"
              fontSize="2xl"
              onClick={() => dispatch(setIsSideNavOpen(true))}
              icon={<HiOutlineChevronDoubleRight />}
              color="black"
              bg="white"
            />
          )}

          {/* <AuthenticatedUserWrapper onAuthenticated={() => setIsAuthenticated(true)}> */}
          {/* <Divider mt={1} /> */}
          <VStack gap="2">
            {navItems.map((m, i) => (
              <Fragment key={i}>
                {m.requiredAppAccess ? (
                  <AppAuth key={m.text} requiredAppAccess={m.requiredAppAccess}>
                    <NavItem item={m} isOpen={isSideNavOpen} />
                  </AppAuth>
                ) : (
                  m.text == 'Dashboard' && hasMultiplePartnerProducts && <NavItem item={m} isOpen={isSideNavOpen} />
                )}
              </Fragment>
            ))}
          </VStack>
          {/* </AuthenticatedUserWrapper> */}
        </Flex>
      )}
    </>
  );
};

export default SideNav;
