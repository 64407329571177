import { Box, Link, Text } from '@chakra-ui/react';

const UpdateIncorrectInfoBox: React.FC = () => {
  return (
    <Box bg="gray.100" rounded="md" p="4">
      <Text fontSize="sm" fontWeight="light">
        The information displayed here is associated with this NPI by CMS. If elements of this data are incorrect, visit{' '}
        <Link
          href="https://nppes.cms.hhs.gov/IAWeb/login.do"
          color="links"
          isExternal
          textDecorationLine="underline"
          wordBreak="break-all"
        >
          https://nppes.cms.hhs.gov/IAWeb/login.do
        </Link>{' '}
        to update the CMS entry.
      </Text>
    </Box>
  );
};

export default UpdateIncorrectInfoBox;
