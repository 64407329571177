import { Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Textarea, VStack } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { FC } from 'react';
import * as Yup from 'yup';
import { AddProductTypeStepProp, productTypeFields } from './helper';
import { AddRefProductTypeModel } from '../../../../../../app/services/partner/api/types';

const FormSchema = Yup.object().shape({
  product_type_name: Yup.string()
    .label(productTypeFields.product_type_name.label)
    .max(productTypeFields.product_type_name.max)
    .required(),
  product_type_desc: Yup.string()
    .label(productTypeFields.product_type_desc.label)
    .max(productTypeFields.product_type_desc.max)
    .nullable(),
});

const Step1: FC<AddProductTypeStepProp> = ({ data, activeStep, setData, setActiveStep }) => {
  const {
    handleSubmit,
    errors,
    touched,
    handleChange,
    values,
    setValues,
    resetForm,
    setFieldValue,
    setFieldTouched,
    submitForm,
  } = useFormik<AddRefProductTypeModel>({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: data,
    onSubmit: (values, form) => {
      setData(d => ({ ...d, ...values }));
      setActiveStep(i => i + 1);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <VStack className="da-container">
        <Box className="da-row">
          <FormControl isInvalid={!!errors.product_type_name && !!touched.product_type_name}>
            <FormLabel htmlFor="product_type_name">{productTypeFields.product_type_name.label}</FormLabel>
            <Box w="full">
              <Input
                size="sm"
                id="product_type_name"
                name="product_type_name"
                onChange={handleChange}
                onBlur={handleChange}
                value={values.product_type_name ?? ''}
                maxLength={productTypeFields.product_type_name.max}
                maxW="272px"
              />
              <FormErrorMessage>{errors.product_type_name}</FormErrorMessage>
            </Box>
          </FormControl>
        </Box>

        <Box className="da-row">
          <FormControl isInvalid={!!errors.product_type_desc && !!touched.product_type_desc}>
            <FormLabel htmlFor="product_type_desc">{productTypeFields.product_type_desc.label}</FormLabel>
            <Box w="full">
              <Textarea
                size="sm"
                id="product_type_desc"
                name="product_type_desc"
                onChange={handleChange}
                onBlur={handleChange}
                value={values.product_type_desc ?? ''}
                maxLength={productTypeFields.product_type_desc.max}
              />
              <FormErrorMessage>{errors.product_type_desc}</FormErrorMessage>
            </Box>
          </FormControl>
        </Box>
      </VStack>

      <Box w="100%" textAlign="right">
        <Button type="submit" colorScheme="brand.main">
          Next
        </Button>
      </Box>
    </form>
  );
};

export default Step1;
