import { Box, Spinner, Switch, Text, VStack, Button } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useGetInitialRolesByRolePrefixQuery } from '../../../app/services/partner/api/refRole';
import { useAddReportMutation } from '../../../app/services/partner/api/refReport';
import { AddReportModel } from '../../../app/services/partner/api/types';
import { useToast } from '@chakra-ui/react';

import { useAppSelector } from '../../../app/state/hooks';
import { PARTNER_PAGE } from '../../../app/constants/partnerPage';
import { PAGE_ACTIVITY } from '../../../app/constants/pageActivity';
import { useAddUserActivityMutation } from '../../../app/services/partner/api/userActivity';

interface RolesSelectionProps {
  report: AddReportModel;
  handleCancel: () => void;
}

const RolesSelection = ({ report, handleCancel }: RolesSelectionProps) => {
  const roles = useGetInitialRolesByRolePrefixQuery('report.hub');
  const [selectedRoles, setSelectedRoles] = useState<number[]>([]);
  const [localReport, setLocalReport] = useState<AddReportModel>(report);
  const [addReport, addReportDetails] = useAddReportMutation();
  const toast = useToast();

  const { selectedPartnerProduct, activityList, pageList, userSessionId } = useAppSelector(s => s.user);
  const [postAddUserActivity] = useAddUserActivityMutation();

  const report_hub_page = pageList.find(i => i.page_name === PARTNER_PAGE.REPORT_HUB);
  const report_created = activityList.find(i => i.activity_name === PAGE_ACTIVITY.REPORT_CREATED);
  const report_draft_cancelled = activityList.find(i => i.activity_name === PAGE_ACTIVITY.REPORT_DRAFT_CANCELLED);

  const handleLogEvent = () => {
    if (report_hub_page && report_created && userSessionId) {
      postAddUserActivity({
        user_session_id: userSessionId,
        ref_activity_id: report_created.ref_activity_id,
        ref_page_id: report_hub_page.ref_page_id,
        partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      })
        .unwrap()
        .then(res => {});
    }
  };

  const handleOnChange = (isChecked: boolean, id: string) => {
    console.log(`TOGGLED: ${isChecked} - ${id}`);
    const roleId = parseInt(id);

    if (isChecked) {
      setSelectedRoles(prevNumbers => [...prevNumbers, roleId]);
    } else {
      setSelectedRoles(prevNumbers => prevNumbers.filter(i => i !== roleId));
    }
  };

  useEffect(() => {
    console.log(`SELECTED ROLES: ${JSON.stringify(selectedRoles)}`);
    setLocalReport(prev => ({ ...prev, list_ref_role_id: selectedRoles }));
  }, [selectedRoles]);

  const handleCancelClicked = () => {
    if (report_hub_page && report_draft_cancelled && userSessionId) {
      postAddUserActivity({
        user_session_id: userSessionId,
        ref_activity_id: report_draft_cancelled.ref_activity_id,
        ref_page_id: report_hub_page.ref_page_id,
        partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      })
        .unwrap()
        .then(res => {});
    }
    handleCancel();
  };

  const handleSubmit = () => {
    let isException = false;

    if (localReport.list_ref_role_id.length > 0) {
      addReport(localReport)
        .unwrap()
        .then(res => {
          console.log(`REF_REPORT: ${JSON.stringify(res)}`);
          handleLogEvent();
        })
        .catch(error => {
          isException = true;
          toast({
            position: 'top-right',
            title: 'Error',
            description: 'Failed to add report',
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        })
        .finally(() => {
          if (!isException) {
            toast({
              position: 'top-right',
              title: 'Success!',
              description: 'Report added.',
              status: 'success',
              duration: 9000,
              isClosable: true,
            });
          }
          handleCancel();
        });
    } else {
      toast({
        position: 'top-right',
        title: 'No Roles Selected!',
        description: 'Please select at least one role',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <VStack>
        <Box mb="1">
          <Text fontWeight={'semibold'} fontSize={'smaller'} mb="-1">
            Roles Selection
          </Text>
        </Box>
        <Box mt="-2" sx={{ border: '1px solid #c2c2c2', borderRadius: '5px', height: '300px' }}>
          {roles.isFetching && roles.isLoading ? (
            <Box height={'inherit'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="lg" />
            </Box>
          ) : (
            (roles.data ?? []).map(r => (
              <Box key={r.ref_role_id} display="flex" justifyContent={'space-between'} my="10px" mx="15px">
                <Box>{r.role_display_name}</Box>
                <Box>
                  <Switch value={r.ref_role_id} onChange={e => handleOnChange(e.target.checked, e.target.value)} />
                </Box>
              </Box>
            ))
          )}
        </Box>
      </VStack>
      <Box display="flex" justifyContent={'flex-end'} mt="5">
        <Box display="flex" gap={2}>
          <Button size="sm" onClick={handleCancelClicked}>
            Cancel
          </Button>
          <Button size="sm" colorScheme="brand.main" gap="2" px="5" onClick={handleSubmit}>
            {addReportDetails.isLoading ? <Spinner size="sm" /> : 'Submit'}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default RolesSelection;
