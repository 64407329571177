import { Box, Button, FormControl, FormErrorMessage, FormLabel, VStack } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { ChangeEvent, FC, useState } from 'react';
import * as Yup from 'yup';
import { useGetAllRefPaymentTrackQuery } from '../../../../../../app/services/partner/api/refPaymentTrack';
import { AddPartnerModel } from '../../../../../../app/services/partner/api/types';
import CustomDatePicker from '../../../../../../components/CustomDatePicker';
import { PropsConfigs } from '../../../../../../components/CustomDatePicker/utils/commonTypes';
import {
  AddPartnerDateSchema,
  AddPartnerStepProp,
  getPaymentTrackLabel,
  maxDate,
  minDate,
  selectStyles,
} from '../../../helper';

const FormSchema = Yup.object().shape({
  enrollment_start_dt: AddPartnerDateSchema({ label: 'Enrollment Start' }),
  enrollment_end_dt: AddPartnerDateSchema({ label: 'Enrollment End' }),
  contract_start_dt: AddPartnerDateSchema({ label: 'Contract Start' }),
  contract_end_dt: AddPartnerDateSchema({ label: 'Contract End' }),
  contract_renewal_dt: AddPartnerDateSchema({ label: 'Contract Renewal' }),
  ref_payment_track_id: Yup.number().label('Payment Track').nullable().moreThan(0, 'Payment Track is a required field'),
});

const propsConfig: PropsConfigs = {
  dateNavBtnProps: {
    colorScheme: 'brand.main.default',
    variant: 'outline',
  },
  dayOfMonthBtnProps: {
    defaultBtnProps: {
      _hover: {
        background: 'brand.main.default',
        color: 'white',
      },
    },
    selectedBtnProps: {
      background: 'brand.main.default',
      color: 'white',
    },
    todayBtnProps: {
      background: 'gray.400',
    },
  },
};

const Step2: FC<AddPartnerStepProp> = ({ data, setData, setActiveStep }) => {
  const paymentTrackDetails = useGetAllRefPaymentTrackQuery();

  const [enrollmentStartDt, setEnrollmentStartDt] = useState<Date | undefined>();
  const [enrollmentEndDt, setEnrollmentEndDt] = useState<Date | undefined>();

  const [contractStartDt, setContractStartDt] = useState<Date | undefined>();
  const [contractEndDt, setContractEndDt] = useState<Date | undefined>();
  const [contractRenewalDt, setContractRenewalDt] = useState<Date | undefined>();

  const {
    handleSubmit,
    errors,
    touched,
    handleChange,
    values,
    setValues,
    resetForm,
    setFieldValue,
    setFieldTouched,
    submitForm,
  } = useFormik<AddPartnerModel>({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: data,
    onSubmit: (values, form) => {
      setData(d => ({ ...d, ...values }));
      setActiveStep(i => i + 1);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <VStack className="da-container">
        <Box className="da-row">
          <FormControl isInvalid={!!errors.enrollment_start_dt && !!touched.enrollment_start_dt}>
            <FormLabel htmlFor="enrollment_start_dt">Enrollment Start Date</FormLabel>
            <CustomDatePicker
              id="enrollment_start_dt"
              name="enrollment_start_dt"
              date={enrollmentStartDt}
              onDateChange={(date: Date) => {
                setValues({
                  ...values,
                  enrollment_start_dt: dayjs(date).format('YYYY-MM-DD'),
                });
                setEnrollmentStartDt(date);
              }}
              minDate={minDate}
              maxDate={maxDate}
              propsConfigs={{
                ...propsConfig,
                inputProps: {
                  placeholder: '',
                  size: 'sm',
                  value: values.enrollment_start_dt ?? undefined,
                  onChange: (e: ChangeEvent<HTMLInputElement>) => {
                    setValues({
                      ...values,
                      enrollment_start_dt: e.target.value,
                    });
                    if (dayjs(e.target.value)?.isValid()) {
                      setEnrollmentStartDt(dayjs(e.target.value).toDate());
                    }
                  },
                },
              }}
            />
            <FormErrorMessage>{errors.enrollment_start_dt}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.enrollment_end_dt && !!touched.enrollment_end_dt}>
            <FormLabel htmlFor="enrollment_end_dt">Enrollment End Date</FormLabel>
            <CustomDatePicker
              id="enrollment_end_dt"
              name="enrollment_end_dt"
              date={enrollmentEndDt}
              onDateChange={(date: Date) => {
                setValues({
                  ...values,
                  enrollment_end_dt: dayjs(date).format('YYYY-MM-DD'),
                });
                setEnrollmentEndDt(date);
              }}
              minDate={minDate}
              maxDate={maxDate}
              propsConfigs={{
                ...propsConfig,
                inputProps: {
                  placeholder: '',
                  size: 'sm',
                  value: values.enrollment_end_dt ?? undefined,
                  onChange: (e: ChangeEvent<HTMLInputElement>) => {
                    setValues({
                      ...values,
                      enrollment_end_dt: e.target.value,
                    });
                    if (dayjs(e.target.value)?.isValid()) {
                      setEnrollmentEndDt(dayjs(e.target.value).toDate());
                    }
                  },
                },
              }}
            />
            <FormErrorMessage>{errors.enrollment_end_dt}</FormErrorMessage>
          </FormControl>
        </Box>

        <Box className="da-row">
          <FormControl isInvalid={!!errors.contract_start_dt && !!touched.contract_start_dt}>
            <FormLabel htmlFor="contract_start_dt">Contract Start Date</FormLabel>
            <CustomDatePicker
              id="contract_start_dt"
              name="contract_start_dt"
              date={contractStartDt}
              onDateChange={(date: Date) => {
                setValues({
                  ...values,
                  contract_start_dt: dayjs(date).format('YYYY-MM-DD'),
                });
                setContractStartDt(date);
              }}
              minDate={minDate}
              maxDate={maxDate}
              propsConfigs={{
                ...propsConfig,
                inputProps: {
                  placeholder: '',
                  size: 'sm',
                  value: values.contract_start_dt ?? undefined,
                  onChange: (e: ChangeEvent<HTMLInputElement>) => {
                    setValues({
                      ...values,
                      contract_start_dt: e.target.value,
                    });
                    if (dayjs(e.target.value)?.isValid()) {
                      setContractStartDt(dayjs(e.target.value).toDate());
                    }
                  },
                },
              }}
            />
            <FormErrorMessage>{errors.contract_start_dt}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.contract_end_dt && !!touched.contract_end_dt}>
            <FormLabel htmlFor="contract_end_dt">Contract End Date</FormLabel>
            <CustomDatePicker
              id="contract_end_dt"
              name="contract_end_dt"
              date={contractEndDt}
              onDateChange={(date: Date) => {
                setValues({
                  ...values,
                  contract_end_dt: dayjs(date).format('YYYY-MM-DD'),
                });
                setContractEndDt(date);
              }}
              minDate={minDate}
              maxDate={maxDate}
              propsConfigs={{
                ...propsConfig,
                inputProps: {
                  placeholder: '',
                  size: 'sm',
                  value: values.contract_end_dt ?? undefined,
                  onChange: (e: ChangeEvent<HTMLInputElement>) => {
                    setValues({
                      ...values,
                      contract_end_dt: e.target.value,
                    });
                    if (dayjs(e.target.value)?.isValid()) {
                      setContractEndDt(dayjs(e.target.value).toDate());
                    }
                  },
                },
              }}
            />
            <FormErrorMessage>{errors.contract_end_dt}</FormErrorMessage>
          </FormControl>
        </Box>

        <Box className="da-row">
          <FormControl isInvalid={!!errors.contract_renewal_dt && !!touched.contract_renewal_dt}>
            <FormLabel htmlFor="contract_renewal_dt">Contract Renewal Date</FormLabel>
            <CustomDatePicker
              id="contract_renewal_dt"
              name="contract_renewal_dt"
              date={contractRenewalDt}
              onDateChange={(date: Date) => {
                setValues({
                  ...values,
                  contract_renewal_dt: dayjs(date).format('YYYY-MM-DD'),
                });
                setContractRenewalDt(date);
              }}
              minDate={minDate}
              maxDate={maxDate}
              propsConfigs={{
                ...propsConfig,
                inputProps: {
                  placeholder: '',
                  size: 'sm',
                  value: values.contract_renewal_dt ?? undefined,
                  onChange: (e: ChangeEvent<HTMLInputElement>) => {
                    setValues({
                      ...values,
                      contract_renewal_dt: e.target.value,
                    });
                    if (dayjs(e.target.value)?.isValid()) {
                      setContractRenewalDt(dayjs(e.target.value).toDate());
                    }
                  },
                },
              }}
            />
            <FormErrorMessage>{errors.contract_renewal_dt}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.ref_payment_track_id && touched.ref_payment_track_id}>
            <FormLabel htmlFor="ref_payment_track_id">Payment Track</FormLabel>
            <Box w="100%">
              <Select
                size="sm"
                id="ref_payment_track_id"
                name="ref_payment_track_id"
                placeholder={paymentTrackDetails.isLoading || paymentTrackDetails.isFetching ? 'Loading...' : ''}
                isDisabled={paymentTrackDetails.isLoading || paymentTrackDetails.isFetching}
                useBasicStyles
                value={(() => {
                  const val = paymentTrackDetails.data?.find(
                    m => m.ref_payment_track_id === values.ref_payment_track_id,
                  );
                  return val
                    ? {
                        label: getPaymentTrackLabel(val),
                        value: val.ref_payment_track_id,
                      }
                    : undefined;
                })()}
                options={[
                  ...(paymentTrackDetails.data ?? []).map(m => {
                    return {
                      label: getPaymentTrackLabel(m),
                      value: m.ref_payment_track_id,
                    };
                  }),
                ]}
                onChange={e => {
                  e &&
                    setValues({
                      ...values,
                      ref_payment_track_id: e.value,
                    });
                }}
                chakraStyles={selectStyles<number>()}
              />
              <FormErrorMessage>{errors.ref_payment_track_id}</FormErrorMessage>
            </Box>
          </FormControl>
        </Box>
      </VStack>

      <Box w="100%" display="flex">
        <Button onClick={() => setActiveStep(i => i - 1)} colorScheme="brand.main">
          Back
        </Button>
        <Button type="submit" colorScheme="brand.main" ml="auto">
          Next
        </Button>
      </Box>
    </form>
  );
};

export default Step2;
