import { MyRolesFilteredSearchResultsModel } from '../../../../../app/services/partner/api/types';
import { Button } from '@chakra-ui/button';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { useAppSelector } from '../../../../../app/state/hooks';

const MyRolesActionViewDetailsCell = ({ original }: { original: MyRolesFilteredSearchResultsModel }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { logonUser } = useAppSelector(i => i.user);

  const handleClick = () => {
    if (original.user_role_access_request_id) {
      if (original.requestor_ref_user_id == logonUser?.ref_user_id) {
        navigate(`/roles/my-roles?requestId=${original.user_role_access_request_id}`);
      }
    } else {
      toast({
        position: 'top-right',
        title: 'No Request Found',
        description: 'There is no associated request for this role.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Button
        size="sm"
        _hover={{ textDecoration: 'none' }}
        variant="link"
        alignSelf={'start'}
        fontWeight="normal"
        color="black"
        onClick={handleClick}
      >
        View Details
      </Button>
    </>
  );
};

export default MyRolesActionViewDetailsCell;
