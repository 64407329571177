import { Box, VStack, Text, Tabs, TabList, TabPanel, TabPanels, Tab, HStack } from '@chakra-ui/react';
import RoleDetails from './Tabs/RoleDetails';
import RoleAppAccessDetails from './Tabs/RoleAppAccessDetails';
import { useAppSelector } from '../../../app/state/hooks';
import { useEffect } from 'react';

import { PARTNER_PAGE } from '../../../app/constants/partnerPage';
import { PAGE_ACTIVITY } from '../../../app/constants/pageActivity';
import { useAddUserActivityMutation } from '../../../app/services/partner/api/userActivity';

import { useState } from 'react';
import AddAppAccessButton from './AddAppAccessButton';

const CreateRole = () => {
  const [rolesUpdatedTime, setRolesUpdatedTime] = useState<string>(new Date().toDateString());
  const [appAccessesUpdatedTime, setAppAccessesUpdatedTime] = useState<string>(new Date().toDateString());
  const [index, setIndex] = useState(0);

  const { selectedPartnerProduct, activityList, pageList, userSessionId } = useAppSelector(s => s.user);
  const [postAddUserActivity] = useAddUserActivityMutation();

  const create_role_page = pageList.find(i => i.page_name === PARTNER_PAGE.CREATE_ROLE);
  const create_role_clicked = activityList.find(i => i.activity_name === PAGE_ACTIVITY.CREATE_ROLE_CLICKED);

  const handleUpdatedRoles = () => {
    setRolesUpdatedTime(prev => new Date().toDateString());
  };

  const handleUpdatedAppAccesses = () => {
    setAppAccessesUpdatedTime(prev => new Date().toDateString());
  };

  useEffect(() => {
    if (create_role_page && create_role_clicked && userSessionId) {
      postAddUserActivity({
        user_session_id: userSessionId,
        ref_activity_id: create_role_clicked.ref_activity_id,
        ref_page_id: create_role_page.ref_page_id,
        partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      })
        .unwrap()
        .then(res => {});
    }
  }, []);

  return (
    <>
      <VStack>
        <Box pb={'5'}>
          <Text sx={{ fontWeight: 'bold', fontSize: '25' }}>Create Role</Text>
        </Box>

        <Box bg="white" shadow="base" p="4" rounded="md" minH="20vh" minW="container.md">
          <Tabs tabIndex={index}>
            <HStack display={'flex'} justifyContent={'space-between'}>
              <TabList width="200px">
                <Tab onClick={() => setIndex(0)}>Details</Tab>
                <Tab onClick={() => setIndex(1)}>App Access</Tab>
              </TabList>
              <Box pr="2">{index === 1 ? <AddAppAccessButton /> : <></>}</Box>
            </HStack>

            <TabPanels>
              <TabPanel>
                <Box>
                  <RoleDetails handleUpdatedRoles={handleUpdatedRoles} />
                </Box>
              </TabPanel>

              <TabPanel>
                <Box>
                  <RoleAppAccessDetails
                    appAccessesUpdatedTime={appAccessesUpdatedTime}
                    rolesUpdatedTime={rolesUpdatedTime}
                  />
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </VStack>
    </>
  );
};

export default CreateRole;
