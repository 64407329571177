import { useLazyGetUserRoleAccessRequestStatusListQuery } from '../../../../../app/services/partner/api/userRoleAccessRequest';
import { UserRoleAccessRequestStatusDisplayModel } from '../../../../../app/services/partner/api/types';
import { Box, VStack, HStack, Spinner, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import StatusDetails from './StatusDetails';
import { useAppSelector } from '../../../../../app/state/hooks';

type StatusTabProps = {
  requestId: number;
  latestStatus: string;
};

const RoleStatusList = ({ requestId, latestStatus }: StatusTabProps) => {
  const [statuses, setStatuses] = useState<UserRoleAccessRequestStatusDisplayModel[]>([]);
  const [getStatuses, geetStatusesDetails] = useLazyGetUserRoleAccessRequestStatusListQuery();

  const { logonUser } = useAppSelector(i => i.user);

  useEffect(() => {
    getStatuses(requestId)
      .unwrap()
      .then(res => setStatuses(res))
      .catch(error => console.log(`Error: ${JSON.stringify(error)}`));
  }, [requestId]);

  return (
    <>
      <VStack>
        <Box pb={'3'}>
          <HStack>
            <Text sx={{ fontWeight: 'bold', fontSize: '20' }}>Status:</Text>
            <Text sx={{ fontSize: '20' }}>{latestStatus}</Text>
          </HStack>

          <VStack pt={5} pl={10}>
            {statuses && statuses.filter(x => x.is_show).map((s, i) => <StatusDetails key={i} status={s} />)}
          </VStack>
        </Box>
        <Box></Box>
      </VStack>
    </>
  );
};

export default RoleStatusList;
