import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Input,
  Skeleton,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { GoPencil } from 'react-icons/go';
import { MdOutlineSave } from 'react-icons/md';
import { Row } from 'react-table';
import { RefDemographic, UserNameFormSchema } from '..';
import { defaultErrorMessage } from '../../../app/constants';
import { getName } from '../../../app/helpers/stringHelper';
import { RefUserModel } from '../../../app/services/partner/api/types';
import { usePutUserDemographicByUserMutation } from '../../../app/services/partner/api/userDemographic';
import { useUserContext } from '../Context';

const DetailsCell = ({ row: { original, index } }: { row: Row<RefUserModel> }) => {
  const { setSelectedUser } = useUserContext();

  return (
    <Box w="100%" bg="white">
      <Button
        variant="link"
        fontSize="sm"
        fontWeight="normal"
        color="#0A7AFF"
        onClick={() => setSelectedUser(original)}
      >
        View
      </Button>
    </Box>
  );
};

export default DetailsCell;
