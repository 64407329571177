import { Box, Heading, Tab, TabList, TabPanels, Tabs, VStack } from '@chakra-ui/react';
import { createElement, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TestEligibleParticipantAdd from './Add';
import TestEligibleParticipantUpdateDisable from './UpdateDisable';

const tabs: {
  label: string;
  path: string;
  component: ({ data }: any) => JSX.Element;
}[] = [
  {
    label: 'Add',
    path: 'add',
    component: TestEligibleParticipantAdd,
  },
  {
    label: 'Update/Disable',
    path: 'update-disable',
    component: TestEligibleParticipantUpdateDisable,
  },
];

const TestEligibleParticipant = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState<number | undefined>();

  useEffect(() => {
    if (tabIndex !== undefined && params.tab !== tabs[tabIndex].path) {
      navigate('./../' + tabs[tabIndex].path);
    }
  }, [tabIndex]);

  useEffect(() => {
    const paramTab = params.tab;

    if (paramTab !== undefined) {
      const pathIndex = tabs.findIndex(f => f.path === paramTab);
      pathIndex !== tabIndex && setTabIndex(pathIndex);
    }
  }, [params]);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  return (
    <VStack gap="8">
      <Heading size="md">Manage Test Accounts Associated with a Partner Product</Heading>

      <Box bg="white" borderWidth="thin" p="4" rounded="md" minW="container.md">
        {tabIndex !== undefined && (
          <Tabs index={tabIndex} onChange={handleTabsChange}>
            <TabList>
              {tabs.map((m, i) => (
                <Tab key={i}>{m.label}</Tab>
              ))}
            </TabList>
            <TabPanels>
              <Box p={3}>{tabs[tabIndex] && createElement(tabs[tabIndex].component)}</Box>
            </TabPanels>
          </Tabs>
        )}
      </Box>
    </VStack>
  );
};

export default TestEligibleParticipant;
