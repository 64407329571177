export function arrayMove(arr: any[], oldIndex: number, newIndex: number) {
  while (oldIndex < 0) {
    oldIndex += arr.length;
  }
  while (newIndex < 0) {
    newIndex += arr.length;
  }
  if (newIndex >= arr.length) {
    var k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr; // for testing purposes
}

/**
 * Example: generateArray(10); //=> [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
 */
export function generateArray(length: number) {
  return Array.from(Array(length).keys());
}
