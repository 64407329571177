import { FC, ReactNode, createContext, useContext, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PartnerModel } from '../../app/services/partner/api/types';
import { useGetPartnerListQuery } from '../../app/services/partner/api/partner';
import { PaginatedApiResult, SortType } from '../../app/services/types';
import { useAppSelector } from '../../app/state/hooks';
import { useAppAccess } from '../../app/hooks/useAppAccess';

type Props = {
  children: ReactNode;
};

export const PartnerInitialSortBy: SortType<PartnerModel> = {
  id: 'partner_name',
  desc: false,
};

type PartnerContextModel = {
  pageNumber: number;
  pageSize: number;
  sortBy: typeof PartnerInitialSortBy;
  selectedPartner: PartnerModel | null;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  setSortBy: React.Dispatch<React.SetStateAction<typeof PartnerInitialSortBy>>;
  setSelectedPartner: React.Dispatch<React.SetStateAction<PartnerModel | null>>;
  isLoading: boolean;
  isFetching: boolean;
  data?: PaginatedApiResult<PartnerModel>;
};

const PartnerContext = createContext<PartnerContextModel>({
  pageNumber: 1,
  pageSize: 10,
  sortBy: PartnerInitialSortBy,
  selectedPartner: null,
  setPageNumber: () => {},
  setPageSize: () => {},
  setSortBy: () => {},
  setSelectedPartner: () => {},
  isLoading: false,
  isFetching: false,
});

const PartnerContextProvider: FC<Props> = ({ children }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState<typeof PartnerInitialSortBy>(PartnerInitialSortBy);
  const [selectedPartner, setSelectedPartner] = useState<PartnerModel | null>(null);
  const { isLoreHealthAdmin, selectedPartnerProduct } = useAppSelector(s => s.user);

  const [searchParams, setSearchParams] = useSearchParams();

  const partnerProductId = selectedPartnerProduct?.partner_product_id || 0;

  const { isLoading, isFetching, data } = useGetPartnerListQuery({
    page_number: pageNumber,
    page_size: pageSize,
    search_string: searchParams.get('spartner') ?? '',
    sort_column: sortBy.id,
    sort_order: sortBy.desc ? 'desc' : 'asc',
    partner_product_id: isLoreHealthAdmin ? 0 : partnerProductId,
  });

  return (
    <PartnerContext.Provider
      value={{
        pageNumber,
        pageSize,
        sortBy,
        selectedPartner,
        setPageNumber,
        setPageSize,
        setSortBy,
        setSelectedPartner,
        isLoading,
        isFetching,
        data,
      }}
    >
      {children}
    </PartnerContext.Provider>
  );
};

export const usePartnerContext = () => {
  const context = useContext(PartnerContext);
  if (!context) throw new Error('PartnerContext not found');
  return context;
};

export default PartnerContextProvider;
