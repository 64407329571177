export type ValueOf<T> = T[keyof T];

export const debounce = (cb: Function, delay = 250) => {
  let timeout: NodeJS.Timeout;

  return (...args: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      cb(...args);
    }, delay);
  };
};

export function debounceLeading(cb: Function, timeout = 300) {
  let timer: NodeJS.Timeout | undefined;
  return function (this: unknown, ...args: any) {
    if (!timer) {
      cb.apply(this, args);
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = undefined;
    }, timeout);
  };
}

export const ensureSafeDecodeUri = (value: any) => {
  try {
    return decodeURIComponent(value);
  } catch (error) {
    return value;
  }
};

// from: https://stackoverflow.com/a/28361560
export function getScrollbarWidth() {
  // Create the div
  const scrollDiv = document.createElement('div');
  scrollDiv.className = 'scrollbar-measure';
  document.body.appendChild(scrollDiv);

  // Get the scrollbar width
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

  // Delete the div
  document.body.removeChild(scrollDiv);

  return scrollbarWidth;
}
