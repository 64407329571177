import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { BsPencil } from 'react-icons/bs';
import { CellProps } from 'react-table';
import * as Yup from 'yup';
import { defaultErrorMessage } from '../../../app/constants';
import { AppAccess } from '../../../app/constants/appAccesses';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import { useAppAccess } from '../../../app/hooks/useAppAccess';
import { useLazyGetRefRoleByPkQuery, useUpdateRefRoleByPkMutation } from '../../../app/services/partner/api/refRole';
import { RefRoleModel } from '../../../app/services/partner/api/types';
import { useLazyGetUserListByRoleIdQuery } from '../../../app/services/partner/api/userRole';
import AppAuth from '../../../features/AppAuth';
import HorizontalLine from '../../HorizontalLine';
import { useAppSelector } from '../../../app/state/hooks';

const FormSchema = Yup.object().shape({
  role_desc: Yup.string().label('Description').max(2000).required(),
});
const ExploreRolesDetailsCell = ({ row: { original } }: CellProps<RefRoleModel>) => {
  const [data, setData] = useState(original);
  const [isEdit, setIsEdit] = useState(false);

  const { isOpen, onClose, onOpen } = useDisclosure();
  const { selectedPartnerProduct } = useAppSelector(s => s.user);
  const toast = useToast();
  const { isUserAdmin } = useAppAccess();

  const [getUsers, getUsersDetails] = useLazyGetUserListByRoleIdQuery();
  const [getRole, getRoleDetails] = useLazyGetRefRoleByPkQuery();
  const [updateRole, updateRoleDetails] = useUpdateRefRoleByPkMutation();

  useEffect(() => {
    if (isOpen) {
      isUserAdmin &&
        getUsers({
          ref_role_id: original.ref_role_id,
          partner_product_id: selectedPartnerProduct?.partner_product_id ?? 0,
        });
      setIsEdit(false);
    }
  }, [isOpen]);

  const { handleSubmit, errors, touched, handleChange, values, resetForm } = useFormik<{ role_desc: string }>({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: {
      role_desc: data.role_desc || '',
    },
    onSubmit: async (values, form) => {
      try {
        await updateRole({
          id: data.ref_role_id,
          role_name: data.role_name,
          role_display_name: data.role_display_name,
          disabled_flag: !!data.disabled_flag,
          role_desc: values.role_desc,
        }).unwrap();
        const refRole = await getRole(data.ref_role_id).unwrap();
        setData(d => ({ ...d, role_desc: refRole.role_desc }));

        toast({ description: `Successfully updated role`, status: 'success' });
        form.resetForm();
      } catch (error) {
        toast({ description: defaultErrorMessage, status: 'error' });
      }
    },
  });

  const isLoading = getRoleDetails.isLoading || getRoleDetails.isFetching || updateRoleDetails.isLoading;

  return (
    <>
      <Button variant="link" color="links" fontWeight="normal" size="sm" onClick={onOpen}>
        View
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="3xl" closeOnOverlayClick={false} isCentered>
        <ModalOverlay />

        <ModalContent sx={{ height: isEdit ? '68%' : '63%' }}>
          <ModalHeader fontSize="medium">Role Details</ModalHeader>

          <ModalCloseButton isDisabled={isLoading} />

          <ModalBody pb="1">
            <VStack display="flex" gap="1" color="blackAlpha.800">
              <HStack justifyContent="space-between" color="blackAlpha.800">
                <Heading size="md">{original.role_display_name}</Heading>
                <Heading size="md">
                  {'Date updated ' + convertUtcToLocal(original.row_modified_datetime_utc, 'MM-D-YYYY') || '-'}
                </Heading>
              </HStack>

              <FormControl isInvalid={!!errors.role_desc && touched.role_desc}>
                <FormLabel
                  color="blackAlpha.700"
                  size="sm"
                  minW="150px"
                  htmlFor="role_desc"
                  m="0"
                  display="flex"
                  gap="4"
                >
                  Description
                  {!isEdit && (
                    <AppAuth requiredAppAccess={AppAccess.RolesWrite}>
                      <Button
                        variant="link"
                        size="sm"
                        colorScheme="brand.main"
                        leftIcon={<BsPencil />}
                        onClick={() => {
                          setIsEdit(true);
                          resetForm();
                        }}
                      >
                        Edit
                      </Button>
                    </AppAuth>
                  )}
                </FormLabel>
                <Box w="full" color="blackAlpha.600">
                  {isEdit ? (
                    <>
                      <Textarea
                        size="sm"
                        id="role_desc"
                        name="role_desc"
                        onChange={handleChange}
                        onBlur={handleChange}
                        value={values.role_desc ?? ''}
                        maxLength={2000}
                      />
                      <FormErrorMessage>{errors.role_desc}</FormErrorMessage>
                    </>
                  ) : (
                    <Text fontSize="sm">{data.role_desc}</Text>
                  )}
                </Box>
              </FormControl>

              {isUserAdmin && (
                <Box>
                  <FormLabel color="blackAlpha.700" fontSize="sm" minW="150px" htmlFor="role_desc" m="0">
                    Users
                  </FormLabel>
                  <TableContainer
                    sx={{
                      th: {
                        color: 'blackAlpha.700',
                        textTransform: 'initial',
                        fontSize: '16px',
                        letterSpacing: '-0.5px',
                      },
                      td: {
                        fontSize: '14px',
                      },
                      height: '300px',
                      overflowY: 'scroll',
                    }}
                  >
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>User Name</Th>
                          <Th>Date Assigned</Th>
                          <Th>Assigned By</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {getUsersDetails.isLoading || getUsersDetails.isFetching ? (
                          <Tr>
                            <Td colSpan={3}>
                              <Spinner color="brand.main.default" />
                            </Td>
                          </Tr>
                        ) : !getUsersDetails.data?.length ? (
                          <Tr>
                            <Td colSpan={3} bg="red.100">
                              No Users
                            </Td>
                          </Tr>
                        ) : (
                          getUsersDetails.data?.map((u, i) => (
                            <Tr key={i}>
                              <Td>{u.assignee_user_name}</Td>
                              <Td>{convertUtcToLocal(original.row_created_datetime_utc, 'MM-D-YYYY') || '-'}</Td>
                              <Td>{u.row_created_by_user_name}</Td>
                            </Tr>
                          ))
                        )}
                      </Tbody>
                    </Table>
                  </TableContainer>
                  <HorizontalLine marginTop="0" />
                </Box>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter gap={3}>
            {isEdit ? (
              <>
                <Button
                  onClick={() => {
                    setIsEdit(false);
                    resetForm();
                  }}
                  ml="auto"
                  variant="outline"
                  colorScheme="brand.main"
                  size="sm"
                  isDisabled={isLoading}
                >
                  Cancel
                </Button>
                <Button size="sm" colorScheme="brand.main" isLoading={isLoading} onClick={() => handleSubmit()}>
                  Save Changes
                </Button>
              </>
            ) : (
              <>
                <Button size="sm" colorScheme="brand.main" isLoading={isLoading} onClick={() => onClose()}>
                  Done
                </Button>
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ExploreRolesDetailsCell;
