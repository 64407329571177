import { SearchFilterModel, MyRolesFilteredSearchParamsModel, MyRolesFilteredSearchResultsModel } from './types';
import { partnerApi } from '.';

const baseUrl = '/my-roles';

const extendedApi = partnerApi.injectEndpoints({
  endpoints: build => ({
    getMyRolesSearchFilter: build.query<SearchFilterModel, number>({
      query: (refUserId: number) => ({
        url: baseUrl + `/filters/${refUserId}`,
      }),
      providesTags: ['MyRoles'],
    }),

    getMyRolesFilteredSearchResults: build.query<MyRolesFilteredSearchResultsModel[], MyRolesFilteredSearchParamsModel>(
      {
        query: ({ role_ids, status_ids, ref_user_id }: MyRolesFilteredSearchParamsModel) => {
          let statusParams: string = '';
          if (status_ids.length < 1) {
            statusParams = 'status_ids=[]';
          } else if (status_ids.length === 1) {
            statusParams = `status_ids=${status_ids[0]}`;
          } else {
            let appendStatus: string = '';
            status_ids.forEach((f, i) => {
              if (i === 0) {
                appendStatus = `status_ids=${f}`;
              } else {
                appendStatus = `${appendStatus}&status_ids=${f}`;
              }
            });
            statusParams = appendStatus;
          }

          let rolesParams: string = '';
          if (role_ids.length < 1) {
            rolesParams = 'role_ids=[]';
          } else if (role_ids.length === 1) {
            rolesParams = `role_ids=${role_ids[0]}`;
          } else {
            let appendStatus: string = '';
            role_ids.forEach((f, i) => {
              if (i === 0) {
                appendStatus = `role_ids=${f}`;
              } else {
                appendStatus = `${appendStatus}&role_ids=${f}`;
              }
            });
            rolesParams = appendStatus;
          }

          const sample = `/filtered/search?ref_user_id=${ref_user_id}&${rolesParams}&${statusParams}`;
          console.log(`SAMPLE ROUTE: ${sample}`);

          return {
            url: `${baseUrl}/filtered/search?ref_user_id=${ref_user_id}&${rolesParams}&${statusParams}`,
          };
        },

        providesTags: ['MyRoles'],
      },
    ),
  }),
});

export const {
  useGetMyRolesSearchFilterQuery,
  useLazyGetMyRolesSearchFilterQuery,
  useGetMyRolesFilteredSearchResultsQuery,
  useLazyGetMyRolesFilteredSearchResultsQuery,
} = extendedApi;
