import { Box, Button, Checkbox, Divider, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { HiChevronDown, HiChevronRight } from 'react-icons/hi';
import { useSearchParams } from 'react-router-dom';
import { FilterDataDetailModel, SearchFilterModel } from '../../../../app/services/partner/api/types';
import { useLazyGetMyRolesSearchFilterQuery } from '../../../../app/services/partner/api/myRoles';
import { useAppSelector } from '../../../../app/state/hooks';

type IProps = {
  onFiltersChange: (filters: FilterDataDetailModel[]) => void;
};

const categoryFilter = 'Role';
const defaultFilter: string[] = [];

const FilterPane: FC<IProps> = ({ onFiltersChange }: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [toggledFilterGroups, setToggledFilterGroups] = useState<string[]>(defaultFilter);
  const [checkedFilters, setCheckedFilters] = useState<FilterDataDetailModel[] | undefined>();
  const [data, setData] = useState<SearchFilterModel | undefined>(undefined);
  const { logonUser } = useAppSelector(s => s.user);
  const [get, getDetails] = useLazyGetMyRolesSearchFilterQuery();

  useEffect(() => {
    get(logonUser?.ref_user_id ?? 3)
      .unwrap()
      .then(res => {
        setData(res);
        console.log(`DAATAA: ${JSON.stringify(res)}`);
      })
      .catch(error => console.log(`ERROR: ${JSON.stringify(error)}`));
  }, [logonUser?.ref_user_id]);

  useEffect(() => {
    if (checkedFilters) {
      onFiltersChange(checkedFilters);

      setSearchParams({
        ...checkedFilters.reduce((obj, val) => {
          const key = val.filter_header.toLowerCase();
          if (obj[key]) {
            obj[key] = obj[key] + ',' + val.display_name;
          } else {
            obj[key] = val.display_name;
          }
          return obj;
        }, {} as any),
        ...(searchParams.get('search') ? { search: searchParams.get('search') } : {}),
      });
    }
  }, [checkedFilters]);

  useEffect(() => {
    //if there are initial filter coming from url, then auto check filters based on url
    const filters: { filter_header: string; display_name: string[] }[] = [];
    searchParams.forEach((value, key) => {
      filters.push({
        filter_header: key,
        display_name: [...decodeURIComponent(value).split(',')],
      });
    });
    if (filters.length > 0 && data) {
      const initFilters: FilterDataDetailModel[] = [];
      const sourceFilters: FilterDataDetailModel[] = [];

      filters.forEach(filter => {
        filter.display_name.forEach((display_name: string) => {
          data?.filters.forEach(i => {
            const item = i.details.find(f => f.display_name === display_name);
            if (item) {
              initFilters.push(item);
            }
          });
        });
      });
      setCheckedFilters(
        initFilters.map(({ display_name, id }) => ({
          filter_header: categoryFilter,
          display_name,
          id,
        })),
      );

      setToggledFilterGroups(
        initFilters.length > 0 ? initFilters.map(m => m.filter_header.toLowerCase()) : defaultFilter,
      );
    }
  }, [data]);

  const onFilterGroupClick = (filterGroupName: string) => {
    if (toggledFilterGroups.includes(filterGroupName)) {
      setToggledFilterGroups(s => s.filter(f => f.toLowerCase() !== filterGroupName));
    } else {
      setToggledFilterGroups(s => [...s, filterGroupName]);
    }
  };

  const isFilterGroupSelected = (filterGroupName: string) =>
    toggledFilterGroups.includes(filterGroupName.toLowerCase());

  const updateCheckedFilters = (obj: FilterDataDetailModel, isChecked: boolean) => {
    if (isChecked) {
      setCheckedFilters(s => [...(s || []), obj]);
    } else {
      setCheckedFilters(s => s?.filter(f => f.display_name !== obj.display_name));
    }
  };

  return (
    <VStack w="100%" h="100vh" overflowY="scroll" maxW={300} bgColor="gray.50" p={3} pt={2}>
      <HStack justifyContent="space-between">
        <Heading size="md">Filters</Heading>
        <Button
          size="sm"
          onClick={() => {
            setCheckedFilters([]);
            setSearchParams(searchParams.get('search') ? { search: searchParams.get('search') ?? '' } : {}, {
              replace: true,
            });
          }}
        >
          Clear
        </Button>
      </HStack>
      <Divider />
      <VStack>
        <>
          {/* {filterData.length > 0 ? (    
            filterData.map(({ filter_header, filter_items }, i) => ( */}
          {getDetails.isLoading ? (
            <>Loading...</>
          ) : (
            data?.filters.map((section, i) => (
              <Box key={i}>
                <Button
                  key={i}
                  w="100%"
                  rightIcon={
                    isFilterGroupSelected(section.category_filter.toLowerCase()) ? (
                      <HiChevronDown />
                    ) : (
                      <HiChevronRight />
                    )
                  }
                  justifyContent="space-between"
                  size="sm"
                  onClick={() => {
                    onFilterGroupClick(section.category_filter.toLowerCase());
                  }}
                >
                  {section.category_filter}
                </Button>
                {/* <>{section?.details && section?.details.map(churro => <Box>{churro.display_name}</Box>)}</> */}
                {isFilterGroupSelected(section.category_filter) &&
                  section?.details &&
                  section.details.map(({ display_name, id, filter_header }, i) => (
                    <VStack key={i} bgColor="gray.100" p={3} pt={0}>
                      <Checkbox
                        name={`${display_name}`.replace(/\s/g, '')}
                        onChange={(e: any) => {
                          updateCheckedFilters(
                            {
                              id,
                              display_name,
                              filter_header: filter_header.toLowerCase(),
                            },
                            e.target.checked,
                          );
                        }}
                        isChecked={checkedFilters?.some(s => s.display_name === display_name)}
                        _hover={{ bgColor: 'gray.200' }}
                      >
                        <Text whiteSpace="initial" fontSize="sm" noOfLines={2} title={display_name} maxW="210px">
                          {display_name}
                        </Text>
                      </Checkbox>
                    </VStack>
                  ))}
              </Box>
            ))
          )}
        </>
      </VStack>
    </VStack>
  );
};

export default FilterPane;
