/* eslint-disable react-hooks/exhaustive-deps */
import { useAccount } from '@azure/msal-react';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppAuthRequiredAppAccess } from '../../app/constants/appAccesses';
import { checkUserHasRequiredAppAccess } from '../../app/hooks/useIsUserHasRequiredRoles';
import { getIdTokenClaims } from '../../app/services/auth/helper';
import { useGetAppAccessByEmailQuery } from '../../app/services/partner/api/userRole';
import { setCurrentUserUserRole } from '../../app/slices/userRoleSlice';
import { useAppSelector } from '../../app/state/hooks';
import { setIsLoreHealthAdmin } from '../../app/slices/userSlice';

type IProps = {
  children: ReactNode | ((isAuthorized: boolean) => React.ReactNode);
  UnAuthorizedComponent?: ReactNode;
  LoadingComponent?: ReactNode;
  requiredAppAccess: AppAuthRequiredAppAccess;
  onUnAuthorized?: () => void;
};

const AppAuth: FC<IProps> = ({
  children,
  requiredAppAccess,
  onUnAuthorized,
  UnAuthorizedComponent,
  LoadingComponent,
}) => {
  const dispatch = useDispatch();
  const { currentUserUserRole } = useAppSelector(s => s.userRole);
  const account = useAccount();
  const [email, setEmail] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isCheckingRole, setIsCheckingRole] = useState(true);

  // TODO tbd
  const { data, isError, isSuccess, error, isLoading, isFetching } = useGetAppAccessByEmailQuery(email, {
    skip: Boolean(currentUserUserRole) || !email,
  });

  useEffect(() => {
    setIsCheckingRole(true);
  }, []);

  useEffect(() => {
    if (currentUserUserRole) {
      const inRole = checkUserHasRequiredAppAccess(currentUserUserRole, requiredAppAccess);

      setIsAuthorized(inRole);
      setIsCheckingRole(false);

      !inRole && onUnAuthorized && onUnAuthorized();
    }
  }, [currentUserUserRole, onUnAuthorized, requiredAppAccess]);

  useEffect(() => {
    if (currentUserUserRole) {
      const loreHealthAdminRead = currentUserUserRole.find(x => x.app_access_name === 'lore.health.admin.access.read');
      const loreHealthAdminWrite = currentUserUserRole.find(
        x => x.app_access_name === 'lore.health.admin.access.write',
      );

      console.log(`ADMIN READ ${JSON.stringify(loreHealthAdminRead)}`);
      console.log(`ADMIN WRITE ${JSON.stringify(loreHealthAdminWrite)}`);

      if (loreHealthAdminRead?.access_flag && loreHealthAdminWrite?.access_flag) {
        dispatch(setIsLoreHealthAdmin(true));
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    const { emails } = getIdTokenClaims(account);
    if (emails?.length) {
      setEmail(emails[0]);
    }
  }, [account]);

  useEffect(() => {
    if (isLoading || isFetching) {
      setIsCheckingRole(true);
    }
  }, [data, isError, isSuccess, error, isLoading, isFetching]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setCurrentUserUserRole(data));
    }
  }, [isSuccess, data]);

  if (typeof children === 'function') {
    return <>{children(isAuthorized)}</>;
  } else {
    if (isCheckingRole) return LoadingComponent ? <>{LoadingComponent}</> : <></>;
    return <>{isAuthorized ? children : UnAuthorizedComponent ? UnAuthorizedComponent : <></>}</>;
  }
};

export default AppAuth;
