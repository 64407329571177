import { Box, Select, Input, FormLabel, VStack, FormControl, FormErrorMessage, Spinner } from '@chakra-ui/react';
import { Button } from '@chakra-ui/button';
import { useEffect, useState } from 'react';
import HorizontalLine from '../../../HorizontalLine';
import {
  UserInvitationFormDataErrorsModel,
  PartnerProductDisplayModel,
  PartnerDisplayModel,
  AddUserInvitationModel,
} from '../../../../app/services/partner/api/types';
import { useGetPartnerDisplayListQuery } from '../../../../app/services/partner/api/partner';
import { useGetRefEmailTemplateDisplayListQuery } from '../../../../app/services/partner/api/refEmailTemplate';
import { useLazyGetAllPartnerProductsDisplayListQuery } from '../../../../app/services/partner/api/partnerProduct';
import { useLazyGetGraphUserDetailsQuery } from '../../../../app/services/partner/api/pim';
import { invitationComponents } from '../../invitationComponents';
import { productTypes } from '../../productTypes';
import { refEmailTemplate } from '../../refEmailTemplate';
import { emailTemplateTypes } from '../../emailTemplateTypes';
import RedAsterisk from '../../../RedAsterisk';
import { useAppSelector } from '../../../../app/state/hooks';
import { useAppAccess } from '../../../../app/hooks/useAppAccess';

import { useToast } from '@chakra-ui/react';

interface InvitationStageProps {
  updateUserInvitation: (newUserInvitation: AddUserInvitationModel) => void;
  updateInvitationStage: (newValue: number) => void;
  updateSsoObjectId: (ssoId: string) => void;
  userInvitation: AddUserInvitationModel;
  refProductTypeId: number | undefined;
  handleCancel: () => void;
  onUpdateEmailContent: (emailContent: string) => void;
  updatePartnerProduct: (newValue: PartnerProductDisplayModel | null) => void;
}

const defaultFormDataErrors: UserInvitationFormDataErrorsModel = {
  first_name: '',
  last_name: '',
  email_address: '',
  partner_id: '',
  partner_product_id: '',
  ref_email_template_id: '',
};

const defaultSelectedPartner: PartnerDisplayModel = {
  partner_id: 0,
  partner_name: '',
};

const ProviderInformationPanel = ({
  onUpdateEmailContent,
  updateUserInvitation,
  updateInvitationStage,
  updateSsoObjectId,
  updatePartnerProduct,
  handleCancel,
  userInvitation,
  refProductTypeId,
}: InvitationStageProps) => {
  const [localUserInvitation, setLocalUserInvitation] = useState<AddUserInvitationModel>(userInvitation);
  const [errors, setErrors] = useState<UserInvitationFormDataErrorsModel>(defaultFormDataErrors);
  const [isPartnerProductListLoading, setIsPartnerProductListLoading] = useState<boolean>(false);
  const [partnerProductList, setPartnerProductList] = useState<PartnerProductDisplayModel[]>([]);
  const [isNextLoading, setIsNextLoading] = useState<boolean>(false);
  const [isPartnerDisabled, setIsPartnerDisabled] = useState<boolean>(false);
  const [selectedPartner, setSelectedPartner] = useState<PartnerDisplayModel>(defaultSelectedPartner);

  const emailTemplates = useGetRefEmailTemplateDisplayListQuery();
  const partners = useGetPartnerDisplayListQuery();
  const [lazyGetPartnerProductsList] = useLazyGetAllPartnerProductsDisplayListQuery();
  const [lazyGetGraphUserDetails] = useLazyGetGraphUserDetailsQuery();
  const toast = useToast();

  const { selectedPartnerProduct } = useAppSelector(s => s.user);
  const { isUserAdmin } = useAppAccess();

  useEffect(() => {
    if (!isUserAdmin) {
      //
      const partnerId = selectedPartnerProduct?.partner_id || 0;
      const partnerProductId = selectedPartnerProduct?.partner_product_id || 0;
      const partnerName = partners.data?.find(item => item.partner_id == partnerId)?.partner_name || '';

      handleOnStringPropertyChange(partnerProductId.toString(), 'partner_product_id');
      handleOnStringPropertyChange(partnerId.toString(), 'partner_id');

      handleSelectedPartnerChange(partnerId, partnerName);
    }
  }, [isUserAdmin]);

  const validateEmail = (value: string) => {
    // Regular expression for email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(value);
  };

  const handleInternalCancel = () => {
    setIsPartnerDisabled(false);
    handleCancel();
  };

  const validate = () => {
    let newErrors = { ...errors };

    if (!localUserInvitation.first_name.trim()) {
      newErrors.first_name = 'First Name is required';
    } else {
      newErrors.first_name = '';
    }

    if (!localUserInvitation.last_name.trim()) {
      newErrors.last_name = 'Last Name is required';
    } else {
      newErrors.last_name = '';
    }

    if (!localUserInvitation.email_address.trim()) {
      newErrors.email_address = 'Email Address is required';
    } else if (!validateEmail(localUserInvitation.email_address)) {
      newErrors.email_address = 'Email Address is invalid';
    } else {
      newErrors.email_address = '';
    }

    if (localUserInvitation.partner_id == 0) {
      newErrors.partner_id = 'Partner is required';
    } else {
      newErrors.partner_id = '';
    }

    if (localUserInvitation.partner_product_id == 0) {
      newErrors.partner_product_id = 'Partner Product is required';
    } else {
      newErrors.partner_product_id = '';
    }

    if (localUserInvitation.ref_email_template_id == 0) {
      newErrors.ref_email_template_id = 'Email Template is required';
    } else {
      newErrors.ref_email_template_id = '';
    }

    setErrors(newErrors);

    return Object.values(newErrors).every(error => error === '');
  };

  const handleOnStringPropertyChange = (prop_value: string, prop_name: string) => {
    setLocalUserInvitation(prevState => ({ ...prevState, [prop_name]: prop_value }));
  };

  const handleSelectedPartnerChange = (partner_id: number, partner_name: string) => {
    setSelectedPartner(prev => ({ ...prev, partner_name: partner_name, partner_id: partner_id }));
  };

  const handleNext = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsNextLoading(true);
    setErrors(defaultFormDataErrors);
    if (validate()) {
      let graphUserId: string | null = null;
      let isInDb: boolean = false;
      // if email_address already has a sso nonprod account, go to 3. else, go to 2'
      lazyGetGraphUserDetails(localUserInvitation.email_address)
        .unwrap()
        .then(details => {
          graphUserId = details.sso_object_id;
          isInDb = details.is_in_db;
        })
        .finally(() => {
          console.log(`SSO_OBJECT_ID?: ${graphUserId}; IS_IN_DB: ${isInDb}`);

          if (!!graphUserId) {
            // user is already existing in SSO Tenant
            // check if existing in our sqlmidb_partner_portal database

            if (isInDb) {
              // if existing in db, display message that the user is
              // already registered with the Parther Portal
              toast({
                position: 'top-right',
                title: 'User already exists!',
                description: `${localUserInvitation.email_address} is already registered with the Partner Portal`,
                status: 'warning',
                duration: 9000,
                isClosable: true,
              });
              setIsNextLoading(false);
              return;
            } else {
              // if not yet in the db, go to 'Roles Selection' and once the 'Submit'
              // button is clicked, create record in DB and send email to the user
              // notifying that they can now start using the Partner Portal
              setIsNextLoading(false);
              updateSsoObjectId(graphUserId);
              updateInvitationStage(invitationComponents.ROLES_SELECTION);
              return;
            }
          } else {
            setIsNextLoading(false);
            updateInvitationStage(invitationComponents.EMAIL_TEMPLATE);
            return;
          }
        });
    } else {
      console.log('INPUTS NOT VALID!!!');
      setIsNextLoading(false);
    }
  };

  useEffect(() => {
    if (!!selectedPartner.partner_name && selectedPartner.partner_id > 0) {
      setIsPartnerProductListLoading(true);
      lazyGetPartnerProductsList(selectedPartner.partner_id)
        .unwrap()
        .then(lstPP => {
          setPartnerProductList(lstPP);
        })
        .finally(() => {
          setIsPartnerProductListLoading(false);
        });
    }
  }, [selectedPartner]);

  useEffect(() => {
    updateUserInvitation(localUserInvitation);
    console.log(`USER INVITATION: ${JSON.stringify(localUserInvitation)}`);
  }, [localUserInvitation]);

  return (
    <>
      <form onSubmit={handleNext}>
        <VStack>
          <Box display="flex" justifyContent={'space-evenly'}>
            <Box sx={{ width: '50%' }} mr="2">
              <FormControl isInvalid={!!errors.first_name}>
                <FormLabel fontSize={'smaller'} mb="-0.2" htmlFor="first-name">
                  First Name
                  <RedAsterisk />
                </FormLabel>
                <Input
                  id="first-name"
                  name="first_name"
                  value={localUserInvitation.first_name}
                  onChange={e => handleOnStringPropertyChange(e.target.value, e.target.name)}
                />
                <FormErrorMessage>{errors.first_name}</FormErrorMessage>
              </FormControl>
            </Box>
            <Box sx={{ width: '50%' }}>
              <FormControl isInvalid={!!errors.last_name}>
                <FormLabel fontSize={'smaller'} mb="-0.2" htmlFor="last-name">
                  Last Name
                  <RedAsterisk />
                </FormLabel>
                <Input
                  id="last-name"
                  name="last_name"
                  value={localUserInvitation.last_name}
                  onChange={e => handleOnStringPropertyChange(e.target.value, e.target.name)}
                />
                <FormErrorMessage>{errors.last_name}</FormErrorMessage>
              </FormControl>
            </Box>
          </Box>

          <Box>
            <FormControl isInvalid={!!errors.email_address}>
              <FormLabel fontSize={'smaller'} mb="-0.2" htmlFor="email">
                Email
                <RedAsterisk />
              </FormLabel>
              <Input
                id="email"
                name="email_address"
                value={localUserInvitation.email_address}
                onChange={e => handleOnStringPropertyChange(e.target.value, e.target.name)}
              />
              <FormErrorMessage>{errors.email_address}</FormErrorMessage>
            </FormControl>
          </Box>

          <Box display="flex" justifyContent={'space-evenly'}>
            <Box sx={{ width: '50%' }} mr="2">
              <FormControl isInvalid={!!errors.partner_id}>
                <FormLabel fontSize={'smaller'} mb="-0.2" htmlFor="partner-id">
                  Partner
                  {isUserAdmin && <RedAsterisk />}
                </FormLabel>
                <Select
                  id="partner-id"
                  name="partner_id"
                  value={localUserInvitation.partner_id}
                  placeholder={partners.isLoading || partners.isFetching ? 'Loading...' : ''}
                  // disabled={partners.isLoading || partners.isFetching || isPartnerDisabled}
                  disabled={partners.isLoading || partners.isFetching || !isUserAdmin}
                  onChange={e => {
                    // handleSelectedPartnerChange(e.target.value, e.target.options[e.target.selectedIndex].text);
                    handleSelectedPartnerChange(
                      parseInt(e.target.value),
                      e.target.options[e.target.selectedIndex].text,
                    );
                    handleOnStringPropertyChange(e.target.value, e.target.name);
                  }}
                >
                  <option key="0" value="">
                    Select an option
                  </option>
                  {(partners.data ?? []).map(o => (
                    <option key={o.partner_id} value={o.partner_id}>
                      {o.partner_name}
                    </option>
                  ))}
                  ;
                </Select>
                <FormErrorMessage>{errors.partner_id}</FormErrorMessage>
              </FormControl>
            </Box>
            <Box sx={{ width: '50%' }}>
              <FormControl isInvalid={!!errors.partner_product_id}>
                <FormLabel fontSize={'smaller'} mb="-0.2" htmlFor="partner-product-id">
                  Partner Product
                  {isUserAdmin && <RedAsterisk />}
                </FormLabel>
                <Select
                  id="partner-product-id"
                  name="partner_product_id"
                  placeholder={isPartnerProductListLoading ? 'Loading...' : ''}
                  // disabled={isPartnerProductListLoading || isPartnerDisabled}
                  disabled={isPartnerProductListLoading || !isUserAdmin}
                  value={localUserInvitation.partner_product_id}
                  onChange={e => {
                    handleOnStringPropertyChange(e.target.value, e.target.name);
                    if (!!e.target.value) {
                      let partnerProduct = partnerProductList.find(
                        i => i.partner_product_id == parseInt(e.target.value),
                      );
                      updatePartnerProduct(partnerProduct ? partnerProduct : null);
                      console.log(`SELECTED PARTNER PRODUCT ${JSON.stringify(partnerProduct)}`);
                    }
                  }}
                >
                  <option value="">Select an option</option>
                  {(partnerProductList ?? []).map(p => (
                    <option key={p.partner_product_id} value={p.partner_product_id}>
                      {p.doing_business_as}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.partner_product_id}</FormErrorMessage>
              </FormControl>
            </Box>
          </Box>

          <Box>
            <FormControl isInvalid={!!errors.ref_email_template_id}>
              <FormLabel fontSize={'smaller'} mb="-0.2" htmlFor="email-template">
                Email Template
                <RedAsterisk />
              </FormLabel>
              <Select
                id="email-template"
                name="ref_email_template_id"
                value={localUserInvitation.ref_email_template_id}
                placeholder={emailTemplates.isLoading || emailTemplates.isFetching ? 'Loading...' : ''}
                disabled={emailTemplates.isLoading || emailTemplates.isFetching}
                onChange={e => {
                  handleOnStringPropertyChange(e.target.value, e.target.name);
                  if (!!e.target.value) {
                    let template = (emailTemplates.data ?? []).find(
                      t => t.ref_email_template_id == parseInt(e.target.value),
                    );

                    if (!!template?.template_content) {
                      onUpdateEmailContent(template?.template_content);

                      if (template?.ref_email_template_id === refEmailTemplate.LORE_HEALTH_ADMINISTRATOR_OR_TEAM) {
                        setIsPartnerDisabled(true);
                      } else {
                        setIsPartnerDisabled(false);
                      }
                    }
                  }
                }}
              >
                <option value="">Select an option</option>
                {refProductTypeId == productTypes.LORE_ACO
                  ? (emailTemplates.data ?? []).map(e => (
                      <option key={e.ref_email_template_id} value={e.ref_email_template_id}>
                        {e.template_name}
                      </option>
                    ))
                  : (emailTemplates.data ?? []).map(
                      e =>
                        e.template_name !== emailTemplateTypes.ACO_CREDENTIALING && (
                          <option key={e.ref_email_template_id} value={e.ref_email_template_id}>
                            {e.template_name}
                          </option>
                        ),
                    )}
                ;
              </Select>
              <FormErrorMessage>{errors.ref_email_template_id}</FormErrorMessage>
            </FormControl>
          </Box>
        </VStack>

        <HorizontalLine marginTop={'20%'} />
        <Box display="flex" justifyContent={'flex-end'}>
          <Box display="flex" gap={2}>
            <Button size="sm" onClick={handleInternalCancel}>
              Cancel
            </Button>
            <Button size="sm" colorScheme="brand.main" gap="2" px="5" type="submit">
              {isNextLoading ? <Spinner size="sm" /> : 'Next'}
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default ProviderInformationPanel;
