import { FC, ReactNode, createContext, useContext, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RefUserModel } from '../../app/services/partner/api/types';
import { useGetUserListQuery } from '../../app/services/partner/api/user';
import { PaginatedApiResult, SortType } from '../../app/services/types';
import { useAppSelector } from '../../app/state/hooks';
import { useAppAccess } from '../../app/hooks/useAppAccess';

type Props = {
  children: ReactNode;
};

export const UserInitialSortBy: SortType<RefUserModel> = {
  id: 'first_name',
  desc: false,
};

type UserContextModel = {
  pageNumber: number;
  pageSize: number;
  sortBy: typeof UserInitialSortBy;
  selectedUser: RefUserModel | null;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  setSortBy: React.Dispatch<React.SetStateAction<typeof UserInitialSortBy>>;
  setSelectedUser: React.Dispatch<React.SetStateAction<RefUserModel | null>>;
  isLoading: boolean;
  isFetching: boolean;
  data?: PaginatedApiResult<RefUserModel>;
};

const UserContext = createContext<UserContextModel>({
  pageNumber: 1,
  pageSize: 10,
  sortBy: UserInitialSortBy,
  selectedUser: null,
  setPageNumber: () => {},
  setPageSize: () => {},
  setSortBy: () => {},
  setSelectedUser: () => {},
  isLoading: false,
  isFetching: false,
});

const UserContextProvider: FC<Props> = ({ children }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState<typeof UserInitialSortBy>(UserInitialSortBy);
  const [selectedUser, setSelectedUser] = useState<RefUserModel | null>(null);
  const { selectedPartnerProduct } = useAppSelector(s => s.user);
  const { isUserAdmin } = useAppAccess();

  const [searchParams, setSearchParams] = useSearchParams();

  const { isLoading, isFetching, data } = useGetUserListQuery({
    page_number: pageNumber,
    page_size: pageSize,
    search_string: searchParams.get('search') ?? '',
    sort_column: sortBy.id,
    sort_order: sortBy.desc ? 'desc' : 'asc',
    partner_product_id: selectedPartnerProduct?.partner_product_id ?? 0,
  });

  useEffect(() => {
    console.log(
      `USER DETAILS: Is User Admin? ${isUserAdmin} - Selected Partner Product ${JSON.stringify(
        selectedPartnerProduct,
      )} -  Data: ${JSON.stringify(data)}`,
    );
  }, []);

  return (
    <UserContext.Provider
      value={{
        pageNumber,
        pageSize,
        sortBy,
        selectedUser,
        setPageNumber,
        setPageSize,
        setSortBy,
        setSelectedUser,
        isLoading,
        isFetching,
        data,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) throw new Error('UserContext not found');
  return context;
};

export default UserContextProvider;
