import { partnerApi } from '.';
import { PaginatedApiResult, PaginatedQueryParams } from '../../types';
import { ReportExploreModel, AddReportModel } from './types';

const baseUrl = '/refreport';

const extendedApi = partnerApi.injectEndpoints({
  endpoints: build => ({
    getRefReports: build.query<
      PaginatedApiResult<ReportExploreModel>,
      PaginatedQueryParams<ReportExploreModel> & { viewer_ref_user_id?: number | undefined }
    >({
      query: params => ({
        url: baseUrl + `/list`,
        params,
      }),
      providesTags: ['Role'],
    }),
    addReport: build.mutation<void, AddReportModel>({
      query: body => ({
        body,
        url: baseUrl,
        method: 'POST',
      }),
      invalidatesTags: ['Role'],
    }),
  }),
});

export const { useGetRefReportsQuery, useLazyGetRefReportsQuery, useAddReportMutation } = extendedApi;
