import { Heading, VStack } from '@chakra-ui/react';
import { FC } from 'react';

type Props = {
  title: string;
  message: string;
};

const MessagePage: FC<Props> = ({ title, message }) => {
  return (
    <VStack align="stretch" alignItems="center" mt="9vh" mx="auto" gap="16">
      <Heading size="4xl">{title}</Heading>
      <Heading size="xl">{message}</Heading>
    </VStack>
  );
};

export default MessagePage;
