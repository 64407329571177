import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Input,
  Skeleton,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useState } from 'react';
import { GoPencil } from 'react-icons/go';
import { MdNotInterested, MdOutlineSave } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { RefDemographic, UserNameFormSchema } from '.';
import { defaultErrorMessage } from '../../app/constants';
import { getName } from '../../app/helpers/stringHelper';
import { useLazyGetUserByEmailQuery } from '../../app/services/partner/api/user';
import { usePutUserDemographicByUserMutation } from '../../app/services/partner/api/userDemographic';
import { setLogonUser } from '../../app/slices/userSlice';
import { useAppSelector } from '../../app/state/hooks';

const LogonUserName = () => {
  const [isEdit, setIsEdit] = useState(false);

  const toast = useToast();
  const dispatch = useDispatch();
  const { logonUser } = useAppSelector(s => s.user);
  const [putAsync, putDetails] = usePutUserDemographicByUserMutation();
  const [getAsync, getDetails] = useLazyGetUserByEmailQuery();

  const { handleSubmit, errors, touched, handleChange, values, resetForm, setSubmitting } = useFormik({
    enableReinitialize: true,
    validationSchema: UserNameFormSchema,
    initialValues: {
      first_name: logonUser?.first_name || '',
      last_name: logonUser?.last_name || '',
    },
    onSubmit: async (values, form) => {
      if (logonUser?.ref_user_id) {
        putAsync({
          refUserId: logonUser.ref_user_id,
          body: [
            {
              ref_demographic_id: RefDemographic.first_name,
              demographic_value: values.first_name,
            },
            {
              ref_demographic_id: RefDemographic.last_name,
              demographic_value: values.last_name,
            },
          ],
        })
          .unwrap()
          .then(() => {
            setIsEdit(false);
            toast({
              description: 'Name successfully updated.',
              status: 'success',
            });
            form.resetForm();
            if (logonUser.email) {
              getAsync(logonUser.email)
                .unwrap()
                .then(user => {
                  dispatch(setLogonUser(user));
                });
            }
          })
          .catch(() => toast({ description: defaultErrorMessage, status: 'error' }));
      }
    },
  });

  const clear = () => {
    resetForm();
    setIsEdit(false);
  };

  return (
    <HStack gap="4">
      {isEdit ? (
        <Box w="full">
          <form onSubmit={handleSubmit}>
            <HStack gap="4" w="full" alignItems="start">
              <FormControl isInvalid={!!errors.first_name && touched.first_name} w="200px">
                {/* <FormLabel>First Name</FormLabel> */}
                <Input
                  id="first_name"
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                  bg="white"
                  placeholder="First Name"
                  size="sm"
                  isReadOnly={putDetails.isLoading}
                />
                <FormErrorMessage>{errors.first_name}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.last_name && touched.last_name} w="200px">
                {/* <FormLabel>Last Name</FormLabel> */}
                <Input
                  id="last_name"
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                  bg="white"
                  placeholder="Last Name"
                  size="sm"
                  isReadOnly={putDetails.isLoading}
                />
                <FormErrorMessage>{errors.last_name}</FormErrorMessage>
              </FormControl>

              <Box mt="1">
                <Button variant="link" colorScheme="brand.main" type="submit" isLoading={putDetails.isLoading}>
                  <HStack gap="1">
                    <Icon as={MdOutlineSave} /> <Text fontWeight="normal">Save</Text>
                  </HStack>
                </Button>
              </Box>

              <Box mt="1">
                <Button variant="link" colorScheme="brand.main" onClick={clear} isDisabled={putDetails.isLoading}>
                  <HStack gap="1">
                    <Icon as={MdNotInterested} /> <Text fontWeight="normal">Cancel</Text>
                  </HStack>
                </Button>
              </Box>
            </HStack>
          </form>
        </Box>
      ) : (
        <>
          {getDetails.isLoading || getDetails.isFetching ? (
            <Box pt="1.5">
              <Skeleton w="400px" height="18px" />
            </Box>
          ) : (
            <Text fontWeight="semibold">{getName(logonUser, { noEmail: true, default: 'N/A' })}</Text>
          )}

          <Button variant="link" colorScheme="brand.main" onClick={() => setIsEdit(true)}>
            <HStack gap="1">
              <Icon as={GoPencil} /> <Text fontWeight="normal">Edit</Text>
            </HStack>
          </Button>
        </>
      )}
    </HStack>
  );
};
export default LogonUserName;
