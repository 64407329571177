import {
  VStack,
  Box,
  IconButton,
  Flex,
  Heading,
  FormControl,
  FormLabel,
  Spinner,
  FormErrorMessage,
  Select,
  Button,
  Textarea,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import HorizontalLine from '../../../HorizontalLine';
import { AddUserRoleAccessRequestModel } from '../../../../app/services/partner/api/types';
import { useGetRefRoleDisplayListQuery } from '../../../../app/services/partner/api/refRole';
import RedAsterisk from '../../../RedAsterisk';
import CustomDatePicker from '../../../../components/CustomDatePicker';
import { PropsConfigs } from '../../../../components/CustomDatePicker/utils/commonTypes';
import dayjs from 'dayjs';
import { useAddUserRoleAccessRequestMutation } from '../../../../app/services/partner/api/userRoleAccessRequest';
import { useToast } from '@chakra-ui/react';

import { useAppSelector } from '../../../../app/state/hooks';
import { useAddUserActivityMutation } from '../../../../app/services/partner/api/userActivity';
import { PARTNER_PAGE } from '../../../../app/constants/partnerPage';
import { PAGE_ACTIVITY } from '../../../../app/constants/pageActivity';

const propsConfig: PropsConfigs = {
  dateNavBtnProps: {
    colorScheme: 'brand.main.default',
    variant: 'outline',
  },
  dayOfMonthBtnProps: {
    defaultBtnProps: {
      _hover: {
        background: 'brand.main.default',
        color: 'white',
      },
    },
    selectedBtnProps: {
      background: 'brand.main.default',
      color: 'white',
    },
    todayBtnProps: {
      background: 'gray.400',
    },
  },
};

const defaultFormDataErrors = {
  ref_role_id: '',
  expiration_datetime_utc: '',
  expiration_datetime_utc2: '',
  business_justification: '',
};

const defaultUserRoleAccessRequest: AddUserRoleAccessRequestModel = {
  ref_role_id: 0,
  expiration_datetime_utc: '',
  business_justification: '',
};

type IProps = {
  handleClose: () => void;
  refRoleId?: number;
  oneYear?: boolean;
  isRenew?: boolean;
};

const RequestDetails = ({ handleClose, refRoleId, oneYear, isRenew }: IProps) => {
  const roles = useGetRefRoleDisplayListQuery();
  const [errors, setErrors] = useState(defaultFormDataErrors);
  const [userRoleAccessRequest, setUserRoleAccessRequest] =
    useState<AddUserRoleAccessRequestModel>(defaultUserRoleAccessRequest);

  const maxDate = dayjs().add(1, 'year').toDate();
  const minDate = dayjs(new Date()).add(1, 'day').startOf('day').toDate();
  const [post, postDetails] = useAddUserRoleAccessRequestMutation();
  const toast = useToast();

  const [postAddUserActivity] = useAddUserActivityMutation();
  const { selectedPartnerProduct, activityList, pageList, userSessionId } = useAppSelector(s => s.user);

  const explore_roles_page = pageList.find(i => i.page_name === PARTNER_PAGE.EXPLORE_ROLES);
  const role_access_request_draft_cancelled = activityList.find(
    i => i.activity_name === PAGE_ACTIVITY.ROLE_ACCESS_REQUEST_DRAFT_CANCELLED,
  );
  const role_access_request_submitted = activityList.find(
    i => i.activity_name === PAGE_ACTIVITY.ROLE_ACCESS_REQUEST_SUBMITTED,
  );

  const logSubmitEvent = () => {
    if (role_access_request_submitted && explore_roles_page && userSessionId) {
      postAddUserActivity({
        user_session_id: userSessionId,
        ref_activity_id: role_access_request_submitted.ref_activity_id,
        ref_page_id: explore_roles_page.ref_page_id,
        partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      })
        .unwrap()
        .then(res => {});
    }
  };

  const logCancelEvent = () => {
    if (role_access_request_draft_cancelled && explore_roles_page && userSessionId) {
      postAddUserActivity({
        user_session_id: userSessionId,
        ref_activity_id: role_access_request_draft_cancelled.ref_activity_id,
        ref_page_id: explore_roles_page.ref_page_id,
        partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      })
        .unwrap()
        .then(res => {});
    }
  };

  useEffect(() => {
    if (refRoleId) {
      setUserRoleAccessRequest(prevState => ({ ...prevState, ref_role_id: refRoleId }));
    }

    if (oneYear) {
      let currentDate = new Date();

      // Add one year to the current date
      let nextYearDate = new Date(currentDate);
      nextYearDate.setFullYear(currentDate.getFullYear() + 1);
      setUserRoleAccessRequest(prevState => ({
        ...prevState,
        expiration_datetime_utc: dayjs(nextYearDate).format('YYYY-MM-DD'),
      }));
    }
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrors(defaultFormDataErrors);

    if (validate()) {
      post(userRoleAccessRequest)
        .unwrap()
        .then(res => {
          toast({
            position: 'top-right',
            title: 'Request Created',
            description: 'Access request created!',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
        })
        .catch(error => {
          toast({
            position: 'top-right',
            title: 'Request Access Failed',
            description: error.data.error_message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        })
        .finally(() => {
          logSubmitEvent();
          handleClose();
        });
    } else {
      console.log('INPUTS NOT VALID!!!');
    }
  };

  const validate = () => {
    let newErrors = { ...errors };

    if (userRoleAccessRequest.ref_role_id === 0) {
      newErrors.ref_role_id = 'Role is required';
    } else {
      newErrors.ref_role_id = '';
    }

    if (!userRoleAccessRequest.expiration_datetime_utc.trim()) {
      newErrors.expiration_datetime_utc = 'Expiration date is required';
    } else {
      newErrors.expiration_datetime_utc = '';
    }

    if (!userRoleAccessRequest.business_justification.trim()) {
      newErrors.business_justification = 'Business justification is required';
    } else {
      newErrors.business_justification = '';
    }
    setErrors(newErrors);
    console.log(`ERRORS: ${JSON.stringify(newErrors)}`);
    console.log(`USER ROLE ACCESS REQUEST: ${JSON.stringify(userRoleAccessRequest)}`);
    return Object.values(newErrors).every(error => error === '');
  };

  const handleOnStringPropertyChange = (prop_value: string, prop_name: string) => {
    setUserRoleAccessRequest(prevState => ({ ...prevState, [prop_name]: prop_value }));
  };

  const handleCancel = () => {
    logCancelEvent();
    handleClose();
  };

  return (
    <>
      <VStack>
        <Flex justifyContent={'space-between'}>
          <Box pt={'1.5'} pb={'1.5'}>
            <Heading as="h3" size="sm">
             {isRenew ? 'Renew Access': 'Request Access'}
            </Heading>
          </Box>

          <Box>
            <IconButton
              sx={{ verticalAlign: 'middle' }}
              variant="link"
              color="black"
              size="xxs"
              aria-label="close modal"
              onClick={handleClose}
              icon={<CloseIcon fontSize={'sm'} />}
            />
          </Box>
        </Flex>
        <Box>
          <HorizontalLine marginTop="3" />
        </Box>
        <Box>
          <form onSubmit={handleSubmit}>
            <VStack>
              <Box>
                <FormControl isInvalid={!!errors.ref_role_id}>
                  <FormLabel fontSize={'smaller'} mb="-0.2" htmlFor="ref-role-id">
                    Choose Role
                    <RedAsterisk />
                  </FormLabel>
                  <Select
                    id="ref-role-id"
                    name="ref_role_id"
                    value={userRoleAccessRequest.ref_role_id}
                    placeholder={roles.isLoading || roles.isFetching ? 'Loading...' : ''}
                    disabled={roles.isLoading || roles.isFetching}
                    onChange={e => {
                      handleOnStringPropertyChange(e.target.value, e.target.name);
                    }}
                  >
                    <option key="0" value="">
                      Select an option
                    </option>
                    {(roles.data ?? []).map((o, i) => (
                      <option key={i} value={o.ref_role_id}>
                        {o.role_display_name}
                      </option>
                    ))}
                    ;
                  </Select>
                  <FormErrorMessage>{errors.ref_role_id}</FormErrorMessage>
                </FormControl>
              </Box>

              <Box>
                <FormControl isInvalid={!!errors.expiration_datetime_utc}>
                  <FormLabel fontSize={'smaller'} mb="-0.2" htmlFor="expiration-datetime-utc">
                    Choose Expiration
                    <RedAsterisk />
                  </FormLabel>
                  <CustomDatePicker
                    id="expiration-datetime-utc"
                    name="expiration_datetime_utc"
                    onDateChange={date => {
                      if (dayjs(date).format('YYYY-MM-DD')) {
                        handleOnStringPropertyChange(dayjs(date).format('YYYY-MM-DD'), 'expiration_datetime_utc');
                      }
                    }}
                    minDate={minDate}
                    maxDate={maxDate}
                    date={
                      userRoleAccessRequest.expiration_datetime_utc
                        ? new Date(userRoleAccessRequest.expiration_datetime_utc)
                        : undefined
                    }
                    propsConfigs={{
                      ...propsConfig,
                      inputProps: {
                        placeholder: '',
                        size: 'sm',
                        value: userRoleAccessRequest.expiration_datetime_utc ?? undefined,
                      },
                    }}
                  />
                  <FormErrorMessage>{errors.expiration_datetime_utc}</FormErrorMessage>
                </FormControl>
              </Box>

              <Box>
                <FormControl isInvalid={!!errors.business_justification}>
                  <FormLabel fontSize={'smaller'} mb="-0.2" htmlFor="business-justification">
                    Fill in Business Justification
                    <RedAsterisk />
                  </FormLabel>
                  <Textarea
                    id="business-justification"
                    name="business_justification"
                    placeholder="Enter Message"
                    value={userRoleAccessRequest.business_justification}
                    onChange={e => handleOnStringPropertyChange(e.target.value, e.target.name)}
                  />
                  <FormErrorMessage>{errors.business_justification}</FormErrorMessage>
                </FormControl>
              </Box>
            </VStack>

            <HorizontalLine marginTop={'2vh'} />
            <Box display="flex" justifyContent={'flex-end'} pt="5">
              <Box display="flex" gap={2}>
                <Button size="sm" onClick={handleCancel}>
                  Cancel
                </Button>

                <Button size="sm" colorScheme="brand.main" gap="2" px="5" type="submit">
                  {postDetails.isLoading ? <Spinner size="sm" /> : 'Send'}
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </VStack>
    </>
  );
};

export default RequestDetails;
