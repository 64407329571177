import { Box, Button, Grid, GridItem, Text, chakra } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { usaStates } from '../../../../../../app/constants/usaStates';
import { useGetAllPartnerProductTagQuery } from '../../../../../../app/services/partner/api/refPartnerProductTag';
import { useGetAllRefPaymentTrackQuery } from '../../../../../../app/services/partner/api/refPaymentTrack';
import { useGetAllProductTypeQuery } from '../../../../../../app/services/partner/api/refProductType';
import { AddPartnerStepProp, getPaymentTrackLabel } from '../../../helper';

import { useAddUserActivityMutation } from '../../../../../../app/services/partner/api/userActivity';
import { useAppSelector } from '../../../../../../app/state/hooks';
import { PARTNER_PAGE } from '../../../../../../app/constants/partnerPage';
import { PAGE_ACTIVITY } from '../../../../../../app/constants/pageActivity';

const Step3: FC<AddPartnerStepProp> = ({ data, setData, setActiveStep, handleSubmit, isLoading, source }) => {
  const productTypesDetails = useGetAllProductTypeQuery();
  const paymentTrackDetails = useGetAllRefPaymentTrackQuery();
  const partnerProductTagsDetails = useGetAllPartnerProductTagQuery();

  const state = usaStates.find(s => s.abbreviation === data.partner_state)?.name;
  const productType = productTypesDetails.data?.find(t => t.ref_product_type_id === data.ref_product_type_id);
  const paymentTrack = paymentTrackDetails.data?.find(t => t.ref_payment_track_id === data.ref_payment_track_id);
  const paymentTrackLabel = paymentTrack ? getPaymentTrackLabel(paymentTrack) : '';

  const [postAddUserActivity] = useAddUserActivityMutation();
  const { selectedPartnerProduct, activityList, pageList, userSessionId } = useAppSelector(s => s.user);

  const partner_products_page = pageList.find(i => i.page_name === PARTNER_PAGE.PARTNER_PRODUCTS);
  const dashboard_page = pageList.find(i => i.page_name === PARTNER_PAGE.DASHBOARD);
  const partner_product_created = activityList.find(i => i.activity_name === PAGE_ACTIVITY.PARTNER_PRODUCT_CREATED);

  const final_page = source === 'partner-product-page' ? partner_products_page : dashboard_page;

  const handleLocalSubmit = () => {
    if (partner_product_created && final_page && userSessionId && source) {
      postAddUserActivity({
        user_session_id: userSessionId,
        ref_activity_id: partner_product_created.ref_activity_id,
        ref_page_id: final_page.ref_page_id,
        partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      })
        .unwrap()
        .then(res => {})
        .finally(() => {
          handleSubmit();
        });
    }
  };

  const productTags = useMemo(() => {
    let productTags = '';
    data.ref_partner_product_tag_ids.forEach((id, index) => {
      const val = partnerProductTagsDetails.data?.find(m => m.ref_partner_product_tag_id === id);
      if (!val?.partner_product_tag) return;
      if (index === 0) {
        productTags = val.partner_product_tag;
      } else {
        const separator = index === data.ref_partner_product_tag_ids.length - 1 ? ' & ' : ', ';
        productTags = productTags + separator + val.partner_product_tag;
      }
    });
    return productTags;
  }, [data.ref_partner_product_tag_ids, partnerProductTagsDetails.data]);

  return (
    <>
      <Grid className="da-container" templateColumns="repeat(3, 1fr)" gap="1rem !important">
        <GridItem className="da-c-entry">
          <chakra.label>Partner Name</chakra.label>
          <Text>{data.partner_name || '-'}</Text>
        </GridItem>
        <GridItem className="da-c-entry" colSpan={2}>
          <chakra.label>Product Type</chakra.label>
          <Text>{productType?.product_type_name || '-'}</Text>
        </GridItem>
        <GridItem className="da-c-entry">
          <chakra.label>Partner Tier</chakra.label>
          <Text>{data.ref_product_tier_id || '-'}</Text>
        </GridItem>
        <GridItem className="da-c-entry">
          <chakra.label>Partner TIN</chakra.label>
          <Text>{data.partner_tin || '-'}</Text>
        </GridItem>
        <GridItem className="da-c-entry" colSpan={2}>
          <chakra.label>Doing Business as</chakra.label>
          <Text>{data.doing_business_as || '-'}</Text>
        </GridItem>
        <GridItem className="da-c-entry">
          <chakra.label>Address 1</chakra.label>
          <Text>{data.partner_address_1 || '-'}</Text>
        </GridItem>
        <GridItem className="da-c-entry" colSpan={2}>
          <chakra.label>Address 2</chakra.label>
          <Text>{data.partner_address_2 || '-'}</Text>
        </GridItem>
        <GridItem className="da-c-entry">
          <chakra.label>City</chakra.label>
          <Text>{data.partner_city || '-'}</Text>
        </GridItem>
        <GridItem className="da-c-entry" colSpan={2}>
          <chakra.label>State</chakra.label>
          <Text>{state || '-'}</Text>
        </GridItem>
        <GridItem className="da-c-entry">
          <chakra.label>Postal Code</chakra.label>
          <Text>{data.partner_zip_code || '-'}</Text>
        </GridItem>
        <GridItem className="da-c-entry" colSpan={2}>
          <chakra.label>Country</chakra.label>
          <Text>{data.partner_country || '-'}</Text>
        </GridItem>
        <GridItem className="da-c-entry">
          <chakra.label>Enrollment Start Date</chakra.label>
          <Text>{data.enrollment_start_dt || '-'}</Text>
        </GridItem>
        <GridItem className="da-c-entry" colSpan={2}>
          <chakra.label>Enrollment End Date</chakra.label>
          <Text>{data.enrollment_end_dt || '-'}</Text>
        </GridItem>
        <GridItem className="da-c-entry">
          <chakra.label>Contract Start Date</chakra.label>
          <Text>{data.contract_start_dt || '-'}</Text>
        </GridItem>
        <GridItem className="da-c-entry">
          <chakra.label>Contract End Date</chakra.label>
          <Text>{data.contract_end_dt || '-'}</Text>
        </GridItem>
        <GridItem className="da-c-entry">
          <chakra.label>Contract Renewal Date</chakra.label>
          <Text>{data.contract_renewal_dt || '-'}</Text>
        </GridItem>
        <GridItem className="da-c-entry">
          <chakra.label>Payment Track</chakra.label>
          <Text>{paymentTrackLabel || '-'}</Text>
        </GridItem>
        <GridItem className="da-c-entry" colSpan={2}>
          <chakra.label>Product Tag</chakra.label>
          <Text>{productTags || '-'}</Text>
        </GridItem>
      </Grid>

      <Box w="100%" display="flex">
        <Button onClick={() => setActiveStep(i => i - 1)} colorScheme="brand.main" isDisabled={isLoading}>
          Back
        </Button>
        <Button onClick={handleLocalSubmit} colorScheme="brand.main" ml="auto" isLoading={isLoading}>
          Submit
        </Button>
      </Box>
    </>
  );
};

export default Step3;
