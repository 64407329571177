export enum PARTNER_PAGE {
  DASHBOARD = 'dashboard',
  ACO_PROVIDER_LIST = 'aco_provider_list',
  EXPLORE_ROLES = 'explore_roles',
  MY_ROLES = 'my_roles',
  CREATE_ROLE = 'create_role',
  ACCESS_APPROVAL = 'access_approval',
  REPORT_HUB = 'report_hub',
  REPORT_HUB_FINANCIAL_REPORT = 'report_hub_financial_report',
  REPORT_HUB_PARTNER_REPORT = 'report_hub_partner_report',
  USERS = 'users',
  PARTNERS = 'partners',
  PARTNER_PRODUCTS = 'partner_products',
}
