import { Box } from '@chakra-ui/react';

const RedAsterisk = () => {
  return (
    <Box as="span" color="red">
      *
    </Box>
  );
};

export default RedAsterisk;
