import { Box, Button, FormControl, FormErrorMessage, FormLabel, Input, VStack } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import * as Yup from 'yup';
import { usaStates } from '../../../../../../app/constants/usaStates';
import { useGetPartnerListQuery } from '../../../../../../app/services/partner/api/partner';
import { useGetAllPartnerProductTagQuery } from '../../../../../../app/services/partner/api/refPartnerProductTag';
import { useGetAllProductTypeQuery } from '../../../../../../app/services/partner/api/refProductType';
import { AddPartnerModel } from '../../../../../../app/services/partner/api/types';
import {
  AddPartnerStepProp,
  addPartnerFieldMaxLength,
  addPartnerFormSchema,
  countries,
  selectStyles,
} from '../../../helper';
import { useAppSelector } from '../../../../../../app/state/hooks';
import { useGetAllProductTiersForDisplayQuery } from '../../../../../../app/services/partner/api/refProductTier';

const FormSchema = Yup.object().shape({
  ...addPartnerFormSchema,
  partner_id: Yup.number().moreThan(0).required(),
  ref_partner_product_tag_ids: Yup.array().required().of(Yup.number().moreThan(0)),
});

const Step1: FC<AddPartnerStepProp> = ({ data, activeStep, setData, setActiveStep }) => {
  const productTypesDetails = useGetAllProductTypeQuery();
  const partnerProductTagsDetails = useGetAllPartnerProductTagQuery();
  const partnerProducTierDetails = useGetAllProductTiersForDisplayQuery();
  const { isLoreHealthAdmin, selectedPartnerProduct } = useAppSelector(s => s.user);
  const [showTierOptions, setShowTierOptions] = useState(false)
  const [selectedProductType, setSelectedProductType] = useState<string | null>(null);
  /**
   * Handles change event for product type select. If the selected product type is 'tier'
   * then show the tier options, otherwise hide them.
   * @param {React.ChangeEvent<HTMLSelectElement>} e - The change event.
   */
  const handleProductTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSelectedProductType(value);
    
    // Show or hide tier options based on the selected product type
    setShowTierOptions(value === 'Trial');
  };
  
  const partnerProductId = selectedPartnerProduct?.partner_product_id || 0;

  const productTypesMap = productTypesDetails.data?.reduce((acc, item) => {
    if (item.product_type_name && item.ref_product_type_id !== null) {
      acc[item.product_type_name] = item.ref_product_type_id;
    }
    return acc;
  }, {} as Record<string, number>)

  const partnersDetails = useGetPartnerListQuery({
    page_number: 1,
    page_size: 99999,
    search_string: '',
    sort_column: 'partner_name',
    sort_order: 'asc',
    partner_product_id: isLoreHealthAdmin ? 0 : partnerProductId,
  });

  const {
    handleSubmit,
    errors,
    touched,
    handleChange,
    values,
    setValues,
    resetForm,
    setFieldValue,
    setFieldTouched,
    submitForm,
  } = useFormik<AddPartnerModel>({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: data,
    onSubmit: (values, form) => {
      setData(d => ({ ...d, ...values }));
      setActiveStep(i => i + 1);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <VStack className="da-container">
        <Box className="da-row">
          <FormControl isInvalid={!!errors.partner_id && touched.partner_id}>
            <FormLabel htmlFor="partner_id">Select a Product</FormLabel>
            <Box w="100%">
              <Select
                size="sm"
                id="partner_id"
                name="partner_id"
                placeholder={partnersDetails.isLoading || partnersDetails.isFetching ? 'Loading...' : ''}
                isDisabled={partnersDetails.isLoading || partnersDetails.isFetching}
                useBasicStyles
                value={(() => {
                  const val = partnersDetails.data?.data?.find(m => m.partner_id === values.partner_id);
                  return val
                    ? {
                        label: val.partner_name,
                        value: val.partner_id,
                      }
                    : undefined;
                })()}
                options={(partnersDetails.data?.data ?? [])
                  .filter((item, index, self) =>
                    index === self.findIndex((t) => (t.partner_id === item.partner_id))
                  )
                  .map(m => {
                    return {
                      label: m.partner_name,
                      value: m.partner_id,
                    };
                  })}
                  //commented for fixing duplication
                  // options={Array.from(new Set(partnersDetails.data?.data ?? []), m => {
                  //   return {
                  //     label: m.partner_name,
                  //     value: m.partner_id,
                  //   };
                  // })}
                onChange={e => {
                  if (e) {
                    const val = partnersDetails.data?.data?.find(m => m.partner_id === e.value);

                    if (val && val.partner_name)
                      setValues({
                        ...values,
                        partner_id: e.value,
                        partner_name: val.partner_name,
                        partner_tin: val.partner_tin,
                        partner_address_1: val.partner_address_1,
                        partner_address_2: val.partner_address_2,
                        partner_city: val.partner_city,
                        partner_state: val.partner_state,
                        partner_zip_code: val.partner_zip_code,
                        partner_country: val.partner_country,
                      });
                  }
                }}
                chakraStyles={selectStyles<number>()}
              />
              <FormErrorMessage>{errors.partner_id}</FormErrorMessage>
            </Box>
          </FormControl>
        </Box>

        <Box className="da-row">
          <FormControl isInvalid={!!errors.partner_tin && touched.partner_tin}>
            <FormLabel htmlFor="partner_tin">Partner TIN</FormLabel>
            <Box w="full">
              <Input
                size="sm"
                id="partner_tin"
                name="partner_tin"
                onChange={handleChange}
                onBlur={handleChange}
                value={values.partner_tin ?? ''}
                maxLength={addPartnerFieldMaxLength.partner_tin}
              />
              <FormErrorMessage>{errors.partner_tin}</FormErrorMessage>
            </Box>
          </FormControl>

          <FormControl isInvalid={!!errors.doing_business_as && touched.doing_business_as}>
            <FormLabel htmlFor="doing_business_as">Doing Business as</FormLabel>
            <Box w="full">
              <Input
                size="sm"
                id="doing_business_as"
                name="doing_business_as"
                onChange={handleChange}
                onBlur={handleChange}
                value={values.doing_business_as ?? ''}
                maxLength={addPartnerFieldMaxLength.doing_business_as}
              />
              <FormErrorMessage>{errors.doing_business_as}</FormErrorMessage>
            </Box>
          </FormControl>
        </Box>

        <Box className='da-row'>
        <FormControl isInvalid={!!errors.ref_product_type_id && touched.ref_product_type_id}>
            <FormLabel htmlFor="ref_product_type_id">Product Type</FormLabel>
            <Box w="100%">
              <Select
                size="sm"
                id="ref_product_type_id"
                name="ref_product_type_id"
                placeholder={productTypesDetails.isLoading || productTypesDetails.isFetching ? 'Loading...' : ''}
                isDisabled={productTypesDetails.isLoading || productTypesDetails.isFetching}
                useBasicStyles
                value={(() => {
                  const val = productTypesDetails.data?.find(m => m.ref_product_type_id === values.ref_product_type_id);
                  return val
                    ? {
                        label: val.product_type_name, // Ensure this matches the expected type
                        value: val.ref_product_type_id, // Ensure this is the correct type (number)
                      }
                    : undefined; // Make sure to return undefined if no match
                })()}
                options={[
                  ...(productTypesDetails.data ?? []).map(m => {
                    return {
                      label: m.product_type_name,
                      value: m.ref_product_type_id,
                    };
                  }),
                ]}
                onChange={(newValue, actionMeta) => {
                  if (newValue) {
                    setValues({
                      ...values,
                      ref_product_type_id: newValue.value,
                    });
                    setSelectedProductType(newValue.label);
                    setShowTierOptions(newValue.label === 'Trial');
                  }
                }} 
                chakraStyles={selectStyles<number>()}
              />
              <FormErrorMessage>{errors.ref_product_type_id}</FormErrorMessage>
            </Box>
          </FormControl>

        {showTierOptions && (
            <FormControl isInvalid={!!errors.ref_product_tier_id && touched.ref_product_tier_id}>
              <FormLabel htmlFor="ref_product_tier_id">Product Tier</FormLabel>
              <Box w="100%"> 
                <Select
                size="sm"
                id='ref_product_tier_id'
                name='ref_product_tier_id'
                placeholder={partnerProducTierDetails.isLoading || partnerProducTierDetails.isFetching ? 'Loading...' : ''}
                isDisabled={partnerProducTierDetails.isLoading || partnerProducTierDetails.isFetching}
                useBasicStyles
                value={(() => {
                  const val = partnerProducTierDetails.data?.find(m => m.ref_product_tier_id === values.ref_product_tier_id);
                  return val
                    ? {
                        label: val.display_text,
                        value: val.ref_product_tier_id,
                      }
                    : undefined;
                })()}
                options={[
                  ...(partnerProducTierDetails.data ?? []).map(m => {
                    return {
                      label: m.display_text,
                      value: m.ref_product_tier_id,
                    };
                  }),
                ]}
                onChange={e => {
                  e &&
                    setValues({
                      ...values,
                      ref_product_tier_id: e.value,
                    });
                }}
                chakraStyles={selectStyles<number>()}
              /> 
              <FormErrorMessage>{errors.ref_product_tier_id}</FormErrorMessage>   
              </Box>
            </FormControl>
            )}
          </Box>
        
        <Box className="da-row">
          <FormControl isInvalid={!!errors.partner_address_1 && touched.partner_address_1}>
            <FormLabel htmlFor="partner_address_1">Address 1</FormLabel>
            <Box w="full">
              <Input
                size="sm"
                id="partner_address_1"
                name="partner_address_1"
                onChange={handleChange}
                onBlur={handleChange}
                value={values.partner_address_1 ?? ''}
                maxLength={addPartnerFieldMaxLength.partner_address_1}
              />
              <FormErrorMessage>{errors.partner_address_1}</FormErrorMessage>
            </Box>
          </FormControl>

          <FormControl isInvalid={!!errors.partner_address_2 && touched.partner_address_2}>
            <FormLabel htmlFor="partner_address_2">Address 2</FormLabel>
            <Box w="full">
              <Input
                size="sm"
                id="partner_address_2"
                name="partner_address_2"
                onChange={handleChange}
                onBlur={handleChange}
                value={values.partner_address_2 ?? ''}
                maxLength={addPartnerFieldMaxLength.partner_address_2}
              />
              <FormErrorMessage>{errors.partner_address_2}</FormErrorMessage>
            </Box>
          </FormControl>
        </Box>
        
        <Box className="da-row">
          <FormControl isInvalid={!!errors.partner_city && touched.partner_city}>
            <FormLabel htmlFor="partner_city">City</FormLabel>
            <Box w="full">
              <Input
                size="sm"
                id="partner_city"
                name="partner_city"
                onChange={handleChange}
                onBlur={handleChange}
                value={values.partner_city ?? ''}
                maxLength={addPartnerFieldMaxLength.partner_city}
              />
              <FormErrorMessage>{errors.partner_city}</FormErrorMessage>
            </Box>
          </FormControl>

          <FormControl isInvalid={!!errors.partner_country && touched.partner_country}>
            <FormLabel htmlFor="partner_country">Country</FormLabel>
            <Box w="100%">
              <Select
                size="sm"
                id="partner_country"
                name="partner_country"
                placeholder=""
                useBasicStyles
                value={(() => {
                  return values.partner_country
                    ? {
                        label: values.partner_country,
                        value: values.partner_country,
                      }
                    : undefined;
                })()}
                options={countries.map(m => {
                  return {
                    label: m,
                    value: m,
                  };
                })}
                onChange={e => {
                  e &&
                    setValues({
                      ...values,
                      partner_country: e.value,
                    });
                }}
                chakraStyles={selectStyles<string>()}
              />
              <FormErrorMessage>{errors.partner_country}</FormErrorMessage>
            </Box>
          </FormControl>
        </Box>
        <Box className="da-row">
        <FormControl isInvalid={!!errors.partner_state && touched.partner_state}>
            <FormLabel htmlFor="partner_state">State</FormLabel>
            <Box w="100%">
              <Select
                size="sm"
                id="partner_state"
                name="partner_state"
                placeholder=""
                useBasicStyles
                value={(() => {
                  const val = usaStates.find(m => m.abbreviation === values.partner_state);
                  return val
                    ? {
                        label: val.name,
                        value: val.abbreviation,
                      }
                    : undefined;
                })()}
                options={usaStates.map(m => {
                  return {
                    label: m.name,
                    value: m.abbreviation,
                  };
                })}
                onChange={e => {
                  e &&
                    setValues({
                      ...values,
                      partner_state: e.value,
                    });
                }}
                chakraStyles={selectStyles<string>()}
              />
              <FormErrorMessage>{errors.partner_state}</FormErrorMessage>
            </Box>
          </FormControl>

          <FormControl isInvalid={!!errors.partner_zip_code && touched.partner_zip_code}>
            <FormLabel htmlFor="partner_zip_code">Postal Code</FormLabel>
            <Box w="full">
              <Input
                size="sm"
                id="partner_zip_code"
                name="partner_zip_code"
                onChange={handleChange}
                onBlur={handleChange}
                value={values.partner_zip_code ?? ''}
                maxLength={addPartnerFieldMaxLength.partner_zip_code}
              />
              <FormErrorMessage>{errors.partner_zip_code}</FormErrorMessage>
            </Box>
          </FormControl>
          </Box>

        <Box className="da-row">
          <FormControl isInvalid={!!errors.ref_partner_product_tag_ids && !!touched.ref_partner_product_tag_ids}>
            <FormLabel htmlFor="ref_partner_product_tag_ids">Product Tag</FormLabel>
            <Box w="100%">
              <Select
                size="sm"
                id="ref_partner_product_tag_ids"
                name="ref_partner_product_tag_ids"
                placeholder={
                  partnerProductTagsDetails.isLoading || partnerProductTagsDetails.isFetching ? 'Loading...' : ''
                }
                isDisabled={partnerProductTagsDetails.isLoading || partnerProductTagsDetails.isFetching}
                isMulti
                useBasicStyles
                value={(() => {
                  return values.ref_partner_product_tag_ids.map(id => {
                    const val = partnerProductTagsDetails.data?.find(m => m.ref_partner_product_tag_id === id);
                    return val
                      ? {
                          label: val.partner_product_tag,
                          value: val.ref_partner_product_tag_id,
                        }
                      : {
                          // should not arrive here, but add just incase
                          label: '',
                          value: -1,
                        };
                  });
                })()}
                options={(partnerProductTagsDetails.data ?? []).map(m => {
                  return {
                    label: m.partner_product_tag,
                    value: m.ref_partner_product_tag_id,
                  };
                })}
                onChange={e => {
                  e &&
                    setValues({
                      ...values,
                      ref_partner_product_tag_ids: e.map(m => m.value),
                    });
                }}
                chakraStyles={{
                  control: (provided, state) => ({
                    ...provided,
                    rounded: 'md',
                    color: 'blackAlpha.600',
                  }),
                  menuList: (provided, state) => ({
                    ...provided,
                    rounded: 'md',
                    color: 'blackAlpha.600',
                  }),
                }}
              />
              <FormErrorMessage>{errors.ref_partner_product_tag_ids}</FormErrorMessage>
            </Box>
          </FormControl>
        </Box>
      </VStack>

      <Box w="100%" textAlign="right">
        <Button type="submit" colorScheme="brand.main">
          Next
        </Button>
      </Box>
    </form>
  );
};

export default Step1;
