import { partnerApi } from '.';
import { AddUserSessionResponseModel, AddUserSessionRequestModel, PurgeUserSessionModel } from './types';

const baseUrl = '/user-session';

const extendedApi = partnerApi.injectEndpoints({
  endpoints: build => ({
    addUserSession: build.mutation<AddUserSessionResponseModel, AddUserSessionRequestModel>({
      query: body => ({
        url: baseUrl,
        body: body,
        method: 'POST',
      }),
    }),

    purgeUserSession: build.mutation<void, PurgeUserSessionModel>({
      query: body => ({
        url: baseUrl + '/purge',
        body: body,
        method: 'POST',
      }),
    }),
  }),
});

export const { useAddUserSessionMutation, usePurgeUserSessionMutation } = extendedApi;
