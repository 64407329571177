import {
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  VStack,
} from '@chakra-ui/react';
import { FiMoreVertical } from 'react-icons/fi';
import { CellProps } from 'react-table';
import AssignUsers from './ActionCells/AssignUsers';
import EditUserRoles from './ActionCells/EditUserRoles';
import DisablePartnerProduct from './ActionCells/DisablePartnerProduct';
import { PartnerProductModel } from '../../../app/services/partner/api/types';
import EditPartnerProductInfoButton from './ActionCells/EditPartnerProductInfo';
import AppAuth from '../../../features/AppAuth';
import { AppAccess } from '../../../app/constants/appAccesses';

const OptionsButton = ({ row: { original } }: CellProps<PartnerProductModel>) => {
  return (
    <>
      <Popover placement="bottom-end">
        <PopoverTrigger>
          <IconButton aria-label="Options" icon={<FiMoreVertical />} variant="ghost" colorScheme="brand.main" h="5" />
        </PopoverTrigger>
        <Portal>
          <PopoverContent w="fit-content">
            <PopoverArrow />
            <PopoverBody>
              <VStack>
                <AppAuth requiredAppAccess={AppAccess.LoreHealthAdminAccess}>
                  <EditPartnerProductInfoButton original={original} />
                </AppAuth>
                <AppAuth requiredAppAccess={AppAccess.PartnerAdministratorPHI}>
                  <EditUserRoles original={original} />
                </AppAuth>
                <AppAuth requiredAppAccess={AppAccess.LoreHealthAdminAccess}>
                  <AssignUsers original={original} />
                </AppAuth>
                <AppAuth requiredAppAccess={AppAccess.LoreHealthAdminAccess}>
                  <DisablePartnerProduct original={original} />
                </AppAuth>
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  );
};

export default OptionsButton;
