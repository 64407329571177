import { Box, VStack, HStack, Text } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import CustomTable from '../../../components/CustomTable';
import { UserRoleAccessRequestDisplayModel } from '../../../app/services/partner/api/types';
import { AppAccess } from '../../../app/constants/appAccesses';
import {
  useGetUserRoleAccessRequestsQuery,
  useLazyGetUserRoleAccessRequestsQuery,
} from '../../../app/services/partner/api/userRoleAccessRequest';
import { CustomTableHeader } from '../../../app/types/appType';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import { CellProps } from 'react-table';
import { useAppSelector } from '../../../app/state/hooks';
import { AppSize } from '../../../app/constants';
import ApproveAccessRequestButton from './ApproveAccessRequestButton';
import DenyAccessRequestButton from './DenyAccessRequestButton';

type Props = {};

const ResultData: FC<Props> = () => {
  const [requests, setRequests] = useState<UserRoleAccessRequestDisplayModel[]>([]);
  const [processedRequests, setProcessedRequests] = useState<number>(0);
  const { isSideNavOpen } = useAppSelector(s => s.app);

  const sideNavWidth = isSideNavOpen ? AppSize.sideNav.width.open : AppSize.sideNav.width.close;

  const [getRequests, getRequestsDetails] = useLazyGetUserRoleAccessRequestsQuery();

  const logRequestProcessed = (num: number) => {
    setProcessedRequests(c => c + num);
  };

  useEffect(() => {
    getRequests(1)
      .unwrap()
      .then(res => {
        setRequests(res);
      });
  }, []);

  useEffect(() => {
    getRequests(1)
      .unwrap()
      .then(res => {
        setRequests(res);
      });
  }, [processedRequests]);

  const Header: CustomTableHeader<UserRoleAccessRequestDisplayModel> = [
    {
      Header: 'ACTION',
      accessor: 'user_role_access_request_id',
      Cell: ({ row: { original } }: { row: { original: UserRoleAccessRequestDisplayModel } }) => {
        return (
          <HStack>
            <ApproveAccessRequestButton
              id={original.user_role_access_request_id}
              user={original.requestor_user_name}
              role={original.role_display_name}
              onDone={logRequestProcessed}
            />
            <DenyAccessRequestButton id={original.user_role_access_request_id} onDone={logRequestProcessed} />
          </HStack>
        );
      },
    },
    {
      Header: 'USER',
      accessor: 'requestor_user_name',
    },
    {
      Header: 'ROLE',
      accessor: 'role_display_name',
    },
    {
      Header: 'BUSINESS JUSTIFICATION',
      accessor: 'business_justification',
    },
    {
      Header: 'CREATION DATE',
      Cell: ({ row: { original } }: CellProps<UserRoleAccessRequestDisplayModel>) => {
        return <>{convertUtcToLocal(original.row_created_datetime_utc) || '-'}</>;
      },
    },
  ];

  return (
    <VStack>
      <Box bg="white" shadow="base" p="4" rounded="md" minW="container.md">
        <CustomTable
          variant="table"
          headers={Header}
          isLoading={getRequestsDetails.isLoading}
          isFetching={getRequestsDetails.isFetching}
          data={requests || []}
          pageCount={1}
          pageSize={requests.length}
          totalRecords={requests.length}
          search={''}
          hideRowsPerPage
          onPageChange={index => {}}
          onPageSizeChange={size => {}}
          onPageSearch={search => {}}
          onSort={() => {}}
          hidePagination={true}
          tableSort={true}
          styles={{
            pagination: { justifyContent: 'start' },
            header: { justifyContent: 'left' },
            tableContainer: {
              sx: {
                maxH: 'calc(100vw - 10rem)',
                maxW: `calc(100vw - 5rem - ${sideNavWidth})`,
                overflow: 'auto',
                table: {
                  borderCollapse: 'separate',
                  borderSpacing: '0',
                  'thead > tr': {
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    zIndex: 2,
                    height: 'auto',
                    bg: 'white',
                  },
                },
              },
            },
          }}
          showNoRecords
        />
      </Box>
    </VStack>
  );
};

export default ResultData;
