import { Box, Flex } from '@chakra-ui/react';
import FilterPane from './FilterPane';
import { useState, useEffect } from 'react';
import { FilterDataDetailModel } from '../../../../app/services/partner/api/types';
import ResultData from './ResultData';

type IProps = {
  updateView: (newValue: number) => void;
};

const MyRoles = ({ updateView }: IProps) => {
  const [filters, setFilters] = useState<FilterDataDetailModel[]>([]);
  const [selectedRoleId, setSelectedRoleId] = useState<number>(0);

  useEffect(() => {
    if (selectedRoleId > 0) {
      updateView(selectedRoleId);
    }
  }, [selectedRoleId]);

  const updateSelectedRoleId = (newValue: number) => {
    setSelectedRoleId(newValue);
  };

  const onFiltersChange = (filters: FilterDataDetailModel[]) => {
    setFilters(filters);
  };

  return (
    <>
      <Box>
        <Flex w="100%">
          <Box w={300}>
            <FilterPane onFiltersChange={onFiltersChange} />
          </Box>
          <Box flex={1} px={3}>
            <ResultData filters={filters} updateSelectedRoleId={updateSelectedRoleId} />
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default MyRoles;
