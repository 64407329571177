import { Box } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { useLazyGetPartnerProductPaginatedListQuery } from '../../../app/services/partner/api/partnerProduct';
import { useAppSelector } from '../../../app/state/hooks';
import CsvDownloadButton, { CsvDownloadButtonProps } from '../../../components/CsvDownloadButton';

import { useAddUserActivityMutation } from '../../../app/services/partner/api/userActivity';
import { PARTNER_PAGE } from '../../../app/constants/partnerPage';
import { PAGE_ACTIVITY } from '../../../app/constants/pageActivity';

const transform: CsvDownloadButtonProps['transform'] = [
  {
    Header: 'Partner Product/Doing Business As',
    accessor: 'doing_business_as',
  },
  {
    Header: 'Partner Name',
    accessor: 'partner_name',
  },
  {
    Header: 'Product Type',
    accessor: 'product_type_name',
  },
  {
    Header: 'Product Tag',
    accessor: 'partner_product_tags',
  },
  {
    Header: 'Enrollment Start Date',
    accessor: 'enrollment_start_dt',
  },
  {
    Header: 'Enrollment End Date',
    accessor: 'enrollment_end_dt',
  },
  {
    Header: 'Users',
    accessor: 'user_count',
  },
];

const ExportAsCsvButton = () => {
  const [searchParams] = useSearchParams();
  const toast = useToast();
  const [get] = useLazyGetPartnerProductPaginatedListQuery();

  const [postAddUserActivity] = useAddUserActivityMutation();
  const { selectedPartnerProduct, activityList, pageList, userSessionId, isLoreHealthAdmin } = useAppSelector(
    s => s.user,
  );

  const partner_product_page = pageList.find(i => i.page_name === PARTNER_PAGE.PARTNER_PRODUCTS);
  const export_partner_product = activityList.find(i => i.activity_name === PAGE_ACTIVITY.EXPORT_PARTNER_PRODUCT);

  const handleLogEvent = () => {
    if (export_partner_product && partner_product_page && userSessionId) {
      postAddUserActivity({
        user_session_id: userSessionId,
        ref_activity_id: export_partner_product.ref_activity_id,
        ref_page_id: partner_product_page.ref_page_id,
        partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      })
        .unwrap()
        .then(res => {});
    }
  };

  if (!selectedPartnerProduct) return null;

  const exportName =
    selectedPartnerProduct.doing_business_as?.toLowerCase().replace(/ /g, '_') + '_partner_products_' + Date.now();

  return (
    <Box>
      <CsvDownloadButton
        exportName={exportName}
        transform={transform}
        handleEmptyDownload={() => toast({ description: 'No entries', status: 'info' })}
        styles={{
          button: {
            size: 'sm',
            px: '5',
          },
        }}
        handleDownload={async () => {
          const partnerProductId = selectedPartnerProduct?.partner_product_id || 0;
          const res = await get({
            page_number: 1,
            page_size: 99999,
            search_string: searchParams.get('search') ?? '',
            sort_order: 'asc',
            sort_column: 'partner_product_id',
            partner_product_id: isLoreHealthAdmin ? 0 : partnerProductId,
          })
            .unwrap()
            .finally(() => handleLogEvent());
          return res.data;
        }}
      >
        Export as CSV
      </CsvDownloadButton>
    </Box>
  );
};

export default ExportAsCsvButton;
