import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { PartnerProductModel } from '../../../../../app/services/partner/api/types';
import { useState } from 'react';
import PartnerProductDetailsModal from '../../../PartnerProductDetailsModal';

const EditPartnerProductInfoButton = ({ original }: { original: PartnerProductModel }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleOnClick = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Button
        size="sm"
        _hover={{ textDecoration: 'none' }}
        variant="link"
        fontWeight="normal"
        textAlign="left"
        onClick={handleOnClick}
      >
        <Box w="100%">Edit Partner Product Info</Box>
      </Button>

      <PartnerProductDetailsModal
        id={original.partner_product_id}
        name={original.doing_business_as}
        edit={true}
        openModal={isModalOpen}
        setModal={setIsModalOpen}
      />
    </>
  );
};

export default EditPartnerProductInfoButton;
