import { Box, VStack, HStack, Flex, Text } from '@chakra-ui/react';
import { UserRoleAccessRequestDisplayModel } from '../../../../../app/services/partner/api/types';
import { useEffect } from 'react';

type IProps = {
  requestId: number;
  handleSetLatestStatus: (status: string) => void;
  request: UserRoleAccessRequestDisplayModel | null | undefined;
};
const RoleDetails = ({ requestId, handleSetLatestStatus, request }: IProps) => {
  useEffect(() => {
    if (request) {
      handleSetLatestStatus(request.status_display_name);
    }
  }, [request]);

  return (
    <>
      <VStack>
        <Box pb={'3'}>
          <Text sx={{ fontWeight: 'bold', fontSize: '20' }}>Request Details</Text>
        </Box>

        <Box>
          <HStack pt="2" display="flex" alignItems="flex-start">
            <Box width="250px" pl="2" alignItems={'start'}>
              <Text color={'#585858'} fontWeight={'bold'}>
                ROLE NAME
              </Text>
            </Box>
            <Box width="250px" pl="2">
              {request?.role_display_name ?? '-'}
            </Box>
            <Box width="250px" pl="2">
              <Text color={'#585858'} fontWeight={'bold'}>
                REQUEST ID
              </Text>
            </Box>
            <Box width="250px" pl="2">
              {requestId}
            </Box>
          </HStack>

          <HStack pt="3" display="flex" alignItems="flex-start">
            <Box width="250px" pl="2" display="flex" alignItems={'flex-start'}>
              <Text color={'#585858'} fontWeight={'bold'}>
                ROLE DESCRIPTION
              </Text>
            </Box>
            <Box width="250px" pl="2">
              {request?.role_desc}
            </Box>
            <Box width="250px" pl="2">
              <Text color={'#585858'} fontWeight={'bold'}>
                REQUESTOR
              </Text>
            </Box>
            <Box width="250px" pl="2">
              {request?.requestor_user_name}
            </Box>
          </HStack>

          <Flex pt="3" display="flex" alignItems="flex-start">
            <Box width="260px" pl="2" display="flex" alignItems={'flex-start'}>
              <Text color={'#585858'} fontWeight={'bold'}>
                BUSINESS JUSTIFICATION
              </Text>
            </Box>
            <Box flex="1" pl="1">
              {request?.business_justification}
            </Box>
          </Flex>
        </Box>
      </VStack>
    </>
  );
};

export default RoleDetails;
