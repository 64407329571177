import { Button, IconButton } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  ButtonGroup,
  CloseButton,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { defaultErrorMessage } from '../../app/constants';
import {
  useAddPartnerProductProviderMutation,
  useLazyGetExternalProviderQuery,
} from '../../app/services/partner/api/partnerProductProvider';
import { CompoundPartnerProductProviderModel } from '../../app/services/partner/api/types';
import { useAppSelector } from '../../app/state/hooks';
import ProviderDetails from './ProviderDetails';
import UpdateIncorrectInfoBox from './UpdateIncorrectInfoBox';

const AddProviderButton = () => {
  const [searchNpi, setSearchNpi] = useState<string>('');
  const [externalProvider, setExternalProvider] = useState<CompoundPartnerProductProviderModel | null>(null);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { selectedPartnerProduct } = useAppSelector(s => s.user);
  const toast = useToast();

  const [getExternal, getExternalDetails] = useLazyGetExternalProviderQuery();
  const [addProvider, addProviderDetails] = useAddPartnerProductProviderMutation();

  const handleOpen = () => {
    setSearchNpi('');
    setExternalProvider(null);
    onOpen();
  };

  const handleAddProvider = () => {
    if (externalProvider && selectedPartnerProduct) {
      addProvider({
        provider_first_name: externalProvider.provider_first_name,
        provider_last_name: externalProvider.provider_last_name,
        provider_middle_name: externalProvider.provider_middle_name,
        provider_tin: selectedPartnerProduct.partner_tin,
        partner_product_id: selectedPartnerProduct.partner_product_id,
        provider_npi: externalProvider.provider_npi,
        provider_pecos_id: externalProvider.provider_pecos_id,
        provider_enrollment_id: externalProvider.provider_enrollment_id,
        provider_state_cd: externalProvider.provider_state_cd,
        provider_provider_type_desc: externalProvider.provider_provider_type_desc,
        provider_org_name: externalProvider.provider_org_name,
        provider_gender: externalProvider.provider_gender,
        provider_provider_type_cd: externalProvider.provider_provider_type_cd,
        user_partner_product_id: selectedPartnerProduct.user_partner_product_id,
        npireg_last_processed_datetime_utc: externalProvider.npireg_last_processed_datetime_utc,
        npireg_address_1: externalProvider.npireg_address_1,
        npireg_city: externalProvider.npireg_city,
        npireg_state: externalProvider.npireg_state,
        npireg_postal_code: externalProvider.npireg_postal_code,
        npireg_telephone_number: externalProvider.npireg_telephone_number,
        npireg_enumeration_date: externalProvider.npireg_enumeration_date,
        npireg_last_updated: externalProvider.npireg_last_updated,
        npireg_taxonomy: externalProvider.npireg_taxonomy,
        disabled_datetime_utc: null,
        list_incorrect_fields: [],
      })
        .unwrap()
        .then(() => {
          onClose();
          toast({
            description: 'Successfully added provider',
            status: 'success',
          });
        })
        .catch(() => {
          toast({ description: defaultErrorMessage, status: 'error' });
        });
    }
  };

  return (
    <>
      <Button size="sm" colorScheme="brand.main" gap="2" px="5" onClick={handleOpen}>
        <AddIcon fontSize="xx-small" />
        Add Provider
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="4xl"
        closeOnOverlayClick={
          !addProviderDetails.isLoading && !getExternalDetails.isLoading && !getExternalDetails.isFetching
        }
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody p="4" display="flex" flexDir="column" gap="6">
            <Box display="flex" gap="4">
              <Heading as="h3" size="sm">
                Provider Details
              </Heading>

              <Box minW="222px" w="222px" ml="auto" alignSelf="start" justifySelf="start">
                <FormControl isInvalid={searchNpi.length > 0 && searchNpi.length < 10}>
                  <Input
                    placeholder="Search NPI (numeric)"
                    size="sm"
                    rounded="md"
                    value={searchNpi}
                    type="number"
                    onChange={e => setSearchNpi(e.target.value)}
                    isDisabled={
                      getExternalDetails.isLoading || getExternalDetails.isFetching || addProviderDetails.isLoading
                    }
                  />

                  <FormErrorMessage>{'Please enter a valid NPI, must numeric and 10 characters long'}</FormErrorMessage>
                </FormControl>
              </Box>
              <Button
                size="sm"
                colorScheme="brand.main"
                isDisabled={!searchNpi || searchNpi.length !== 10 || addProviderDetails.isLoading}
                isLoading={getExternalDetails.isLoading || getExternalDetails.isFetching}
                onClick={() => {
                  if (!!searchNpi) {
                    setExternalProvider(null);
                    getExternal(searchNpi)
                      .unwrap()
                      .then(res => setExternalProvider(res))
                      .catch(e => {
                        console.log(e.status);
                        console.log(e.data?.error_message);
                      });
                  }
                }}
              >
                Search
              </Button>
              <Box>
                <IconButton
                  variant="link"
                  color="black"
                  size="xs"
                  aria-label="close modal"
                  onClick={onClose}
                  icon={<CloseButton />}
                  isDisabled={
                    getExternalDetails.isLoading || getExternalDetails.isFetching || addProviderDetails.isLoading
                  }
                />
              </Box>
            </Box>
            {externalProvider && (
              <>
                <UpdateIncorrectInfoBox />

                <Heading as="h2" size="md" fontWeight="semibold" textAlign="center">
                  TIN {selectedPartnerProduct?.partner_tin}
                </Heading>

                <ProviderDetails entry={externalProvider} />
                <ButtonGroup w="100%">
                  <Button
                    ml="auto"
                    colorScheme="brand.main"
                    size="sm"
                    onClick={handleAddProvider}
                    isLoading={addProviderDetails.isLoading}
                  >
                    Add Provider
                  </Button>
                </ButtonGroup>
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddProviderButton;
