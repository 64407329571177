import { partnerApi } from '.';
import { PaginatedApiResult, PaginatedQueryParams } from '../../types';
import { AddRefAppAccessModel, RefAppAccessDisplayModel } from './types';

const baseUrl = '/ref-app-access';

const extendedApi = partnerApi.injectEndpoints({
  endpoints: build => ({
    getRefAppAccessDisplayList: build.query<RefAppAccessDisplayModel[], void>({
      query: () => ({
        url: baseUrl + '/display/list',
      }),
      providesTags: ['AppAccess'],
    }),

    addRefAppAccess: build.mutation<void, AddRefAppAccessModel>({
      query: body => ({
        body: body,
        method: 'POST',
        url: baseUrl,
      }),
      invalidatesTags: ['AppAccess'],
    }),
  }),
});

export const {
  useGetRefAppAccessDisplayListQuery,
  useLazyGetRefAppAccessDisplayListQuery,
  useAddRefAppAccessMutation,
} = extendedApi;
