import { StepsTheme as Steps } from 'chakra-ui-steps';
import {
  Badge,
  extendTheme,
  StyleFunctionProps,
  Th,
  theme,
  ThemeConfig,
  VStack,
  withDefaultColorScheme,
} from '@chakra-ui/react';

import { mode } from '@chakra-ui/theme-tools';
import brandColors from './brandColors';
import Checkbox from './components/checkbox';
import Tooltip from './components/tooltip';
import Error from './styles/error';
import SideNav from './styles/sideNav';
import StepContent from './styles/stepContent';
import Quill from './styles/quill';
const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-24px)',
};

/*
Body font color: #595959
Header bar color: #3794AA
Header small bar for test env color: #4DB748
Left menu text color: #888888
Left menu selected color: #13707F
Left menu icon color: #13707F
Button color: #3794AA
Error color: #D13745
*/
export const appColors = {
  brandColors,
  body: {
    textColor: brandColors.primary.whiteBlack.black,
  },
  header: {
    bgColor: brandColors.primary.teal[400],
  },
  brand: {
    error: brandColors.alert.red[300],
    main: {
      default: brandColors.primary.teal[300],
      50: '#dff9ff',
      100: '#bee6ef',
      200: '#9bd4e2',
      300: '#75c2d4',
      400: '#52b0c7',
      500: brandColors.primary.teal[300],
      600: brandColors.primary.teal[300],
      700: '#175462',
      800: '#05333d',
      900: '#001418',
    },
  },
  testEnvironmentIndicator: brandColors.primary.green[300],
  inactive: '#999999',
  activeRow: theme.colors.gray['100'],
  activeRowIndicator: {
    border: brandColors.primary.teal[300],
  },
  links: brandColors.secondary.blue[200],
  backgroundColor: '#F5F5F5',
  hightLightColor: '#F2F2F2',
};

Badge.defaultProps = { ...Badge.defaultProps, color: 'inherit' };
Th.defaultProps = { ...Th.defaultProps, color: 'inherit' };
VStack.defaultProps = { ...VStack.defaultProps, align: 'stretch' };

export default extendTheme(
  {
    styles: {
      global: (props: StyleFunctionProps) => ({
        'html,body': {
          color: mode(brandColors.primary.whiteBlack.black, brandColors.primary.whiteBlack[100])(props),
        },
        ...Error(props),
        ...SideNav(props),
        ...StepContent(props),
        ...Quill(props),
      }),
    },
    components: {
      Checkbox,
      Form: {
        variants: {
          floating: {
            container: {
              _focusWithin: {
                label: {
                  ...activeLabelStyles,
                },
              },
              'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label': {
                ...activeLabelStyles,
              },
              label: {
                top: 0,
                left: 0,
                zIndex: 2,
                position: 'absolute',
                backgroundColor: 'white',
                pointerEvents: 'none',
                mx: 3,
                px: 1,
                my: 2,
                transformOrigin: 'left top',
              },
            },
          },
        },
      },
      Steps,
      Tooltip,
    },
    colors: {
      ...appColors,
    },
  } as ThemeConfig,
  withDefaultColorScheme({
    colorScheme: 'brand.main',
    components: ['Checkbox', 'Switch', 'Tabs'],
  }),
);
