import { partnerApi } from '.';
import { UserPartnerProductModel, AddUserPartnerProductModel, ToggleUserPartnerProductModel } from './types';

const baseUrl = '/user-partner-product';

const extendedApi = partnerApi.injectEndpoints({
  endpoints: builder => ({
    getUserPartnerProducts: builder.query<UserPartnerProductModel[], number>({
      query: refUserId => baseUrl + `/${refUserId}`,
    }),

    addUserPartnerProduct: builder.mutation<void, AddUserPartnerProductModel>({
      query: body => ({
        body,
        url: baseUrl,
        method: 'POST',
      }),
      invalidatesTags: ['UserPartnerProduct'],
    }),
    toggleActiveUserPartnerProduct: builder.mutation<void, ToggleUserPartnerProductModel>({
      query: ({ user_partner_product_id, disabled }) => ({
        url: baseUrl + `/active/${disabled}/id/${user_partner_product_id}`,
        method: 'PUT',
      }),
    }),
  }),
});

export const {
  useGetUserPartnerProductsQuery,
  useLazyGetUserPartnerProductsQuery,
  useAddUserPartnerProductMutation,
  useToggleActiveUserPartnerProductMutation,
} = extendedApi;
