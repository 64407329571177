import {
  Box,
  Flex,
  Heading,
  IconButton,
  VStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Textarea,
  Spinner,
  Button,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import HorizontalLine from '../../../HorizontalLine';
import RedAsterisk from '../../../RedAsterisk';
import { AddRefAppAccessModel } from '../../../../app/services/partner/api/types';
import { useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { useAddRefAppAccessMutation } from '../../../../app/services/partner/api/refAppAccess';

import { useAppSelector } from '../../../../app/state/hooks';
import { PARTNER_PAGE } from '../../../../app/constants/partnerPage';
import { PAGE_ACTIVITY } from '../../../../app/constants/pageActivity';
import { useAddUserActivityMutation } from '../../../../app/services/partner/api/userActivity';

type AppAccessDetailsProps = {
  handleClose: () => void;
};

const defaultFormDataErrors = {
  app_access_display_name: '',
  app_access_desc: '',
};

const defaultRefAppAccess = {
  app_access_display_name: '',
  app_access_desc: '',
};

const AppAccessDetails = ({ handleClose }: AppAccessDetailsProps) => {
  const [refAppAccess, setRefAppAccess] = useState<AddRefAppAccessModel>(defaultRefAppAccess);
  const [errors, setErrors] = useState(defaultFormDataErrors);
  const [postRefAppAccess, postRefAppAccessDetails] = useAddRefAppAccessMutation();

  const { selectedPartnerProduct, activityList, pageList, userSessionId } = useAppSelector(s => s.user);
  const [postAddUserActivity] = useAddUserActivityMutation();

  const create_role_page = pageList.find(i => i.page_name === PARTNER_PAGE.CREATE_ROLE);
  const app_access_created = activityList.find(i => i.activity_name === PAGE_ACTIVITY.APP_ACCESS_CREATED);

  const toast = useToast();

  const handleLogEvent = () => {
    if (create_role_page && app_access_created && userSessionId) {
      postAddUserActivity({
        user_session_id: userSessionId,
        ref_activity_id: app_access_created.ref_activity_id,
        ref_page_id: create_role_page.ref_page_id,
        partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      })
        .unwrap()
        .then(res => {});
    }
  };

  const validate = () => {
    let newErrors = { ...errors };

    if (!refAppAccess.app_access_desc.trim()) {
      newErrors.app_access_desc = 'Description is required';
    } else {
      newErrors.app_access_desc = '';
    }

    if (!refAppAccess.app_access_display_name.trim()) {
      newErrors.app_access_display_name = 'Name is required';
    } else {
      newErrors.app_access_display_name = '';
    }
    setErrors(newErrors);

    return Object.values(newErrors).every(error => error === '');
  };

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validate()) {
      let isException = false;

      postRefAppAccess(refAppAccess)
        .unwrap()
        .then(res => {
          console.log(`REF_APP_ACCESS: ${JSON.stringify(res)}`);
        })
        .catch(error => {
          isException = true;
          toast({
            position: 'top-right',
            title: 'Error',
            description: 'There seems to be a problem. Please try again later.',
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
          console.log(`ERROR: ${JSON.stringify(error)}`);
        })
        .finally(() => {
          if (!isException) {
            handleLogEvent();
            handleClear();
            toast({
              position: 'top-right',
              title: 'Success!',
              description: 'App Access added.',
              status: 'success',
              duration: 9000,
              isClosable: true,
            });
          }
        });
    }
  };

  const handleClear = () => {
    setRefAppAccess(defaultRefAppAccess);
    setErrors(defaultFormDataErrors);
  };

  const handleOnStringPropertyChange = (prop_value: string, prop_name: string) => {
    setRefAppAccess(prevState => ({ ...prevState, [prop_name]: prop_value }));
  };

  return (
    <>
      <Flex justifyContent={'space-between'}>
        <Box pt={'1.5'} pb={'1.5'}>
          <Heading as="h3" size="sm">
            Add App Access
          </Heading>
        </Box>

        <Box>
          <IconButton
            sx={{ verticalAlign: 'middle' }}
            variant="link"
            color="black"
            size="xxs"
            aria-label="close modal"
            onClick={handleClose}
            icon={<CloseIcon fontSize={'sm'} />}
          />
        </Box>
      </Flex>
      <Box>
        <HorizontalLine marginTop="3" />
      </Box>

      <VStack>
        <form onSubmit={handleSave}>
          <Box>
            <Box sx={{ width: '100%' }} mr="2">
              <FormControl isInvalid={!!errors.app_access_display_name}>
                <FormLabel fontSize={'smaller'} mb="-0.2" htmlFor="app-access-display-name">
                  App Access Display Name
                  <RedAsterisk />
                </FormLabel>
                <Input
                  id="app-access-display-name"
                  name="app_access_display_name"
                  value={refAppAccess.app_access_display_name}
                  onChange={e => handleOnStringPropertyChange(e.target.value, e.target.name)}
                />
                <FormErrorMessage>{errors.app_access_display_name}</FormErrorMessage>
              </FormControl>
            </Box>

            <Box sx={{ width: '100%' }} pt={'5'} mr="2">
              <FormControl isInvalid={!!errors.app_access_desc}>
                <FormLabel fontSize={'smaller'} mb="-0.2" htmlFor="app-access-desc">
                  App Access Description
                  <RedAsterisk />
                </FormLabel>
                <Textarea
                  id="app-access-desc"
                  name="app_access_desc"
                  value={refAppAccess.app_access_desc}
                  onChange={e => handleOnStringPropertyChange(e.target.value, e.target.name)}
                />
                <FormErrorMessage>{errors.app_access_desc}</FormErrorMessage>
              </FormControl>
            </Box>
          </Box>

          <Box display="flex" justifyContent={'flex-end'} pt={'5'}>
            <Box display="flex">
              <Button size="sm" colorScheme="brand.main" px="5" type="submit">
                {postRefAppAccessDetails.isLoading ? <Spinner size="sm" /> : 'Save'}
              </Button>
            </Box>
          </Box>
        </form>
      </VStack>
    </>
  );
};

export default AppAccessDetails;
