import { Box, Accordion, AccordionItem, AccordionIcon, AccordionButton, HStack } from '@chakra-ui/react';
import { useLazyGetUserRoleListByRefUserIdQuery } from '../../app/services/partner/api/userRole';
import { useEffect, useState } from 'react';

interface UserRolePanelProps {
  refUserId: number;
}

interface UserRoleBoxProps {
  role: string | undefined | null;
}

const UserRoleBox: React.FC<UserRoleBoxProps> = ({ role }) => {
  return (
    <Box
      px={2}
      py={1}
      mr={2}
      mb={1}
      style={{
        border: '1px solid #e2e8f0',
        backgroundColor: '#e2e8f0',
        display: 'inline-block',
        fontWeight: 'bolder',
      }}
      rounded="md"
    >
      {role}
    </Box>
  );
};

const UserRolePanel: React.FC<UserRolePanelProps> = ({ refUserId }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [arrayUno, setArrayUno] = useState<(JSX.Element | undefined)[]>([]);
  const [arrayDos, setArrayDos] = useState<(JSX.Element | undefined)[]>([]);
  const [getUserRoles, getUserRolesDetails] = useLazyGetUserRoleListByRefUserIdQuery();

  const handleToggle = () => {
    setIsOpen(isOpen => !isOpen);
  };

  useEffect(() => {
    if (!!refUserId) {
      getUserRoles(refUserId)
        .unwrap()
        .then(roles => {
          const arrUno = roles.map(item => {
            if (item.role_display_name !== null)
              return <UserRoleBox key={item.user_role_id} role={item?.role_display_name} />;
          });
          setArrayUno(arrUno);

          const arrDos = roles.slice(0, 7).map(item => {
            if (item.role_display_name !== null)
              return <UserRoleBox key={item.user_role_id} role={item?.role_display_name} />;
          });
          setArrayDos(arrDos);
        })
        .catch(error => {
          console.log(`ERRORS: ${JSON.stringify(error)}`);
        })
        .finally(() => {});
    }
  }, [refUserId]);

  return (
    <HStack>
      <Accordion allowToggle sx={{ width: '100%' }}>
        <AccordionItem sx={{ width: '100%', borderTopColor: '#fff', borderBottomColor: '#fff' }} key={0}>
          <AccordionButton
            onClick={handleToggle}
            display="flex"
            justifyContent={'space-between'}
            w="100%"
            sx={{
              border: '1px solid #e1e1e1',
              borderRadius: '10px',
            }}
          >
            <Box sx={{ textAlign: 'left' }}>{isOpen ? arrayUno : arrayDos}</Box>
            <AccordionIcon />
          </AccordionButton>
        </AccordionItem>
      </Accordion>
    </HStack>
  );
};

export default UserRolePanel;
