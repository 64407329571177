import { UseToastOptions } from '@chakra-ui/react';

export const fileConfig = {
  acceptedFiles: (process.env.REACT_APP_FILE_TYPES || '').split(','),
  maxFileSize: process.env.REACT_APP_MAX_FILE_SIZE,
  maxFileCount: process.env.REACT_APP_MAX_FILE_COUNT,
};

export const DefaultToastOptions: UseToastOptions = {
  position: 'top-right',
  duration: 3000,
  variant: 'left-accent',
  isClosable: true,
};

export const headerHeight = '60px';
export const AppSize = {
  sideNav: {
    widthNumber: {
      open: 256,
      close: 72,
    },
    width: {
      open: '256px',
      close: '72px',
    },
  },
};

export const defaultErrorMessage = `Something went wrong, please try again later or contact admin.`;
