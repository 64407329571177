import { partnerApi } from '.';
import { GraphUserModel } from './types';

const baseUrl = '/pim';

const extendedApi = partnerApi.injectEndpoints({
  endpoints: build => ({
    getGraphUserDetails: build.query<GraphUserModel, string>({
      query: email_address => ({
        url: baseUrl + `/graph/user/${email_address}`,
      }),
      providesTags: ['PIM'],
    }),
  }),
});

export const { useGetGraphUserDetailsQuery, useLazyGetGraphUserDetailsQuery } = extendedApi;
