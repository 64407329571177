import { partnerApi } from '.';
import {
  AddUserRoleAccessRequestModel,
  AddUserRoleAccessRequestDetailModel,
  UserRoleAccessRequestDisplayModel,
  UserRoleAccessRequestDetailsDisplayModel,
  UserRoleAccessRequestStatusDisplayModel,
} from './types';

const baseUrl = '/user-role-access-request';

const extendedApi = partnerApi.injectEndpoints({
  endpoints: build => ({
    addUserRoleAccessRequest: build.mutation<
      {
        result: {
          user_role_access_request_id: number;
        };
        action: 'inserted';
      },
      AddUserRoleAccessRequestModel
    >({
      query: body => ({
        url: baseUrl,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['UserRoleAccessRequest'],
    }),
    addUserRoleAccessRequestDetail: build.mutation<
      {
        result: {
          user_role_access_request_detail_id: number;
        };
        action: 'inserted';
      },
      AddUserRoleAccessRequestDetailModel
    >({
      query: body => ({
        url: baseUrl + '/detail',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['UserRoleAccessRequest'],
    }),
    getUserRoleAccessRequestStatusList: build.query<UserRoleAccessRequestStatusDisplayModel[], number>({
      query: userRoleAccessRequestId => baseUrl + `/statuses/list/${userRoleAccessRequestId}`,
    }),
    getUserRoleAccessRequest: build.query<UserRoleAccessRequestDisplayModel, number>({
      query: userRoleAccessRequestId => baseUrl + `/${userRoleAccessRequestId}`,
    }),
    getUserRoleAccessRequestDetails: build.query<UserRoleAccessRequestDetailsDisplayModel[], number>({
      query: userRoleAccessRequestId => baseUrl + `/details/${userRoleAccessRequestId}`,
    }),
    getUserRoleAccessRequests: build.query<UserRoleAccessRequestDisplayModel[], number>({
      query: refAccessRequestStatusId => baseUrl + `/status/${refAccessRequestStatusId}`,
    }),
  }),
});

export const {
  useAddUserRoleAccessRequestMutation,
  useAddUserRoleAccessRequestDetailMutation,
  useGetUserRoleAccessRequestsQuery,
  useLazyGetUserRoleAccessRequestsQuery,
  useGetUserRoleAccessRequestDetailsQuery,
  useLazyGetUserRoleAccessRequestDetailsQuery,
  useGetUserRoleAccessRequestQuery,
  useLazyGetUserRoleAccessRequestQuery,
  useGetUserRoleAccessRequestStatusListQuery,
  useLazyGetUserRoleAccessRequestStatusListQuery,
} = extendedApi;
