import { Alert, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Row } from 'react-table';
import { AppSize } from '../../app/constants';
import { convertUtcToLocal } from '../../app/helpers/dateHelper';
import { useGetEligibleParticipantListQuery } from '../../app/services/partner/api/eligibleParticipant';
import { TestEligibleParticipantModel } from '../../app/services/partner/api/types';
import { SortType } from '../../app/services/types';
import { useAppSelector } from '../../app/state/hooks';
import { appColors } from '../../app/theme';
import CustomTable from '../../components/CustomTable';
import TestAddEligibleParticipantActionCell from './components/ActionCell';

const InitialSortBy: SortType<TestEligibleParticipantModel> = {
  id: 'first_name',
  desc: false,
};

const TestEligibleParticipantUpdateDelete = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState(InitialSortBy);
  const { isSideNavOpen } = useAppSelector(s => s.app);

  const { data, isLoading, isFetching } = useGetEligibleParticipantListQuery({
    page_number: pageNumber,
    page_size: pageSize,
    search_string: searchParams.get('search') ?? '',
    sort_column: sortBy.id,
    sort_order: sortBy.desc ? 'desc' : 'asc',
  });

  const Header = [
    {
      Header: 'First Name',
      accessor: 'first_name',
    },
    {
      Header: 'Last Name',
      accessor: 'last_name',
    },
    {
      Header: 'Email',
      accessor: 'email',
      isSortable: false,
    },
    // {
    //   Header: "Partner",
    //   accessor: "partner_name",
    // },
    {
      Header: 'Product Type',
      accessor: 'product_type_name',
    },
    {
      Header: 'Doing business as',
      accessor: 'doing_business_as',
      isSortable: false,
    },
    {
      Header: 'Date Modified',
      accessor: 'row_modified_datetime_utc',
      isSortable: false,
      Cell: ({ row: { original } }: { row: Row<TestEligibleParticipantModel> }) => {
        return <>{convertUtcToLocal(original.row_modified_datetime_utc) || '-'}</>;
      },
    },
    {
      Header: 'Start Date',
      accessor: 'start_date',
      isSortable: false,
      Cell: ({ row: { original } }: { row: Row<TestEligibleParticipantModel> }) => {
        return <>{convertUtcToLocal(original.start_date) || '-'}</>;
      },
    },
    {
      Header: 'End Date',
      accessor: 'end_date',
      isSortable: false,
      Cell: ({ row: { original } }: { row: Row<TestEligibleParticipantModel> }) => {
        return <>{convertUtcToLocal(original.end_date) || '-'}</>;
      },
    },
    {
      Header: 'Actions',
      isSortable: false,
      styles: { textAlign: 'center' },
      Cell: TestAddEligibleParticipantActionCell,
    },
  ];

  useEffect(() => {
    // needed for search and filters
    setPageNumber(1);
  }, [searchParams]);

  const sideNavWidth = isSideNavOpen ? AppSize.sideNav.width.open : AppSize.sideNav.width.close;

  return (
    <VStack spacing={3}>
      <Alert
        bg={appColors.hightLightColor}
        borderRadius={5}
        my={2}
        borderLeftWidth={4}
        borderLeftColor={appColors.brand.main.default}
      >
        <VStack align="stretch">
          <Text>Update/Disable the Partner Product associated with the Test Account.</Text>
          <Text>
            Disabling the test account here will end the association with a Partner Product and will not delete the
            account in the Lore Community.
          </Text>
        </VStack>
      </Alert>

      <CustomTable
        variant="table"
        isLoading={isLoading}
        isFetching={isFetching}
        data={data?.data ?? []}
        pageCount={data?.total_pages || 0}
        pageSize={data?.page_size || pageSize}
        totalRecords={data?.total_records || 0}
        pageIndex={pageNumber - 1}
        headers={Header}
        search={searchParams.get('search') ?? ''}
        initialState={{ sortBy: [InitialSortBy] }}
        manualSortBy
        disableSortRemove
        showNoRecords
        onPageChange={index => {
          setPageNumber(index + 1);
        }}
        onPageSizeChange={size => {
          setPageNumber(1);
          setPageSize(size);
        }}
        onPageSearchDebounce={400}
        onPageSearch={search => {
          setPageNumber(1);

          setSearchParams(
            prev => {
              if (search) prev.set('search', search);
              else prev.delete('search');
              return prev;
            },
            { replace: true },
          );
        }}
        onSort={sort => {
          if (sort[0]) {
            setPageNumber(1);
            setSortBy(sort[0]);
          }
        }}
        styles={{
          pagination: { justifyContent: 'start' },
          header: { justifyContent: 'left' },
          tableContainer: {
            sx: {
              maxW: `calc(100vw - 6.7rem - ${sideNavWidth})`,
              overflow: 'auto',
              table: {
                borderCollapse: 'separate',
                borderSpacing: '0',
                'thead > tr': {
                  position: 'sticky',
                  left: 0,
                  top: 0,
                  zIndex: 2,
                  height: 'auto',
                  bg: 'white',
                },
                tr: {
                  'th:last-child': {
                    position: 'sticky',
                    right: '0px',
                    zIndex: 2,
                    bg: 'white',
                    '&:hover': {
                      background: 'inherit',
                    },
                    w: '90px',
                    borderLeft: '1px',
                    borderColor: 'gray.100',
                  },
                  'td:last-child': {
                    position: 'sticky',
                    right: '0px',
                    zIndex: 2,
                    bg: 'white',
                    '&:hover': {
                      background: 'inherit',
                    },
                    w: '90px',
                    borderLeft: '1px',
                    borderColor: 'gray.100',
                  },
                },
              },
            },
          },
        }}
        manual={true}
        // onRowClick={(row) => onClickRow(row)}
      />
    </VStack>
  );
};

export default TestEligibleParticipantUpdateDelete;
