import { partnerApi } from '.';
import { PaginatedApiResult, PaginatedQueryParams } from '../../types';
import {
  AddPartnerProductProviderModel,
  CompoundPartnerProductProviderModel,
  PartnerProductProviderModel,
  UpdatePartnerProductProviderModel,
} from './types';

const baseUrl = '/provider';

const extendedApi = partnerApi.injectEndpoints({
  endpoints: build => ({
    getPartnerProductProviderList: build.query<
      PaginatedApiResult<PartnerProductProviderModel>,
      PaginatedQueryParams<PartnerProductProviderModel> & { partner_product_id: number }
    >({
      query: params => ({
        url: baseUrl + `/list`,
        params,
      }),
      providesTags: ['PartnerProductProvider'],
    }),

    getPartnerProductProviderById: build.query<PartnerProductProviderModel, number>({
      query: partnerProductProviderId => baseUrl + `/${partnerProductProviderId}`,
    }),

    getExternalProvider: build.query<CompoundPartnerProductProviderModel, string>({
      // npi should be number, but string can do just fine
      query: npi => baseUrl + `/${npi}/external`,
      // providesTags: ["PartnerProductProvider"],
    }),

    addPartnerProductProvider: build.mutation<
      {
        result: {
          partner_product_provider_id: number;
        };
        action: 'inserted';
      },
      AddPartnerProductProviderModel
    >({
      query: body => ({
        url: baseUrl,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PartnerProductProvider'],
    }),

    updatePartnerProductProvider: build.mutation<
      {
        result: {
          partner_product_provider_id: number;
        };
        action: 'updated';
      },
      UpdatePartnerProductProviderModel
    >({
      query: body => ({
        url: baseUrl,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['PartnerProductProvider'],
    }),

    deletePartnerProductProvider: build.mutation<void, number>({
      query: partnerProductProviderId => ({
        url: baseUrl + `/${partnerProductProviderId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PartnerProductProvider'],
    }),
  }),
});

export const {
  useGetPartnerProductProviderListQuery,
  useLazyGetPartnerProductProviderListQuery,
  useLazyGetExternalProviderQuery,
  useGetPartnerProductProviderByIdQuery,
  useLazyGetPartnerProductProviderByIdQuery,
  useAddPartnerProductProviderMutation,
  useUpdatePartnerProductProviderMutation,
  useDeletePartnerProductProviderMutation,
} = extendedApi;
