import {
  Link,
  Modal,
  ModalOverlay,
  Heading,
  ModalContent,
  ModalBody,
  Box,
  IconButton,
  CloseButton,
  Flex,
  VStack,
  Input,
  Select,
  Text,
  Spinner,
  Button,
  HStack,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import './PartnerProductDetailsModal.css';
import HorizontalLine from '../../HorizontalLine';
import CustomDatePicker from '../../../components/CustomDatePicker';
import { useToast } from '@chakra-ui/react';
import dayjs from 'dayjs';

import { useGetAllProductTiersForDisplayQuery } from '../../../app/services/partner/api/refProductTier';
import { useGetAllProductTypeQuery } from '../../../app/services/partner/api/refProductType';
import { useGetAllRefPaymentTrackQuery } from '../../../app/services/partner/api/refPaymentTrack';
import { useGetAllPartnerProductTagQuery } from '../../../app/services/partner/api/refPartnerProductTag';
import { useLazyGetPartnerProductForUpdateQuery } from '../../../app/services/partner/api/partnerProduct';
import { useUpdatePartnerProductAndTagsMutation } from '../../../app/services/partner/api/partnerProduct';
import {
  PartnerProductUpdateModel,
  PartnerProductTagModel,
  UpdatePartnerProductAndTagsModel,
} from '../../../app/services/partner/api/types';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import { PropsConfigs } from '../../../components/CustomDatePicker/utils/commonTypes';

interface UserTagBoxProps {
  tag: string | undefined | null;
}

const propsConfig: PropsConfigs = {
  dateNavBtnProps: {
    colorScheme: 'brand.main.default',
    variant: 'outline',
  },
  dayOfMonthBtnProps: {
    defaultBtnProps: {
      _hover: {
        background: 'brand.main.default',
        color: 'white',
      },
    },
    selectedBtnProps: {
      background: 'brand.main.default',
      color: 'white',
    },
    todayBtnProps: {
      background: 'gray.400',
    },
  },
};

interface TagSectionProps {
  tags: PartnerProductTagModel[] | [];
  isEditMode: boolean;
  onClick: (id: number) => void;
}
const TagsSection = ({ tags, isEditMode, onClick }: TagSectionProps) => {
  return (
    <Box sx={{ border: '1px solid #e2e8f0', borderRadius: '5px', padding: '5px' }}>
      {tags.map((e, i) => (
        <>
          <Box
            key={i}
            px={2}
            py={1}
            mr={2}
            mb={1}
            style={{
              border: '1px solid #e2e8f0',
              backgroundColor: '#e2e8f0',
              display: 'inline-block',
              fontWeight: 'bolder',
              fontSize: '12px',
            }}
            rounded="md"
          >
            <HStack>
              <Text>{e.partner_product_tag}</Text>
              {isEditMode && <CloseButton size={'sm'} onClick={() => onClick(e.ref_partner_product_tag_id)} />}
            </HStack>
          </Box>
        </>
      ))}
    </Box>
  );
};

const defaultPartnerProductView: PartnerProductUpdateModel = {
  partner_product_id: 0,
  ref_product_type_id: 0,
  ref_product_tier_id: null,
  product_tier_name: '',
  product_type_name: '',
  partner_name: '',
  partner_tin: '',
  doing_business_as: '',
  partner_address_1: '',
  partner_address_2: '',
  partner_city: '',
  partner_state: '',
  partner_zip_code: '',
  partner_country: '',
  enrollment_start_dt: '',
  enrollment_end_dt: '',
  ref_payment_track_id: null,
  payment_track_name: '',
  contract_start_date: '',
  contract_renewal_date: '',
  contract_end_date: '',
  partner_product_tags: [],
};

const defaultPartnerProductForUpdate: UpdatePartnerProductAndTagsModel = {
  partner_product_id: 0,
  ref_product_type_id: 0,
  ref_payment_track_id: null,
  ref_product_tier_id: null,
  enrollment_start_dt: null,
  enrollment_end_dt: null,
  partner_product_tag_ids: [],
};

const defaultChangeMessages = {
  ref_product_type_id: '',
  ref_payment_track_id: '',
  ref_product_tier_id: '',
  enrollment_start_dt: '',
  enrollment_end_dt: '',
  partner_product_tag_ids: '',
};

type PartnerProductDetailsModalProps = {
  name: string | null;
  id: number;
  edit?: boolean;
  openModal: boolean;
  setModal: (open: boolean) => void;
};

const PartnerProductDetailsModal = ({ name, id, edit, openModal, setModal }: PartnerProductDetailsModalProps) => {
  //const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(edit ?? false);
  const [partnerProductView, setPartnerProductView] = useState<PartnerProductUpdateModel>(defaultPartnerProductView);
  const [forUpdatePartnerProduct, setForUpdatePartnerProduct] =
    useState<UpdatePartnerProductAndTagsModel>(defaultPartnerProductForUpdate);
  const [partnerProductTagsView, setPartnerProductTagsView] = useState<PartnerProductTagModel[] | []>([]);
  const [update, updateDetails] = useUpdatePartnerProductAndTagsMutation();
  const [changeMessages, setChangeMessages] = useState(defaultChangeMessages);
  const [originalTags, setOriginalTags] = useState<number[] | []>([]);
  const toast = useToast();

  const [getPartnerProduct, getPartnerProductDetails] = useLazyGetPartnerProductForUpdateQuery();

  const maxDate = dayjs().add(5, 'year').toDate();
  const minDate = dayjs(new Date()).add(-1, 'year').startOf('day').toDate();

  const productTiers = useGetAllProductTiersForDisplayQuery();
  const productTypes = useGetAllProductTypeQuery();
  const paymentTracks = useGetAllRefPaymentTrackQuery();
  const productTags = useGetAllPartnerProductTagQuery();

  useEffect(() => {
    if (openModal) {
      getPartnerProduct(id)
        .unwrap()
        .then(res => {
          setPartnerProductView(res);
          setPartnerProductTagsView(res.partner_product_tags);
          setOriginalTags(res.partner_product_tags.map(i => i.ref_partner_product_tag_id));
          setForUpdatePartnerProduct(prev => ({
            ...prev,
            ref_product_type_id: res.ref_product_type_id,
            partner_product_id: id,
            enrollment_end_dt: res.enrollment_end_dt,
            enrollment_start_dt: res.enrollment_start_dt,
            ref_payment_track_id: res.ref_payment_track_id,
            ref_product_tier_id: res.ref_product_tier_id,
            partner_product_tag_ids: res.partner_product_tags.map(i => i.ref_partner_product_tag_id),
          }));
        });
    }
  }, [openModal]);

  const handleCancel = () => {
    // if (isEditMode) {
    //   setIsEditMode(false);
    // } else {
    //   handleExitModal();
    // }
    setModal(false);
    handleExitModal();
  };

  const arraysHaveSameContent = (arr1: number[], arr2: number[]) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const sortedArr1 = arr1.slice().sort((a, b) => a - b);
    const sortedArr2 = arr2.slice().sort((a, b) => a - b);

    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    console.log(`TEST: ${JSON.stringify(forUpdatePartnerProduct)}`);
  }, [forUpdatePartnerProduct]);

  const handleExitModal = () => {
    setModal(false);
    setIsEditMode(false);
    setForUpdatePartnerProduct(defaultPartnerProductForUpdate);
    setChangeMessages(defaultChangeMessages);
  };

  const addPartnerProductTag = (newTag: PartnerProductTagModel) => {
    console.log(`Tag: ${JSON.stringify(newTag)}`);

    if (partnerProductTagsView.some(i => i.ref_partner_product_tag_id === newTag.ref_partner_product_tag_id)) {
      console.log(`ID: ${newTag.ref_partner_product_tag_id} is already selected!`);
    } else {
      setPartnerProductTagsView([...partnerProductTagsView, newTag]);

      const ids = partnerProductTagsView.map(item => item.ref_partner_product_tag_id);

      ids.push(newTag.ref_partner_product_tag_id);
      handleOnTagsPropertyChange(ids);
    }
  };

  const removeProductTag = (id: number) => {
    const tagCount = partnerProductTagsView.length;

    if (tagCount === 1) {
      toast({ status: 'info', description: 'Partner Product should at least have one Product Tag!' });
    } else {
      const updatedTags = partnerProductTagsView.filter(tag => tag.ref_partner_product_tag_id !== id);
      setPartnerProductTagsView(updatedTags);

      const ids = updatedTags.map(item => item.ref_partner_product_tag_id);
      handleOnTagsPropertyChange(ids);
    }
  };

  const onTagRemove = (id: number) => {
    removeProductTag(id);
  };

  const handleAddPartnerProductTag = (id: number, value: string) => {
    const newTag: PartnerProductTagModel = {
      ref_partner_product_tag_id: id,
      partner_product_tag: value,
      partner_product_tag_id: 0,
    };
    addPartnerProductTag(newTag);
  };

  const handleOnStringPropertyChange = (prop_value: string, prop_name: string) => {
    console.log(`key: ${prop_name} - value: ${prop_value}`);
    setForUpdatePartnerProduct(prevState => ({ ...prevState, [prop_name]: prop_value }));
  };

  const handleOnTagsPropertyChange = (tags: number[]) => {
    setForUpdatePartnerProduct(prev => ({ ...prev, partner_product_tag_ids: tags }));
  };

  const noChanges = () => {
    let newChanges = { ...changeMessages };

    if ((partnerProductView.ref_payment_track_id || 0) === (forUpdatePartnerProduct.ref_payment_track_id || 0)) {
      newChanges.ref_payment_track_id = '';
    } else {
      newChanges.ref_payment_track_id = 'ref_payment_track_id updated';
    }

    if ((partnerProductView.ref_product_tier_id || 0) === (forUpdatePartnerProduct.ref_product_tier_id || 0)) {
      newChanges.ref_product_tier_id = '';
    } else {
      newChanges.ref_product_tier_id = 'ref_product_tier_id updated';
    }

    if ((partnerProductView.ref_product_type_id || 0) === (forUpdatePartnerProduct.ref_product_type_id || 0)) {
      newChanges.ref_product_type_id = '';
    } else {
      newChanges.ref_product_type_id = 'ref_product_type_id updated';
    }

    if ((partnerProductView.enrollment_end_dt || '') === (forUpdatePartnerProduct.enrollment_end_dt || '')) {
      newChanges.enrollment_end_dt = '';
    } else {
      newChanges.enrollment_end_dt = 'enrollment_end_dt updated';
    }

    if ((partnerProductView.enrollment_start_dt || '') === (forUpdatePartnerProduct.enrollment_start_dt || '')) {
      newChanges.enrollment_start_dt = '';
    } else {
      newChanges.enrollment_start_dt = 'enrollment_start_dt updated';
    }

    console.log(`ORIGINAL TAGS: ${JSON.stringify(originalTags)}`);
    console.log(`FOR UPDATE TAGS: ${JSON.stringify(forUpdatePartnerProduct.partner_product_tag_ids)}`);

    if (arraysHaveSameContent(originalTags, forUpdatePartnerProduct.partner_product_tag_ids)) {
      newChanges.partner_product_tag_ids = '';
    } else {
      newChanges.partner_product_tag_ids = 'partner_product_tag_ids updated';
    }

    console.log(`COMPARE - NEW CHANGES: ${JSON.stringify(newChanges)}`);

    const are_changes = Object.values(newChanges).every(change => change === '');

    console.log(`COMPARE - NO CHANGES: ${are_changes}`);

    setChangeMessages(newChanges);
    return Object.values(newChanges).every(change => change === '');
  };

  const handleSaveClick = () => {
    setChangeMessages(defaultChangeMessages);
    // console.log(`COMPARE - for update: ${JSON.stringify(forUpdatePartnerProduct)}`);
    // console.log(`COMPARE - for view only: ${JSON.stringify(partnerProductView)}`);
    // console.log(`COMPARE - for view only TAGS: ${JSON.stringify(partnerProductTagsView)}`);
    if (!noChanges()) {
      update({
        partner_product_id: forUpdatePartnerProduct.partner_product_id,
        ref_payment_track_id: forUpdatePartnerProduct.ref_payment_track_id,
        ref_product_tier_id: forUpdatePartnerProduct.ref_product_tier_id,
        enrollment_end_dt: forUpdatePartnerProduct.enrollment_end_dt,
        enrollment_start_dt: forUpdatePartnerProduct.enrollment_start_dt,
        ref_product_type_id: forUpdatePartnerProduct.ref_product_type_id,
        partner_product_tag_ids: forUpdatePartnerProduct.partner_product_tag_ids,
      })
        .unwrap()
        .then(res => {
          toast({ description: `${name} successfully updated!`, status: 'success' });
          handleExitModal();
        })
        .catch(error => toast({ description: 'Update failed', status: 'error' }));
    } else {
      toast({ description: `No changes detected`, status: 'info' });
    }
  };

  return (
    <>
      <Modal isOpen={openModal} onClose={() => setModal(false)} size="4xl" closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody p="4" display="flex" flexDir="column" gap="6">
            {getPartnerProductDetails.isFetching ? (
              <Box width="100%" height="100vh">
                <Flex justify="center" align="center" height="100%">
                  <Spinner size="lg" />
                </Flex>
              </Box>
            ) : (
              <>
                <Flex justifyContent={'space-between'}>
                  <Box pt={'1.5'}>
                    <Heading as="h3" size="sm">
                      {name}
                    </Heading>
                  </Box>

                  <Box>
                    <IconButton
                      variant="link"
                      color="black"
                      size="xxs"
                      aria-label="close modal"
                      onClick={handleExitModal}
                      icon={<CloseButton />}
                    />
                  </Box>
                </Flex>

                <VStack>
                  <Box display="flex" justifyContent={'space-evenly'} className="line-box">
                    <Box sx={{ width: '50%' }} mr="2">
                      <Box fontSize={'smaller'} mb="-0.2">
                        Partner TIN
                      </Box>
                      <Text className="value-text">{partnerProductView?.partner_tin || '-'}</Text>
                    </Box>
                    <Box sx={{ width: '50%' }} mr="2">
                      <Box fontSize={'smaller'} mb="-0.2">
                        Product Type
                      </Box>
                      {isEditMode ? (
                        <>
                          <Select
                            id="product-type"
                            name="ref_product_type_id"
                            placeholder={productTypes.isLoading ? 'Loading...' : ''}
                            disabled={productTypes.isLoading}
                            value={forUpdatePartnerProduct.ref_product_type_id}
                            onChange={e => {
                              handleOnStringPropertyChange(e.target.value, e.target.name);
                            }}
                          >
                            <option value="">Select an option</option>
                            {(productTypes.data || []).map(e => (
                              <option key={e.ref_product_type_id} value={e.ref_product_type_id || ''}>
                                {e.product_type_name}
                              </option>
                            ))}
                          </Select>
                        </>
                      ) : (
                        <Text className="value-text">{partnerProductView?.product_type_name || '-'}</Text>
                      )}
                    </Box>
                  </Box>

                  <Box display="flex" justifyContent={'space-evenly'} className="line-box">
                    <Box sx={{ width: '50%' }} mr="2">
                      <Box fontSize={'smaller'} mb="-0.2">
                        Address 1
                      </Box>
                      <Text className="value-text">{partnerProductView?.partner_address_1 || '-'}</Text>
                    </Box>
                    <Box sx={{ width: '50%' }} mr="2">
                      <Box fontSize={'smaller'} mb="-0.2">
                        Doing Business As
                      </Box>
                      <Text className="value-text">{partnerProductView?.doing_business_as || '-'}</Text>
                    </Box>
                  </Box>

                  <Box display="flex" justifyContent={'space-evenly'}>
                    <Box sx={{ width: '50%' }} mr="2">
                      <Box fontSize={'smaller'} mb="-0.2">
                        City
                      </Box>
                      <Text className="value-text">{partnerProductView?.partner_city || '-'}</Text>
                    </Box>
                    <Box sx={{ width: '50%' }} mr="2">
                      <Box fontSize={'smaller'} mb="-0.2">
                        Address 2
                      </Box>
                      <Text className="value-text">{partnerProductView?.partner_address_2 || '-'}</Text>
                    </Box>
                  </Box>

                  <Box display="flex" justifyContent={'space-evenly'} className="line-box">
                    <Box sx={{ width: '50%' }} mr="2">
                      <Box fontSize={'smaller'} mb="-0.2">
                        Postal Code
                      </Box>
                      <Text className="value-text">{partnerProductView?.partner_zip_code || '-'}</Text>
                    </Box>
                    <Box sx={{ width: '50%' }} mr="2">
                      <Box fontSize={'smaller'} mb="-0.2">
                        State
                      </Box>
                      <Text className="value-text">{partnerProductView?.partner_state || '-'}</Text>
                    </Box>
                  </Box>

                  <Box display="flex" justifyContent={'space-evenly'} className="line-box">
                    <Box sx={{ width: '50%' }} mr="2">
                      <Box fontSize={'smaller'} mb="-0.2">
                        Enrollment Start Date
                      </Box>
                      {isEditMode ? (
                        <>
                          <CustomDatePicker
                            id="enrollment-start-date"
                            name="enrollment_start_dt"
                            onDateChange={date => {
                              if (dayjs(date).format('YYYY-MM-DD')) {
                                handleOnStringPropertyChange(dayjs(date).format('YYYY-MM-DD'), 'enrollment_start_dt');
                                // const maxDate = dayjs().add(1, 'year').toDate();
                                // const minDate = dayjs(new Date()).add(1, 'day').startOf('day').toDate();
                              }
                            }}
                            minDate={minDate}
                            maxDate={
                              dayjs(forUpdatePartnerProduct?.enrollment_end_dt).isValid()
                                ? dayjs(forUpdatePartnerProduct?.enrollment_end_dt)
                                    .add(-1, 'day')
                                    .toDate()
                                : maxDate
                            }
                            date={
                              forUpdatePartnerProduct?.enrollment_start_dt
                                ? new Date(forUpdatePartnerProduct.enrollment_start_dt)
                                : undefined
                            }
                            propsConfigs={{
                              ...propsConfig,
                              inputProps: {
                                placeholder: '',
                                size: 'sm',
                                value: dayjs(forUpdatePartnerProduct?.enrollment_start_dt).isValid()
                                  ? dayjs(forUpdatePartnerProduct?.enrollment_start_dt).format('YYYY-MM-DD')
                                  : '',
                              },
                            }}
                          />
                        </>
                      ) : (
                        <Text className="value-text">
                          {convertUtcToLocal(partnerProductView?.enrollment_start_dt, 'YYYY-MM-DD') || '-'}
                        </Text>
                      )}
                    </Box>
                    <Box sx={{ width: '50%' }} mr="2">
                      <Box fontSize={'smaller'} mb="-0.2">
                        Enrollment End Date
                      </Box>
                      {isEditMode ? (
                        <>
                          <CustomDatePicker
                            id="enrollment-end-date"
                            name="enrollment_end_dt"
                            onDateChange={date => {
                              if (dayjs(date).format('YYYY-MM-DD')) {
                                handleOnStringPropertyChange(dayjs(date).format('YYYY-MM-DD'), 'enrollment_end_dt');
                              }
                            }}
                            minDate={
                              dayjs(forUpdatePartnerProduct?.enrollment_start_dt).isValid()
                                ? dayjs(forUpdatePartnerProduct?.enrollment_start_dt)
                                    .add(1, 'day')
                                    .toDate()
                                : maxDate
                            }
                            maxDate={maxDate}
                            date={
                              forUpdatePartnerProduct?.enrollment_end_dt
                                ? new Date(forUpdatePartnerProduct.enrollment_end_dt)
                                : undefined
                            }
                            propsConfigs={{
                              ...propsConfig,
                              inputProps: {
                                placeholder: '',
                                size: 'sm',
                                value: dayjs(forUpdatePartnerProduct?.enrollment_end_dt).isValid()
                                  ? dayjs(forUpdatePartnerProduct?.enrollment_end_dt).format('YYYY-MM-DD')
                                  : '',
                              },
                            }}
                          />
                        </>
                      ) : (
                        <Text className="value-text">
                          {convertUtcToLocal(partnerProductView?.contract_end_date, 'YYYY-MM-DD') || '-'}
                        </Text>
                      )}
                    </Box>
                  </Box>

                  <Box display="flex" justifyContent={'space-evenly'} className="line-box">
                    <Box sx={{ width: '50%' }} mr="2">
                      <Box fontSize={'smaller'} mb="-0.2">
                        Contract Start Date
                      </Box>
                      <Text className="value-text">
                        {convertUtcToLocal(partnerProductView?.contract_start_date, 'YYYY-MM-DD') || '-'}
                      </Text>
                    </Box>
                    <Box sx={{ width: '50%' }} mr="2">
                      <Box fontSize={'smaller'} mb="-0.2">
                        Country
                      </Box>
                      <Text className="value-text">{partnerProductView?.partner_country || '-'}</Text>
                    </Box>
                  </Box>

                  <Box display="flex" justifyContent={'space-evenly'} className="line-box">
                    <Box sx={{ width: '50%' }} mr="2">
                      <Box fontSize={'smaller'} mb="-0.2">
                        Contract Renewal Date
                      </Box>
                      <Text className="value-text">
                        {convertUtcToLocal(partnerProductView?.contract_renewal_date, 'YYYY-MM-DD') || '-'}
                      </Text>
                    </Box>
                    <Box sx={{ width: '50%' }} mr="2">
                      <Box fontSize={'smaller'} mb="-0.2">
                        Contract End Date
                      </Box>
                      <Text className="value-text">
                        {convertUtcToLocal(partnerProductView?.contract_end_date, 'YYYY-MM-DD') || '-'}
                      </Text>
                    </Box>
                  </Box>

                  <Box display="flex" justifyContent={'space-evenly'} className="line-box">
                    <Box sx={{ width: '50%' }} mr="2">
                      <Box fontSize={'smaller'} mb="-0.2">
                        Product Tier
                      </Box>
                      {isEditMode ? (
                        <>
                          <Select
                            id="product-tier"
                            placeholder={productTiers.isLoading ? 'Loading...' : ''}
                            disabled={productTiers.isLoading}
                            value={forUpdatePartnerProduct?.ref_product_tier_id || 0}
                            name="ref_product_tier_id"
                            onChange={e => {
                              handleOnStringPropertyChange(e.target.value, e.target.name);
                            }}
                          >
                            <option value="">Select an option</option>
                            {(productTiers.data || []).map(e => (
                              <option key={e.ref_product_tier_id} value={e.ref_product_tier_id || ''}>
                                {e.display_text}
                              </option>
                            ))}
                          </Select>
                        </>
                      ) : (
                        <Text className="value-text">{partnerProductView?.product_tier_name || '-'}</Text>
                      )}
                    </Box>
                    <Box sx={{ width: '50%' }} mr="2">
                      <Box fontSize={'smaller'} mb="-0.2">
                        Payment Track
                      </Box>
                      {isEditMode ? (
                        <>
                          <Select
                            id="payment-track"
                            placeholder={paymentTracks.isLoading ? 'Loading...' : ''}
                            disabled={paymentTracks.isLoading}
                            value={forUpdatePartnerProduct?.ref_payment_track_id || 0}
                            name="ref_payment_track_id"
                            onChange={e => {
                              handleOnStringPropertyChange(e.target.value, e.target.name);
                            }}
                          >
                            <option value="">Select an option</option>
                            {(paymentTracks.data || []).map(e => (
                              <option key={e.ref_payment_track_id} value={e.ref_payment_track_id || ''}>
                                {e.payment_track_name}
                              </option>
                            ))}
                          </Select>
                        </>
                      ) : (
                        <Text className="value-text">{partnerProductView?.payment_track_name || '-'}</Text>
                      )}
                    </Box>
                  </Box>

                  <Box>
                    <Box mr="2">
                      <Box fontSize={'smaller'} mb="-0.2">
                        Product Tags
                      </Box>
                      {isEditMode ? (
                        <>
                          <Select
                            id="payment-track"
                            placeholder={productTags.isLoading ? 'Loading...' : ''}
                            disabled={productTags.isLoading}
                            value={0}
                            //onClick={(e) => setPartnerProductTagsView(...prev, prev)}
                            //onChange={e => {e && setPartnerProductTagsView(...prev, { partner_product_tag: e.})}}
                            onChange={e =>
                              handleAddPartnerProductTag(
                                parseInt(e.target.value),
                                e.target.options[e.target.selectedIndex].text,
                              )
                            }
                          >
                            <option value="">Select an option</option>
                            {(productTags.data || []).map(e => (
                              <option key={e.ref_partner_product_tag_id} value={e.ref_partner_product_tag_id || ''}>
                                {e.partner_product_tag}
                              </option>
                            ))}
                          </Select>
                        </>
                      ) : (
                        <></>
                      )}
                      <Box sx={{ pt: '10px' }}>
                        <TagsSection
                          tags={isEditMode ? partnerProductTagsView : partnerProductView.partner_product_tags}
                          isEditMode={isEditMode}
                          onClick={onTagRemove}
                        />
                      </Box>
                    </Box>
                  </Box>

                  <HorizontalLine marginTop={'20px'} />
                  <Box display="flex" justifyContent={'flex-end'}>
                    <Box display="flex" gap={2}>
                      <Button color="#3182ce" size="sm" onClick={handleCancel}>
                        Cancel
                      </Button>

                      {isEditMode ? (
                        <Button
                          size="sm"
                          onClick={handleSaveClick}
                          colorScheme="brand.main"
                          gap="2"
                          px="5"
                          type="submit"
                        >
                          {updateDetails.isLoading ? <Spinner size={'sm'} /> : 'Save'}
                        </Button>
                      ) : (
                        <Button
                          size="sm"
                          onClick={() => setIsEditMode(true)}
                          colorScheme="brand.main"
                          gap="2"
                          px="5"
                          type="submit"
                        >
                          Edit
                        </Button>
                      )}
                    </Box>
                  </Box>
                </VStack>
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PartnerProductDetailsModal;
