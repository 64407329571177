import { SearchIcon } from '@chakra-ui/icons';
import {
  Alert,
  Badge,
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Skeleton,
  Spinner,
  Stack,
  Table,
  TableContainer,
  TableContainerProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  chakra,
  theme,
  useToken,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go';
import {
  HiOutlineChevronDoubleLeft,
  HiOutlineChevronDoubleRight,
  HiOutlineChevronLeft,
  HiOutlineChevronRight,
} from 'react-icons/hi';
import { Column, RowPropGetter, TableState, usePagination, useSortBy, useTable } from 'react-table';
import { generateArray } from '../../app/helpers/arrayHelper';
import { debounce } from '../../app/helpers/utilities';
import { SortType } from '../../app/services/types';
import { appColors } from '../../app/theme';
import EditableCell from './EditableCell';

export type IProps<T> = {
  data: T[];
  pageIndex?: number;
  pageCount: number;
  pageSize: number;
  totalRecords?: number;
  isLoading: boolean;
  isFetching: boolean;
  headers: Column<any>[];
  hiddenColumns?: string[];
  tableSort?: boolean;
  search: string;
  hidePagination?: boolean;
  hideHeaders?: boolean;
  hideRowsPerPage?: boolean;
  showRecordCount?: boolean;
  manual?: boolean;
  manualSortBy?: boolean;
  disableSortRemove?: boolean;
  disableHoverHighlight?: boolean;
  initialState?: Partial<TableState<object>> | undefined;
  rowDisabledOnTrue?: (row: T) => boolean;
  rowActiveOnTrue?: (row: T) => boolean;
  activeRow?: { property: string; value: any };
  variant: 'table' | 'grid';
  onPageChange: (pageIndex: number) => void;
  onPageSizeChange?: (size: number) => void;
  onPageSearchDebounce?: number;
  onPageSearch?: (search: string) => void;
  onSort: (sort: SortType<T>[]) => void;
  getRowProps?: (row: any) => RowPropGetter<object> | undefined;
  onRowClick?: (row: T) => void;
  showNoRecords?: boolean;
  isRowAdded?: boolean;
  updateData?: (
    rowIndex: number,
    columnId: string,
    value: string | File | number | number[],
    fileColumnName?: string,
  ) => void;
  styles?: {
    loadingStyle?: 'default' | 'overlay';
    tableContainer?: TableContainerProps;
    pagination?: {
      justifyContent: string;
    };
    header?: {
      justifyContent: string;
    };
    searchInput?: {
      w: string;
    };
  };
  extraComponents?: {
    afterSearch?: JSX.Element;
    afterPagination?: JSX.Element;
  };
};

/**
//! example to put to props.styles.tableContainerSx, for doing column freeze
const tableContainerSx = {
  maxW: "70vw",
  height: "50vh",
  overflow: "auto",
  table: {
    maxW: "70vw",
    borderSpacing: "0",
    "thead > tr": {
      position: "sticky",
      left: 0,
      top: 0,
      zIndex: 10,
      height: "auto",
      bg: "white",
    },
    tr: {
      //! 1st child sticky
      "th:first-child": {
        position: "sticky",
        left: "0px",
        zIndex: 10,
        bg: "white",
        w: "140px",
      },
      "td:first-child": {
        position: "sticky",
        left: "0px",
        zIndex: 10,
        bg: "white",
        w: "140px",
      },
      
      //! upto 2nd child sticky
      "th:nth-child(2)": {
        position: "sticky",
        left: "140px",
        zIndex: 10,
        bg: "white",
      },
      "td:nth-child(2)": {
        position: "sticky",
        left: "140px",
        zIndex: 10,
        bg: "white",
      },

      //! last child sticky
      "th:last-child": {
        position: "sticky",
        right: "0px",
        zIndex: 10,
        bg: "white",
        "&:hover": {
          background: "inherit",
        },
        w: "90px",
      },
      "td:last-child": {
        position: "sticky",
        right: "0px",
        zIndex: 10,
        bg: "white",
        "&:hover": {
          background: "inherit",
        },
        w: "90px",
      },

      //! upto 2nd to the last child sticky
      "th:nth-last-child(2)": {
        position: "sticky",
        right: "90px",
        zIndex: 10,
        bg: "white",
      },
      "td:nth-last-child(2)": {
        position: "sticky",
        right: "90px",
        zIndex: 10,
        bg: "white",
      },
    },
  },
}
 */

/**
 * Freeze examples: (To freeze, let's do manual for now and comeup, with better solutions later)
 *  - react-table: https://codesandbox.io/s/objective-frog-m4imr?fontsize=14&hidenavigation=1&theme=dark&file=/src/App.js
 *  - vanilla html & css: https://www.codeproject.com/Articles/5320138/HTML-Table-Freeze-Row-and-Column-with-CSS
 */
function CustomTable<T>(props: IProps<T>) {
  const columns = useMemo<Column<any>[]>(() => props.headers, [props.headers]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [tablePageCount, setTablePageCount] = useState(0);
  const [rowAdded, setRowAdded] = useState<boolean>(props.isRowAdded ?? false);
  const searchRef = useRef('');

  const [blackAlpha800, blackAlpha700] = useToken('colors', ['blackAlpha.800', 'blackAlpha.700']);

  const [isTableLoading, setIsTableLoading] = useState(true);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    state: { pageIndex, pageSize, sortBy },
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: {
        pageIndex: 0,
        pageSize: 18,
        hiddenColumns: props.hiddenColumns || [],
        ...props.initialState,
      },
      pageCount: tablePageCount,
      manualPagination: true,
      autoResetPage: false, //prevent re-executing hook with initialState again
      manualSortBy: props.manualSortBy,
      disableSortRemove: props.disableSortRemove,
      autoResetSortBy: false,
    },
    useSortBy,
    usePagination,
  );

  const keyUpHandler = (event: any) => {
    event.preventDefault();
    gotoPage(0);
    // props.onPageSearch && props.onPageSearch(search);
    props.onPageSearch && props.onPageSearch(searchRef.current);
  };

  const onRowClick = (row: any) => {
    if (props.onRowClick) {
      props.onRowClick(row.original);
    }
  };

  useEffect(() => {
    // setSearch(props.search);
    searchRef.current = props.search;
  }, [props.search]);

  useEffect(() => {
    setTableData(props.data);
    setTablePageCount(props.pageCount);
  }, [props]);

  useEffect(() => {
    if (!props.manual) {
      const index = pageIndex === 0 ? 1 : pageIndex + 1;
      props.onPageChange(index);
    }
  }, [pageIndex, pageSize, sortBy]);

  useEffect(() => {
    props.onSort(sortBy as SortType<T>[]);
  }, [sortBy]);

  useEffect(() => {
    setIsTableLoading(props.isLoading || props.isFetching);
  }, [props.isLoading, props.isFetching]);

  useEffect(() => {
    if (props.manual) {
      if (props.pageIndex !== null && props.pageIndex !== undefined) {
        gotoPage(props.pageIndex);
      }
    }
  }, [props.pageIndex]);

  const navButtons = useMemo(() => {
    const beforeCount = pageIndex;
    const afterCount = props.pageCount - pageIndex - 1;

    const beforeActual = beforeCount > 2 ? 2 : beforeCount < 0 ? 0 : beforeCount;
    const afterActual = afterCount > 2 ? 2 : afterCount < 0 ? 0 : afterCount;

    let beforeAdd = afterActual < 2 && beforeCount - beforeActual > 0 ? beforeCount - beforeActual : 0;
    beforeAdd = beforeAdd > 2 ? 2 : beforeAdd;
    beforeAdd = beforeAdd === afterActual ? beforeAdd : beforeAdd - afterActual;
    beforeAdd = beforeAdd < 0 ? 0 : beforeAdd;

    // (before less than allowable? so move to after) && (Can add more element on after?) ? get count to add else zero
    // should be same logic for beforeAdd, just change after to before
    // there must be a better logic than this :(
    let afterAdd = beforeActual < 2 && afterCount - afterActual > 0 ? afterCount - afterActual : 0;
    afterAdd = afterAdd > 2 ? 2 : afterAdd; // allowable after add
    afterAdd = afterAdd === beforeActual ? afterAdd : afterAdd - beforeActual; // get actual afterAdd, not sure about this
    afterAdd = afterAdd < 0 ? 0 : afterAdd; // make sure positive number

    return {
      before: generateArray(beforeActual + beforeAdd)
        .map(i => pageIndex - i)
        .reverse(),
      after: generateArray(afterActual + afterAdd).map(i => pageIndex + 2 + i),
    };
  }, [pageIndex, props.pageCount]);

  const tableView = (
    <Box position="relative">
      {props.styles?.loadingStyle === 'overlay' && props.data.length > 0 && (props.isLoading || props.isFetching) && (
        <Box position="absolute" top="50%" left="50%" p="4" bg="rgba(255, 255, 255, 1)" rounded="md" shadow="lg">
          <Box w="1.5rem" h="1.5rem">
            <Spinner />
          </Box>
        </Box>
      )}

      <TableContainer {...props.styles?.tableContainer}>
        {((props.styles?.loadingStyle === 'overlay' && props.data.length <= 0 && isTableLoading) ||
          (props.styles?.loadingStyle !== 'overlay' && isTableLoading)) &&
        page.length <= 0 ? (
          <Loading pageSize={props.pageSize} />
        ) : (
          <>
            <Table variant="simple" size="sm" mt={1} {...getTableProps()}>
              {!props.hideHeaders && (
                <Thead>
                  {headerGroups.map(headerGroup => {
                    return (
                      <Tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => {
                          const columnStyle = (column as any).styles;
                          const isSortable = (column as any).isSortable ?? true;
                          const tableSort = props.tableSort ?? true;
                          const applySortIcon = column.isSortedDesc ? (
                            <Icon as={GoTriangleDown} aria-label="sorted descending" />
                          ) : (
                            <Icon as={GoTriangleUp} aria-label="sorted ascending" />
                          );
                          return (
                            <Th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps({
                                  ...(isSortable ? {} : { onClick: () => {} }),
                                }),
                              )}
                              style={{
                                color: blackAlpha700,
                                whiteSpace: tableSort ? 'nowrap' : 'initial',
                                textTransform: 'initial',
                                fontSize: '16px',
                                letterSpacing: '-0.5px',
                                ...columnStyle,
                              }}
                            >
                              {column.render('Header')}
                              {tableSort && isSortable && (
                                <chakra.span pl="3">{column.isSorted ? applySortIcon : null}</chakra.span>
                              )}
                            </Th>
                          );
                        })}
                      </Tr>
                    );
                  })}
                </Thead>
              )}
              <Tbody {...getTableBodyProps()}>
                {((props.styles?.loadingStyle === 'overlay' && props.data.length <= 0 && isTableLoading) ||
                  (props.styles?.loadingStyle !== 'overlay' && isTableLoading)) &&
                page.length > 0 ? (
                  <Tr>
                    <Td colSpan={columns.length + 1}>
                      <Loading pageSize={props.pageSize} />
                    </Td>
                  </Tr>
                ) : (
                  page.map(row => {
                    prepareRow(row);

                    const rowProps = {
                      ...row.getRowProps(props.getRowProps ? props.getRowProps(row) : undefined),
                    };

                    const rowStyle = rowProps.style || {};
                    if (props.rowDisabledOnTrue && props.rowDisabledOnTrue(row.original as unknown as T)) {
                      rowStyle.color = appColors.inactive;
                    }

                    if (props.rowActiveOnTrue && props.rowActiveOnTrue(row.original as unknown as T)) {
                      rowStyle.backgroundColor = appColors.activeRow;
                    }

                    rowProps.style = rowStyle;

                    return (
                      <Tr
                        {...rowProps}
                        _hover={{
                          background: props.disableHoverHighlight ? undefined : 'gray.200',
                        }}
                        onClick={() => onRowClick(row as unknown as T)}
                        style={{ cursor: props.onRowClick ? 'pointer' : 'initial' }}
                      >
                        {row.cells.map(cell => {
                          const cellColumn = cell.column as any;
                          const columnStyle = cellColumn.styles;
                          const isEditable = cellColumn.isEditable;
                          const type = cellColumn.type as React.HTMLInputTypeAttribute;
                          const dropdownOptions = cellColumn.dropdownOptions;
                          return (
                            <Td
                              verticalAlign={isEditable ? 'top' : 'inherit'}
                              {...cell.getCellProps()}
                              style={{
                                color: blackAlpha800,
                                ...columnStyle,
                              }}
                            >
                              {isEditable && props.updateData ? (
                                <EditableCell
                                  value={cell.value}
                                  row={row}
                                  column={cell.column}
                                  updateData={props.updateData}
                                  type={type}
                                  dropdownOptions={dropdownOptions}
                                  fileColumnName={cellColumn.fileColumnName}
                                />
                              ) : (
                                cell.render('Cell')
                              )}
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })
                )}
              </Tbody>
            </Table>
            {props.showNoRecords && page.length == 0 && (
              <Alert w="full" status="error">
                No Record(s) found
              </Alert>
            )}
          </>
        )}
      </TableContainer>
    </Box>
  );

  const gridView = (
    <Stack>
      {(props.styles?.loadingStyle === 'overlay' && props.data.length <= 0) ||
      (props.styles?.loadingStyle !== 'overlay' && (props.isLoading || props.isFetching)) ? (
        <Stack mt={1}>
          {[...Array(props.pageSize)].map((m, i) => (
            <Skeleton key={i} height="18px" />
          ))}
        </Stack>
      ) : (
        <>
          <Box>
            <List spacing={2}>
              {page.map((row, index) => {
                prepareRow(row);

                const rowProps = {
                  ...row.getRowProps(props.getRowProps ? props.getRowProps(row) : undefined),
                };

                const rowStyle = rowProps.style || {};
                if (props.rowDisabledOnTrue && props.rowDisabledOnTrue(row.original as unknown as T)) {
                  rowStyle.borderLeftColor = appColors.inactive;
                }

                if (props.rowActiveOnTrue && props.rowActiveOnTrue(row.original as unknown as T)) {
                  rowStyle.boxShadow = theme.shadows.md;
                  rowStyle.background = appColors.activeRow;
                }

                rowProps.style = rowStyle;

                return (
                  <ListItem key={index}>
                    <Stack
                      p={2}
                      width="100%"
                      border="1px"
                      borderLeft={'8px'}
                      borderColor="gray.200"
                      borderLeftColor="activeRowIndicator.border"
                      {...rowProps}
                    >
                      <HStack alignItems="start">
                        <Stack flexGrow={1} wordBreak="break-all">
                          {row.cells.map((cell, i) => {
                            const isAction = (cell.column as any).isAction;
                            return (
                              !isAction && (
                                <Flex key={i}>
                                  <Text as="strong" pr={2} whiteSpace="nowrap">
                                    {cell.column.render('Header')}:
                                  </Text>
                                  <Text borderRightColor="gray.200">{cell.render('Cell')}</Text>
                                </Flex>
                              )
                            );
                          })}
                        </Stack>
                        <Stack>
                          {row.cells.map((cell, ii) => {
                            const isAction = (cell.column as any).isAction;
                            return isAction && <Fragment key={ii}>{cell.render('Cell')}</Fragment>;
                          })}
                        </Stack>
                      </HStack>
                    </Stack>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </>
      )}
    </Stack>
  );

  const renderTableOrGrid = (variant: string) => {
    switch (variant) {
      case 'table': {
        return tableView;
      }
      case 'grid': {
        return gridView;
      }
      default:
        return tableView;
    }
  };

  return (
    <VStack align="stretch" w="100%">
      {!props.hidePagination && (
        <Flex justifyContent="space-between" mb={2} p={0}>
          <HStack w="100%" justifyContent={props.styles?.header?.justifyContent ?? 'space-between'}>
            {props.showRecordCount && (
              <Badge alignItems="center" display="flex" px="2" py="1">
                {props.totalRecords || 0} record
                {(props.totalRecords || 0) > 1 && <>s</>}
              </Badge>
            )}
            {props.onPageSearch && (
              <InputGroup justifyContent="flex-end" w={props.styles?.searchInput?.w ?? '320px'}>
                <Input
                  id="search"
                  placeholder="search"
                  name="search"
                  // value={search}
                  // onChange={(e) => setSearch(e.target.value)}
                  // onKeyUp={keyUpHandler}
                  defaultValue={searchRef.current}
                  onChange={e => (searchRef.current = e.target.value)}
                  onKeyUp={
                    props.onPageSearchDebounce ? debounce(keyUpHandler, props.onPageSearchDebounce) : keyUpHandler
                  }
                />
                <InputRightElement children={<SearchIcon className="SearchIcon" color="brand.main.default" />} />
              </InputGroup>
            )}
          </HStack>
          {props.extraComponents?.afterSearch && props.extraComponents.afterSearch}
        </Flex>
      )}

      {renderTableOrGrid(props.variant)}

      <HStack justifyContent="space-between" w="100%">
        {/* {!props.isLoading && page.length === 0 ? (
          <></>
        ) : ( */}
        <Flex justifyContent={props.styles?.pagination?.justifyContent ?? 'end'} alignItems="center" mt={2} gap="4">
          {!props.hideRowsPerPage && (
            <Flex justifyContent="end">
              <Text flexShrink={0} mr={2} fontSize="md" lineHeight={2}>
                Rows per page
              </Text>
              {/* <Select
                    size="sm"
                    w="16"
                    // value={pageSizeInternal}
                    defaultValue={pageSizeInternal}
                    mr="3"
                    fontWeight="medium"
                    iconColor={appColors.brand.main.default}
                    onChange={(e) => {
                      props.onPageSizeChange && props.onPageSizeChange(Number(e.target.value));
                      gotoPage(0);
                      setPageSizeInternal(Number(e.target.value));
                    }}
                    rounded="md"
                  >
                    {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                      <chakra.option key={pageSize} value={pageSize}>
                        <Text as="span" fontWeight="medium">
                          {pageSize}
                        </Text>
                      </chakra.option>
                    ))}
                  </Select> */}
              <Select
                isDisabled={props.isFetching}
                size="sm"
                value={{ label: props.pageSize.toString(), value: props.pageSize }}
                useBasicStyles
                onChange={e => {
                  if (e) {
                    props.onPageSizeChange && props.onPageSizeChange(e.value);
                    gotoPage(0);
                  }
                }}
                options={[5, 10, 20, 30, 40, 50].map(n => ({ label: n.toString(), value: n }))}
                menuPlacement="top"
                chakraStyles={{
                  control: (provided, state) => ({
                    ...provided,
                    rounded: 'md',
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    zIndex: 3,
                  }),
                }}
              />

              <Box
                flexShrink={0}
                mr={2}
                fontSize="sm"
                fontWeight="medium"
                lineHeight={2}
                style={{
                  borderRadius: '5px',
                  padding: '0 15px 0 15px',
                  border: '1px solid #e3e4e4',
                  backgroundColor: '#e3e4e4',
                  margin: '0 0 0 20px',
                }}
              >
                {props?.totalRecords} Records
              </Box>
            </Flex>
          )}

          <HStack hidden={props.hidePagination}>
            <IconButton
              aria-label="first page"
              onClick={() => {
                if (props.manual) props.onPageChange(0);
                else gotoPage(0);
              }}
              isDisabled={!canPreviousPage || props.isFetching}
              size="xs"
              icon={<HiOutlineChevronDoubleLeft />}
              fontSize="20px"
              fontWeight="normal"
              variant="link"
              color="black"
            />
            <IconButton
              aria-label="previous page"
              onClick={() => {
                if (props.manual) props.onPageChange(pageIndex - 1);
                else previousPage();
              }}
              isDisabled={!canPreviousPage || props.isFetching}
              size="xs"
              icon={<HiOutlineChevronLeft />}
              fontSize="20px"
              fontWeight="normal"
              variant="link"
              color="black"
            />

            {props.manual &&
              navButtons.before.map(n => (
                <IconButton
                  key={n}
                  aria-label={'go to page ' + n}
                  onClick={() => {
                    props.onPageChange(n - 1);
                  }}
                  isDisabled={props.isFetching}
                  size="xs"
                  icon={<>{n}</>}
                  fontSize="16px"
                  fontWeight="normal"
                  variant="link"
                  color="black"
                />
              ))}

            <IconButton
              aria-label="current page"
              colorScheme="brand.main"
              onClick={() => {}}
              isDisabled={props.isFetching}
              size="xs"
              icon={<>{pageIndex + 1}</>}
              fontSize="16px"
              fontWeight="normal"
              px="1"
            />

            {props.manual &&
              navButtons.after.map(n => (
                <IconButton
                  key={n}
                  aria-label={'go to page ' + n}
                  onClick={() => {
                    props.onPageChange(n - 1);
                  }}
                  isDisabled={props.isFetching}
                  size="xs"
                  icon={<>{n}</>}
                  fontSize="16px"
                  fontWeight="normal"
                  variant="link"
                  color="black"
                />
              ))}

            <IconButton
              aria-label="next page"
              onClick={() => {
                if (props.manual) props.onPageChange(pageIndex + 1);
                else nextPage();
              }}
              isDisabled={!canNextPage || props.isFetching}
              size="xs"
              icon={<HiOutlineChevronRight />}
              fontSize="20px"
              fontWeight="bold"
              variant="link"
              color="black"
            />
            <IconButton
              aria-label="last page"
              onClick={() => {
                if (props.manual) props.onPageChange(props.pageCount - 1);
                else gotoPage(props.pageCount - 1);
              }}
              isDisabled={!canNextPage || props.isFetching}
              size="xs"
              icon={<HiOutlineChevronDoubleRight />}
              fontSize="20px"
              fontWeight="normal"
              variant="link"
              color="black"
            />
          </HStack>
        </Flex>
        {/* )} */}
        {props.extraComponents?.afterPagination && props.extraComponents.afterPagination}
      </HStack>
    </VStack>
  );
}

function Loading({ pageSize }: { pageSize: number }) {
  return (
    <Stack mt={1} w="full">
      {[...Array(pageSize)].map((m, i) => (
        <Skeleton key={i} height="18px" />
      ))}
    </Stack>
  );
}

export default CustomTable;
