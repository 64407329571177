import { partnerApi } from '.';
import { AddUserActivityModel } from './types';

const baseUrl = '/user-activity';

const extendedApi = partnerApi.injectEndpoints({
  endpoints: build => ({
    addUserActivity: build.mutation<void, AddUserActivityModel>({
      query: body => ({
        url: baseUrl,
        body: body,
        method: 'POST',
      }),
    }),
  }),
});

export const { useAddUserActivityMutation } = extendedApi;
