import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  RefUserModel,
  UserAcknowledgementModel,
  UserPartnerProductModel,
  RefActivityDisplayModel,
  RefPageDisplayModel,
} from '../services/partner/api/types';
export type AppState = {
  // isUserOnboard: boolean;
  // isUserConsent: boolean;
  //refUserId: number;
  isLoreHealthAdmin: boolean;
  isProfileEditSuccess: boolean | null;
  logonUser: RefUserModel | null;
  userSessionId: number | null;
  acknowledgement: UserAcknowledgementModel | null;
  isUserAcknowledged: boolean;
  userPartnerProducts: UserPartnerProductModel[] | null;
  selectedPartnerProduct: UserPartnerProductModel | null;
  hasMultiplePartnerProducts: boolean;
  pageList: RefPageDisplayModel[] | [];
  activityList: RefActivityDisplayModel[] | [];
};

const initialState: AppState = {
  //isUserOnboard: false,
  //isUserConsent: false,
  isLoreHealthAdmin: false,
  isProfileEditSuccess: null,
  //refUserId: 0,
  logonUser: null,
  userSessionId: null,

  acknowledgement: null,
  isUserAcknowledged: false,

  userPartnerProducts: null,
  selectedPartnerProduct: null,
  hasMultiplePartnerProducts: false,

  pageList: [],
  activityList: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // setIsUserOnboard: (state, action: PayloadAction<boolean>) => {
    //   state.isUserOnboard = action.payload;
    // },
    // setIsUserConsent: (state, action: PayloadAction<boolean>) => {
    //   state.isUserConsent = action.payload;
    // },
    // setRefUserId: (state, action: PayloadAction<number>) => {
    //   state.refUserId = action.payload;
    // },
    setUserSessionId: (state, action: PayloadAction<number>) => {
      state.userSessionId = action.payload;
    },
    setIsLoreHealthAdmin: (state, action: PayloadAction<boolean>) => {
      state.isLoreHealthAdmin = action.payload;
    },
    setLogonUser: (state, action: PayloadAction<RefUserModel>) => {
      state.logonUser = action.payload;
    },
    setIsProfileEditSuccess: (state, action: PayloadAction<boolean>) => {
      state.isProfileEditSuccess = action.payload;
    },
    setUserAcknowledgement: (state, action: PayloadAction<UserAcknowledgementModel | null>) => {
      state.acknowledgement = action.payload;
      state.isUserAcknowledged = !!action.payload;
    },
    setUserPartnerProducts: (state, action: PayloadAction<UserPartnerProductModel[]>) => {
      state.userPartnerProducts = action.payload;
      state.hasMultiplePartnerProducts = action.payload.length > 1;
      if (action.payload.length === 1) state.selectedPartnerProduct = action.payload[0];
    },
    setActivityList: (state, action: PayloadAction<RefActivityDisplayModel[]>) => {
      state.activityList = action.payload;
    },
    setPageList: (state, action: PayloadAction<RefPageDisplayModel[]>) => {
      state.pageList = action.payload;
    },
    setSelectedPartnerProduct: (state, action: PayloadAction<UserPartnerProductModel>) => {
      state.selectedPartnerProduct = action.payload;
    },
  },
});

export const {
  setIsProfileEditSuccess,
  setIsLoreHealthAdmin,
  setLogonUser,
  setUserAcknowledgement,
  setUserPartnerProducts,
  setSelectedPartnerProduct,
  setUserSessionId,
  setPageList,
  setActivityList,
} = userSlice.actions;
export default userSlice.reducer;
