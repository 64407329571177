import { partnerApi } from '.';
import { RefProductTierDisplayModel } from './types';

const baseUrl = '/product-tier';

const extendedApi = partnerApi.injectEndpoints({
  endpoints: build => ({
    getAllProductTiersForDisplay: build.query<RefProductTierDisplayModel[], void>({
      query: () => baseUrl + '/list',
      providesTags: ['ProductTier'],
    }),
  }),
});

export const { useGetAllProductTiersForDisplayQuery, useLazyGetAllProductTiersForDisplayQuery } = extendedApi;
