import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Card,
  CloseButton,
  Flex,
  Heading,
  Portal,
  Step,
  StepIcon,
  StepIndicator,
  StepSeparator,
  StepStatus,
  Stepper,
  VStack,
  useDisclosure,
  useSteps,
  useToast,
} from '@chakra-ui/react';
import { createElement, useState } from 'react';
import { defaultErrorMessage } from '../../../app/constants';
import { useAddProductTypeMutation } from '../../../app/services/partner/api/refProductType';
import { AddRefProductTypeModel } from '../../../app/services/partner/api/types';
import AddSuccess from '../../Dashboard/Admin/AddSuccess';
import { AddPartnerModalStyle } from '../../Dashboard/Admin/helper';
import Step1 from '../../Dashboard/Admin/ProductType/AfterSearch/Add/Step1';
import Step2 from '../../Dashboard/Admin/ProductType/AfterSearch/Add/Step2';
import {
  AddProductTypeStepProp,
  addProductTypeInitialValues,
} from '../../Dashboard/Admin/ProductType/AfterSearch/Add/helper';

import { useAddUserActivityMutation } from '../../../app/services/partner/api/userActivity';
import { useAppSelector } from '../../../app/state/hooks';
import { PARTNER_PAGE } from '../../../app/constants/partnerPage';
import { PAGE_ACTIVITY } from '../../../app/constants/pageActivity';

const steps = [{ component: Step1 }, { component: Step2 }];

const AddProductTypeButton = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleOpen = () => {
    onOpen();
  };

  return (
    <>
      <Button size="sm" gap="2" colorScheme={'brand.main'} px="5" onClick={handleOpen}>
        <AddIcon fontSize="xx-small" />
        Add Product Type
      </Button>

      <Portal>{isOpen && <Child onClose={onClose} />}</Portal>
    </>
  );
};

const Child = ({ onClose }: { onClose: () => void }) => {
  const [postAddUserActivity] = useAddUserActivityMutation();
  const { selectedPartnerProduct, activityList, pageList, userSessionId } = useAppSelector(s => s.user);

  const partner_products_page = pageList.find(i => i.page_name === PARTNER_PAGE.PARTNER_PRODUCTS);
  const product_type_draft_cancelled = activityList.find(
    i => i.activity_name === PAGE_ACTIVITY.PRODUCT_TYPE_DRAFT_CANCELLED,
  );

  const handleClose = () => {
    if (product_type_draft_cancelled && partner_products_page && userSessionId) {
      postAddUserActivity({
        user_session_id: userSessionId,
        ref_activity_id: product_type_draft_cancelled.ref_activity_id,
        ref_page_id: partner_products_page.ref_page_id,
        partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      })
        .unwrap()
        .then(res => {})
        .finally(() => {
          onClose();
        });
    }
  };

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const [isSuccess, setIsSuccess] = useState(false);
  const [data, setData] = useState<AddRefProductTypeModel>(addProductTypeInitialValues);

  const [addProductType, addProductTypeDetails] = useAddProductTypeMutation();
  const toast = useToast();

  const clear = () => {
    setActiveStep(0);
    setData(addProductTypeInitialValues);
  };

  const handleSubmit = () => {
    addProductType(data)
      .unwrap()
      .then(() => {
        clear();
        setIsSuccess(true);
      })
      .catch(() => {
        toast({ description: defaultErrorMessage, status: 'error' });
      });
  };

  return (
    <Box {...AddPartnerModalStyle}>
      {isSuccess ? (
        <AddSuccess
          clear={clear}
          text="You have successfully added a new product type."
          actions={
            <Flex w="100%" gap="4">
              <Button onClick={onClose} colorScheme="brand.main" ml="auto">
                Close
              </Button>
            </Flex>
          }
        />
      ) : (
        <VStack maxW="880px" mx="auto" gap="6">
          <Heading as="h2" fontSize="xl" fontWeight="semibold">
            Add a Product Type
          </Heading>
          <Card p="4">
            <CloseButton onClick={handleClose} ml="auto" isDisabled={addProductTypeDetails.isLoading} />

            <Stepper size="sm" index={activeStep} gap="0" colorScheme="brand.main" px="10" mt="4" mb="6">
              {steps.map((_, index) => (
                <Step key={index} style={{ gap: 0 }}>
                  <StepIndicator>
                    <StepStatus complete={<StepIcon />} />
                  </StepIndicator>
                  <StepSeparator style={{ marginLeft: 0 }} />
                </Step>
              ))}
            </Stepper>
            {createElement<AddProductTypeStepProp>(steps[activeStep].component, {
              data,
              activeStep,
              setData,
              setActiveStep,
              handleSubmit,
              isLoading: addProductTypeDetails.isLoading,
            })}
          </Card>
        </VStack>
      )}
    </Box>
  );
};

export default AddProductTypeButton;
