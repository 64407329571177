export const cleanTinValue = (tin: any) => (tin?.toString() || '').replace('-', '').trim();

export const isValidString = (inputString: any) => {
  return !!inputString && typeof inputString === 'string' && !!inputString.length;
};

export const validateEmail = (email: string) => {
  return !!email
    .toLowerCase()
    // .match(/[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*/);
    .match(
      // from: https://stackoverflow.com/a/201378
      // eslint-disable-next-line no-control-regex
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
    );
};

export const isValidGuid = (guid: string) => {
  const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return guidRegex.test(guid);
};

export const getFileName = (path: string) => {
  return path.replace(/^.*[\\\/]/, '');
};

export const removeImageFromHtmlString = (text: string): [string, boolean] => {
  try {
    const document = new DOMParser().parseFromString(text, 'text/html');
    let hasImage = false;
    for (var i = document.images.length; i-- > 0; ) {
      hasImage = true;
      document.images[i].parentNode?.removeChild(document.images[i]);
    }
    return [document.body.innerHTML, hasImage];
  } catch (error) {
    return [text, false];
  }
};

export const stringOrHtmlIsEmpty = (text: string | undefined | null): boolean => {
  if (!text || text.trim() === '' || text.trim() === '<p><br></p>') return true;

  const doc = new DOMParser().parseFromString(text, 'text/xml');
  if (doc?.firstChild?.textContent?.trim() === '') return true;

  return false;
};

export const replaceAllInstances = (input: string, search: string, replacement: string): string => {
  // Escape special characters in search string to ensure correct behavior in regex
  const escapedSearch = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  // Create a regular expression with the 'g' flag to find all instances
  const regex = new RegExp(escapedSearch, 'g');

  // Use the replace method with the regex to replace all instances
  return input.replace(regex, replacement);
};

export const getName = (
  obj: { [key: string]: any } | null,
  opts?: { firstNameOnly?: boolean; noEmail?: boolean; default?: string },
): string => {
  const defaultValue = opts?.default ? opts.default : '';

  if (opts?.firstNameOnly) return obj?.first_name || obj?.last_name || obj?.email || defaultValue;

  const fname = obj?.first_name ? obj.first_name : '';
  const lname = obj?.last_name ? obj.last_name : '';
  const email = obj?.email ? obj.email : '';
  const userCompleteName = obj?.user_complete_name ? obj.user_complete_name : '';

  const noNameValue = opts?.noEmail ? defaultValue : email;
  return !fname && !lname ? noNameValue : !fname ? lname : fname + (lname ? ` ${lname}` : '');
};

export const isValidURL = (url: string): boolean => {
  const pattern: RegExp = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
  return pattern.test(url);
};

export const captializeWord = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);
export const captializeWords = (sentence: string) =>
  sentence
    .split(' ')
    .map(w => captializeWord(w))
    .join(' ');
