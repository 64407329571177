import { partnerApi } from '.';
import { RefPaymentTrackModel } from './types';

const baseUrl = '/payment-track';

const extendedApi = partnerApi.injectEndpoints({
  endpoints: build => ({
    getAllRefPaymentTrack: build.query<RefPaymentTrackModel[], void>({
      query: () => baseUrl,
    }),
  }),
});

export const { useGetAllRefPaymentTrackQuery, useLazyGetAllRefPaymentTrackQuery } = extendedApi;
