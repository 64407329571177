const AddSuccessCheckIcon = () => {
  return (
    <svg width="114" height="114" viewBox="0 0 114 114" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 4784">
        <circle id="Ellipse 6" cx="57" cy="57" r="53" stroke="#3794AA" stroke-width="8" />
        <path
          id="Vector 29"
          d="M38.2158 59.267L48.5137 74.018C49.3221 75.1759 51.0433 75.154 51.8221 73.976L75.4601 38.2158"
          stroke="#3794AA"
          stroke-width="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};

export default AddSuccessCheckIcon;
