import { Avatar, Box, HStack, Heading, Text, VStack } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { Row } from 'react-table';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { AppSize } from '../../app/constants';
import { AppAccess } from '../../app/constants/appAccesses';
import { FirstNameSchema, LastNameSchema } from '../../app/constants/validations';
import { getAvatarUrl } from '../../app/helpers/avatarHelper';
import { convertUtcToLocal } from '../../app/helpers/dateHelper';
import { getName } from '../../app/helpers/stringHelper';
import { RefUserModel, UserRoleAppAccessModel } from '../../app/services/partner/api/types';
import { useAppSelector } from '../../app/state/hooks';
import { DynamicObject } from '../../app/types/appType';
import CustomTable from '../../components/CustomTable';
import AppAuth from '../../features/AppAuth';
import DetailsCell from './Cells/DetailsCell';
import DisableCell from './Cells/DisableCell';
import UserContextProvider, { UserInitialSortBy, useUserContext } from './Context';
import LogonUserName from './LogonUserName';
import UserDrawer from './UserDrawer';
import UserRolePanel from './UserRolePanel';
import CreateInvitationButton from './SendInvitationModal/CreateInvitationButton';

import { PARTNER_PAGE } from '../../app/constants/partnerPage';
import { PAGE_ACTIVITY } from '../../app/constants/pageActivity';
import { useAddUserActivityMutation } from '../../app/services/partner/api/userActivity';

export const UserNameFormSchema = Yup.object().shape({
  first_name: FirstNameSchema,
  last_name: LastNameSchema,
});

export const RefDemographic = {
  first_name: 1,
  last_name: 2,
  middle_name: 3,
};

interface UsersChildProps {
  userRoles: UserRoleAppAccessModel[] | undefined;
}

const Users = () => {
  const { currentUserUserRole } = useAppSelector(s => s.userRole);

  return (
    <UserContextProvider>
      <UsersChild userRoles={currentUserUserRole} />
      <UserDrawer />
    </UserContextProvider>
  );
};

const UsersChild = ({ userRoles }: UsersChildProps) => {
  const { pageNumber, pageSize, sortBy, setPageNumber, setPageSize, setSortBy, isLoading, isFetching, data } =
    useUserContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const { logonUser, selectedPartnerProduct, activityList, pageList, userSessionId } = useAppSelector(s => s.user);
  const { isSideNavOpen } = useAppSelector(s => s.app);

  const [postAddUserActivity] = useAddUserActivityMutation();

  const users_page = pageList.find(i => i.page_name === PARTNER_PAGE.USERS);
  const users_clicked = activityList.find(i => i.activity_name === PAGE_ACTIVITY.USERS_CLICKED);

  useEffect(() => {
    if (users_page && users_clicked && userSessionId) {
      postAddUserActivity({
        user_session_id: userSessionId,
        ref_activity_id: users_clicked.ref_activity_id,
        ref_page_id: users_page.ref_page_id,
        partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      })
        .unwrap()
        .then(res => {});
    }
  }, []);

  const Header = [
    {
      Header: 'Name',
      accessor: 'first_name',
      Cell: ({ row: { original, index } }: { row: Row<RefUserModel> }) => {
        return (
          <HStack>
            <Avatar
              w="32px"
              h="32px"
              size="md"
              bg="gray.300"
              src={getAvatarUrl(original.sso_object_id ?? '')}
              name={getName(original)}
            >
              {/* TODO tbd temporary badge */}
              {/* <AvatarBadge boxSize="0.90rem" bg={["green.500", "yellow.300", "red.500"][getRandomInt(3)]} /> */}
            </Avatar>
            <Box>
              <Text fontSize="sm">{getName(original, { default: 'N/A', noEmail: true })}</Text>
              <Text fontSize="xs" color="blackAlpha.600">
                {original.email}
              </Text>
            </Box>
          </HStack>
        );
      },
    },
    {
      Header: 'ID',
      accessor: 'ref_user_id',
    },
    {
      Header: 'Created By',
      accessor: 'row_created_by_user_name',
    },
    {
      Header: 'Date Created',
      accessor: 'row_created_datetime_utc',
      Cell: ({ row: { original, index } }: { row: Row<RefUserModel> }) => {
        return <>{convertUtcToLocal(original.row_created_datetime_utc, 'YYYY-MM-D') || '-'}</>;
      },
    },
    {
      Header: 'Modified By',
      accessor: 'row_modified_by_user_name',
    },
    {
      Header: 'Date Modified',
      accessor: 'row_modified_datetime_utc',
      Cell: ({ row: { original, index } }: { row: Row<RefUserModel> }) => {
        return <>{convertUtcToLocal(original.row_modified_datetime_utc, 'YYYY-MM-D') || '-'}</>;
      },
    },
    {
      Header: 'Disable Date',
      accessor: 'disabled_datetime_utc',
      Cell: ({ row: { original, index } }: { row: Row<RefUserModel> }) => {
        return <>{convertUtcToLocal(original.disabled_datetime_utc, 'YYYY-MM-D') || '-'}</>;
      },
    },
    {
      Header: 'Disable',
      accessor: 'disabled_flag',
      Cell: DisableCell,
    },
    {
      Header: 'Details',
      Cell: DetailsCell,
    },
  ];

  const sideNavWidth = isSideNavOpen ? AppSize.sideNav.width.open : AppSize.sideNav.width.close;

  return (
    <VStack gap="6" w="100%">
      <Box>
        <HStack w="100%" gap="6" alignItems="start">
          <Avatar
            w="110px"
            h="110px"
            size="2xl"
            bg="gray.300"
            src={getAvatarUrl(logonUser?.sso_object_id ?? '')}
            name={getName(logonUser)}
          />

          <VStack w="100%" gap="2">
            <LogonUserName />
            <Text color="blackAlpha.600">{logonUser?.email}</Text>
            {/* ADD User Role accordion here */}
            <UserRolePanel refUserId={logonUser?.ref_user_id ?? 0} />
          </VStack>
        </HStack>
      </Box>

      <AppAuth requiredAppAccess={AppAccess.LoreHealthAndPartnerAdminAccess}>
        <Box mt="14px" w="100%">
          <VStack>
            <Heading fontSize="20px" mb="6">
              Users
            </Heading>
          </VStack>

          <Box bg="white" p="4" shadow="base" rounded="md">
            <CustomTable
              variant="table"
              isLoading={isLoading}
              isFetching={isFetching}
              data={data?.data ?? []}
              pageCount={data?.total_pages || 0}
              pageSize={pageSize || data?.page_size || 0}
              totalRecords={data?.total_records || 0}
              pageIndex={pageNumber - 1}
              headers={Header}
              search={searchParams.get('search') ?? ''}
              initialState={{ sortBy: [UserInitialSortBy] }}
              manualSortBy
              disableSortRemove
              disableHoverHighlight
              showNoRecords
              onPageChange={index => {
                setPageNumber(index + 1);
              }}
              onPageSizeChange={size => {
                setPageSize(size);
                setPageNumber(1);
              }}
              onPageSearchDebounce={400}
              onPageSearch={search => {
                setPageNumber(1);

                const params: DynamicObject = {};
                searchParams.forEach((val, key) => (params[key] = val));
                setSearchParams({ ...params, search: search }, { replace: true });
              }}
              onSort={sort => {
                if (sort[0]) {
                  setPageNumber(1);
                  setSortBy(sort[0]);
                }
              }}
              styles={{
                pagination: { justifyContent: 'start' },
                header: { justifyContent: 'left' },
                loadingStyle: 'overlay',
                tableContainer: {
                  sx: {
                    maxW: `calc(100vw - 6.7rem - ${sideNavWidth})`,
                    overflow: 'auto',
                    table: {
                      borderCollapse: 'separate',
                      borderSpacing: '0',
                      'thead > tr': {
                        position: 'sticky',
                        left: 0,
                        top: 0,
                        zIndex: 2,
                        height: 'auto',
                        bg: 'white',
                      },
                      tr: {
                        'th:last-child': {
                          position: 'sticky',
                          right: '0px',
                          zIndex: 2,
                          bg: 'white',
                          w: '99px',
                        },
                        'td:last-child': {
                          position: 'sticky',
                          right: '0px',
                          zIndex: 2,
                          bg: 'white',
                          w: '99px',
                        },
                        'th:nth-last-of-type(2)': {
                          position: 'sticky',
                          right: '99px',
                          zIndex: 2,
                          bg: 'white',
                          borderLeft: '1px',
                          borderColor: 'gray.100',
                        },
                        'td:nth-last-of-type(2)': {
                          position: 'sticky',
                          right: '99px',
                          zIndex: 2,
                          bg: 'white',
                          borderLeft: '1px',
                          borderColor: 'gray.100',
                        },
                      },
                    },
                  },
                },
              }}
              manual={true}
              extraComponents={{
                afterSearch: (
                  <AppAuth requiredAppAccess={AppAccess.LoreHealthAndPartnerAdminAccess}>
                    <CreateInvitationButton />
                  </AppAuth>
                ),
              }}
            />
          </Box>
        </Box>
      </AppAuth>
    </VStack>
  );
};

export default Users;
