import { Box, Button } from '@chakra-ui/react';
import { PartnerModel } from '../../../../app/services/partner/api/types';
import { useState, useEffect } from 'react';
import PartnerDetailsModal from '../../PartnerDetailsModal';

const EditPartnerInfoBUtton = ({ original }: { original: PartnerModel }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const SOURCE = 'button';

  const handleOnClick = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    console.log(`Edit Partner Info Clicked - (isModalOpen) - ${isModalOpen}`);
  }, [isModalOpen]);

  return (
    <>
      <Button
        size="sm"
        _hover={{ textDecoration: 'none' }}
        variant="link"
        fontWeight="normal"
        textAlign="left"
        onClick={handleOnClick}
      >
        <Box w="100%">Edit Partner Info</Box>
      </Button>

      <PartnerDetailsModal partner={original} source={SOURCE} openModal={isModalOpen} setModal={setIsModalOpen} />
    </>
  );
};

export default EditPartnerInfoBUtton;
