import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserRoleAppAccessModel } from '../services/partner/api/types';
type UserRoleState = {
  currentUserUserRole?: UserRoleAppAccessModel[];
};

const initialState: UserRoleState = {};

export const userRoleSlice = createSlice({
  name: 'userRoleSlice',
  initialState,
  reducers: {
    setCurrentUserUserRole: (state, action: PayloadAction<UserRoleAppAccessModel[]>) => {
      state.currentUserUserRole = action.payload;
    },
  },
});

export const { setCurrentUserUserRole } = userRoleSlice.actions;
export default userRoleSlice.reducer;
