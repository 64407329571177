import { partnerApi } from '.';
import { RefPartnerProductTagModel } from './types';

const baseUrl = '/partner-product-tag';

const extendedApi = partnerApi.injectEndpoints({
  endpoints: build => ({
    getAllPartnerProductTag: build.query<RefPartnerProductTagModel[], void>({
      query: () => baseUrl,
    }),
  }),
});

export const { useGetAllPartnerProductTagQuery, useLazyGetAllPartnerProductTagQuery } = extendedApi;
