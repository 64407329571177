import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Card,
  CloseButton,
  Flex,
  Heading,
  Portal,
  Step,
  StepIcon,
  StepIndicator,
  StepSeparator,
  StepStatus,
  Stepper,
  VStack,
  useDisclosure,
  useSteps,
  useToast,
} from '@chakra-ui/react';
import { createElement, useState } from 'react';
import { defaultErrorMessage } from '../../../app/constants';
import { useAddPartnerModelMutation } from '../../../app/services/partner/api/partner';
import { AddPartnerModel } from '../../../app/services/partner/api/types';
import AddSuccess from '../../Dashboard/Admin/AddSuccess';
import { AddPartnerModalStyle, AddPartnerStepProp, addPartnerInitialValues } from '../../Dashboard/Admin/helper';
import Step1 from '../../Dashboard/Admin/Partner/AfterSearch/Add/Step1';
import Step2 from '../../Dashboard/Admin/Partner/AfterSearch/Add/Step2';
import Step3 from '../../Dashboard/Admin/Partner/AfterSearch/Add/Step3';

import { useAddUserActivityMutation } from '../../../app/services/partner/api/userActivity';
import { useAppSelector } from '../../../app/state/hooks';
import { PARTNER_PAGE } from '../../../app/constants/partnerPage';
import { PAGE_ACTIVITY } from '../../../app/constants/pageActivity';

const steps = [{ component: Step1 }, { component: Step2 }, { component: Step3 }];

const AddPartnerButton = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleOpen = () => {
    onOpen();
  };

  return (
    <>
      <Button colorScheme="brand.main" size="sm" leftIcon={<AddIcon fontSize="xx-small" />} onClick={handleOpen}>
        Add
      </Button>

      <Portal>{isOpen && <Child onClose={onClose} />}</Portal>
    </>
  );
};

const Child = ({ onClose }: { onClose: () => void }) => {
  const [postAddUserActivity] = useAddUserActivityMutation();
  const { selectedPartnerProduct, activityList, pageList, userSessionId } = useAppSelector(s => s.user);

  const partners_page = pageList.find(i => i.page_name === PARTNER_PAGE.PARTNERS);
  const partner_draft_cancelled = activityList.find(i => i.activity_name === PAGE_ACTIVITY.PARTNER_DRAFT_CANCELLED);

  const handleCancelSubmit = () => {
    if (partners_page && partner_draft_cancelled && userSessionId) {
      postAddUserActivity({
        user_session_id: userSessionId,
        ref_activity_id: partner_draft_cancelled.ref_activity_id,
        ref_page_id: partners_page.ref_page_id,
        partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      })
        .unwrap()
        .then(res => {})
        .finally(() => {
          handleSubmit();
        });
    }
    onClose();
  };

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const [isSuccess, setIsSuccess] = useState(false);
  const [data, setData] = useState<AddPartnerModel>(addPartnerInitialValues);
  const source = 'partner-page';

  const [addPartner, addPartnerDetails] = useAddPartnerModelMutation();
  const toast = useToast();

  const clear = () => {
    setActiveStep(0);
    setData(addPartnerInitialValues);
  };

  const handleSubmit = () => {
    addPartner(data)
      .unwrap()
      .then(() => {
        clear();
        setIsSuccess(true);
      })
      .catch(() => {
        toast({ description: defaultErrorMessage, status: 'error' });
      });
  };

  return (
    <Box {...AddPartnerModalStyle}>
      {isSuccess ? (
        <AddSuccess
          clear={clear}
          text="You have successfully added a new Partner."
          actions={
            <Flex w="100%" gap="4">
              <Button onClick={onClose} colorScheme="brand.main" ml="auto">
                Close
              </Button>
            </Flex>
          }
        />
      ) : (
        <VStack maxW="880px" mx="auto" gap="6">
          <Heading as="h2" fontSize="xl" fontWeight="semibold">
            Add a Partner
          </Heading>
          <Card p="4">
            <CloseButton onClick={handleCancelSubmit} ml="auto" isDisabled={addPartnerDetails.isLoading} />

            <Stepper size="sm" index={activeStep} gap="0" colorScheme="brand.main" px="10" mt="4" mb="6">
              {steps.map((_, index) => (
                <Step key={index} style={{ gap: 0 }}>
                  <StepIndicator>
                    <StepStatus complete={<StepIcon />} />
                  </StepIndicator>
                  <StepSeparator style={{ marginLeft: 0 }} />
                </Step>
              ))}
            </Stepper>
            {createElement<AddPartnerStepProp>(steps[activeStep].component, {
              data,
              activeStep,
              setData,
              setActiveStep,
              handleSubmit,
              isLoading: addPartnerDetails.isLoading,
              source,
            })}
          </Card>
        </VStack>
      )}
    </Box>
  );
};

export default AddPartnerButton;
