import { Button, IconButton } from '@chakra-ui/button';
import { AddIcon } from '@chakra-ui/icons';
import { Box, CloseButton, Heading, Flex, Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';

import { AddUserInvitationModel, PartnerProductDisplayModel } from '../../../app/services/partner/api/types';
import UserInformationPanel from './Panels/UserInformation';
import EmailTemplatePanel from './Panels/EmailTemplate';
import RolesSelectionPanel from './Panels/RolesSelection';
import ProgressIndicator from './ProgressIndicator';

import { useDisclosure } from '@chakra-ui/hooks';
import { useState } from 'react';

const defaultValues: AddUserInvitationModel = {
  first_name: '',
  last_name: '',
  email_address: '',
  partner_id: 0,
  partner_product_id: 0,
  ref_email_template_id: 0,
  user_role_list: [],
};

const CreateInvitationButton = () => {
  const [invitationStage, setInvitationStage] = useState<number>(1);
  const [userInvitation, setUserInvitation] = useState<AddUserInvitationModel>(defaultValues);
  const [emailContent, setEmailContent] = useState<string>('');
  const [ssoObjectId, setSsoObjectId] = useState<string>('');
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [selectedPartnerProduct, setSelectedPartnerProduct] = useState<PartnerProductDisplayModel | null>(null);

  const handleCancel = () => {
    setUserInvitation(defaultValues);
    setInvitationStage(1);
    onClose();
  };

  const handleOnEmailContentChange = (updatedEmailContent: string) => {
    setEmailContent(updatedEmailContent);
  };

  const handleOpen = () => {
    onOpen();
  };

  const updatePartnerProduct = (newValue: PartnerProductDisplayModel | null) => {
    setSelectedPartnerProduct(newValue);
  };

  const updateInvitationStage = (newValue: number) => {
    setInvitationStage(newValue);
  };

  const updateUserInvitation = (updatedUserInvitation: AddUserInvitationModel) => {
    setUserInvitation(prevUserInvitation => ({ ...prevUserInvitation, ...updatedUserInvitation }));
  };

  const updateSsoObjectId = (ssoId: string) => {
    setSsoObjectId(ssoId);
  };

  let content: JSX.Element | null = null;

  switch (invitationStage) {
    case 1:
      content = (
        <UserInformationPanel
          onUpdateEmailContent={handleOnEmailContentChange}
          refProductTypeId={selectedPartnerProduct?.ref_product_type_id}
          handleCancel={handleCancel}
          userInvitation={userInvitation}
          updateUserInvitation={updateUserInvitation}
          updateInvitationStage={updateInvitationStage}
          updateSsoObjectId={updateSsoObjectId}
          updatePartnerProduct={updatePartnerProduct}
        />
      );
      break;
    case 2:
      content = (
        <EmailTemplatePanel
          emailContent={emailContent}
          handleCancel={handleCancel}
          partnerName={selectedPartnerProduct?.partner_name ?? ''}
          updateInvitationStage={updateInvitationStage}
        />
      );
      break;
    case 3:
      content = (
        <RolesSelectionPanel
          handleCancel={handleCancel}
          refProductTypeId={selectedPartnerProduct?.ref_product_type_id}
          ssoObjectId={ssoObjectId}
          userInvitation={userInvitation}
        />
      );
      break;
  }

  return (
    <>
      <Button size="sm" colorScheme="brand.main" gap="2" px="5" onClick={handleOpen}>
        <AddIcon fontSize="xx-small" />
        Invite User
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="3xl" closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent position="absolute" top="10%" left="30%">
          <ModalBody p="4" display="flex" flexDir="column" gap="3">
            <Flex justifyContent={'space-between'}>
              <Box pt={'1.5'}>
                <Heading as="h3" size="sm">
                  User Invite
                </Heading>
              </Box>

              <Box>
                <IconButton
                  variant="link"
                  color="black"
                  size="xxs"
                  aria-label="close modal"
                  onClick={onClose}
                  icon={<CloseButton />}
                />
              </Box>
            </Flex>

            <Box display="flex" gap="4" justifyContent={'center'} mb={5}>
              <ProgressIndicator invitationStage={invitationStage} />
            </Box>

            <Box>{content}</Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateInvitationButton;
