import { partnerApi } from '.';
import {
  UserRoleAppAccessModel,
  UserRoleModel,
  UserRoleDisplayModel,
  AddUserRoleByAdminModel,
  RefRoleWithUserValuesModel,
  GetUserRoleByRoleAndPartnerProductModel,
} from './types';

const baseUrl = '/user-role';

const extendedApi = partnerApi.injectEndpoints({
  endpoints: build => ({
    getRefRoleListWithUserValues: build.query<RefRoleWithUserValuesModel[], number>({
      query: refUserId => baseUrl + `/roles/${refUserId}`,
    }),
    getUserRoleListByRefUserId: build.query<UserRoleDisplayModel[], number>({
      query: refUserId => baseUrl + `/user/${refUserId}`,
    }),
    getUserListByRoleId: build.query<UserRoleModel[], GetUserRoleByRoleAndPartnerProductModel>({
      query: model => baseUrl + `/${model.ref_role_id}/users/${model.partner_product_id}`,
    }),
    getAppAccessByEmail: build.query<UserRoleAppAccessModel[], string>({
      query: email => baseUrl + `/appaccess/email/${email}`,
    }),
    addUserRoleByAdmin: build.mutation<void, AddUserRoleByAdminModel>({
      query: body => ({
        url: baseUrl + '/admin',
        body: body,
        method: 'POST',
      }),
    }),

    // updatePartnerProductAndTags: build.mutation<void, UpdatePartnerProductAndTagsModel>({
    //   query: ({ partner_product_id, ...body }) => ({
    //     url: baseUrl + `/tags/${partner_product_id}`,
    //     method: 'PUT',
    //     body,
    //   }),
    //   invalidatesTags: ['PartnerProduct'],
    // }),
  }),
});

export const {
  useLazyGetRefRoleListWithUserValuesQuery,
  useLazyGetUserListByRoleIdQuery,
  useGetAppAccessByEmailQuery,
  useLazyGetAppAccessByEmailQuery,
  useGetUserRoleListByRefUserIdQuery,
  useLazyGetUserRoleListByRefUserIdQuery,
  useAddUserRoleByAdminMutation,
} = extendedApi;
