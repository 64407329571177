import {
  FilterDataDetailModel,
  MyRolesFilteredSearchParamsModel,
  MyRolesFilteredSearchResultsModel,
} from '../../../../app/services/partner/api/types';
import { Box, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { CustomTableHeader } from '../../../../app/types/appType';
import { useLazyGetMyRolesFilteredSearchResultsQuery } from '../../../../app/services/partner/api/myRoles';
import CustomTableV2 from '../../../../components/CustomTableV2';
import CustomTable from '../../../../components/CustomTable';
import { convertUtcToLocal } from '../../../../app/helpers/dateHelper';
import { useAppSelector } from '../../../../app/state/hooks';
import { CellProps } from 'react-table';
import MyRolesActionCell from './MyRolesActionCell';

type IProps = {
  filters: FilterDataDetailModel[];
  updateSelectedRoleId: (newValue: number) => void;
};

const ResultData = ({ filters }: IProps) => {
  const [getSearchResults, getSearchResultsDetails] = useLazyGetMyRolesFilteredSearchResultsQuery();
  const [searchResults, setSearchresults] = useState<MyRolesFilteredSearchResultsModel[]>([]);
  const { logonUser } = useAppSelector(s => s.user);

  const toast = useToast();
  const DEFAULT_FETCH_ERROR_MESSAGE = 'A problem has been encountered while fetching your search results';

  const Headers: CustomTableHeader<MyRolesFilteredSearchResultsModel> = [
    {
      Header: 'STATUS',
      accessor: 'status_display_name',
      Cell: ({ row: { original } }: CellProps<MyRolesFilteredSearchResultsModel>) => {
        return <>{original.status_display_name || '-'}</>;
      },
    },
    {
      Header: 'ROLE',
      accessor: 'role_display_name',
      Cell: ({ row: { original } }: CellProps<MyRolesFilteredSearchResultsModel>) => {
        return <>{original.role_display_name || '-'}</>;
      },
    },
    {
      Header: 'REQUEST DATE',
      styles: { textAlign: 'center' },
      Cell: ({ row: { original } }: CellProps<MyRolesFilteredSearchResultsModel>) => {
        return <>{convertUtcToLocal(original.request_datetime_utc) || '-'}</>;
      },
    },
    {
      Header: 'EXPIRATION DATE',
      styles: { textAlign: 'center' },
      Cell: ({ row: { original } }: CellProps<MyRolesFilteredSearchResultsModel>) => {
        return <>{convertUtcToLocal(original.expiration_datetime_utc) || '-'}</>;
      },
    },
    {
      Header: 'ACTION',
      styles: { textAlign: 'center' },
      Cell: MyRolesActionCell,
    },
  ];

  useEffect(() => {
    const roleIds: Array<number> = filters.map(i => {
      if (i.filter_header.toLowerCase() === 'role') {
        return i.id;
      } else {
        return 0;
      }
    });

    const statusIds: Array<number> = filters.map(i => {
      if (i.filter_header.toLowerCase() === 'status') {
        return i.id;
      } else {
        return 0;
      }
    });

    const params: MyRolesFilteredSearchParamsModel = {
      ref_user_id: logonUser?.ref_user_id ?? 0,
      role_ids: roleIds ?? [],
      status_ids: statusIds ?? [],
    };

    // console.log(`PARAMS: ${JSON.stringify(filters)}`);

    getSearchResults(params)
      .unwrap()
      .then(res => setSearchresults(res))
      .catch(error => toast({ description: error, status: 'error' }));
  }, [filters]);

  return (
    <VStack key={0}>
      <Box bg="white" shadow="base" p="4" rounded="md" minH="20vh" minW="container.md">
        <CustomTable
          pageCount={1}
          hidePagination
          hideRowsPerPage
          onPageChange={() => {
            return 1;
          }}
          search={''}
          onSort={() => {
            return 1;
          }}
          headers={Headers}
          showRecordCount={false}
          variant="table"
          pageSize={searchResults.length ?? 0}
          isLoading={getSearchResultsDetails.isLoading}
          isFetching={getSearchResultsDetails.isFetching}
          data={searchResults}
        />
      </Box>
    </VStack>
  );
};

export default ResultData;
