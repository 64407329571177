import { Text, Box, Icon } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';

interface ProgressIndicatorProps {
  invitationStage: number;
}

interface NumberedCircleProps {
  id: number;
  invitationStage: number;
}

const NumberedCircle = ({ id, invitationStage }: NumberedCircleProps) => {
  return (
    <Box
      display="inline-block"
      bg={id <= invitationStage ? '#3794aa' : '#d6d6d6'}
      borderRadius={'full'}
      fontWeight={'bold'}
      color={id <= invitationStage ? 'white' : '#6f6f6f'}
      width="30px"
      textAlign={'center'}
      lineHeight={'30px'}
      fontSize={'sm'}
      mr={2}
    >
      <Text>{id}</Text>
    </Box>
  );
};

interface InvitationStageLabelProps {
  id: number;
  invitationStage: number;
  labelText: string;
}

const ArrowIcon = () => {
  return (
    <Box pt={'3px'} display="flex" justifyContent={'flex-end'} pr="2">
      <ChevronRightIcon boxSize={'25px'} />
    </Box>
  );
};

const InvitationStageLabel = ({ id, invitationStage, labelText }: InvitationStageLabelProps) => {
  return (
    <>
      <Text
        textAlign={'center'}
        fontWeight={'bold'}
        fontSize={'15px'}
        ml="-3"
        pt={'1'}
        color={id <= invitationStage ? 'black' : '#858585'}
      >
        {labelText}
      </Text>
    </>
  );
};

const stages: { id: number; label: string }[] = [
  { id: 1, label: 'User Information' },
  { id: 2, label: 'Email Template' },
  { id: 3, label: 'Roles Selection' },
];

const ProgressIndicator = ({ invitationStage }: ProgressIndicatorProps) => {
  return (
    <>
      <Box display="flex" justifyContent={'center'}>
        {stages.map(item => (
          <Box key={item.id} display="flex" gap="2">
            <NumberedCircle id={item.id} invitationStage={invitationStage} />
            <InvitationStageLabel id={item.id} invitationStage={invitationStage} labelText={item.label} />
            {item.id < 3 ? <ArrowIcon /> : <></>}
          </Box>
        ))}
      </Box>
    </>
  );
};

export default ProgressIndicator;
