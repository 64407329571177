import { Box, SimpleGrid, VStack } from '@chakra-ui/react';
import DashboardAdminPartners from './Partner';
import DashboardAdminPartnerProducts from './PartnerProducts';
import DashboardAdminPartnerType from './ProductType';

const DashboardAdmin = () => {
  return (
    <VStack
      mt="2"
      gap="6"
      // h="100%"
      sx={{
        '& .dashboard-card': {
          bg: 'white',
          shadow: 'md',
          rounded: 'lg',
          p: '4',
          minH: '448px',
          h: '100%',
          flexGrow: '1',
        },
      }}
    >
      <Box flex="1 1 auto">
        <DashboardAdminPartners />
      </Box>

      <SimpleGrid columns={2} spacing={6} w="100%">
        <DashboardAdminPartnerType />
        <DashboardAdminPartnerProducts />
      </SimpleGrid>
    </VStack>
  );
};

export default DashboardAdmin;
