import { partnerApi } from '.';
import { RefEmailTemplateDisplayModel } from './types';
import { TemplateRoleMappingModel } from './types';

const baseUrl = '/refemailtemplate';

const extendedApi = partnerApi.injectEndpoints({
  endpoints: build => ({
    getTemplateRoleMappingByTemplateId: build.query<TemplateRoleMappingModel[], number>({
      query: id => baseUrl + `/mapping/${id}`,
    }),

    getRefEmailTemplateDisplayList: build.query<RefEmailTemplateDisplayModel[], void>({
      query: () => ({
        url: baseUrl + '/list',
      }),
      providesTags: ['RefEmailTemplate'],
    }),
  }),
});

export const { useGetRefEmailTemplateDisplayListQuery, useGetTemplateRoleMappingByTemplateIdQuery } = extendedApi;
