import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Input,
  Skeleton,
  Text,
  useToast,
  Switch,
  VStack,
  Spinner,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { getName } from '../../../../../app/helpers/stringHelper';
import { useGetRefRoleDisplayListQuery } from '../../../../../app/services/partner/api/refRole';
import { useLazyGetUserRoleListByRefUserIdQuery } from '../../../../../app/services/partner/api/userRole';
import {
  UserRoleDisplayModel,
  SelectedUserModel,
  RefRoleWithUserValuesModel,
} from '../../../../../app/services/partner/api/types';
import { useAddUserRoleByAdminMutation } from '../../../../../app/services/partner/api/userRole';
import { useLazyGetRefRoleListWithUserValuesQuery } from '../../../../../app/services/partner/api/userRole';

interface RolesDrawerProps {
  isRolesDrawerOpen: boolean;
  setIsRolesDrawerOpen: (open: boolean) => void;
  selectedUser: SelectedUserModel | null;
}

const RolesDrawer = ({ selectedUser, setIsRolesDrawerOpen, isRolesDrawerOpen }: RolesDrawerProps) => {
  const [userRoles, setUserRoles] = useState<RefRoleWithUserValuesModel[] | []>([]);
  const roles = useGetRefRoleDisplayListQuery();
  const [getUserRoles, getUserRolesDetails] = useLazyGetRefRoleListWithUserValuesQuery();
  const [post, postDetails] = useAddUserRoleByAdminMutation();
  const toast = useToast();

  const handleClose = () => {
    setIsRolesDrawerOpen(false);
  };

  useEffect(() => {
    if (selectedUser) {
      getUserRoles(selectedUser.ref_user_id)
        .unwrap()
        .then(res => {
          setUserRoles(res);
          console.log(`USER ID: ${selectedUser.ref_user_id} - USER ROLES: ${JSON.stringify(res)}`);
        });
    }
  }, [selectedUser]);

  const handleOnChange = (isChecked: boolean, id: string) => {
    console.log(`TOGGLED: ${isChecked} - ${id}`);
    const roleId = parseInt(id);

    setUserRoles(prev =>
      prev.map(item => (item.ref_role_id === roleId ? { ...item, disabled_flag: !item.disabled_flag } : item)),
    );

    post({ disabled_flag: !isChecked, ref_role_id: roleId, ref_user_id: selectedUser?.ref_user_id || 0 })
      .unwrap()
      .then(res => {
        toast({ status: 'success', description: 'Success' });
        console.log(`TOGGLED: ${isChecked} - ${roleId}`);
      });

    // if (isChecked) {
    //   setSelectedRoles(prevNumbers => [...prevNumbers, roleId]);
    // } else {
    //   setSelectedRoles(prevNumbers => prevNumbers.filter(i => i !== roleId));
    // }
  };

  interface RoleSwitchProps {
    refRoleId: number;
  }

  //   const RoleSwitch = ({ refRoleId }: RoleSwitchProps) => {
  //     const userRole = userRoles.find(i => i.ref_role_id === refRoleId);

  //     return (
  //       <>
  //         {userRole && userRole.ref_user_id === selectedUser?.ref_user_id ? (
  //           <Switch value={refRoleId} isChecked={true} onChange={e => handleOnChange(e.target.checked, e.target.value)} />
  //         ) : (
  //           <Switch value={refRoleId} onChange={e => handleOnChange(e.target.checked, e.target.value)} />
  //         )}
  //       </>
  //     );
  //   };

  return (
    <>
      <Drawer isOpen={isRolesDrawerOpen} placement="right" onClose={handleClose}>
        <DrawerOverlay />
        <DrawerContent sx={{ m: '4', rounded: 'md', p: '0' }} w="426px" maxW="426px">
          <DrawerCloseButton mt="1" onClick={handleClose} />

          <DrawerBody px="4" pb="4" pt="12" display="flex" flexDir="column" gap="1">
            <VStack gap="1">
              <Text fontWeight="semibold" fontSize="xl">
                {getName(selectedUser, { noEmail: true, default: selectedUser?.user_complete_name })}
              </Text>
              <Text fontSize="md" color="blackAlpha.600">
                {selectedUser?.email || '-'}
              </Text>
              <Text fontSize="md" pb={'5'}>
                <Text as="span" fontWeight="semibold">
                  SSO:
                </Text>{' '}
                <Text as="span" color="blackAlpha.600">
                  {selectedUser?.sso_object_id || '-'}
                </Text>
              </Text>
            </VStack>

            <VStack>
              <Text fontWeight="semibold" fontSize={'lg'}>
                Roles
              </Text>
              {getUserRolesDetails.isLoading || getUserRolesDetails.isFetching ? (
                <Spinner size={'sm'} />
              ) : (
                userRoles.map((r, index) => (
                  <Box key={index} display="flex" justifyContent={'space-between'} my="10px" mx="15px">
                    <Box>{r.role_display_name}</Box>
                    <Box>
                      <Switch
                        value={r.ref_role_id}
                        isChecked={!r.disabled_flag}
                        onChange={e => handleOnChange(e.target.checked, e.target.value)}
                      />
                    </Box>
                  </Box>
                ))
              )}
            </VStack>
          </DrawerBody>

          <DrawerFooter p="0" m="4">
            <Button colorScheme="brand.main" onClick={handleClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default RolesDrawer;
