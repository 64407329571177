import { Box, Text, VStack, Textarea } from '@chakra-ui/react';
import { Button } from '@chakra-ui/button';
import HorizontalLine from '../../../HorizontalLine';
import { useState, useEffect } from 'react';
import { emailTemplatePlaceholders } from '../../emailTemplatePlaceholders';
import { replaceAllInstances } from '../../../../app/helpers/stringHelper';

interface InvitationStageProps {
  emailContent: string;
  partnerName: string;
  updateInvitationStage: (newValue: number) => void;
  handleCancel: () => void;
}

const EmailTemplatePanel = ({
  emailContent,
  partnerName,
  updateInvitationStage,
  handleCancel,
}: InvitationStageProps) => {
  const handleNext = () => {
    updateInvitationStage(3);
  };

  const [compoundedEmailContent, setCompoundedEmailContent] = useState('');

  useEffect(() => {
    let newContent = replaceAllInstances(
      replaceAllInstances(emailContent, emailTemplatePlaceholders.DOING_BUSINESS_AS, partnerName),
      emailTemplatePlaceholders.REGISTER_LINK,
      '',
    );

    setCompoundedEmailContent(newContent);
  }, []);

  return (
    <>
      <VStack mb="3">
        <Box mb="1">
          <Text fontWeight={'semibold'} fontSize={'smaller'} mb="-1">
            Email Template Review
          </Text>
        </Box>
        <Box mt="-2">
          <Box
            py={'3'}
            px={'5'}
            dangerouslySetInnerHTML={{ __html: compoundedEmailContent }}
            minHeight="200px"
            maxHeight="500px"
            borderRadius="5px"
            sx={{ border: '1px solid #e2e8f0;' }}
          ></Box>
        </Box>
      </VStack>
      <HorizontalLine marginTop={'0'} />
      <Box display="flex" justifyContent={'flex-end'} mt="5">
        <Box display="flex" gap={2}>
          <Button size="sm" onClick={handleCancel}>
            Cancel
          </Button>
          <Button size="sm" colorScheme="brand.main" gap="2" px="5" onClick={handleNext}>
            Next
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default EmailTemplatePanel;
