import { Box, Card, Heading, Text, VStack } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import AddSuccessCheckIcon from './AddSuccessCheckIcon';

type Props = {
  clear: () => void;
  actions: ReactNode;
  text: string;
};
const AddSuccess: FC<Props> = ({ clear, actions, text }) => {
  return (
    <VStack maxW="474px" mx="auto" gap="6">
      <Card p="4" pt="6" minH="400px" display="flex" flexDir="column">
        <Box flexGrow="1" display="flex" flexDir="column" gap="6" alignItems="center">
          <Heading as="h2" fontSize="xl" fontWeight="semibold">
            Success
          </Heading>
          <AddSuccessCheckIcon />
          <Text fontSize="14px" color="blackAlpha.700">
            {text}
          </Text>
        </Box>
        {actions}
      </Card>
    </VStack>
  );
};

export default AddSuccess;
