import DashboardAdminPartnerTypeAfterSearchAdd from './Add';

const DashboardAdminPartnerTypeAfterSearch = () => {
  return (
    <>
      <DashboardAdminPartnerTypeAfterSearchAdd />
    </>
  );
};

export default DashboardAdminPartnerTypeAfterSearch;
