import { Box, Button, FormControl, FormErrorMessage, FormLabel, Input, VStack } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useFormik } from 'formik';
import { FC } from 'react';
import * as Yup from 'yup';
import { usaStates } from '../../../../../../app/constants/usaStates';
import { useGetAllProductTypeQuery } from '../../../../../../app/services/partner/api/refProductType';
import { AddPartnerModel } from '../../../../../../app/services/partner/api/types';
import {
  AddPartnerStepProp,
  addPartnerFieldMaxLength,
  addPartnerFormSchema,
  countries,
  selectStyles,
} from '../../../helper';

const FormSchema = Yup.object().shape(addPartnerFormSchema);

const Step1: FC<AddPartnerStepProp> = ({ data, activeStep, setData, setActiveStep }) => {
  const productTypesDetails = useGetAllProductTypeQuery();

  const {
    handleSubmit,
    errors,
    touched,
    handleChange,
    values,
    setValues,
    resetForm,
    setFieldValue,
    setFieldTouched,
    submitForm,
  } = useFormik<AddPartnerModel>({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: data,
    onSubmit: (values, form) => {
      setData(d => ({ ...d, ...values }));
      setActiveStep(i => i + 1);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <VStack className="da-container">
        <Box className="da-row">
          <FormControl isInvalid={!!errors.partner_name && !!touched.partner_name}>
            <FormLabel htmlFor="partner_name">Partner Name</FormLabel>
            <Box w="full">
              <Input
                size="sm"
                id="partner_name"
                name="partner_name"
                onChange={handleChange}
                onBlur={handleChange}
                value={values.partner_name ?? ''}
                maxLength={addPartnerFieldMaxLength.partner_name}
              />
              <FormErrorMessage>{errors.partner_name}</FormErrorMessage>
            </Box>
          </FormControl>

          <FormControl isInvalid={!!errors.ref_product_type_id && touched.ref_product_type_id}>
            <FormLabel htmlFor="ref_product_type_id">Product Type</FormLabel>
            <Box w="100%">
              <Select
                size="sm"
                id="ref_product_type_id"
                name="ref_product_type_id"
                placeholder={productTypesDetails.isLoading || productTypesDetails.isFetching ? 'Loading...' : ''}
                isDisabled={productTypesDetails.isLoading || productTypesDetails.isFetching}
                useBasicStyles
                value={(() => {
                  const val = productTypesDetails.data?.find(m => m.ref_product_type_id === values.ref_product_type_id);
                  return val
                    ? {
                        label: val.product_type_name,
                        value: val.ref_product_type_id,
                      }
                    : undefined;
                })()}
                options={(productTypesDetails.data ?? []).map(m => {
                  return {
                    label: m.product_type_name,
                    value: m.ref_product_type_id,
                  };
                })}
                onChange={e => {
                  e &&
                    setValues({
                      ...values,
                      ref_product_type_id: e.value,
                    });
                }}
                chakraStyles={selectStyles<number>()}
              />
              <FormErrorMessage>{errors.ref_product_type_id}</FormErrorMessage>
            </Box>
          </FormControl>
        </Box>

        <Box className="da-row">
          <FormControl isInvalid={!!errors.partner_tin && touched.partner_tin}>
            <FormLabel htmlFor="partner_tin">Partner TIN</FormLabel>
            <Box w="full">
              <Input
                size="sm"
                id="partner_tin"
                name="partner_tin"
                onChange={handleChange}
                onBlur={handleChange}
                value={values.partner_tin ?? ''}
                maxLength={addPartnerFieldMaxLength.partner_tin}
              />
              <FormErrorMessage>{errors.partner_tin}</FormErrorMessage>
            </Box>
          </FormControl>

          <FormControl isInvalid={!!errors.doing_business_as && touched.doing_business_as}>
            <FormLabel htmlFor="doing_business_as">Doing Business as</FormLabel>
            <Box w="full">
              <Input
                size="sm"
                id="doing_business_as"
                name="doing_business_as"
                onChange={handleChange}
                onBlur={handleChange}
                value={values.doing_business_as ?? ''}
                maxLength={addPartnerFieldMaxLength.doing_business_as}
              />
              <FormErrorMessage>{errors.doing_business_as}</FormErrorMessage>
            </Box>
          </FormControl>
        </Box>

        <Box className="da-row">
          <FormControl isInvalid={!!errors.partner_address_1 && touched.partner_address_1}>
            <FormLabel htmlFor="partner_address_1">Address 1</FormLabel>
            <Box w="full">
              <Input
                size="sm"
                id="partner_address_1"
                name="partner_address_1"
                onChange={handleChange}
                onBlur={handleChange}
                value={values.partner_address_1 ?? ''}
                maxLength={addPartnerFieldMaxLength.partner_address_1}
              />
              <FormErrorMessage>{errors.partner_address_1}</FormErrorMessage>
            </Box>
          </FormControl>

          <FormControl isInvalid={!!errors.partner_address_2 && touched.partner_address_2}>
            <FormLabel htmlFor="partner_address_2">Address 2</FormLabel>
            <Box w="full">
              <Input
                size="sm"
                id="partner_address_2"
                name="partner_address_2"
                onChange={handleChange}
                onBlur={handleChange}
                value={values.partner_address_2 ?? ''}
                maxLength={addPartnerFieldMaxLength.partner_address_2}
              />
              <FormErrorMessage>{errors.partner_address_2}</FormErrorMessage>
            </Box>
          </FormControl>
        </Box>

        <Box className="da-row">
          <FormControl isInvalid={!!errors.partner_city && touched.partner_city}>
            <FormLabel htmlFor="partner_city">City</FormLabel>
            <Box w="full">
              <Input
                size="sm"
                id="partner_city"
                name="partner_city"
                onChange={handleChange}
                onBlur={handleChange}
                value={values.partner_city ?? ''}
                maxLength={addPartnerFieldMaxLength.partner_city}
              />
              <FormErrorMessage>{errors.partner_city}</FormErrorMessage>
            </Box>
          </FormControl>

          <FormControl isInvalid={!!errors.partner_state && touched.partner_state}>
            <FormLabel htmlFor="partner_state">State</FormLabel>
            <Box w="100%">
              <Select
                size="sm"
                id="partner_state"
                name="partner_state"
                placeholder=""
                useBasicStyles
                value={(() => {
                  const val = usaStates.find(m => m.abbreviation === values.partner_state);
                  return val
                    ? {
                        label: val.name,
                        value: val.abbreviation,
                      }
                    : undefined;
                })()}
                options={[
                  ...usaStates.map(m => {
                    return {
                      label: m.name,
                      value: m.abbreviation,
                    };
                  }),
                ]}
                onChange={e => {
                  e &&
                    setValues({
                      ...values,
                      partner_state: e.value,
                    });
                }}
                chakraStyles={selectStyles<string>()}
              />
              <FormErrorMessage>{errors.partner_state}</FormErrorMessage>
            </Box>
          </FormControl>
        </Box>

        <Box className="da-row">
          <FormControl isInvalid={!!errors.partner_zip_code && touched.partner_zip_code}>
            <FormLabel htmlFor="partner_zip_code">Postal Code</FormLabel>
            <Box w="full">
              <Input
                size="sm"
                id="partner_zip_code"
                name="partner_zip_code"
                onChange={handleChange}
                onBlur={handleChange}
                value={values.partner_zip_code ?? ''}
                maxLength={addPartnerFieldMaxLength.partner_zip_code}
              />
              <FormErrorMessage>{errors.partner_zip_code}</FormErrorMessage>
            </Box>
          </FormControl>

          <FormControl isInvalid={!!errors.partner_country && touched.partner_country}>
            <FormLabel htmlFor="partner_country">Country</FormLabel>
            <Box w="100%">
              <Select
                size="sm"
                id="partner_country"
                name="partner_country"
                placeholder=""
                useBasicStyles
                value={(() => {
                  return values.partner_country
                    ? {
                        label: values.partner_country,
                        value: values.partner_country,
                      }
                    : undefined;
                })()}
                options={[
                  ...countries.map(m => {
                    return {
                      label: m,
                      value: m,
                    };
                  }),
                ]}
                onChange={e => {
                  e &&
                    setValues({
                      ...values,
                      partner_country: e.value,
                    });
                }}
                chakraStyles={selectStyles<string>()}
              />
              <FormErrorMessage>{errors.partner_country}</FormErrorMessage>
            </Box>
          </FormControl>
        </Box>
      </VStack>

      <Box w="100%" textAlign="right">
        <Button type="submit" colorScheme="brand.main">
          Next
        </Button>
      </Box>
    </form>
  );
};

export default Step1;
