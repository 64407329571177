import { Box, Text } from '@chakra-ui/react';
import ResultData from './ResultData';

import { useAppSelector } from '../../../app/state/hooks';
import { useEffect } from 'react';

import { PARTNER_PAGE } from '../../../app/constants/partnerPage';
import { PAGE_ACTIVITY } from '../../../app/constants/pageActivity';
import { useAddUserActivityMutation } from '../../../app/services/partner/api/userActivity';

const AccessApproval = () => {
  const { selectedPartnerProduct, activityList, pageList, userSessionId } = useAppSelector(s => s.user);
  const [postAddUserActivity] = useAddUserActivityMutation();

  const access_approval_page = pageList.find(i => i.page_name === PARTNER_PAGE.ACCESS_APPROVAL);
  const access_approval_clicked = activityList.find(i => i.activity_name === PAGE_ACTIVITY.ACCESS_APPROVAL_CLICKED);

  useEffect(() => {
    if (access_approval_page && access_approval_clicked && userSessionId) {
      postAddUserActivity({
        user_session_id: userSessionId,
        ref_activity_id: access_approval_clicked.ref_activity_id,
        ref_page_id: access_approval_page.ref_page_id,
        partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      })
        .unwrap()
        .then(res => {});
    }
  }, []);

  return (
    <>
      <Box pb={'5'}>
        <Text sx={{ fontWeight: 'bold', fontSize: '25' }}>Access Approval</Text>
      </Box>

      <Box>
        <ResultData />
      </Box>
    </>
  );
};

export default AccessApproval;
