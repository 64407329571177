import { Box, VStack, Text, Tooltip } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { AppAccess } from '../../../app/constants/appAccesses';
import { CellProps } from 'react-table';
import { AppSize } from '../../../app/constants';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import { useGetRefReportsQuery } from '../../../app/services/partner/api/refReport';
import { ReportExploreModel } from '../../../app/services/partner/api/types';
import { SortType } from '../../../app/services/types';
import { useAppSelector } from '../../../app/state/hooks';
import { CustomTableHeader, DynamicObject } from '../../../app/types/appType';
import { useAddUserActivityMutation } from '../../../app/services/partner/api/userActivity';
import CustomTable from '../../../components/CustomTable';
import './ExploreReports.css';
import AppAuth from '../../../features/AppAuth';
import CreateReportButton from '../CreateReportButton';

import { PARTNER_PAGE } from '../../../app/constants/partnerPage';
import { PAGE_ACTIVITY } from '../../../app/constants/pageActivity';

type Props = {};
const InitialSortBy: SortType<ReportExploreModel> = {
  id: 'report_name',
  desc: false,
};

const ResultData: FC<Props> = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState(InitialSortBy);

  const { isSideNavOpen } = useAppSelector(s => s.app);
  const { logonUser } = useAppSelector(s => s.user);

  //const [getReports, { isLoading, isFetching, data }] = useLazyGetRefReportsQuery();

  const { data, isLoading, isFetching } = useGetRefReportsQuery({
    page_number: pageNumber,
    page_size: pageSize,
    search_string: searchParams.get('search') ?? '',
    sort_column: sortBy.id,
    sort_order: sortBy.desc ? 'desc' : 'asc',
    viewer_ref_user_id: logonUser?.ref_user_id || 1,
  });

  useEffect(() => {
    // needed for search and filters
    setPageNumber(1);
  }, [searchParams]);

  const sideNavWidth = isSideNavOpen ? AppSize.sideNav.width.open : AppSize.sideNav.width.close;

  useEffect(() => {
    // needed for search and filters
    setPageNumber(1);
  }, [searchParams]);

  return (
    <VStack>
      <Box>
        <Text sx={{ fontWeight: 'bold', fontSize: '25' }}>Report Hub</Text>
      </Box>
      <Box bg="blackAlpha.100" p="4" pl="4" rounded="lg" mb="2" mt="2">
        <ul style={{ paddingLeft: '20px' }}>
          <li>To request access to a report, please contact partner@lore.co.</li>
          <li>
            Reminder: When you click on a report, you'll be asked to provide your Lore email address, that looks like
            userabc@lore.co.
          </li>
        </ul>
      </Box>
      <Box bg="white" shadow="base" p="4" rounded="md" minH="20vh" minW="container.md">
        <CustomTable
          variant="table"
          isLoading={isLoading}
          isFetching={isFetching}
          data={data?.data ?? []}
          pageCount={data?.total_pages || 0}
          pageSize={data?.page_size || pageSize}
          totalRecords={data?.total_records || 0}
          pageIndex={pageNumber - 1}
          headers={Header}
          search={searchParams.get('search') ?? ''}
          initialState={{ sortBy: [InitialSortBy] }}
          manualSortBy
          disableSortRemove
          onPageChange={index => {
            setPageNumber(index + 1);
          }}
          onPageSizeChange={size => {
            setPageNumber(1);
            setPageSize(size);
          }}
          onPageSearchDebounce={400}
          onPageSearch={search => {
            setPageNumber(1);

            const params: DynamicObject = {};
            searchParams.forEach((val, key) => (params[key] = val));
            setSearchParams({ ...params, search: search }, { replace: true });
          }}
          onSort={sort => {
            if (sort[0]) {
              setPageNumber(1);
              setSortBy(sort[0]);
            }
          }}
          manual={true}
          styles={{
            pagination: { justifyContent: 'start' },
            header: { justifyContent: 'left' },
            tableContainer: {
              sx: {
                maxW: `calc(100vw - 5rem - ${sideNavWidth})`,
                overflow: 'auto',
                table: {
                  borderCollapse: 'separate',
                  borderSpacing: '0',
                  'thead > tr': {
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    zIndex: 2,
                    height: 'auto',
                    bg: 'white',
                  },
                  tr: {
                    'th:last-child': {
                      position: 'sticky',
                      right: '0px',
                      zIndex: 2,
                      bg: 'white',
                      w: '90px',
                      borderLeft: '1px',
                      borderColor: 'gray.100',
                    },
                    'td:last-child': {
                      position: 'sticky',
                      right: '0px',
                      zIndex: 2,
                      bg: 'white',
                      w: '90px',
                      borderLeft: '1px',
                      borderColor: 'gray.100',
                    },
                  },
                },
              },
            },
          }}
          extraComponents={{
            afterSearch: (
              <AppAuth requiredAppAccess={AppAccess.LoreHealthAdminAccess}>
                <CreateReportButton />
              </AppAuth>
            ),
          }}
        />
      </Box>
    </VStack>
  );
};

const Header: CustomTableHeader<ReportExploreModel> = [
  {
    Header: 'Name',
    accessor: 'report_name',
    styles: { verticalAlign: 'top' },
    Cell: ({ row: { original } }: CellProps<ReportExploreModel>) => {
      const { selectedPartnerProduct, activityList, pageList, userSessionId } = useAppSelector(s => s.user);
      const [postAddUserActivity] = useAddUserActivityMutation();

      function getPageName(): string {
        switch (original.ref_report_id) {
          case 1:
            return String(PARTNER_PAGE.REPORT_HUB_FINANCIAL_REPORT);
          case 2:
            return String(PARTNER_PAGE.REPORT_HUB_PARTNER_REPORT);
          default:
            return '';
        }
      }

      function getActivityName(): string {
        switch (original.ref_report_id) {
          case 1:
            return String(PAGE_ACTIVITY.REPORT_HUB_FINANCIAL_REPORT_CLICKED);
          case 2:
            return String(PAGE_ACTIVITY.REPORT_HUB_PARTNER_REPORT_CLICKED);
          default:
            return '';
        }
      }

      const page = pageList.find(i => i.page_name === getPageName());
      const activity = activityList.find(i => i.activity_name === getActivityName());

      const handleClick = () => {
        console.log(`${original.ref_report_id} clicked!`);

        if (page && activity && userSessionId) {
          postAddUserActivity({
            user_session_id: userSessionId,
            ref_activity_id: activity.ref_activity_id,
            ref_page_id: page.ref_page_id,
            partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
          })
            .unwrap()
            .then(res => {});
        }
      };

      return original.has_report_access ? (
        <a
          color="links"
          className={'report-link'}
          target="_blank"
          href={original.report_url}
          onClick={handleClick}
          rel="noopener noreferrer"
        >
          {original.report_name}
        </a>
      ) : (
        <>
          <Tooltip label="Please request access to this report">
            <Text>{original.report_name}</Text>
          </Tooltip>
        </>
      );
    },
  },
  {
    Header: 'Description',
    accessor: 'report_desc',
    isSortable: false,
    styles: { minWidth: '350px', whiteSpace: 'initial', verticalAlign: 'top' },
  },
  {
    Header: 'Last Modified Date',
    isSortable: false,
    styles: { verticalAlign: 'top' },
    Cell: ({ row: { original } }: CellProps<ReportExploreModel>) => {
      return <>{convertUtcToLocal(original.row_modified_datetime_utc) || '-'}</>;
    },
  },
];

export default ResultData;
