import { Alert, AlertIcon, Box, Button, Heading, Image, Link, Text, VStack, useToast } from '@chakra-ui/react';
import { FC, ReactNode, useEffect, useState } from 'react';
import {
  useLazyGetAcknowledgementByRefUserIdQuery,
  usePostAcknowledgementMutation,
} from '../../app/services/partner/api/acknowledgement';
import { setUserAcknowledgement } from '../../app/slices/userSlice';
import { useAppDispatch, useAppSelector } from '../../app/state/hooks';
import LoreLogo from '../../assets/images/LoreLogo.png';
import PageLoading from '../../components/PageLoading';
import { setSelectedPartnerProduct } from '../../app/slices/userSlice';

type Props = {
  children: ReactNode;
};

const ConsentWrapper: FC<Props> = ({ children }) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);

  const { isUserAcknowledged, logonUser, userPartnerProducts } = useAppSelector(s => s.user);
  const partnerProductCount = userPartnerProducts?.length ?? 0;
  const dispatch = useAppDispatch();

  const [getAsync, getDetails] = useLazyGetAcknowledgementByRefUserIdQuery();
  const [postAsync, postDetails] = usePostAcknowledgementMutation();

  const fetch = ({ successToast = false }: { successToast?: boolean }) => {
    if (logonUser) {
      getAsync(logonUser.ref_user_id)
        .unwrap()
        .then(res => {
          setIsLoading(false);
          if (!!res) {
            if (successToast) {
              toast({ description: 'Welcome to Partner Portal!', status: 'success' });
            }
            dispatch(setUserAcknowledgement(res));
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  const handleContinue = () => {
    if (logonUser) {
      postAsync({ ref_user_id: logonUser.ref_user_id })
        .unwrap()
        .then(() => {
          fetch({ successToast: true });
        });
    }
  };

  useEffect(() => {
    if (userPartnerProducts && partnerProductCount == 1) {
      //set selected to that one partner product
      dispatch(setSelectedPartnerProduct(userPartnerProducts[0]));
    }
    if (!isUserAcknowledged && logonUser) fetch({});
    else setIsLoading(false);
  }, []);

  return isUserAcknowledged ? (
    <>{children}</>
  ) : isLoading && postDetails.isUninitialized ? (
    <PageLoading />
  ) : (
    // 120px is header + footer
    <Box h="calc(100vh - 120px)" display="flex" justifyContent="center" alignItems="center">
      <VStack bg="white" p="6" rounded="md" shadow="lg" w="521px" alignItems="center" gap="6" mx="4">
        <Heading as="h2" size="md" w="fit-content">
          Acknowledgement
        </Heading>

        {(getDetails.isError || postDetails.isError) && (
          <Alert status="warning" mb={3}>
            <AlertIcon />
            Your account was not setup. Please contact admin.
          </Alert>
        )}

        <Image borderRadius="md" height="416px" h="68px" w="auto" src={LoreLogo} alt="Lore Logo" objectFit="fill" />

        <Text>
          The Lore Health Partner Portal may contain Protected Health Information and other private or confidential
          information. By accessing the portal, you acknowledge and agree to maintain the privacy and confidentiality of
          all information in the portal in accordance with all applicable laws. Any data privacy or security issues
          should be immediately reported to{' '}
          <Link href="mailto:compliance@lore.co" isExternal color="blue.500">
            compliance@lore.co
          </Link>
          .{' '}
        </Text>

        <Button
          variant="solid"
          colorScheme="brand.main"
          mt="14"
          isDisabled={postDetails.isLoading || getDetails.isLoading || getDetails.isFetching}
          isLoading={postDetails.isLoading || getDetails.isLoading || getDetails.isFetching}
          onClick={handleContinue}
        >
          Continue
        </Button>
      </VStack>
    </Box>
  );
};

export default ConsentWrapper;
