import { Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { useAppSelector } from '../../app/state/hooks';
type Props = {};

const Footer = (props: Props) => {
  const { isUserAcknowledged, userPartnerProducts, selectedPartnerProduct, hasMultiplePartnerProducts } =
    useAppSelector(s => s.user);
  const bg = useColorModeValue('gray.50', 'gray.900');

  const isChoosePartnerProductDisplay = !selectedPartnerProduct && hasMultiplePartnerProducts;

  return (
    <>
      {!isChoosePartnerProductDisplay && (
        <Stack
          h="60px"
          w="100%"
          py={4}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justifyContent="center"
          alignItems="center"
          bg={isUserAcknowledged ? bg : undefined}
        >
          <Text fontSize="sm">&copy; {new Date().getFullYear()} Lore Health, LLC. All rights reserved.</Text>
        </Stack>
      )}
    </>
  );
};

export default Footer;
