import { partnerApi } from '.';
import { PutUserDemographicByUserParams } from './types';

const baseUrl = '/userdemographic';

const extendedApi = partnerApi.injectEndpoints({
  endpoints: builder => ({
    putUserDemographicByUser: builder.mutation<void, PutUserDemographicByUserParams>({
      query: ({ refUserId, body }) => ({
        url: baseUrl + `/${refUserId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['UserDemographic'],
    }),
  }),
});

export const { usePutUserDemographicByUserMutation } = extendedApi;
