import { partnerApi } from '.';
import { UserAcknowledgementModel } from './types';

const baseUrl = '/acknowledgement';

const extendedApi = partnerApi.injectEndpoints({
  endpoints: builder => ({
    postAcknowledgement: builder.mutation<void, { ref_user_id: number }>({
      query: params => ({
        url: baseUrl,
        method: 'POST',
        params,
      }),
    }),

    getAcknowledgementByRefUserId: builder.query<UserAcknowledgementModel, number>({
      query: ref_user_id => baseUrl + `/${ref_user_id}`,
    }),
  }),
});

export const { usePostAcknowledgementMutation, useLazyGetAcknowledgementByRefUserIdQuery } = extendedApi;
