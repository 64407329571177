import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/hooks';
import { Button } from '@chakra-ui/button';
import { UnlockIcon } from '@chakra-ui/icons';
import RequestDetails from './RequestDetails';
import { FC, useState, useEffect } from 'react';

type RequestAccessButtonProps = {
  openRequestAccess?: boolean;
};

const RequestAccessButton: FC<RequestAccessButtonProps> = ({ openRequestAccess }: RequestAccessButtonProps) => {
  const { onClose, onOpen } = useDisclosure();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  interface CustomMotionPreset {
    exit: { x: string; opacity: number };
    enter: { x: string; opacity: number };
  }

  // Custom motion preset for adjusting x position
  const customMotionPreset = {
    exit: { x: '-100vw', opacity: 0 },
    enter: { x: '0vw', opacity: 1 },
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setIsOpen(openRequestAccess ?? false);
  }, [openRequestAccess]);

  return (
    <>
      <Button size="sm" colorScheme="brand.main" gap="2" px="5" onClick={handleOpen}>
        <UnlockIcon fontSize="xx-small" />
        Request Access
      </Button>

      <Modal isOpen={isOpen} onClose={handleClose} size="xl" closeOnOverlayClick={true}>
        <ModalOverlay />
        <ModalContent position="absolute" top="20%" left="35%">
          <ModalBody p="4" display="flex" flexDir="column" gap="3">
            <RequestDetails handleClose={handleClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RequestAccessButton;
