import { partnerApi } from '.';
import { PaginatedApiResult, PaginatedQueryParams } from '../../types';
import {
  AddPartnerModel,
  PartnerDisplayModel,
  PartnerModel,
  UpdatePartnerModel,
  ToggleActivePartnerModel,
} from './types';

const baseUrl = '/partner';

const extendedApi = partnerApi.injectEndpoints({
  endpoints: build => ({
    updatePartner: build.mutation<void, UpdatePartnerModel>({
      query: ({ partner_id, ...body }) => ({
        url: baseUrl + `/${partner_id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Partner'],
    }),

    toggleActivePartner: build.mutation<void, ToggleActivePartnerModel>({
      query: ({ partner_id, is_active }) => ({
        url: baseUrl + `/active/${is_active}/id/${partner_id}`,
        method: 'PUT',
      }),
    }),

    getPartnerDisplayList: build.query<PartnerDisplayModel[], void>({
      query: () => ({
        url: baseUrl + '/active',
      }),
      providesTags: ['Partner'],
    }),
    getPartnerList: build.query<
      PaginatedApiResult<PartnerModel>,
      PaginatedQueryParams<PartnerModel> & { partner_product_id: number }
    >({
      query: params => ({
        url: baseUrl + `/list`,
        params,
      }),
      providesTags: ['Partner'],
    }),
    addPartnerModel: build.mutation<void, AddPartnerModel>({
      // do not include partner_id, only for data manipulition in UI
      query: ({ partner_id, ...body }) => ({
        url: baseUrl,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Partner', 'PartnerProduct'],
    }),
  }),
});

export const {
  useGetPartnerListQuery,
  useGetPartnerDisplayListQuery,
  useAddPartnerModelMutation,
  useLazyGetPartnerListQuery,
  useUpdatePartnerMutation,
  useToggleActivePartnerMutation,
} = extendedApi;
