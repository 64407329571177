import { GoX } from 'react-icons/go';
import { CloseButton } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/hooks';
import { useState } from 'react';
import { useAddUserRoleAccessRequestDetailMutation } from '../../../../app/services/partner/api/userRoleAccessRequest';
import {
  Modal,
  Box,
  Heading,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Button,
  useToast,
  Text,
  Tooltip,
  IconButton,
  Spinner,
  Textarea,
  Flex,
} from '@chakra-ui/react';

import { useAppSelector } from '../../../../app/state/hooks';
import { PARTNER_PAGE } from '../../../../app/constants/partnerPage';
import { PAGE_ACTIVITY } from '../../../../app/constants/pageActivity';
import { useAddUserActivityMutation } from '../../../../app/services/partner/api/userActivity';

interface DenyAccessRequestButtonProps {
  id: number;
  onDone: (num: number) => void;
}
const DenyAccessRequestButton = ({ id, onDone }: DenyAccessRequestButtonProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [post, postDetails] = useAddUserRoleAccessRequestDetailMutation();
  const [remarks, setRemarks] = useState<string>('');
  const toast = useToast();

  const { selectedPartnerProduct, activityList, pageList, userSessionId } = useAppSelector(s => s.user);
  const [postAddUserActivity] = useAddUserActivityMutation();

  const create_role_page = pageList.find(i => i.page_name === PARTNER_PAGE.CREATE_ROLE);
  const role_request_rejected = activityList.find(i => i.activity_name === PAGE_ACTIVITY.ROLE_REQUEST_REJECTED);

  const handleLogEvent = () => {
    if (create_role_page && role_request_rejected && userSessionId) {
      postAddUserActivity({
        user_session_id: userSessionId,
        ref_activity_id: role_request_rejected.ref_activity_id,
        ref_page_id: create_role_page.ref_page_id,
        partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      })
        .unwrap()
        .then(res => {});
    }
  };

  const handleOpen = () => {
    onOpen();
  };

  const handleCancel = () => {
    onClose();
  };

  const handleConfirm = (id: number, remarks: string) => {
    post({
      user_role_access_request_id: id,
      remarks: remarks,
      status_name: 'rejected',
    })
      .unwrap()
      .then(res => {
        toast({
          position: 'top-right',
          title: 'Rejected!',
          description: 'Access request rejected!',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        handleLogEvent();
      })
      .catch(error => {
        toast({
          position: 'top-right',
          title: 'Request Access Rejection Failed',
          description: error.data.error_message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => {
        handleCancel();
        onDone(1);
      });
  };

  return (
    <>
      <Tooltip label="Reject">
        <IconButton
          size={'xs'}
          onClick={handleOpen}
          style={{ backgroundColor: '#D13745', color: '#fff' }}
          icon={<GoX />}
          aria-label="Reject"
        />
      </Tooltip>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
        // closeOnOverlayClick={
        //   !addProviderDetails.isLoading && !getExternalDetails.isLoading && !getExternalDetails.isFetching
        // }
      >
        <ModalOverlay />
        <ModalContent position="absolute" top="30%" left="36%">
          <ModalBody p="4" display="flex" flexDir="column" gap="6">
            <Flex justifyContent={'space-between'}>
              <Box pt={'1.5'}>
                <Heading as="h3" size="sm">
                  Reason For Rejection
                </Heading>
              </Box>

              <Box>
                <IconButton
                  variant="link"
                  color="black"
                  size="xxs"
                  aria-label="close modal"
                  onClick={onClose}
                  icon={<CloseButton />}
                />
              </Box>
            </Flex>

            <Box sx={{ width: '100%', pt: '-5' }}>
              <Textarea value={remarks} onChange={e => setRemarks(e.target.value)} sx={{ width: '100%' }} autoFocus />
            </Box>

            <Box display="flex" justifyContent={'flex-end'}>
              <Box display="flex" gap={2}>
                <Button size="sm" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button size="sm" colorScheme="brand.main" gap="2" px="5" onClick={() => handleConfirm(id, remarks)}>
                  {postDetails.isLoading ? <Spinner size={'sm'} /> : 'Reject'}
                </Button>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DenyAccessRequestButton;
