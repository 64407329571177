import { AccountInfo, InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import { ExtendedClaimsType } from '../types';
import { msalConfig } from './authConfig';

export const getScopes = () => {
  //return ["user_impersonation"];
  return ['Partner.UIAccess'].map(scope => `${process.env.REACT_APP_PARTNER_APP_ID_URI}/${scope}`);
};

export const acquireAccessToken = async (): Promise<string> => {
  let instance = globalThis.msalInstance;
  if (!instance) {
    instance = new PublicClientApplication(msalConfig);
  }
  //const instance = new PublicClientApplication(msalConfig);
  const accounts = instance.getAllAccounts();

  console.log('acquireTokenSilent - acquiring token');
  let token = '';
  const scopes = getScopes();
  console.log('acquireTokenSilent - acquiring token', { scopes });
  try {
    const authResult = await instance.acquireTokenSilent({
      scopes,
      account: accounts[0],
    });
    token = authResult.accessToken;
  } catch (error) {
    console.log('acquireTokenSilent - acquiring token', { error, accounts });
    // in case if silent token acquisition fails, fallback to an interactive method
    if (error instanceof InteractionRequiredAuthError) {
      if (accounts.length) {
        try {
          console.log('acquireTokenPopup - acquiring token');
          const authResult = await instance.acquireTokenRedirect({
            scopes,
          });
          token = authResult.accessToken;
        } catch (error) {
          console.log('acquireTokenPopup - acquiring token', { error });
        }
      }
    }
  }
  console.log('acquireAccessToken - token', { token });
  return token;
};

export const getIdTokenClaims = (account: AccountInfo | null) => {
  return account?.idTokenClaims as ExtendedClaimsType;
};
