import {
  Box,
  Button,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  InputGroup,
  ModalHeader,
  ModalOverlay,
  Input,
  Spinner,
  Heading,
  Flex,
  VStack,
  useDisclosure,
  useToast,
  Table,
  TableContainer,
  Switch,
  Link,
  Thead,
  Text,
  Tbody,
  Td,
  Th,
  Tr,
} from '@chakra-ui/react';
import {
  PartnerProductModel,
  PartnerProductAssignedUserModel,
  SelectedUserModel,
} from '../../../../../app/services/partner/api/types';
import { useLazyGetPartnerProductAssignedUsersQuery } from '../../../../../app/services/partner/api/partnerProduct';
import { useToggleActiveUserPartnerProductMutation } from '../../../../../app/services/partner/api/userPartnerProduct';
import { useState } from 'react';
import { convertUtcToLocal } from '../../../../../app/helpers/dateHelper';
import UserCountContainer from '../../../../UserCountContainer';
import RolesDrawer from './RolesDrawer';

const defaultSelectedUser: SelectedUserModel = {
  user_complete_name: '',
  ref_user_id: 0,
  email: '',
  sso_object_id: '',
};

const EditUserRoles = ({ original }: { original: PartnerProductModel }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [assignedUsers, setAssignedUsers] = useState<PartnerProductAssignedUserModel[] | []>([]);
  const [isRolesDrawerOpen, setIsRolesDrawerOpen] = useState<boolean>(false);
  const [getUsers, getUsersDetails] = useLazyGetPartnerProductAssignedUsersQuery();
  const [selectedUser, setSelectedUser] = useState<SelectedUserModel | null>(defaultSelectedUser);
  const [put] = useToggleActiveUserPartnerProductMutation();
  const toast = useToast();

  const handleOnClick = () => {
    onOpen();

    getUsers(original.partner_product_id)
      .unwrap()
      .then(res => {
        setAssignedUsers(res);
      });
  };

  const handleOnClose = () => {};

  const handleChangeSelectedUser = (user: SelectedUserModel | null) => {
    if (user) {
      setIsRolesDrawerOpen(true);
    } else {
      setIsRolesDrawerOpen(true);
    }
    setSelectedUser(user);
  };

  const handleOnChange = (isChecked: boolean, id: string) => {
    const userPartnerProductId = parseInt(id);

    //update user partner product disabled_flag
    console.log(`User Partner Product ID: ${userPartnerProductId} - is checked?: ${isChecked}`);

    put({ user_partner_product_id: userPartnerProductId, disabled: isChecked })
      .unwrap()
      .then(res => {
        setAssignedUsers(prev =>
          prev.map(item =>
            item.user_partner_product_id === userPartnerProductId ? { ...item, disabled_flag: isChecked } : item,
          ),
        );
        toast({
          status: 'success',
          description: 'User Partner Product updated!',
        });
      });
  };

  const getFontColor = (isDisabled: boolean) => {
    return isDisabled ? '#adadad' : '##333333';
  };

  return (
    <>
      <Button
        size="sm"
        _hover={{ textDecoration: 'none' }}
        variant="link"
        fontWeight="normal"
        textAlign="left"
        onClick={handleOnClick}
      >
        <Box w="100%">Edit User Roles</Box>
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="3xl" closeOnOverlayClick={false}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Users</ModalHeader>

          <ModalCloseButton onClick={onClose} />

          <ModalBody>
            <VStack>
              <Box pt={'1.5'}>
                <Heading as="h3" size="sm">
                  {original.doing_business_as}
                </Heading>
              </Box>

              <Box py="1" borderY="1px" borderColor="blackAlpha.400">
                {getUsersDetails.isLoading || getUsersDetails.isFetching ? (
                  <Box>
                    <Flex justify="center" align="center" height="100%">
                      <Spinner size="lg" />
                    </Flex>
                  </Box>
                ) : (
                  <>
                    <TableContainer
                      sx={{
                        th: {
                          color: 'blackAlpha.700',
                          textTransform: 'initial',
                          fontSize: '16px',
                          letterSpacing: '-0.5px',
                        },
                        td: {
                          fontSize: '14px',
                          width: '200px',
                        },
                        height: '500px',
                        overflowY: 'scroll',
                      }}
                    >
                      <Table variant="unstyled">
                        <Thead>
                          <Tr>
                            <Th>User Name</Th>
                            <Th>Date Assigned</Th>
                            <Th>Assigned Roles</Th>
                            <Th>Edit Roles</Th>
                            <Th>Disable</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {!assignedUsers.length ? (
                            <Tr>
                              <Td colSpan={5} bg="red.100">
                                No Users
                              </Td>
                            </Tr>
                          ) : (
                            assignedUsers.map(
                              ({
                                user_complete_name,
                                assigned_datetime_utc,
                                number_of_roles,
                                user_partner_product_id,
                                sso_object_id,
                                email,
                                disabled_flag,
                                ref_user_id,
                              }) => (
                                <Tr key={user_partner_product_id}>
                                  <Td>
                                    <Text color={getFontColor(disabled_flag)}>{user_complete_name ?? '-'}</Text>
                                  </Td>
                                  <Td>
                                    <Text color={getFontColor(disabled_flag)}>
                                      {convertUtcToLocal(assigned_datetime_utc, 'YYYY-MM-D')}
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Flex justify="center" align="center" height="100%">
                                      <UserCountContainer count={number_of_roles} isDisabled={disabled_flag} />
                                    </Flex>
                                  </Td>
                                  <Td>
                                    <Link
                                      pointerEvents={disabled_flag ? 'none' : 'auto'}
                                      color={disabled_flag ? 'gray.500' : '#0c7aff'}
                                      cursor={disabled_flag ? 'not-allowed' : 'pointer'}
                                      onClick={() => {
                                        setSelectedUser({
                                          ref_user_id: ref_user_id,
                                          sso_object_id: sso_object_id,
                                          email: email,
                                          user_complete_name: user_complete_name,
                                        });
                                        setIsRolesDrawerOpen(true);
                                      }}
                                    >
                                      View
                                    </Link>
                                  </Td>
                                  <Td>
                                    <Switch
                                      value={user_partner_product_id}
                                      onChange={e => handleOnChange(e.target.checked, e.target.value)}
                                      isChecked={disabled_flag}
                                    />
                                  </Td>
                                </Tr>
                              ),
                            )
                          )}
                        </Tbody>
                      </Table>
                    </TableContainer>
                    <RolesDrawer
                      selectedUser={selectedUser}
                      isRolesDrawerOpen={isRolesDrawerOpen}
                      setIsRolesDrawerOpen={setIsRolesDrawerOpen}
                    />
                  </>
                )}
              </Box>

              <Box sx={{ display: 'flex', alignContent: 'end', paddingBottom: '10px', paddingTop: '10px' }}>
                <Button onClick={onClose} ml="auto" color="#fff" backgroundColor="#3794aa">
                  Close
                </Button>
              </Box>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditUserRoles;
