import {
  Link,
  Modal,
  ModalOverlay,
  Heading,
  ModalContent,
  ModalBody,
  Box,
  IconButton,
  CloseButton,
  Flex,
  VStack,
  Input,
  Text,
  Spinner,
  Button,
  HStack,
  useToast,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useEffect, useState } from 'react';
import { PartnerModel, UpdatePartnerModel } from '../../../app/services/partner/api/types';
import { useUpdatePartnerMutation } from '../../../app/services/partner/api/partner';
import { usaStates } from '../../../app/constants/usaStates';
import { selectStyles, countries } from '../../Dashboard/Admin/helper';
import CustomDatePicker from '../../../components/CustomDatePicker';
import { PropsConfigs } from '../../../components/CustomDatePicker/utils/commonTypes';
import dayjs from 'dayjs';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import HorizontalLine from '../../HorizontalLine';
import './PartnerDetailsModal.css';

const propsConfig: PropsConfigs = {
  dateNavBtnProps: {
    colorScheme: 'brand.main.default',
    variant: 'outline',
  },
  dayOfMonthBtnProps: {
    defaultBtnProps: {
      _hover: {
        background: 'brand.main.default',
        color: 'white',
      },
    },
    selectedBtnProps: {
      background: 'brand.main.default',
      color: 'white',
    },
    todayBtnProps: {
      background: 'gray.400',
    },
  },
};

const defaultPartnerForUpdate: UpdatePartnerModel = {
  partner_id: 0,
  product_tier_name: '',
  partner_address_1: '',
  partner_address_2: '',
  partner_city: '',
  partner_country: '',
  partner_name: '',
  partner_state: '',
  partner_tin: '',
  partner_zip_code: '',
  doing_business_as: '',
  contract_start_dt: null,
  contract_end_dt: null,
  contract_renewal_dt: null,
};

const defaultChangeMessages = {
  partner_address_1: '',
  partner_address_2: '',
  partner_city: '',
  partner_country: '',
  partner_name: '',
  partner_state: '',
  partner_tin: '',
  partner_zip_code: '',
  doing_business_as: '',
  contract_start_dt: '',
  contract_end_dt: '',
  contract_renewal_dt: '',
};

type PartnerDetailsModalProps = {
  openModal: boolean;
  setModal: (open: boolean) => void;
  partner: PartnerModel;
  source: string;
};

const PartnerDetailsModal = ({ partner, openModal, source, setModal }: PartnerDetailsModalProps) => {
  //const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(source === 'button');
  const [forUpdatePartner, setForUpdatePartner] = useState<UpdatePartnerModel>(defaultPartnerForUpdate);
  const [update, updateDetails] = useUpdatePartnerMutation();
  const [changeMessages, setChangeMessages] = useState(defaultChangeMessages);
  const toast = useToast();

  const maxDate = dayjs().add(5, 'year').toDate();
  const minDate = dayjs(new Date()).add(-1, 'year').startOf('day').toDate();

  useEffect(() => {
    //setIsModalOpen(openModal);

    if (openModal) {
      setForUpdatePartner(prev => ({
        ...prev,
        partner_id: partner.partner_id,
        partner_address_1: partner.partner_address_1,
        partner_address_2: partner.partner_address_2,
        partner_city: partner.partner_city,
        partner_country: partner.partner_country,
        partner_name: partner.partner_name,
        partner_state: partner.partner_state,
        partner_tin: partner.partner_tin,
        partner_zip_code: partner.partner_zip_code,
        doing_business_as: partner.doing_business_as,
        contract_start_dt: partner.contract_start_dt,
        contract_end_dt: partner.contract_end_dt,
        contract_renewal_dt: partner.contract_renewal_dt,
      }));
    }
  }, [openModal]);

  const handleCancel = () => {
    // if (isEditMode) {
    //   setIsEditMode(false);
    // } else {
    //   handleExitModal();
    // }
    setModal(false);
    handleExitModal();
  };

  const handleExitModal = () => {
    setModal(false);
    setIsEditMode(false);

    setIsEditMode(source === 'button');
    setForUpdatePartner(defaultPartnerForUpdate);
    setChangeMessages(defaultChangeMessages);
  };

  const noChanges = () => {
    let newChanges = { ...changeMessages };

    if ((partner.partner_address_1 || '') === (forUpdatePartner.partner_address_1 || '')) {
      newChanges.partner_address_1 = '';
    } else {
      newChanges.partner_address_1 = 'partner_address_1 updated';
    }

    if ((partner.partner_address_2 || '') === (forUpdatePartner.partner_address_2 || '')) {
      newChanges.partner_address_2 = '';
    } else {
      newChanges.partner_address_2 = 'partner_address_2 updated';
    }

    if ((partner.partner_city || '') === (forUpdatePartner.partner_city || '')) {
      newChanges.partner_city = '';
    } else {
      newChanges.partner_city = 'partner_city updated';
    }

    if ((partner.partner_country || '') === (forUpdatePartner.partner_country || '')) {
      newChanges.partner_country = '';
    } else {
      newChanges.partner_country = 'partner_country updated';
    }

    if ((partner.partner_name || '') === (forUpdatePartner.partner_name || '')) {
      newChanges.partner_name = '';
    } else {
      newChanges.partner_name = 'partner_name updated';
    }

    if ((partner.partner_tin || '') === (forUpdatePartner.partner_tin || '')) {
      newChanges.partner_tin = '';
    } else {
      newChanges.partner_tin = 'partner_tin updated';
    }

    if ((partner.partner_zip_code || '') === (forUpdatePartner.partner_zip_code || '')) {
      newChanges.partner_zip_code = '';
    } else {
      newChanges.partner_zip_code = 'partner_zip_code updated';
    }

    if ((partner.doing_business_as || '') === (forUpdatePartner.doing_business_as || '')) {
      newChanges.doing_business_as = '';
    } else {
      newChanges.doing_business_as = 'doing_business_as updated';
    }

    if ((partner.contract_start_dt || '') === (forUpdatePartner.contract_start_dt || '')) {
      newChanges.contract_start_dt = '';
    } else {
      newChanges.contract_start_dt = 'contract_start_dt updated';
    }

    if ((partner.contract_end_dt || '') === (forUpdatePartner.contract_end_dt || '')) {
      newChanges.contract_end_dt = '';
    } else {
      newChanges.contract_end_dt = 'contract_end_dt updated';
    }

    if ((partner.contract_renewal_dt || '') === (forUpdatePartner.contract_renewal_dt || '')) {
      newChanges.contract_renewal_dt = '';
    } else {
      newChanges.contract_renewal_dt = 'contract_renewal_dt updated';
    }

    console.log(`COMPARE - NEW CHANGES: ${JSON.stringify(newChanges)}`);

    const are_changes = Object.values(newChanges).every(change => change === '');

    console.log(`COMPARE - NO CHANGES: ${are_changes}`);

    setChangeMessages(newChanges);
    return Object.values(newChanges).every(change => change === '');
  };

  const handleOnStringPropertyChange = (prop_value: string, prop_name: string) => {
    console.log(` ForUPDATE: key: ${prop_name} - value: ${prop_value}`);
    setForUpdatePartner(prevState => ({ ...prevState, [prop_name]: prop_value }));
  };

  const handleSaveClick = () => {
    setChangeMessages(defaultChangeMessages);
    // console.log(`COMPARE - for update: ${JSON.stringify(forUpdatePartnerProduct)}`);
    // console.log(`COMPARE - for view only: ${JSON.stringify(partnerProductView)}`);
    // console.log(`COMPARE - for view only TAGS: ${JSON.stringify(partnerProductTagsView)}`);
    if (!noChanges()) {
      console.log(`FOR UPDATE FINAL: ${JSON.stringify(forUpdatePartner)}`);
      update({
        partner_id: partner.partner_id,
        product_tier_name: forUpdatePartner.product_tier_name,
        partner_address_1: forUpdatePartner.partner_address_1,
        partner_address_2: forUpdatePartner.partner_address_2,
        partner_city: forUpdatePartner.partner_city,
        partner_country: forUpdatePartner.partner_country,
        partner_name: forUpdatePartner.partner_name,
        partner_state: forUpdatePartner.partner_state,
        partner_tin: forUpdatePartner.partner_tin,
        partner_zip_code: forUpdatePartner.partner_zip_code,
        doing_business_as: forUpdatePartner.doing_business_as,
        contract_start_dt: forUpdatePartner.contract_start_dt,
        contract_end_dt: forUpdatePartner.contract_end_dt,
        contract_renewal_dt: forUpdatePartner.contract_renewal_dt,
      })
        .unwrap()
        .then(res => {
          toast({ description: `${partner.partner_name} successfully updated!`, status: 'success' });
          handleExitModal();
        })
        .catch(error => toast({ description: 'Update failed', status: 'error' }));
    } else {
      toast({ description: `No changes detected`, status: 'info' });
    }
  };

  return (
    <>
      <Modal isOpen={openModal} onClose={() => setModal(false)} size="4xl" closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody p="4" display="flex" flexDir="column" gap="6">
            <Flex justifyContent={'space-between'}>
              <Box pt={'1.5'}>
                <Heading as="h3" size="sm">
                  {partner.partner_name}
                </Heading>
              </Box>

              <Box>
                <IconButton
                  variant="link"
                  color="black"
                  size="xxs"
                  aria-label="close modal"
                  onClick={handleExitModal}
                  icon={<CloseButton />}
                />
              </Box>
            </Flex>

            <VStack>
              <Box display="flex" justifyContent={'space-evenly'} className="line-box">
                <Box sx={{ width: '50%' }} mr="2">
                  <Box fontSize={'smaller'} mb="-0.2">
                    Partner TIN
                  </Box>
                  {isEditMode ? (
                    <>
                      <Input
                        id="partner-tin"
                        name="partner_tin"
                        size="sm"
                        value={forUpdatePartner.partner_tin || ''}
                        onChange={e => {
                          handleOnStringPropertyChange(e.target.value, e.target.name);
                        }}
                      ></Input>
                    </>
                  ) : (
                    <Text className="value-text">{partner?.partner_tin || '-'}</Text>
                  )}
                </Box>
                <Box sx={{ width: '50%' }} mr="2">
                  <Box fontSize={'smaller'} mb="-0.2">
                    Doing Business As
                  </Box>
                  {isEditMode ? (
                    <>
                      <Input
                        id="doing-business-as"
                        name="doing_business_as"
                        size="sm"
                        value={forUpdatePartner.doing_business_as || ''}
                        onChange={e => {
                          handleOnStringPropertyChange(e.target.value, e.target.name);
                        }}
                      ></Input>
                    </>
                  ) : (
                    <Text className="value-text">{partner?.doing_business_as || '-'}</Text>
                  )}
                </Box>
              </Box>

              <Box display="flex" justifyContent={'space-evenly'} className="line-box">
                <Box sx={{ width: '50%' }} mr="2">
                  <Box fontSize={'smaller'} mb="-0.2">
                    Address 1
                  </Box>
                  {isEditMode ? (
                    <>
                      <Input
                        id="partner-address-1"
                        name="partner_address_1"
                        size="sm"
                        value={forUpdatePartner.partner_address_1 || ''}
                        onChange={e => {
                          handleOnStringPropertyChange(e.target.value, e.target.name);
                        }}
                      ></Input>
                    </>
                  ) : (
                    <Text className="value-text">{partner?.partner_address_1 || '-'}</Text>
                  )}
                </Box>
                <Box sx={{ width: '50%' }} mr="2">
                  <Box fontSize={'smaller'} mb="-0.2">
                    Address 2
                  </Box>
                  {isEditMode ? (
                    <>
                      <Input
                        id="partner-address-2"
                        name="partner_address_2"
                        size="sm"
                        value={forUpdatePartner.partner_address_2 || ''}
                        onChange={e => {
                          handleOnStringPropertyChange(e.target.value, e.target.name);
                        }}
                      ></Input>
                    </>
                  ) : (
                    <Text className="value-text">{partner?.partner_address_2 || '-'}</Text>
                  )}
                </Box>
              </Box>

              <Box display="flex" justifyContent={'space-evenly'} className="line-box">
                <Box sx={{ width: '50%' }} mr="2">
                  <Box fontSize={'smaller'} mb="-0.2">
                    City
                  </Box>
                  {isEditMode ? (
                    <>
                      <Input
                        id="city"
                        name="partner_city"
                        value={forUpdatePartner.partner_city || ''}
                        size="sm"
                        onChange={e => {
                          handleOnStringPropertyChange(e.target.value, e.target.name);
                        }}
                      ></Input>
                    </>
                  ) : (
                    <Text className="value-text">{partner?.partner_city || '-'}</Text>
                  )}
                </Box>
                <Box sx={{ width: '50%' }} mr="2">
                  <Box fontSize={'smaller'} mb="-0.2">
                    State
                  </Box>
                  {isEditMode ? (
                    <>
                      <Select
                        size="sm"
                        id="partner_state"
                        name="partner_state"
                        placeholder=""
                        useBasicStyles
                        value={(() => {
                          const val = usaStates.find(m => m.abbreviation === forUpdatePartner.partner_state);
                          return val
                            ? {
                                label: val.name,
                                value: val.abbreviation,
                              }
                            : undefined;
                        })()}
                        options={[
                          ...usaStates.map(m => {
                            return {
                              label: m.name,
                              value: m.abbreviation,
                            };
                          }),
                        ]}
                        onChange={e => {
                          e && handleOnStringPropertyChange(e.value, 'partner_state');
                        }}
                        chakraStyles={selectStyles<string>()}
                      />
                    </>
                  ) : (
                    <Text className="value-text">{partner?.partner_state || '-'}</Text>
                  )}
                </Box>
              </Box>

              <Box>
                <Box sx={{ width: '100%', pr: '7px' }} mr="2">
                  <Box fontSize={'smaller'} mb="-0.2">
                    Country
                  </Box>
                  {isEditMode ? (
                    <>
                      <Select
                        size="sm"
                        id="partner_country"
                        name="partner_country"
                        placeholder=""
                        useBasicStyles
                        value={(() => {
                          return forUpdatePartner.partner_country
                            ? {
                                label: forUpdatePartner.partner_country,
                                value: forUpdatePartner.partner_country,
                              }
                            : undefined;
                        })()}
                        options={[
                          ...countries.map(m => {
                            return {
                              label: m,
                              value: m,
                            };
                          }),
                        ]}
                        onChange={e => {
                          e && handleOnStringPropertyChange(e.value, 'partner_country');
                        }}
                        chakraStyles={selectStyles<string>()}
                      />
                    </>
                  ) : (
                    <Text className="value-text">{partner?.partner_address_2 || '-'}</Text>
                  )}
                </Box>
              </Box>

              <Box display="flex" justifyContent={'space-evenly'} className="line-box">
                <Box sx={{ width: '50%' }} mr="2">
                  <Box fontSize={'smaller'} mb="-0.2">
                    Postal Code
                  </Box>
                  {isEditMode ? (
                    <>
                      <Input
                        id="zip-code"
                        name="partner_zip_code"
                        size="sm"
                        value={forUpdatePartner.partner_zip_code || ''}
                        onChange={e => {
                          handleOnStringPropertyChange(e.target.value, e.target.name);
                        }}
                      ></Input>
                    </>
                  ) : (
                    <Text className="value-text">{partner?.partner_city || '-'}</Text>
                  )}
                </Box>
                <Box sx={{ width: '50%' }} mr="2">
                  <Box fontSize={'smaller'} mb="-0.2">
                    Contract Renewal Date
                  </Box>
                  {isEditMode ? (
                    <>
                      <CustomDatePicker
                        id="contract-renewal-date"
                        name="contract_renewal_dt"
                        onDateChange={date => {
                          if (dayjs(date).format('YYYY-MM-DD')) {
                            handleOnStringPropertyChange(dayjs(date).format('YYYY-MM-DD'), 'contract_renewal_dt');
                            // const maxDate = dayjs().add(1, 'year').toDate();
                            // const minDate = dayjs(new Date()).add(1, 'day').startOf('day').toDate();
                          }
                        }}
                        minDate={minDate}
                        maxDate={
                          dayjs(forUpdatePartner?.contract_renewal_dt).isValid()
                            ? dayjs(forUpdatePartner?.contract_renewal_dt)
                                .add(-1, 'day')
                                .toDate()
                            : maxDate
                        }
                        date={
                          forUpdatePartner?.contract_renewal_dt
                            ? new Date(forUpdatePartner.contract_renewal_dt)
                            : undefined
                        }
                        propsConfigs={{
                          ...propsConfig,
                          inputProps: {
                            placeholder: '',
                            size: 'sm',
                            value: dayjs(forUpdatePartner?.contract_renewal_dt).isValid()
                              ? dayjs(forUpdatePartner?.contract_renewal_dt).format('YYYY-MM-DD')
                              : '',
                          },
                        }}
                      />
                    </>
                  ) : (
                    <Text className="value-text">
                      {convertUtcToLocal(forUpdatePartner?.contract_renewal_dt, 'YYYY-MM-DD') || '-'}
                    </Text>
                  )}
                </Box>
              </Box>

              <Box display="flex" justifyContent={'space-evenly'} className="line-box">
                <Box sx={{ width: '50%' }} mr="2">
                  <Box fontSize={'smaller'} mb="-0.2">
                    Contract Start Date
                  </Box>
                  {isEditMode ? (
                    <>
                      <CustomDatePicker
                        id="contract-start-date"
                        name="contract_start_dt"
                        onDateChange={date => {
                          if (dayjs(date).format('YYYY-MM-DD')) {
                            handleOnStringPropertyChange(dayjs(date).format('YYYY-MM-DD'), 'contract_start_dt');
                            // const maxDate = dayjs().add(1, 'year').toDate();
                            // const minDate = dayjs(new Date()).add(1, 'day').startOf('day').toDate();
                          }
                        }}
                        minDate={minDate}
                        maxDate={
                          dayjs(forUpdatePartner?.contract_start_dt).isValid()
                            ? dayjs(forUpdatePartner?.contract_start_dt)
                                .add(-1, 'day')
                                .toDate()
                            : maxDate
                        }
                        date={
                          forUpdatePartner?.contract_start_dt ? new Date(forUpdatePartner.contract_start_dt) : undefined
                        }
                        propsConfigs={{
                          ...propsConfig,
                          inputProps: {
                            placeholder: '',
                            size: 'sm',
                            value: dayjs(forUpdatePartner?.contract_start_dt).isValid()
                              ? dayjs(forUpdatePartner?.contract_start_dt).format('YYYY-MM-DD')
                              : '',
                          },
                        }}
                      />
                    </>
                  ) : (
                    <Text className="value-text">
                      {convertUtcToLocal(forUpdatePartner?.contract_start_dt, 'YYYY-MM-DD') || '-'}
                    </Text>
                  )}
                </Box>
                <Box sx={{ width: '50%' }} mr="2">
                  <Box fontSize={'smaller'} mb="-0.2">
                    Contract End Date
                  </Box>
                  {isEditMode ? (
                    <>
                      <CustomDatePicker
                        id="contract-end-date"
                        name="contract_end_dt"
                        onDateChange={date => {
                          if (dayjs(date).format('YYYY-MM-DD')) {
                            handleOnStringPropertyChange(dayjs(date).format('YYYY-MM-DD'), 'contract_end_dt');
                            // const maxDate = dayjs().add(1, 'year').toDate();
                            // const minDate = dayjs(new Date()).add(1, 'day').startOf('day').toDate();
                          }
                        }}
                        minDate={minDate}
                        maxDate={
                          dayjs(forUpdatePartner?.contract_end_dt).isValid()
                            ? dayjs(forUpdatePartner?.contract_end_dt)
                                .add(-1, 'day')
                                .toDate()
                            : maxDate
                        }
                        date={
                          forUpdatePartner?.contract_end_dt ? new Date(forUpdatePartner.contract_end_dt) : undefined
                        }
                        propsConfigs={{
                          ...propsConfig,
                          inputProps: {
                            placeholder: '',
                            size: 'sm',
                            value: dayjs(forUpdatePartner?.contract_end_dt).isValid()
                              ? dayjs(forUpdatePartner?.contract_end_dt).format('YYYY-MM-DD')
                              : '',
                          },
                        }}
                      />
                    </>
                  ) : (
                    <Text className="value-text">
                      {convertUtcToLocal(forUpdatePartner?.contract_end_dt, 'YYYY-MM-DD') || '-'}
                    </Text>
                  )}
                </Box>
              </Box>

              <HorizontalLine marginTop={'20px'} />
              <Box display="flex" justifyContent={'flex-end'}>
                <Box display="flex" gap={2}>
                  <Button color="#3182ce" size="sm" onClick={handleCancel}>
                    Cancel
                  </Button>

                  {isEditMode ? (
                    <Button size="sm" onClick={handleSaveClick} colorScheme="brand.main" gap="2" px="5" type="submit">
                      {updateDetails.isLoading ? <Spinner size={'sm'} /> : 'Save'}
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      onClick={() => setIsEditMode(true)}
                      colorScheme="brand.main"
                      gap="2"
                      px="5"
                      type="submit"
                    >
                      Edit
                    </Button>
                  )}
                </Box>
              </Box>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PartnerDetailsModal;
