import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { Select, chakraComponents } from 'chakra-react-select';
import { useFormik } from 'formik';
import { useMemo } from 'react';
import * as Yup from 'yup';
import { defaultErrorMessage } from '../../app/constants';
import { FirstNameSchema, LastNameSchema } from '../../app/constants/validations';
import { validateEmail } from '../../app/helpers/stringHelper';
import { usePostEligibleParticipantTestMutation } from '../../app/services/partner/api/eligibleParticipant';
import { useGetAllPartnerProductsListQuery } from '../../app/services/partner/api/partnerProduct';
import { AddTestEligibleParticipantModel } from '../../app/services/partner/api/types';
import { appColors } from '../../app/theme';

const TestEligibleParticipantFormSchema = Yup.object().shape({
  first_name: FirstNameSchema,
  last_name: LastNameSchema,
  email: Yup.string()
    .label('Email')
    .max(100)
    .required()
    .test((str, { createError }) => {
      if (!str) return true;
      return validateEmail(str)
        ? true
        : createError({
            message: 'Email must be a valid email',
            path: 'email',
          });
    }),
  partner_product_id: Yup.number().required().moreThan(0, 'Partner Product is a required field'),
});

const TestEligibleParticipantAdd = () => {
  const toast = useToast();

  const getDetails = useGetAllPartnerProductsListQuery();
  const [postAsync, postDetails] = usePostEligibleParticipantTestMutation();

  const { handleSubmit, errors, touched, handleChange, values, setValues, resetForm } =
    useFormik<AddTestEligibleParticipantModel>({
      enableReinitialize: true,
      validationSchema: TestEligibleParticipantFormSchema,
      initialValues: {
        first_name: '',
        last_name: '',
        email: '',
        partner_product_id: 0,
      },
      onSubmit: (values, form) => {
        postAsync(values)
          .unwrap()
          .then(res => {
            form.resetForm();
            toast({
              description: 'Successfully added eligible participant with ID: ' + res?.result?.eligible_participant_id,
              status: 'success',
            });
          })
          .catch(e => {
            const message =
              e?.status === 500 &&
              e?.data?.error_message ===
                `The required column 'eligible_participant_id' was not present in the results of a 'FromSql' operation.` ? (
                <Text>
                  Account <Text as="strong">{values.email}</Text> already exists.
                </Text>
              ) : (
                defaultErrorMessage
              );
            toast({ description: message, status: 'error' });
          });
      },
    });

  const clearModal = () => {
    resetForm();
  };

  const sortedProductPartners = useMemo(() => {
    const list = [...(getDetails.data ?? [])];
    return list.sort(
      (a, b) =>
        (a.product_type_name ?? '').localeCompare(b.product_type_name ?? '') ||
        (a.doing_business_as ?? '').localeCompare(b.doing_business_as ?? ''),
    );
  }, [getDetails.data]);

  return getDetails.isLoading || getDetails.isFetching ? (
    <>Loading...</>
  ) : (
    <VStack>
      <Alert
        bg={appColors.hightLightColor}
        borderRadius={5}
        my={2}
        borderLeftWidth={4}
        borderLeftColor={appColors.brand.main.default}
      >
        <VStack align="stretch">
          <Text>
            To see Rewards you will need to both select a partner product and set Enrollment to "Complete" in the{' '}
            <Link href="https://backoffice.lorehealthcare.io/users" color={appColors.links} isExternal>
              back office
            </Link>
            .
          </Text>
        </VStack>
      </Alert>

      <form onSubmit={handleSubmit}>
        <VStack spacing="5" pt={5}>
          <FormControl isInvalid={!!errors.first_name && touched.first_name} display="flex">
            <FormLabel minW="150px" htmlFor="first_name">
              First Name
            </FormLabel>
            <Box w="full">
              <Input
                size="sm"
                id="first_name"
                name="first_name"
                onChange={handleChange}
                onBlur={e =>
                  setValues({
                    ...values,
                    first_name: e.target.value.trim(),
                  })
                }
                value={values.first_name ?? ''}
                maxLength={100}
              />
              <FormErrorMessage>{errors.first_name}</FormErrorMessage>
            </Box>
          </FormControl>

          <FormControl isInvalid={!!errors.last_name && touched.last_name} display="flex">
            <FormLabel minW="150px" htmlFor="last_name">
              Last Name
            </FormLabel>
            <Box w="full">
              <Input
                size="sm"
                id="last_name"
                name="last_name"
                onChange={handleChange}
                onBlur={e =>
                  setValues({
                    ...values,
                    last_name: e.target.value.trim(),
                  })
                }
                value={values.last_name ?? ''}
                maxLength={100}
              />
              <FormErrorMessage>{errors.last_name}</FormErrorMessage>
            </Box>
          </FormControl>

          <FormControl isInvalid={!!errors.email && touched.email} display="flex">
            <FormLabel minW="150px" htmlFor="email">
              Email
            </FormLabel>
            <Box w="full">
              <Input
                size="sm"
                id="email"
                name="email"
                onChange={handleChange}
                onBlur={e =>
                  setValues({
                    ...values,
                    email: e.target.value.trim(),
                  })
                }
                value={values.email ?? ''}
                maxLength={100}
              />
              <FormErrorMessage>{errors.email}</FormErrorMessage>
            </Box>
          </FormControl>

          {getDetails.data && (
            <FormControl isInvalid={!!errors.partner_product_id && touched.partner_product_id} display="flex">
              <FormLabel minW="150px" htmlFor="partner_product_id">
                Partner Product
              </FormLabel>
              <Box w="full">
                <Select
                  id="partner_product_id"
                  name="partner_product_id"
                  useBasicStyles
                  size="sm"
                  placeholder="Select partner product..."
                  value={(() => {
                    const val = getDetails.data.find(f => f.partner_product_id === values.partner_product_id);
                    return val ? { label: val.product_type_name, value: val.partner_product_id, data: val } : null;
                  })()}
                  options={sortedProductPartners.map(m => ({
                    label: m.product_type_name,
                    value: m.partner_product_id,
                    data: m,
                  }))}
                  onChange={e => {
                    setValues({
                      ...values,
                      partner_product_id: parseInt(e?.value?.toString() || '0'),
                    });
                  }}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: provided => ({
                      ...provided,
                      zIndex: 1401,
                    }),
                  }}
                  maxMenuHeight={300}
                  components={{
                    Option: ({ children, ...props }) => (
                      <chakraComponents.Option {...props}>
                        <Text>
                          {children}{' '}
                          <Text as="span" color="gray.400">
                            ({props.data.data.doing_business_as})
                          </Text>
                        </Text>
                      </chakraComponents.Option>
                    ),
                    SingleValue: props => {
                      return (
                        <chakraComponents.SingleValue {...props}>
                          <Text>
                            {props.data.label}{' '}
                            <Text as="span" color="gray.400">
                              ({props.data.data.doing_business_as})
                            </Text>
                          </Text>
                        </chakraComponents.SingleValue>
                      );
                    },
                  }}
                />
                <FormErrorMessage>{errors.partner_product_id}</FormErrorMessage>
              </Box>
            </FormControl>
          )}

          <ButtonGroup>
            <Button isDisabled={postDetails.isLoading} variant="outline" onClick={() => clearModal()} ml="auto">
              Cancel
            </Button>
            <Button isLoading={postDetails.isLoading} variant="solid" colorScheme="brand.main" type="submit">
              Submit
            </Button>
          </ButtonGroup>
        </VStack>
      </form>
    </VStack>
  );
};

export default TestEligibleParticipantAdd;
