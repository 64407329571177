import { Box, Heading, VStack, Flex, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { AppSize } from '../../../../app/constants';
import { useGetPartnerProductPaginatedListQuery } from '../../../../app/services/partner/api/partnerProduct';
import { PartnerProductModel } from '../../../../app/services/partner/api/types';
import { SortType } from '../../../../app/services/types';
import { useAppSelector } from '../../../../app/state/hooks';
import { CustomTableHeader } from '../../../../app/types/appType';
import CustomTable from '../../../../components/CustomTable';
import DashboardAdminPartnerProductsAfterSearch from './AfterSearch';
import AppAuth from '../../../../features/AppAuth';
import { AppAccess } from '../../../../app/constants/appAccesses';
import PartnerProductDetailsButton from '../../../PartnerProducts/PartnerProductDetailsModal/PartnerProductDetailsButton';
import ViewAllButton from '../../ViewAllButton';

const InitialSortBy: SortType<PartnerProductModel> = {
  id: 'partner_name',
  desc: false,
};

const Header: CustomTableHeader<PartnerProductModel> = [
  {
    Header: 'Partner Product/Doing Business as',
    accessor: 'doing_business_as',
    isSortable: false,
    Cell: ({ row: { original } }: CellProps<PartnerProductModel>) => {
      const { isLoreHealthAdmin } = useAppSelector(s => s.user);
      return (
        <Flex
          width={'200px'}
          minH={original.partner_product_tags.split('|').length > 1 ? '50px' : ''}
          direction={'column'}
        >
          {isLoreHealthAdmin ? (
            <PartnerProductDetailsButton name={original.doing_business_as} id={original.partner_product_id} />
          ) : (
            <Text>{original.doing_business_as}</Text>
          )}
        </Flex>
      );
    },
  },
  {
    Header: 'Partner Name',
    accessor: 'partner_name',
    Cell: ({ row: { original } }: CellProps<PartnerProductModel>) => {
      return (
        <Flex minH={original.partner_product_tags.split('|').length > 1 ? '50px' : ''} direction={'column'}>
          <Text>{original.partner_name}</Text>
        </Flex>
      );
    },
  },
  {
    Header: 'Partner TIN',
    accessor: 'partner_tin',
    isSortable: false,
    Cell: ({ row: { original } }: CellProps<PartnerProductModel>) => {
      return (
        <Flex minH={original.partner_product_tags.split('|').length > 1 ? '50px' : ''} direction={'column'}>
          <Text>{original.partner_tin}</Text>
        </Flex>
      );
    },
  },
  {
    Header: 'Product Tag',
    accessor: 'partner_product_tags',
    isSortable: false,
    Cell: ({ row: { original } }: CellProps<PartnerProductModel>) => {
      return (
        <>
          {(original.partner_product_tags ?? []).split('|').map((i, x) =>
            original.partner_product_tags.split('|').length > 1 ? (
              <div key={x}>
                {i}
                <br />
                <br />
              </div>
            ) : (
              i
            ),
          )}
        </>
      );
    },
  },

  {
    Header: 'Product Type',
    accessor: 'product_type_name',
    Cell: ({ row: { original } }: CellProps<PartnerProductModel>) => {
      return (
        <Flex minH={original.partner_product_tags.split('|').length > 1 ? '50px' : ''} direction={'column'}>
          <Text>{original.product_type_name}</Text>
        </Flex>
      );
    },
  },
];

const DashboardAdminPartnerProducts = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState(InitialSortBy);

  const { isSideNavOpen } = useAppSelector(s => s.app);

  const { isLoreHealthAdmin, selectedPartnerProduct } = useAppSelector(s => s.user);

  const partnerProductId = selectedPartnerProduct?.partner_product_id || 0;

  const { data, isLoading, isFetching } = useGetPartnerProductPaginatedListQuery({
    page_number: pageNumber,
    page_size: pageSize,
    search_string: searchParams.get('sproduct') ?? '',
    sort_column: sortBy.id,
    sort_order: sortBy.desc ? 'desc' : 'asc',
    partner_product_id: isLoreHealthAdmin ? 0 : partnerProductId,
  });

  const sideNavWidth =
    (isSideNavOpen ? AppSize.sideNav.widthNumber.open : AppSize.sideNav.widthNumber.close) / 2 + 'px';

  return (
    <VStack gap="6" h="100%">
      <Heading as="h2" fontSize="xl" fontWeight="semibold">
        Partner Product
      </Heading>

      <Box className="dashboard-card">
        <CustomTable
          variant="table"
          isLoading={isLoading}
          isFetching={isFetching}
          data={data?.data ?? []}
          pageCount={data?.total_pages || 0}
          pageSize={data?.page_size || pageSize}
          totalRecords={data?.total_records || 0}
          pageIndex={pageNumber - 1}
          headers={Header}
          search={searchParams.get('sproduct') ?? ''}
          initialState={{ sortBy: [InitialSortBy] }}
          manualSortBy
          disableSortRemove
          disableHoverHighlight
          showNoRecords
          onPageChange={index => {
            setPageNumber(index + 1);
          }}
          onPageSizeChange={size => {
            setPageNumber(1);
            setPageSize(size);
          }}
          onPageSearchDebounce={400}
          onPageSearch={search => {
            setPageNumber(1);

            setSearchParams(
              prev => {
                if (search) prev.set('sproduct', search);
                else prev.delete('sproduct');
                return prev;
              },
              { replace: true },
            );
          }}
          onSort={sort => {
            if (sort[0]) {
              setPageNumber(1);
              setSortBy(sort[0]);
            }
          }}
          styles={{
            loadingStyle: 'overlay',
            pagination: { justifyContent: 'start' },
            header: { justifyContent: 'left' },
            tableContainer: {
              sx: {
                maxW: `calc(50vw  - 4.25rem - ${sideNavWidth})`,
                overflow: 'auto',
                table: {
                  borderCollapse: 'separate',
                  borderSpacing: '0',
                  'thead > tr': {
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    zIndex: 2,
                    height: 'auto',
                    bg: 'white',
                  },
                },
              },
            },
          }}
          manual={true}
          extraComponents={{
            afterSearch: (
              <AppAuth requiredAppAccess={AppAccess.PartnerWrite}>
                <DashboardAdminPartnerProductsAfterSearch />
                <ViewAllButton path={'/partner-products'} />
              </AppAuth>
            ),
          }}
        />
      </Box>
    </VStack>
  );
};

export default DashboardAdminPartnerProducts;
