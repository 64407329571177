import { Box, Flex } from '@chakra-ui/react';
import ResultData from './ResultData';

import { useAppSelector } from '../../../app/state/hooks';
import { useEffect } from 'react';

import { PARTNER_PAGE } from '../../../app/constants/partnerPage';
import { PAGE_ACTIVITY } from '../../../app/constants/pageActivity';
import { useAddUserActivityMutation } from '../../../app/services/partner/api/userActivity';

const ExploreReports = () => {
  const { selectedPartnerProduct, activityList, pageList, userSessionId } = useAppSelector(s => s.user);
  const [postAddUserActivity] = useAddUserActivityMutation();

  const report_hub_page = pageList.find(i => i.page_name === PARTNER_PAGE.REPORT_HUB);
  const report_hub_clicked = activityList.find(i => i.activity_name === PAGE_ACTIVITY.REPORT_HUB_CLICKED);

  useEffect(() => {
    if (report_hub_page && report_hub_clicked && userSessionId) {
      postAddUserActivity({
        user_session_id: userSessionId,
        ref_activity_id: report_hub_clicked.ref_activity_id,
        ref_page_id: report_hub_page.ref_page_id,
        partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      })
        .unwrap()
        .then(res => {});
    }
  }, []);

  return (
    <>
      <Flex w="100%">
        <Box flex={4}>
          <ResultData />
        </Box>
      </Flex>
    </>
  );
};

export default ExploreReports;
