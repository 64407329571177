import {
  Box,
  Spinner,
  Input,
  FormLabel,
  VStack,
  FormControl,
  Textarea,
  FormErrorMessage,
  Button,
  Text,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import RedAsterisk from '../../../../RedAsterisk';
import { AddRefRoleModel } from '../../../../../app/services/partner/api/types';
import { useAddRefRoleMutation } from '../../../../../app/services/partner/api/refRole';
import { useToast } from '@chakra-ui/react';

import { useAppSelector } from '../../../../../app/state/hooks';
import { PARTNER_PAGE } from '../../../../../app/constants/partnerPage';
import { PAGE_ACTIVITY } from '../../../../../app/constants/pageActivity';
import { useAddUserActivityMutation } from '../../../../../app/services/partner/api/userActivity';

type RoleDetailsProps = {
  handleUpdatedRoles: () => void;
};

const defaultFormDataErrors = {
  role_name: '',
  role_desc: '',
  role_display_name: '',
};

const defaultRefRole = {
  role_desc: '',
  role_display_name: '',
};

const RoleDetails = ({ handleUpdatedRoles }: RoleDetailsProps) => {
  const [localRefRoleModel, setLocalRefRoleModel] = useState<AddRefRoleModel>(defaultRefRole);
  const [errors, setErrors] = useState(defaultFormDataErrors);
  const [postRole, postRoleDetails] = useAddRefRoleMutation();

  const { selectedPartnerProduct, activityList, pageList, userSessionId } = useAppSelector(s => s.user);
  const [postAddUserActivity] = useAddUserActivityMutation();

  const create_role_page = pageList.find(i => i.page_name === PARTNER_PAGE.CREATE_ROLE);
  const role_created = activityList.find(i => i.activity_name === PAGE_ACTIVITY.ROLE_CREATED);

  const handleLogEvent = () => {
    if (create_role_page && role_created && userSessionId) {
      postAddUserActivity({
        user_session_id: userSessionId,
        ref_activity_id: role_created.ref_activity_id,
        ref_page_id: create_role_page.ref_page_id,
        partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      })
        .unwrap()
        .then(res => {});
    }
  };

  const toast = useToast();

  const validate = () => {
    let newErrors = { ...errors };

    if (!localRefRoleModel.role_desc.trim()) {
      newErrors.role_desc = 'Description is required';
    } else {
      newErrors.role_desc = '';
    }

    if (!localRefRoleModel.role_display_name.trim()) {
      newErrors.role_display_name = 'Name is required';
    } else {
      newErrors.role_display_name = '';
    }
    setErrors(newErrors);

    return Object.values(newErrors).every(error => error === '');
  };

  const handleClear = () => {
    setLocalRefRoleModel(defaultRefRole);
    setErrors(defaultFormDataErrors);
  };

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrors(defaultFormDataErrors);

    if (validate()) {
      handleUpdatedRoles();

      let isException = false;

      postRole(localRefRoleModel)
        .unwrap()
        .then(res => {
          console.log(`REF_ROLE: ${JSON.stringify(res)}`);
        })
        .catch(error => {
          isException = true;
          toast({
            position: 'top-right',
            title: 'Error',
            description: 'There seems to be a problem. Please try again later.',
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
          console.log(`ERROR: ${JSON.stringify(error)}`);
        })
        .finally(() => {
          if (!isException) {
            handleClear();
            handleLogEvent();
            toast({
              position: 'top-right',
              title: 'Success!',
              description: 'Role added',
              status: 'success',
              duration: 9000,
              isClosable: true,
            });
          }
        });
    } else {
      console.log('INPUTS NOT VALID!!!');
    }
  };

  const handleOnStringPropertyChange = (prop_value: string, prop_name: string) => {
    setLocalRefRoleModel(prevState => ({ ...prevState, [prop_name]: prop_value }));
  };

  return (
    <>
      <VStack>
        <Box>
          <Text sx={{ fontWeight: 'bold', fontSize: '20' }}>Role Details</Text>
        </Box>

        <VStack>
          <form onSubmit={handleSave}>
            <Box>
              <Box sx={{ width: '100%' }} mr="2">
                <FormControl isInvalid={!!errors.role_display_name}>
                  <FormLabel fontSize={'smaller'} mb="-0.2" htmlFor="role-display-name">
                    Role Display Name
                    <RedAsterisk />
                  </FormLabel>
                  <Input
                    id="role-display-name"
                    name="role_display_name"
                    value={localRefRoleModel.role_display_name}
                    onChange={e => handleOnStringPropertyChange(e.target.value, e.target.name)}
                  />
                  <FormErrorMessage>{errors.role_display_name}</FormErrorMessage>
                </FormControl>
              </Box>

              <Box sx={{ width: '100%' }} pt={'5'} mr="2">
                <FormControl isInvalid={!!errors.role_desc}>
                  <FormLabel fontSize={'smaller'} mb="-0.2" htmlFor="role-desc">
                    Role Description
                    <RedAsterisk />
                  </FormLabel>
                  <Textarea
                    id="role-desc"
                    name="role_desc"
                    value={localRefRoleModel.role_desc}
                    onChange={e => handleOnStringPropertyChange(e.target.value, e.target.name)}
                  />
                  <FormErrorMessage>{errors.role_desc}</FormErrorMessage>
                </FormControl>
              </Box>
            </Box>

            <Box display="flex" justifyContent={'flex-end'} pt={'5'}>
              <Box display="flex">
                <Button size="sm" colorScheme="brand.main" px="5" type="submit">
                  {postRoleDetails.isLoading ? <Spinner size="sm" /> : 'Save'}
                </Button>
              </Box>
            </Box>
          </form>
        </VStack>
      </VStack>
    </>
  );
};

export default RoleDetails;
