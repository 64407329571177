import { Box } from '@chakra-ui/react';

interface HorizontalLineProps {
  marginTop: string;
}

const HorizontalLine = ({ marginTop }: HorizontalLineProps) => {
  return (
    <Box
      borderBottom="1px"
      borderColor="#c2c2c2"
      width="100%"
      mb={2} // Adjust margin as needed
      mt={marginTop}
    />
  );
};

export default HorizontalLine;
