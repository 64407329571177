import { Modal, ModalBody, ModalContent, ModalOverlay, Box } from '@chakra-ui/react';
import { Button } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import AppAccessDetails from './AppAccessDetails';

const AddAppAccessButton = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button size="sm" colorScheme="brand.main" gap="2" px="5" onClick={handleOpen}>
        <AddIcon fontSize="xx-small" />
        Add App Access
      </Button>

      <Modal isOpen={isOpen} onClose={handleClose} size="xl" closeOnOverlayClick={true}>
        <ModalOverlay />
        <ModalContent position="absolute" top="20%" left="35%">
          <ModalBody p="4" display="flex" flexDir="column" gap="3">
            <AppAccessDetails handleClose={handleClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddAppAccessButton;
