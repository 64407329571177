import { Box, Heading, Flex, Text } from '@chakra-ui/react';
import { PartnerProductModel } from '../../app/services/partner/api/types';
import { SortType } from '../../app/services/types';
import CustomTable from '../../components/CustomTable';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AppSize } from '../../app/constants';
import { CustomTableHeader } from '../../app/types/appType';
import { CellProps } from 'react-table';
import { convertUtcToLocal } from '../../app/helpers/dateHelper';
import { useAppSelector } from '../../app/state/hooks';
import { useGetPartnerProductPaginatedListQuery } from '../../app/services/partner/api/partnerProduct';
import OptionsButton from './OptionsButton';
import ExportAsCsvButton from './Buttons/ExportAsCsvButton';
import AddPartnerProductButton from './Buttons/AddPartnerProductButton';
import AddProductTypeButton from './Buttons/AddProductTypeButton';
import AppAuth from '../../features/AppAuth';
import { AppAccess } from '../../app/constants/appAccesses';
import UserCountContainer from '../UserCountContainer';
import PartnerProductDetailsButton from './PartnerProductDetailsModal/PartnerProductDetailsButton';

import { useAddUserActivityMutation } from '../../app/services/partner/api/userActivity';
import { useEffect } from 'react';
import { PARTNER_PAGE } from '../../app/constants/partnerPage';
import { PAGE_ACTIVITY } from '../../app/constants/pageActivity';

const InitialSortBy: SortType<PartnerProductModel> = {
  id: 'doing_business_as',
  desc: false,
};

const Header: CustomTableHeader<PartnerProductModel> = [
  {
    Header: 'Partner Product/Doing Business as',
    accessor: 'doing_business_as',
    isSortable: false,
    Cell: ({ row: { original } }: CellProps<PartnerProductModel>) => {
      const { isLoreHealthAdmin } = useAppSelector(s => s.user);
      return (
        <Flex
          width={'200px'}
          minH={original.partner_product_tags.split('|').length > 1 ? '50px' : ''}
          direction={'column'}
        >
          {isLoreHealthAdmin ? (
            <PartnerProductDetailsButton name={original.doing_business_as} id={original.partner_product_id} />
          ) : (
            <Text>{original.doing_business_as}</Text>
          )}
        </Flex>
      );
    },
  },
  {
    Header: 'Partner Name',
    accessor: 'partner_name',
    Cell: ({ row: { original } }: CellProps<PartnerProductModel>) => {
      return (
        <Flex minH={original.partner_product_tags.split('|').length > 1 ? '50px' : ''} direction={'column'}>
          <Text>{original.partner_name}</Text>
        </Flex>
      );
    },
  },
  {
    Header: 'Product Type',
    accessor: 'product_type_name',
    Cell: ({ row: { original } }: CellProps<PartnerProductModel>) => {
      return (
        <Flex minH={original.partner_product_tags.split('|').length > 1 ? '50px' : ''} direction={'column'}>
          <Text>{original.product_type_name}</Text>
        </Flex>
      );
    },
  },
  {
    Header: 'Product Tag',
    accessor: 'partner_product_tags',
    isSortable: false,
    Cell: ({ row: { original } }: CellProps<PartnerProductModel>) => {
      return (
        <>
          {(original.partner_product_tags ?? []).split('|').map((i, x) =>
            original.partner_product_tags.split('|').length > 1 ? (
              <div key={x}>
                {i}
                <br />
                <br />
              </div>
            ) : (
              i
            ),
          )}
        </>
      );
    },
  },
  {
    Header: 'Enrollment Start Date',
    Cell: ({ row: { original } }: CellProps<PartnerProductModel>) => {
      return (
        <Flex minH={original.partner_product_tags.split('|').length > 1 ? '50px' : ''} direction={'column'}>
          <Text>{convertUtcToLocal(original.enrollment_start_dt, 'YYYY-MM-D') || '-'}</Text>
        </Flex>
      );
    },
  },
  {
    Header: 'Enrollment End Date',
    Cell: ({ row: { original } }: CellProps<PartnerProductModel>) => {
      return (
        <Flex minH={original.partner_product_tags.split('|').length > 1 ? '50px' : ''} direction={'column'}>
          <Text>{convertUtcToLocal(original.enrollment_end_dt, 'YYYY-MM-D') || '-'}</Text>
        </Flex>
      );
    },
  },
  {
    Header: 'Users',
    accessor: 'user_count',
    isSortable: false,
    Cell: ({ row: { original } }: CellProps<PartnerProductModel>) => {
      return (
        <Flex minH={original.partner_product_tags.split('|').length > 1 ? '50px' : ''} direction={'column'}>
          <UserCountContainer count={original.user_count} />
        </Flex>
      );
    },
  },
  {
    Header: 'Action',
    Cell: OptionsButton,
  },
];

const PartnerProductList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState(InitialSortBy);

  const [postAddUserActivity] = useAddUserActivityMutation();
  const { selectedPartnerProduct, activityList, pageList, userSessionId } = useAppSelector(s => s.user);

  const { isSideNavOpen } = useAppSelector(s => s.app);

  const { isLoreHealthAdmin } = useAppSelector(s => s.user);

  const partnerProductId = selectedPartnerProduct?.partner_product_id || 0;

  const { data, isLoading, isFetching } = useGetPartnerProductPaginatedListQuery({
    page_number: pageNumber,
    page_size: pageSize,
    search_string: searchParams.get('sproduct') ?? '',
    sort_column: sortBy.id,
    sort_order: sortBy.desc ? 'desc' : 'asc',
    partner_product_id: isLoreHealthAdmin ? 0 : partnerProductId,
  });

  const sideNavWidth =
    (isSideNavOpen ? AppSize.sideNav.widthNumber.open : AppSize.sideNav.widthNumber.close) / 2 + 'px';

  const partner_products_page = pageList.find(i => i.page_name === PARTNER_PAGE.PARTNER_PRODUCTS);
  const partner_products_clicked = activityList.find(i => i.activity_name === PAGE_ACTIVITY.PARTNER_PRODUCTS_CLICKED);

  useEffect(() => {
    console.log(`IS LORE HEALTH ADMIN: ${isLoreHealthAdmin}`);
    if (partner_products_clicked && partner_products_page && userSessionId) {
      postAddUserActivity({
        user_session_id: userSessionId,
        ref_activity_id: partner_products_clicked.ref_activity_id,
        ref_page_id: partner_products_page.ref_page_id,
        partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      })
        .unwrap()
        .then(res => {});
    }
  }, []);

  return (
    <>
      <Heading as="h2" fontSize="xl" fontWeight="semibold" pb="2">
        Partner Products
      </Heading>

      <Box bg="white" shadow="base" p="4" rounded="md" minW="container.md">
        <CustomTable
          variant="table"
          isLoading={isLoading}
          isFetching={isFetching}
          data={data?.data ?? []}
          pageCount={data?.total_pages || 0}
          pageSize={data?.page_size || pageSize}
          totalRecords={data?.total_records || 0}
          pageIndex={pageNumber - 1}
          headers={Header}
          search={searchParams.get('sproduct') ?? ''}
          initialState={{ sortBy: [InitialSortBy] }}
          manualSortBy
          disableSortRemove
          disableHoverHighlight
          showNoRecords
          onPageChange={index => {
            setPageNumber(index + 1);
          }}
          onPageSizeChange={size => {
            setPageNumber(1);
            setPageSize(size);
          }}
          onPageSearchDebounce={400}
          onPageSearch={search => {
            setPageNumber(1);
            setSearchParams(
              prev => {
                if (search) prev.set('sproduct', search);
                else prev.delete('sproduct');
                return prev;
              },
              { replace: true },
            );
          }}
          onSort={sort => {
            if (sort[0]) {
              setPageNumber(1);
              setSortBy(sort[0]);
            }
          }}
          styles={{
            loadingStyle: 'overlay',
            pagination: { justifyContent: 'start' },
            header: { justifyContent: 'left' },
            tableContainer: {
              sx: {
                maxW: `calc(90vw - ${sideNavWidth})`,
                overflow: 'auto',
                table: {
                  borderCollapse: 'separate',
                  borderSpacing: '0',
                  'thead > tr': {
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    zIndex: 2,
                    height: 'auto',
                    bg: 'white',
                  },
                  tr: {
                    'th:last-child': {
                      position: 'sticky',
                      right: '0px',
                      zIndex: 2,
                      bg: 'white',
                      w: '90px',
                      borderLeft: '1px',
                      borderColor: 'gray.100',
                    },
                    'td:last-child': {
                      position: 'sticky',
                      right: '0px',
                      zIndex: 2,
                      bg: 'white',
                      w: '90px',
                      borderLeft: '1px',
                      borderColor: 'gray.100',
                    },
                  },
                },
              },
            },
          }}
          manual={true}
          // onRowClick={(row) => onClickRow(row)}
          extraComponents={{
            // TODO
            // afterSearch: (
            //   <AppAuth requiredAppAccess={AppAccess.PartnerWrite}>
            //     <DashboardAdminPartnerProductsAfterSearch />
            //   </AppAuth>
            // ),
            // afterPagination: <ExportAsCsvButton />,
            afterSearch: (
              <Box sx={{ display: 'flex', justifyContent: 'space-evenly', gap: 2 }}>
                <AppAuth requiredAppAccess={AppAccess.LoreHealthAdminAccess}>
                  <AddPartnerProductButton />
                </AppAuth>
                <AppAuth requiredAppAccess={AppAccess.LoreHealthAdminAccess}>
                  <AddProductTypeButton />
                </AppAuth>
              </Box>
            ),
            afterPagination: <ExportAsCsvButton />,
          }}
        />
      </Box>
    </>
  );
};

export default PartnerProductList;
