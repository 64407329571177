import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Row } from 'react-table';
import { defaultErrorMessage } from '../../../app/constants';
import { getName } from '../../../app/helpers/stringHelper';
import { RefUserModel } from '../../../app/services/partner/api/types';
import { useUpdateUserStatusMutation } from '../../../app/services/partner/api/user';

const DisableCell = ({ row: { original, index } }: { row: Row<RefUserModel> }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();
  // const [enableAsync, enableDetails] = [() => {}, { isLoading: false }];
  // const [disableAsync, disableDetails] = useDeleteUserByIdMutation();
  const [updateStatus, updateStatusDetails] = useUpdateUserStatusMutation();

  const isLoading = updateStatusDetails.isLoading;
  const isDisabled = original.disabled_flag;

  const handleOpen = () => {
    onOpen();
  };

  const handleDisable = () => {
    updateStatus({ refUserId: original.ref_user_id, isActive: isDisabled })
      .unwrap()
      .then(() => {
        toast({
          description: `Successfully ${isDisabled ? 'enabled' : 'disabled'} user "${getName(original)}"`,
        });
        onClose();
      })
      .catch(() => {
        toast({ description: defaultErrorMessage, status: 'error' });
      });
  };

  return (
    <>
      <Switch size="lg" isChecked={original.disabled_flag} onChange={handleOpen} />

      <Modal isOpen={isOpen} onClose={onClose} size="xl" closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isDisabled ? 'Enable' : 'Disable'} User</ModalHeader>
          <ModalCloseButton isDisabled={isLoading} />
          <ModalBody>
            <Text>
              Are you sure you want to {(isDisabled ? 'enable' : 'disable') + ' user '}
              <Text as="span" fontWeight="bold">
                {getName(original)}
              </Text>
              ?
            </Text>
          </ModalBody>
          <ModalFooter gap={3}>
            <Button onClick={onClose} ml="auto" isDisabled={isLoading}>
              Cancel
            </Button>
            <Button colorScheme={isDisabled ? 'brand.main' : 'red'} isLoading={isLoading} onClick={handleDisable}>
              {isDisabled ? 'Enable' : 'Disable'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DisableCell;
