import { Box, Text } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { convertUtcToLocal } from '../../../../../../../app/helpers/dateHelper';

type StatusIndicatorProps = {
  is_show: boolean;
  is_latest_date: boolean;
  status_datetime_utc: string;
  status_name: string;
};

const StatusIndicator = ({ status_name, is_show, is_latest_date, status_datetime_utc }: StatusIndicatorProps) => {
  const getBGColor = (): string => {
    if (status_name === 'rejected') {
      return 'red';
    } else if (typeof status_datetime_utc === 'undefined' || status_datetime_utc === null) {
      return '';
    } else {
      return '#4eb748';
    }
  };

  const getFontColor = (): string => {
    if (status_name === 'rejected') {
      if (is_latest_date) {
        return '#fff';
      } else {
        return 'red';
      }
    } else if (typeof status_datetime_utc === 'undefined' || status_datetime_utc === null) {
      return '';
    } else if (is_latest_date) {
      return '#fff';
    } else {
      return '#4eb748';
    }
  };

  return (
    <>
      {status_name === 'expired' || status_datetime_utc === null ? (
        <Box
          borderRadius={'full'}
          borderWidth={'2px'}
          borderStyle={'solid'}
          fontWeight={'bold'}
          borderColor={'#ebebeb'}
          width={'18px'}
          color={'#fff'}
          textAlign={'center'}
          lineHeight={'13px'}
          fontSize={'xxs'}
          mr={2}
        >
          <Text>{'x'}</Text>
        </Box>
      ) : is_show ? (
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          bg={getBGColor()}
          borderRadius={'full'}
          fontWeight={'bold'}
          color={getFontColor()}
          width={is_latest_date ? '18px' : '18px'}
          textAlign={'center'}
          lineHeight={is_latest_date ? '18px' : '18px'}
          fontSize={'xxs'}
          mr={2}
        >
          {is_latest_date ? (
            <ArrowForwardIcon height={'18px'} fontSize={'xxs'} fontWeight={'extrabold'} />
          ) : (
            <Text>''</Text>
          )}
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default StatusIndicator;
