import DashboardAdminPartnersAfterSearchAdd from './Add';

const DashboardAdminPartnersAfterSearch = () => {
  return (
    <>
      <DashboardAdminPartnersAfterSearchAdd />
    </>
  );
};

export default DashboardAdminPartnersAfterSearch;
