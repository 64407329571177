import { Link, Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

type ViewAllButtonProps = {
  path: string;
};

const ViewAllButton = ({ path }: ViewAllButtonProps) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(path);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '5px',
        fontSize: '15px',
        fontWeight: 'bold',
        alignItems: 'center',
        width: '100px',
      }}
    >
      <Link
        onClick={handleOnClick}
        sx={{
          color: 'blue',
        }}
      >
        View All
      </Link>
    </Box>
  );
};

export default ViewAllButton;
