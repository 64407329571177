import {
  Box,
  Button,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  InputGroup,
  ModalHeader,
  ModalOverlay,
  Input,
  Spinner,
  Flex,
  VStack,
  useDisclosure,
  useToast,
  Table,
  TableContainer,
  Thead,
  Tbody,
  Td,
  Th,
  Tr,
} from '@chakra-ui/react';

import { useState } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import HorizontalLine from '../../../HorizontalLine';
import { PartnerProductModel, PartnerProductAssignableUserModel } from '../../../../app/services/partner/api/types';
import { useLazyGetPartnerProductAssignableUsersQuery } from '../../../../app/services/partner/api/partnerProduct';
import { useAddUserPartnerProductMutation } from '../../../../app/services/partner/api/userPartnerProduct';

interface AssignUserButtonProps {
  partnerProductId: number;
  partnerProductName: string;
  searchString: string;
  refUserId: number;
  reloadUsers: (e: string) => void;
}

const AssignUserButton = ({
  partnerProductId,
  refUserId,
  partnerProductName,
  searchString,
  reloadUsers,
}: AssignUserButtonProps) => {
  const [post, postDetails] = useAddUserPartnerProductMutation();
  const toast = useToast();

  const handleOnClick = () => {
    console.log(`FROM ASSIGN BUTTON: ppid ${partnerProductId}; userId ${refUserId}`);

    post({ ref_user_id: refUserId, partner_product_id: partnerProductId })
      .unwrap()
      .then(res => {
        toast({ status: 'success', description: `Assigned to ${partnerProductName}!` });
        reloadUsers(searchString);
      })
      .catch(error => {
        toast({ status: 'error', description: `Unable to assign.` });
      });
  };

  return (
    <>
      <Button
        sx={{ width: '100px', border: '2px solid #3794aa', backgroundColor: '#fff', color: '#3794aa' }}
        onClick={handleOnClick}
      >
        {postDetails.isLoading ? <Spinner size="xs" /> : 'Assign'}
      </Button>
    </>
  );
};

const AssignUsers = ({ original }: { original: PartnerProductModel }) => {
  const [search, setSearch] = useState<string>(' ');
  const [users, setUsers] = useState<PartnerProductAssignableUserModel[] | []>([]);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [get, getDetails] = useLazyGetPartnerProductAssignableUsersQuery();

  const handleOnClick = () => {
    onOpen();

    get({ partner_product_id: original.partner_product_id, search_string: encodeURIComponent(search.trim() || ' ') })
      .unwrap()
      .then(res => {
        console.log(`1st Load SEARCH PARAMS: ppid - ${original.partner_product_id}; search - ${search}`);
        setUsers(res);
      });
  };

  const handleOnClose = () => {
    onClose();
    setSearch('');
  };

  const handleSearchChange = (e: string) => {
    setSearch(e);
    get({ partner_product_id: original.partner_product_id, search_string: encodeURIComponent(e.trim() || ' ') })
      .unwrap()
      .then(res => {
        console.log(`SEARCH PARAMS: ppid - ${original.partner_product_id}; search - ${e}`);
        setUsers(res);
      });
  };

  return (
    <>
      <Button
        size="sm"
        _hover={{ textDecoration: 'none' }}
        variant="link"
        fontWeight="normal"
        textAlign="left"
        onClick={handleOnClick}
      >
        <Box w="100%">Assign Users</Box>
      </Button>

      <Modal isOpen={isOpen} onClose={handleOnClose} size="5xl" closeOnOverlayClick={false}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Assign Users</ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <VStack>
              <InputGroup justifyContent="flex-end">
                <Input
                  id="search"
                  placeholder="Search users"
                  name="search"
                  value={search}
                  onChange={e => handleSearchChange(e.target.value)}
                />
                <InputRightElement children={<SearchIcon className="SearchIcon" color="gray.300" />} />
              </InputGroup>

              <TableContainer
                sx={{
                  th: {
                    color: 'blackAlpha.700',
                    textTransform: 'initial',
                    fontSize: '16px',
                    letterSpacing: '-0.5px',
                  },
                  td: {
                    fontSize: '14px',
                    width: '200px',
                  },
                  height: '500px',
                  overflowY: 'scroll',
                }}
              >
                <Table variant="unstyled">
                  <Thead>
                    <Tr>
                      <Th>First Name</Th>
                      <Th>Last Name</Th>
                      <Th>Email</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {getDetails.isLoading || getDetails.isFetching ? (
                      <Tr>
                        <Td colSpan={4}>
                          <Flex justify="center" align="center" height="100%">
                            <Spinner size="lg" />
                          </Flex>
                        </Td>
                      </Tr>
                    ) : !getDetails.data?.length ? (
                      <Tr>
                        <Td colSpan={4} bg="red.100">
                          No Users
                        </Td>
                      </Tr>
                    ) : (
                      getDetails.data?.map((u, i) => (
                        <Tr key={i}>
                          <Td>{u.first_name || '-'}</Td>
                          <Td>{u.last_name || '-'}</Td>
                          <Td>{u.email}</Td>
                          <Td>
                            <AssignUserButton
                              partnerProductId={original.partner_product_id}
                              partnerProductName={original.doing_business_as ?? 'Partner Product'}
                              searchString={search}
                              refUserId={u.ref_user_id}
                              reloadUsers={handleSearchChange}
                            />
                          </Td>
                        </Tr>
                      ))
                    )}
                  </Tbody>
                </Table>
              </TableContainer>

              <HorizontalLine marginTop={'10px'} />
              <Box sx={{ display: 'flex', alignContent: 'end', paddingBottom: '10px' }}>
                <Button onClick={onClose} ml="auto" color="#fff" backgroundColor="#3794aa">
                  Close
                </Button>
              </Box>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AssignUsers;
