import { MyRolesFilteredSearchResultsModel } from '../../../../../app/services/partner/api/types';
import { Modal, ModalBody, ModalContent, ModalOverlay, Button } from '@chakra-ui/react';
import { useState } from 'react';
import RequestDetails from '../../../ExploreRoles/RequestAccessButton/RequestDetails';

const MyRolesActionRequestAccessCell = ({ original }: { original: MyRolesFilteredSearchResultsModel }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button
        size="sm"
        _hover={{ textDecoration: 'none' }}
        variant="link"
        fontWeight="normal"
        alignSelf={'start'}
        color="black"
        onClick={handleOpen}
      >
        Request Access
      </Button>

      <Modal isOpen={isOpen} onClose={handleClose} size="xl" closeOnOverlayClick={true}>
        <ModalOverlay />
        <ModalContent position="absolute" top="20%" left="35%">
          <ModalBody p="4" display="flex" flexDir="column" gap="3">
            <RequestDetails handleClose={handleClose} refRoleId={original.ref_role_id} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MyRolesActionRequestAccessCell;
