import { UserRoleAccessRequestStatusDisplayModel } from '../../../../../../app/services/partner/api/types';
import { HStack, Box, Text } from '@chakra-ui/react';
import StatusIndicator from './StatusIndicator';
import { convertUtcToLocal } from '../../../../../../app/helpers/dateHelper';

type StatusDetailsProps = {
  status: UserRoleAccessRequestStatusDisplayModel;
};
const StatusDetails = ({ status }: StatusDetailsProps) => {
  const is_no_date =
    typeof status.status_datetime_utc === undefined ||
    status.status_datetime_utc === null ||
    status.status_datetime_utc === '';

  return (
    <>
      <HStack pt="3">
        <StatusIndicator
          key={status.ref_access_request_status_id}
          is_latest_date={status.is_latest_date}
          status_name={status.status_name}
          is_show={status.is_show}
          status_datetime_utc={status.status_datetime_utc}
        />
        <Box w={'80px'}>
          <Text color={'#585858'}>{status.status_display_name}</Text>
        </Box>
        <Box w={'200px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Text>{is_no_date ? '-' : convertUtcToLocal(status.status_datetime_utc)}</Text>
        </Box>
      </HStack>
    </>
  );
};

export default StatusDetails;
