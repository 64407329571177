import { ValueOf } from '../helpers/utilities';

export const StandardAppAccess = {
  ProviderRead: 'provider.read',
  ProviderWrite: 'provider.write',
  UserRead: 'user.read',
  UserWrite: 'user.write',
  PartnerRead: 'partner.read',
  PartnerWrite: 'partner.write',
  PartnerProductRead: 'partner.product.read',
  PartnerProductWrite: 'partner.product.write',
  InviteRead: 'invite.read',
  InviteWrite: 'invite.write',
  PatientRead: 'patient.read',
  PatientWrite: 'patient.write',
  ReportHubRead: 'reporthub.read',
  ReportHubWrite: 'reporthub.write',
  AcoPaymentReportRead: 'acopaymentreport.read',
  AcoPaymentReportWrite: 'acopaymentreport.write',
  OrdersToSignRead: 'orderstosign.read',
  OrdersToSignWrite: 'orderstosign.write',
  OrdersToSignCgmRead: 'orderstosigncgm.read',
  OrdersToSignCgmWrite: 'orderstosigncgm.write',
  FulfillmentRead: 'fulfillment.read',
  FulfillmentWrite: 'fulfillment.write',
  FulfillmentCgmRead: 'fulfillmentcgm.read',
  FulfillmentCgmWrite: 'fulfillmentcgm.write',
  AnalyticsRead: 'analytics.read',
  AnalyticsWrite: 'analytics.write',
  RolesRead: 'roles.read',
  RolesWrite: 'roles.write',
  FileShareRead: 'fileshare.read',
  FileShareWrite: 'fileshare.write',
  FileShareFinancialRead: 'filesharefinancial.read',
  FileShareFinancialWrite: 'filesharefinancial.write',
  FileSharePhiRead: 'filesharephi.read',
  FileSharePhiWrite: 'filesharephi.write',
  FileShareDedentifiedRead: 'filesharedeidentified.read',
  FileShareDedentifiedWrite: 'filesharedeidentified.write',
} as const;

// prettier-ignore
export const StandardAppAccessCombined = {
  Provider: [StandardAppAccess.ProviderRead, StandardAppAccess.ProviderWrite],
  User: [StandardAppAccess.UserRead, StandardAppAccess.UserWrite],
  Partner: [StandardAppAccess.PartnerRead, StandardAppAccess.PartnerWrite],
  Invite: [StandardAppAccess.InviteRead, StandardAppAccess.InviteWrite],
  Patient: [StandardAppAccess.PatientRead, StandardAppAccess.PatientWrite],
  ReportHub: [StandardAppAccess.ReportHubRead, StandardAppAccess.ReportHubWrite],
  AcoPaymentReport: [StandardAppAccess.AcoPaymentReportRead, StandardAppAccess.AcoPaymentReportWrite],
  OrdersToSign: [StandardAppAccess.OrdersToSignRead, StandardAppAccess.OrdersToSignWrite],
  OrdersToSignCgm: [StandardAppAccess.OrdersToSignCgmRead, StandardAppAccess.OrdersToSignCgmWrite],
  Fulfillment: [StandardAppAccess.FulfillmentRead, StandardAppAccess.FulfillmentWrite],
  FulfillmentCgm: [StandardAppAccess.FulfillmentCgmRead, StandardAppAccess.FulfillmentCgmWrite],
  Analytics: [StandardAppAccess.AnalyticsRead, StandardAppAccess.AnalyticsWrite],
  Roles: [StandardAppAccess.RolesRead, StandardAppAccess.RolesWrite],
  FileShare: [StandardAppAccess.FileShareRead, StandardAppAccess.FileShareWrite],
  FileShareFinancial: [StandardAppAccess.FileShareFinancialRead, StandardAppAccess.FileShareFinancialWrite],
  FileSharePhi: [StandardAppAccess.FileSharePhiRead, StandardAppAccess.FileSharePhiWrite],
  FileShareDedentified: [StandardAppAccess.FileShareDedentifiedRead, StandardAppAccess.FileShareDedentifiedWrite],
  PartnerAdministratorPHI: [StandardAppAccess.ProviderRead, StandardAppAccess.ProviderWrite, StandardAppAccess.PatientRead, StandardAppAccess.ReportHubRead, StandardAppAccess.UserRead, StandardAppAccess.UserWrite, StandardAppAccess.UserRead, StandardAppAccess.UserWrite],
};

export const SpecialAppAccess = {
  LoreHealthAdminAccessRead: 'lore.health.admin.access.read',
  LoreHealthAdminAccessWrite: 'lore.health.admin.access.write',
  LoreHealthTeamAccessRead: 'lore.health.team.access.read',
  LoreHealthTeamAccessWrite: 'lore.health.team.access.write',
  PartnerAdministratorPHIAccessRead: 'partner.admin.phi.access.read',
  PartnerAdministratorPHIAccessWrite: 'partner.admin.phi.access.write',
} as const;

// prettier-ignore
export const SpecialAppAccessCombined = {
  LoreHealthAndPartnerAdminAccess: [SpecialAppAccess.LoreHealthAdminAccessRead, SpecialAppAccess.LoreHealthAdminAccessWrite, SpecialAppAccess.LoreHealthTeamAccessRead, SpecialAppAccess.PartnerAdministratorPHIAccessRead, SpecialAppAccess.PartnerAdministratorPHIAccessWrite],
  LoreHealthAdminAccess: [SpecialAppAccess.LoreHealthAdminAccessRead, SpecialAppAccess.LoreHealthAdminAccessWrite],
  LoreHealthTeamAccess: [SpecialAppAccess.LoreHealthTeamAccessRead, SpecialAppAccess.LoreHealthTeamAccessWrite],
  LoreHealthAdminAndTeamAccess: [SpecialAppAccess.LoreHealthTeamAccessRead, SpecialAppAccess.LoreHealthTeamAccessWrite, SpecialAppAccess.LoreHealthAdminAccessRead, SpecialAppAccess.LoreHealthAdminAccessWrite],
  LoreAdminPartnerAdminAndTeamAccess: [SpecialAppAccess.LoreHealthAdminAccessRead, SpecialAppAccess.LoreHealthAdminAccessWrite, SpecialAppAccess.LoreHealthTeamAccessRead, SpecialAppAccess.PartnerAdministratorPHIAccessRead]
};

export const AppAccess = {
  ...StandardAppAccess,
  ...StandardAppAccessCombined,
  ...SpecialAppAccess,
  ...SpecialAppAccessCombined,
  standard: { ...StandardAppAccess, ...StandardAppAccessCombined },
  special: { ...SpecialAppAccess, ...SpecialAppAccessCombined },
};

export type AppAccessName = ValueOf<typeof StandardAppAccess> | ValueOf<typeof SpecialAppAccess>;
export type AppAuthRequiredAppAccess = AppAccessName | Array<AppAccessName> | '*';
