import { CSSProperties, FC } from 'react';

type Props = {
  style?: CSSProperties;
};

const HeaderLogo: FC<Props> = ({ style }) => {
  return (
    <svg width="106" height="47" viewBox="0 0 106 47" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <mask
        id="mask0_45_5152"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="106"
        height="47"
      >
        <rect width="106" height="47" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_45_5152)">
        <path
          d="M43.4221 27.9496C43.4221 27.9496 43.4037 27.9311 43.376 27.8942C43.3113 27.8112 43.2467 27.7373 43.1821 27.6543C42.9236 27.3312 42.462 26.7036 41.8989 25.836C40.865 26.0575 40.108 26.1682 39.6925 26.2144C39.591 26.2144 39.4894 26.2329 39.3879 26.2421C39.3417 26.2421 39.3233 26.2421 39.3233 26.2421C37.3569 26.5559 35.8337 28.2357 35.7414 30.2663C35.7414 30.3401 35.7414 30.3955 35.7414 30.3955C35.7229 31.5031 36.1384 32.6107 36.9877 33.4506C37.1908 33.6537 37.4123 33.8383 37.6523 33.986C37.6893 34.0136 37.7354 34.0229 37.7724 34.0506C37.9662 34.1705 38.1693 34.2813 38.3724 34.3644C38.5294 34.429 38.6955 34.4659 38.8617 34.5121C38.9632 34.5397 39.0556 34.5767 39.1571 34.5951C39.4248 34.6505 39.711 34.6782 39.9972 34.6782C41.1973 34.6782 42.2682 34.189 43.0436 33.3952C43.0436 33.3952 43.0713 33.3583 43.1175 33.3122C44.493 31.8077 44.613 29.5279 43.4314 27.9219L43.4221 27.9496Z"
          fill="white"
        />
        <path
          d="M70.2678 30.4786C70.2678 28.3557 68.6892 26.5651 66.6674 26.2513C66.6674 26.2513 66.6397 26.2513 66.6028 26.2421C66.5012 26.2236 66.3997 26.2144 66.2982 26.2144C65.8827 26.1682 65.1442 26.0575 64.1195 25.8452C63.5656 26.7128 63.104 27.3312 62.8455 27.645C62.7809 27.7189 62.7163 27.8019 62.6516 27.885C62.624 27.9219 62.6055 27.9404 62.6055 27.9404C61.3961 29.6018 61.5438 31.9738 63.0486 33.4783C64.1472 34.5767 65.7073 34.9182 67.1105 34.549C68.9107 34.0598 70.2585 32.4353 70.2585 30.4878L70.2678 30.4786Z"
          fill="white"
        />
        <path
          d="M81.9268 8.6222C81.9268 8.6222 81.9084 8.63143 81.8807 8.65912C81.7976 8.72373 81.7237 8.78834 81.6407 8.85295C81.3175 9.11139 80.699 9.56365 79.8312 10.1267C80.0528 11.1604 80.1636 11.9173 80.2097 12.3326C80.2097 12.4341 80.2282 12.5357 80.2374 12.6372C80.2374 12.6834 80.2374 12.7018 80.2374 12.7018C80.5513 14.7047 82.2961 16.2645 84.3917 16.2922C84.6317 16.2922 84.8717 16.2738 85.1118 16.2276C85.1395 16.2276 85.1764 16.2276 85.2041 16.2276C86.0165 16.0799 86.8011 15.6831 87.4289 15.0554C88.6382 13.8463 88.9614 12.0834 88.4075 10.5789C88.389 10.5328 88.3798 10.4866 88.3613 10.4405C88.2782 10.2467 88.1767 10.0621 88.0659 9.87747C88.029 9.82209 88.0013 9.75748 87.9643 9.7021C87.8259 9.48981 87.6689 9.29598 87.4843 9.11139C87.4658 9.09293 87.4566 9.07447 87.4381 9.05601C85.9334 7.55154 83.5608 7.41309 81.9084 8.6222H81.9268Z"
          fill="white"
        />
        <path
          d="M25.7897 12.6926C25.7897 12.6926 25.7897 12.6741 25.7897 12.628C25.8082 12.5265 25.8174 12.4249 25.8174 12.3234C25.8635 11.9081 25.9743 11.1604 26.1959 10.1359C25.3281 9.5729 24.7004 9.1114 24.3865 8.86219C24.3126 8.79759 24.2295 8.73298 24.1465 8.66837C24.1095 8.64068 24.1003 8.62222 24.1003 8.62222C22.4386 7.4131 20.0661 7.56078 18.5613 9.06525C18.3674 9.25908 18.192 9.4806 18.0443 9.70211C17.9982 9.77595 17.9613 9.84979 17.9243 9.92363C17.832 10.0898 17.7397 10.2467 17.6658 10.422C17.6197 10.542 17.592 10.662 17.5458 10.7912C17.4997 10.9297 17.4535 11.0681 17.4258 11.2158C17.3889 11.4189 17.3704 11.6312 17.3612 11.8527C17.3612 11.9173 17.3427 11.9819 17.3427 12.0557C17.3427 12.3419 17.3704 12.628 17.4258 12.8957C17.4258 12.9326 17.4535 12.9695 17.4627 13.0064C17.5181 13.2464 17.5828 13.4771 17.6751 13.6987C17.6751 13.6987 17.6751 13.7079 17.6843 13.7171C17.9982 14.4463 18.5059 15.0739 19.1521 15.5262C19.1706 15.5446 19.1891 15.5631 19.2075 15.5723C19.4014 15.7015 19.6045 15.8123 19.8168 15.9046C19.8537 15.9231 19.8907 15.9507 19.9276 15.96C20.1584 16.0615 20.3984 16.1261 20.6477 16.1815C20.6754 16.1815 20.6938 16.2 20.7215 16.2C20.9892 16.2553 21.2754 16.283 21.5616 16.283C23.6849 16.283 25.4758 14.7047 25.7897 12.6834V12.6926Z"
          fill="white"
        />
        <path
          d="M52.9954 43.2988C51.066 43.2988 49.4966 41.7205 49.4966 39.7915C49.4966 37.8901 51.1121 36.2933 53.0231 36.2933C53.937 36.2933 54.8048 36.6625 55.4695 37.3363C56.1434 38.0193 56.5126 38.9054 56.5126 39.8468C56.5126 41.7574 54.934 43.308 53.0046 43.308M53.0046 47C56.965 47 60.1961 43.7972 60.2053 39.8561C60.2053 35.8872 56.9742 32.6198 53.0323 32.6106C49.0719 32.6106 45.8224 35.8503 45.8224 39.8007C45.8224 43.788 49.0258 47 53.0138 47H53.0046Z"
          fill="white"
        />
        <path
          d="M24.0911 31.4199C23.1494 31.4199 22.2632 31.0507 21.5985 30.3861C20.2322 29.0201 20.2322 26.7957 21.5985 25.4297C22.2447 24.7836 23.1217 24.4236 24.0634 24.4236C25.005 24.4236 25.9005 24.7836 26.5652 25.4482C27.2114 26.0943 27.5622 26.9711 27.5529 27.9125C27.5529 28.8632 27.1837 29.7585 26.519 30.4231C25.8728 31.0692 25.005 31.4199 24.0818 31.4199M24.0818 35.1118C25.9097 35.1118 27.7376 34.4196 29.1223 33.0351C31.9287 30.2385 31.9564 25.6328 29.1777 22.8453C27.7745 21.4424 25.9189 20.7409 24.0634 20.7409C22.2078 20.7409 20.3799 21.4332 18.986 22.8269C16.1703 25.642 16.1611 30.1831 18.986 32.9982C20.3892 34.4104 22.2447 35.1118 24.0911 35.1118H24.0818Z"
          fill="white"
        />
        <path
          d="M81.9274 31.4293C80.995 31.4293 80.1087 31.0601 79.444 30.3956C78.7886 29.7403 78.4378 28.8634 78.4378 27.922C78.4378 26.9713 78.8071 26.0852 79.4625 25.4299C80.0995 24.793 80.9673 24.4423 81.9089 24.4423C82.8505 24.4423 83.7645 24.8115 84.4384 25.4761C85.7862 26.8236 85.7677 29.0388 84.4014 30.4048C83.7368 31.0694 82.8598 31.4293 81.9274 31.4293ZM81.9274 35.1213C83.7645 35.1213 85.6016 34.4198 87.0048 33.0169C89.802 30.2202 89.8204 25.6606 87.0417 22.8732C85.6293 21.4611 83.7645 20.7503 81.8997 20.7503C80.0349 20.7503 78.2255 21.4426 76.8499 22.8178C74.0435 25.6145 74.0343 30.211 76.8315 33.0076C78.2439 34.4198 80.081 35.1213 81.9274 35.1213Z"
          fill="white"
        />
        <path
          d="M93.7993 2.50281C92.8761 2.50281 91.9991 2.13361 91.3345 1.45983C90.6605 0.786051 90.2913 -0.100014 90.3005 -1.03223C90.3005 -2.92436 91.9253 -4.5119 93.8455 -4.5119C95.7564 -4.5119 97.3073 -2.93359 97.3073 -1.00454C97.3073 0.924501 95.7287 2.49358 93.7993 2.49358M93.7993 6.18553C97.7874 6.19476 101 2.98276 101 -0.995316C101 -4.95494 97.7966 -8.18539 93.8547 -8.19462C93.8547 -8.19462 93.8547 -8.19462 93.8455 -8.19462C89.8851 -8.19462 86.6171 -4.95493 86.6171 -1.023C86.6171 2.93662 89.8574 6.19476 93.8085 6.19476L93.7993 6.18553Z"
          fill="white"
        />
        <path
          d="M43.1914 27.6542C43.256 27.7281 43.3206 27.8112 43.3852 27.8942C43.4129 27.9311 43.4314 27.9496 43.4314 27.9496C44.6038 29.5556 44.4561 31.8354 43.0806 33.3399C43.5883 32.7768 46.9486 29.3618 53.0046 29.3525C59.6052 29.3525 63.0025 33.4229 63.0025 33.4229C63.0302 33.4506 63.0579 33.4691 63.0856 33.4968C63.0856 33.4968 63.0579 33.4875 63.0579 33.4783C63.0579 33.4783 63.0579 33.4783 63.0486 33.4783C61.5439 31.9738 61.3962 29.6018 62.6055 27.9404C62.6055 27.9404 62.624 27.9219 62.6517 27.885C62.7163 27.8019 62.7809 27.7281 62.8456 27.645C63.104 27.322 63.5564 26.7036 64.1195 25.8452C62.0609 25.4022 58.8759 24.4792 55.0079 22.5778C54.8417 22.4671 54.6663 22.3748 54.4817 22.3009C54.4355 22.2825 54.3894 22.264 54.334 22.2455C54.1678 22.1809 54.0016 22.1256 53.8262 22.0886C53.7801 22.0794 53.7339 22.0609 53.6785 22.0517C53.4662 22.0148 53.2446 21.9871 53.0138 21.9871C53.0138 21.9871 53.0138 21.9871 53.0046 21.9871C53.0046 21.9871 53.0046 21.9871 52.9954 21.9871C52.7646 21.9871 52.5523 22.0148 52.3307 22.0517C52.2845 22.0517 52.2384 22.0702 52.1922 22.0886C52.0168 22.1256 51.8507 22.1809 51.6845 22.2455C51.6383 22.264 51.5829 22.2825 51.5368 22.3009C51.3521 22.384 51.1767 22.4763 51.0106 22.587C47.1517 24.4792 43.9853 25.4022 41.9174 25.8452C42.4805 26.722 42.9421 27.3404 43.2006 27.6635L43.1914 27.6542Z"
          fill="white"
          fillOpacity="0.75"
        />
        <path
          d="M21.7739 16.2645C22.5863 16.2276 27.3222 16.2368 31.578 20.4825C35.843 24.756 35.8338 29.5001 35.7876 30.2847C35.8799 28.2541 37.3754 26.565 39.3418 26.2604C39.3418 26.2604 39.3695 26.2604 39.4064 26.2512C39.5079 26.2328 39.6095 26.2235 39.711 26.2235C40.1265 26.1774 40.8835 26.0666 41.9174 25.8451C40.7727 24.073 39.1848 21.1932 37.7909 17.1136C37.7539 16.9198 37.6893 16.726 37.6155 16.5414C37.597 16.4952 37.5693 16.4491 37.5508 16.4029C37.477 16.2368 37.3939 16.0799 37.3016 15.9322C37.2739 15.8953 37.2554 15.8491 37.2277 15.803C37.1077 15.6184 36.9692 15.443 36.8123 15.2861C36.8123 15.2861 36.8123 15.2861 36.8031 15.2861C36.8031 15.2861 36.8031 15.2861 36.8031 15.2769C36.6461 15.12 36.4707 14.9815 36.2861 14.8615C36.2492 14.8338 36.203 14.8062 36.1569 14.7785C36.0092 14.6862 35.8522 14.6031 35.686 14.5293C35.6399 14.5108 35.5937 14.4831 35.5476 14.4646C35.3629 14.3908 35.1691 14.3354 34.9752 14.2893C30.8856 12.8863 27.9961 11.2988 26.2328 10.1543C26.0113 11.1788 25.9005 11.9264 25.8543 12.3418C25.8543 12.4433 25.8359 12.5448 25.8266 12.6464C25.8266 12.6925 25.8266 12.711 25.8266 12.711C25.522 14.6677 23.8418 16.2091 21.8016 16.3106V16.2737L21.7739 16.2645Z"
          fill="white"
          fillOpacity="0.75"
        />
        <path
          d="M66.5936 26.242H66.6582C68.6615 26.5558 70.2124 28.3002 70.2585 30.3862C70.2678 30.3862 70.2493 30.3862 70.2585 30.3862C70.2585 30.3862 70.2585 30.3862 70.2585 30.3769C70.2585 30.3769 69.797 25.1251 74.4589 20.4548C79.1302 15.7845 84.383 16.2829 84.383 16.2829H84.4014C82.3058 16.2552 80.5518 14.6954 80.2472 12.6833C80.2472 12.6833 80.2472 12.6648 80.2472 12.6186C80.2287 12.5171 80.2195 12.4156 80.2195 12.3141C80.1733 11.8987 80.0626 11.1419 79.841 10.1081C78.0685 11.2526 75.179 12.8494 71.0894 14.2431C70.8955 14.2893 70.7017 14.3446 70.517 14.4185C70.4709 14.4369 70.4247 14.4646 70.3786 14.4831C70.2124 14.5569 70.0554 14.64 69.9077 14.7323C69.8616 14.76 69.8246 14.7877 69.7785 14.8154C69.5939 14.9353 69.4185 15.0738 69.2615 15.2307C69.2615 15.2307 69.2615 15.2307 69.2615 15.2399C69.2615 15.2399 69.2615 15.2399 69.2523 15.2399C69.0953 15.3968 68.9569 15.5722 68.8369 15.7568C68.8092 15.7937 68.7815 15.8399 68.763 15.8768C68.6707 16.0337 68.5876 16.1906 68.5138 16.3475C68.4953 16.3937 68.4676 16.4398 68.4491 16.486C68.3753 16.6706 68.3199 16.8644 68.2737 17.0582C66.8705 21.1563 65.2734 24.0452 64.1379 25.8174C65.1534 26.0389 65.9012 26.1404 66.3166 26.1866C66.4182 26.1866 66.5197 26.205 66.6213 26.2143L66.5936 26.242Z"
          fill="white"
          fillOpacity="0.75"
        />
        <path
          d="M81.9367 -10.5391C81.9367 -10.5391 81.9182 -10.5483 81.8813 -10.576C81.7982 -10.6406 81.7243 -10.7052 81.6412 -10.7698C81.3181 -11.0283 80.6996 -11.4805 79.8318 -12.0435C79.3795 -9.98528 78.4656 -6.8102 76.5731 -2.95211C76.4623 -2.78597 76.37 -2.6106 76.2961 -2.42601C76.2776 -2.37986 76.2592 -2.33371 76.2407 -2.27833C76.1761 -2.11219 76.1207 -1.94605 76.0838 -1.77068C76.0746 -1.72453 76.0561 -1.67838 76.0469 -1.623C76.0099 -1.41072 75.9822 -1.1892 75.973 -0.967683C75.973 -0.967683 75.973 -0.967684 75.973 -0.958454C75.973 -0.958454 75.973 -0.958452 75.973 -0.949223C75.973 -0.718475 76.0007 -0.50619 76.0469 -0.284673C76.0469 -0.238523 76.0653 -0.192374 76.0838 -0.136995C76.1207 0.038373 76.1761 0.204514 76.2407 0.370651C76.2592 0.4168 76.2776 0.46295 76.2961 0.51833C76.3792 0.702927 76.4715 0.878294 76.5731 1.04443C78.4656 4.90252 79.3887 8.06837 79.8318 10.1359C80.6996 9.57284 81.3181 9.12058 81.6412 8.86214C81.7151 8.79753 81.7982 8.73292 81.8813 8.66831C81.9182 8.64062 81.9274 8.62216 81.9274 8.62216C83.5799 7.41305 85.9617 7.56073 87.4572 9.0652C87.4387 9.04674 87.4295 9.0652 87.4203 9.04674C87.4203 9.04674 83.3491 5.65014 83.3491 -0.949223C83.3491 -7.54859 87.4203 -10.936 87.4203 -10.936C87.4387 -10.9544 87.448 -10.9544 87.4664 -10.9636C85.9617 -9.45918 83.5891 -9.32073 81.9367 -10.5298V-10.5391Z"
          fill="white"
          fillOpacity="0.75"
        />
        <path
          d="M18.5244 -11.0559C18.5613 -11.019 18.589 -11.0006 18.6259 -10.9636C18.6259 -10.9636 22.6971 -7.56705 22.6971 -0.967683C22.6971 5.37325 18.9675 8.71446 18.6813 8.9729V8.99136C20.1768 7.56996 22.4847 7.44074 24.1095 8.63139C24.1095 8.63139 24.128 8.64062 24.1557 8.66831C24.2388 8.73292 24.3126 8.79753 24.3957 8.86214C24.7188 9.12058 25.3373 9.58207 26.2051 10.1359C26.6482 8.0776 27.5714 4.89329 29.4731 1.0352C29.5839 0.869062 29.6762 0.693695 29.7593 0.509097C29.7777 0.462948 29.7962 0.416799 29.8147 0.36142C29.8793 0.195281 29.9347 0.0291443 29.9716 -0.146223C29.9808 -0.192373 29.9993 -0.238523 30.0085 -0.293901C30.0455 -0.506188 30.0732 -0.727707 30.0732 -0.958454C30.0732 -0.958454 30.0732 -0.958452 30.0732 -0.967683C30.0732 -0.967683 30.0732 -0.967685 30.0732 -0.976914C30.0732 -1.19843 30.0455 -1.41995 30.0085 -1.63223C30.0085 -1.67838 29.9901 -1.72453 29.9716 -1.77991C29.9347 -1.95528 29.8793 -2.12142 29.8147 -2.28756C29.7962 -2.3337 29.7777 -2.38908 29.7593 -2.43523C29.6762 -2.61983 29.5839 -2.7952 29.4731 -2.96134C27.5714 -6.83789 26.6482 -10.013 26.2051 -12.0805C25.3373 -11.5174 24.7188 -11.0652 24.3957 -10.8067C24.3218 -10.7421 24.2388 -10.6775 24.1557 -10.6129C24.1187 -10.5852 24.1095 -10.5668 24.1095 -10.5668C22.4478 -9.35765 20.0753 -9.50532 18.5705 -11.0098C18.5521 -11.0283 18.5428 -11.0467 18.5244 -11.0652V-11.0559Z"
          fill="white"
          fillOpacity="0.75"
        />
        <path
          d="M12.1822 2.50252C11.2591 2.50252 10.3821 2.13332 9.71739 1.45954C9.04348 0.785758 8.67421 -0.100307 8.68345 -1.03253C8.68345 -2.92465 10.3082 -4.51219 12.2284 -4.51219C14.1394 -4.51219 15.6903 -2.93388 15.6903 -1.00484C15.6903 0.924208 14.1117 2.49329 12.1822 2.49329M12.1822 6.18524C16.1703 6.18524 19.3829 2.98247 19.3829 -1.00484C19.3829 -4.96446 16.1796 -8.19491 12.2376 -8.20414C12.2376 -8.20414 12.2376 -8.20414 12.2284 -8.20414C8.26802 -8.20414 5.00002 -4.96446 5.00002 -1.03253C5.00002 2.92709 8.24033 6.18524 12.1915 6.18524H12.1822Z"
          fill="white"
        />
        <path
          d="M52.9862 9.13903C50.2721 9.13903 47.7241 8.08682 45.8132 6.17624C43.9022 4.26565 42.8498 1.70898 42.8498 -1.01384C42.8498 -3.6905 43.9115 -6.22872 45.8501 -8.14854C47.7888 -10.0776 50.3459 -11.1482 53.0508 -11.1482C55.7095 -11.1482 58.2298 -10.0776 60.1499 -8.13931C62.0886 -6.18257 63.1502 -3.61666 63.141 -0.903078C63.141 4.63485 58.5713 9.13903 52.9862 9.13903ZM52.9862 15.2861C61.9594 15.2861 69.2801 8.02221 69.2893 -0.89385C69.3078 -9.87452 61.9686 -17.2861 53.0508 -17.2953C53.0508 -17.2953 53.0508 -17.2953 53.0416 -17.2953C44.0776 -17.2953 36.7015 -9.94836 36.7015 -1.01384C36.7015 8.01299 43.9669 15.2861 52.9862 15.2861Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default HeaderLogo;
