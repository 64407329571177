import {
  Button,
  ButtonGroup,
  CloseButton,
  HStack,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FiRefreshCcw } from 'react-icons/fi';
import { defaultErrorMessage } from '../../../app/constants';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import {
  useLazyGetExternalProviderQuery,
  useLazyGetPartnerProductProviderByIdQuery,
  useUpdatePartnerProductProviderMutation,
} from '../../../app/services/partner/api/partnerProductProvider';
import { PartnerProductProviderModel } from '../../../app/services/partner/api/types';
import { useAppSelector } from '../../../app/state/hooks';
import ProviderDetails from '../ProviderDetails';
import UpdateIncorrectInfoBox from '../UpdateIncorrectInfoBox';
import { GoX } from 'react-icons/go';

const AcoProviderListActionCellView = ({ original }: { original: PartnerProductProviderModel }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();
  const { selectedPartnerProduct } = useAppSelector(s => s.user);

  const [isLoading, setIsLoading] = useState(false);
  const [displayedData, setDisplayedData] = useState(original);

  const [getExternal] = useLazyGetExternalProviderQuery();
  const [updateProvider] = useUpdatePartnerProductProviderMutation();
  const [getProvider] = useLazyGetPartnerProductProviderByIdQuery();

  const handleOpen = () => {
    setDisplayedData(original);
    setIsLoading(false);
    onOpen();
  };

  const handleRefresh = async () => {
    if (!selectedPartnerProduct) return;

    setIsLoading(true);
    try {
      const external = await getExternal(original.provider_npi.toString()).unwrap();
      const updateData = {
        partner_product_provider_id: original.partner_product_provider_id,
        provider_first_name: external.provider_first_name,
        provider_last_name: external.provider_last_name,
        provider_middle_name: external.provider_middle_name,
        provider_tin: selectedPartnerProduct.partner_tin,
        partner_product_id: selectedPartnerProduct.partner_product_id,
        provider_npi: external.provider_npi,
        provider_pecos_id: external.provider_pecos_id,
        provider_enrollment_id: external.provider_enrollment_id,
        provider_state_cd: external.provider_state_cd,
        provider_provider_type_desc: external.provider_provider_type_desc,
        provider_org_name: external.provider_org_name,
        provider_gender: external.provider_gender,
        provider_provider_type_cd: external.provider_provider_type_cd,
        user_partner_product_id: selectedPartnerProduct.user_partner_product_id,
        npireg_last_processed_datetime_utc: external.npireg_last_processed_datetime_utc,
        npireg_address_1: external.npireg_address_1,
        npireg_city: external.npireg_city,
        npireg_state: external.npireg_state,
        npireg_postal_code: external.npireg_postal_code,
        npireg_telephone_number: external.npireg_telephone_number,
        npireg_enumeration_date: external.npireg_enumeration_date,
        npireg_last_updated: external.npireg_last_updated,
        npireg_taxonomy: external.npireg_taxonomy,
        disabled_datetime_utc: null,
      };
      await updateProvider(updateData).unwrap();
      const updatedProvider = await getProvider(original.partner_product_provider_id).unwrap();
      setDisplayedData(original => ({ ...original, ...updatedProvider }));
      // TODO tbd update date must reflect today & fetch this entry after update
    } catch (error) {
      toast({ description: defaultErrorMessage, status: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button
        size="sm"
        _hover={{ textDecoration: 'none' }}
        variant="link"
        fontWeight="normal"
        color="black"
        onClick={handleOpen}
      >
        View Provider Details
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="4xl" closeOnOverlayClick={!isLoading}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody p="4" display="flex" flexDir="column" gap="6">
            <HStack gap="4">
              <Heading as="h3" size="sm">
                Provider Details
              </Heading>
              <Button
                size="sm"
                colorScheme="brand.main"
                ml="auto"
                variant="outline"
                gap="2"
                isLoading={isLoading}
                onClick={handleRefresh}
              >
                <FiRefreshCcw />
                Refresh Data
              </Button>
              <IconButton
                variant="ghost"
                color="black"
                fontSize="lg"
                aria-label="close modal"
                onClick={onClose}
                icon={<GoX />}
                isDisabled={isLoading}
              />
            </HStack>

            <UpdateIncorrectInfoBox />

            <HStack w="100%" justifyContent="space-between">
              <Heading as="h2" size="md" fontWeight="semibold" textAlign="center">
                NPI {displayedData.provider_npi}
              </Heading>

              <Heading as="h2" size="md" fontWeight="semibold" textAlign="center">
                TIN {displayedData.partner_tin}
              </Heading>

              <Heading as="h2" size="md" fontWeight="semibold" textAlign="center">
                Date Updated {convertUtcToLocal(displayedData.row_modified_datetime_utc, 'MM-D-YYYY')}
              </Heading>
            </HStack>

            <ProviderDetails entry={displayedData} />

            <ButtonGroup w="100%">
              <Button ml="auto" colorScheme="brand.main" size="sm" onClick={onClose} isDisabled={isLoading}>
                Done
              </Button>
            </ButtonGroup>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AcoProviderListActionCellView;
