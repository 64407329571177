import { Box, Text } from '@chakra-ui/react';
import { useState, FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { AppSize } from '../../../app/constants';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import { useAppAccess } from '../../../app/hooks/useAppAccess';
import { useGetRefRolesQuery } from '../../../app/services/partner/api/refRole';
import { RefRoleModel } from '../../../app/services/partner/api/types';
import { SortType } from '../../../app/services/types';
import { useAppSelector } from '../../../app/state/hooks';
import { CustomTableHeader } from '../../../app/types/appType';
import CustomTable from '../../../components/CustomTable';
import ExploreRolesDetailsCell from './ViewDetails';
import RequestAccessButton from './RequestAccessButton';
import AppAuth from '../../../features/AppAuth';
import { AppAccess } from '../../../app/constants/appAccesses';
import { useEffect } from 'react';

import { PARTNER_PAGE } from '../../../app/constants/partnerPage';
import { PAGE_ACTIVITY } from '../../../app/constants/pageActivity';
import { useAddUserActivityMutation } from '../../../app/services/partner/api/userActivity';

const InitialSortBy: SortType<RefRoleModel> = {
  id: 'role_display_name',
  desc: false,
};

const ExploreRoles = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState(InitialSortBy);
  const { isSideNavOpen } = useAppSelector(s => s.app);
  const { isUserAdmin } = useAppAccess();

  const { selectedPartnerProduct, activityList, pageList, userSessionId } = useAppSelector(s => s.user);
  const [postAddUserActivity] = useAddUserActivityMutation();

  const explore_roles_page = pageList.find(i => i.page_name === PARTNER_PAGE.EXPLORE_ROLES);
  const explore_roles_clicked = activityList.find(i => i.activity_name === PAGE_ACTIVITY.EXPLORE_ROLES_CLICKED);

  const refRoleId = searchParams.get('roleId');
  const openRequestAccess = (searchParams.get('openRequestAccess') ?? 'false').toLowerCase() === 'true';

  const { data, isLoading, isFetching } = useGetRefRolesQuery({
    page_number: pageNumber,
    page_size: pageSize,
    search_string: searchParams.get('search') ?? '',
    sort_column: sortBy.id,
    sort_order: sortBy.desc ? 'desc' : 'asc',
    partner_product_id: selectedPartnerProduct?.partner_product_id ?? 0,
  });

  const sideNavWidth = isSideNavOpen ? AppSize.sideNav.width.open : AppSize.sideNav.width.close;

  const Header: CustomTableHeader<RefRoleModel> = [
    {
      Header: 'Role Name',
      accessor: 'role_display_name',
      styles: { verticalAlign: 'top' },
    },
    {
      Header: 'Description',
      accessor: 'role_desc',
      styles: { whiteSpace: 'initial', minWidth: '300px', maxWidth: '520px' },
    },
    {
      Header: 'Last Modified',
      styles: { verticalAlign: 'top' },
      Cell: ({ row: { original } }: CellProps<RefRoleModel>) => {
        return <>{convertUtcToLocal(original.row_modified_datetime_utc, 'YYYY-MM-D') || '-'}</>;
      },
    },
    ...(isUserAdmin
      ? [
          {
            Header: 'Users',
            styles: { verticalAlign: 'top' },
            Cell: ({ row: { original } }: CellProps<RefRoleModel>) => {
              return (
                <Box bg="brand.main.default" w="fit-content" px="1" rounded="sm">
                  <Text fontSize="xs" fontWeight="bold" color="white">
                    {original.user_count}
                  </Text>
                </Box>
              );
            },
          },
        ]
      : []),
    {
      Header: 'Details',
      styles: { verticalAlign: 'top' },
      Cell: ExploreRolesDetailsCell,
    },
  ];

  useEffect(() => {
    if (explore_roles_page && explore_roles_clicked && userSessionId) {
      postAddUserActivity({
        user_session_id: userSessionId,
        ref_activity_id: explore_roles_clicked.ref_activity_id,
        ref_page_id: explore_roles_page.ref_page_id,
        partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      })
        .unwrap()
        .then(res => {});
    }
  }, []);

  return (
    <>
      <Box pb={'5'}>
        <Text sx={{ fontWeight: 'bold', fontSize: '25' }}>Explore Roles</Text>
      </Box>
      <Box bg="white" shadow="base" p="4" rounded="md" minW="container.md">
        <CustomTable
          variant="table"
          isLoading={isLoading}
          isFetching={isFetching}
          data={data?.data ?? []}
          pageCount={data?.total_pages || 0}
          pageSize={data?.page_size || pageSize}
          totalRecords={data?.total_records || 0}
          pageIndex={pageNumber - 1}
          headers={Header}
          search={searchParams.get('search') ?? ''}
          initialState={{ sortBy: [InitialSortBy] }}
          manualSortBy
          disableSortRemove
          disableHoverHighlight
          showNoRecords
          onPageChange={index => {
            setPageNumber(index + 1);
          }}
          onPageSizeChange={size => {
            setPageNumber(1);
            setPageSize(size);
          }}
          onPageSearchDebounce={400}
          onPageSearch={search => {
            setPageNumber(1);

            setSearchParams(
              prev => {
                if (search) prev.set('search', search);
                else prev.delete('search');
                return prev;
              },
              { replace: true },
            );
          }}
          onSort={sort => {
            if (sort[0]) {
              setPageNumber(1);
              setSortBy(sort[0]);
            }
          }}
          styles={{
            loadingStyle: 'overlay',
            pagination: { justifyContent: 'start' },
            header: { justifyContent: 'left' },
            tableContainer: {
              sx: {
                maxW: `calc(100vw - 5rem - ${sideNavWidth})`,
                overflow: 'auto',
                table: {
                  borderCollapse: 'separate',
                  borderSpacing: '0',
                  'thead > tr': {
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    zIndex: 2,
                    height: 'auto',
                    bg: 'white',
                  },
                  tr: {
                    'th:last-child': {
                      position: 'sticky',
                      right: '0px',
                      zIndex: 2,
                      bg: 'white',
                      w: '90px',
                      borderLeft: '1px',
                      borderColor: 'gray.100',
                    },
                    'td:last-child': {
                      position: 'sticky',
                      right: '0px',
                      zIndex: 2,
                      bg: 'white',
                      w: '90px',
                      borderLeft: '1px',
                      borderColor: 'gray.100',
                    },
                  },
                },
              },
            },
          }}
          manual={true}
          extraComponents={{
            afterSearch: (
              <AppAuth requiredAppAccess={AppAccess.RolesRead}>
                <RequestAccessButton openRequestAccess={openRequestAccess} />
              </AppAuth>
            ),
          }}
          // onRowClick={(row) => onClickRow(row)}
          // extraComponents={{
          //   afterSearch: <AddProviderButton />,
          //   afterPagination: <ExportAsCsvButton />,
          // }}
        />
      </Box>
    </>
  );
};

export default ExploreRoles;
