import { BoxProps } from '@chakra-ui/react';
import countriesObject from 'countries-list/minimal/countries.en.min.json';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { AddPartnerModel, RefPaymentTrackModel } from '../../../app/services/partner/api/types';
import { SelectStyles } from '../../../app/types/appType';
import { equal } from 'assert';

export const AddPartnerModalStyle: BoxProps = {
  bg: 'backgroundColor',
  pos: 'fixed',
  top: '0',
  left: '0',
  w: '100%',
  h: 'calc(100vh - 60px)',
  zIndex: 2,
  mt: '60px',
  p: '6',
  overflowY: 'auto',
  sx: {
    '.da-container': {
      borderBottom: '1px',
      borderBottomColor: 'blackAlpha.400',
      pb: '4',
      mb: '6',
      gap: '0',
      label: {
        color: 'blackAlpha.700',
        fontSize: '14px',
        fontWeight: '500',
        mb: 0,
      },
      input: {
        rounded: 'md',
        color: 'blackAlpha.600',
      },
      '.chakra-form-control .chakra-form__error-message': {
        mt: 1,
        fontSize: '12px',
      },
      '.da-row': {
        display: 'flex',
        gap: '4',
        alignItems: 'start',
        minH: '4.5rem',
      },
      '.da-row button': {
        color: 'blackAlpha.500',
      },
      '.da-c-entry': {
        '& p': {
          color: 'blackAlpha.600',
          fontSize: '14px',
        },
      },
    },
  },
};

export const addPartnerInitialValues: AddPartnerModel = {
  partner_id: null,
  partner_tin: null,
  partner_name: '',
  partner_address_1: null,
  partner_address_2: null,
  partner_city: null,
  partner_state: null,
  partner_zip_code: null,
  doing_business_as: '',
  partner_country: null,
  contract_start_dt: null,
  contract_end_dt: null,
  contract_renewal_dt: null,
  ref_product_type_id: 0,
  ref_payment_track_id: null,
  ref_partner_product_tag_ids: [],
  enrollment_start_dt: null,
  enrollment_end_dt: null,
  product_tier_name: '',
  ref_product_tier_id: null
};

export type AddPartnerStepProp = {
  data: AddPartnerModel;
  activeStep: number;
  setData: React.Dispatch<React.SetStateAction<AddPartnerModel>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  handleSubmit: () => void;
  isLoading: boolean;
  source?: 'partner-page' | 'partner-product-page' | 'dashboard' | null;
};

export function selectStyles<T>(): SelectStyles<T> {
  return {
    control: (provided, state) => ({
      ...provided,
      rounded: 'md',
      color: 'blackAlpha.600',
    }),
    menuList: (provided, state) => ({
      ...provided,
      rounded: 'md',
      color: 'blackAlpha.600',
    }),
  };
}

export const countries = Object.entries(countriesObject).map(c => c[1]);

export const getPaymentTrackLabel = (p: RefPaymentTrackModel) => {
  return `${p.payment_track_desc} - $${p.first_cohort_amt ?? 0} | $${p.second_cohort_amt ?? 0} | ${
    (p.performance_based_pct ?? 0) * 100
  }%`;
};

export const addPartnerFieldMaxLength = {
  partner_name: 150,
  partner_tin: 20,
  doing_business_as: 500,
  partner_address_1: 150,
  partner_address_2: 150,
  partner_city: 100,
  partner_state: 100,
  partner_country: 100,
  partner_zip_code: 9,
};
const productTypesMap: { [key: string]: number } = {
  Trial: 8,
};

export const addPartnerFormSchema = {
  
  partner_name: Yup.string().label('Partner Name').max(addPartnerFieldMaxLength.partner_name).required(),
  ref_product_type_id: Yup.number().label('Product Type').required().moreThan(0, 'Product Type is a required field'),
  ref_product_tier_id: Yup.number()
  .label('Product Tier')
  .when('ref_product_type_id', (ref_product_type_id, schema) => {
    if ((ref_product_type_id as any[]).includes(productTypesMap["Trial"])) {
      return schema.required('Product Tier is required when Product Type is Trial');
    } else {
      return schema.nullable();
    }
  }),
  partner_tin: Yup.string().label('Partner TIN').max(addPartnerFieldMaxLength.partner_tin).nullable(),
  doing_business_as: Yup.string().label('Doing Business as').max(addPartnerFieldMaxLength.doing_business_as).required(),
  partner_address_1: Yup.string().label('Address 1').max(addPartnerFieldMaxLength.partner_address_1).nullable(),
  partner_address_2: Yup.string().label('Address 2').max(addPartnerFieldMaxLength.partner_address_2).nullable(),
  partner_city: Yup.string().label('Country').max(addPartnerFieldMaxLength.partner_city).nullable(),
  partner_state: Yup.string().label('State').max(addPartnerFieldMaxLength.partner_state).nullable(),
  partner_country: Yup.string().label('Country').max(addPartnerFieldMaxLength.partner_country).nullable(),
  partner_zip_code: Yup.string().label('Postal Code').max(addPartnerFieldMaxLength.partner_zip_code).nullable(),
};

export const minDate = dayjs(new Date(0)).startOf('day').toDate();
export const maxDate = dayjs().add(20, 'year').toDate();
export const AddPartnerDateSchema = ({ label }: { label: string }) => {
  const schema = Yup.string()
    .test((str, { createError, path }) => {
      if (!str) return true;
      const date: dayjs.Dayjs = dayjs(str, 'YYYY-MM-DD', true);
      return date.isValid()
        ? true
        : createError({
            message: `${label} in invalid`,
            path,
          });
    })
    .test((str, { createError, path }) => {
      if (!str) return true;
      const date = dayjs(str, 'YYYY-MM-DD', true);
      return date.isAfter(minDate)
        ? true
        : createError({
            message: `${label} should not be lower than epoch date`,
            path,
          });
    })
    .test((str, { createError, path }) => {
      if (!str) return true;
      const date = dayjs(str, 'YYYY-MM-DD', true);
      return date.isBefore(maxDate)
        ? true
        : createError({
            message: `${label} should not be higher than 20 years from now`,
            path,
          });
    })
    .label(label)
    .nullable();

  return schema;
};
