import { Box, Heading, Link } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AppSize } from '../../app/constants';
import { AppAccess } from '../../app/constants/appAccesses';
import { useGetPartnerProductProviderListQuery } from '../../app/services/partner/api/partnerProductProvider';
import { PartnerProductProviderModel } from '../../app/services/partner/api/types';
import { SortType } from '../../app/services/types';
import { useAppSelector } from '../../app/state/hooks';
import { CustomTableHeader } from '../../app/types/appType';
import CustomTable from '../../components/CustomTable';
import AppAuth from '../../features/AppAuth';
import AcoProviderListActionCell from './AcoProviderListActionCell';
import AddProviderButton from './AddProviderButton';
import ExportAsCsvButton from './ExportAsCsvButton';

import { PARTNER_PAGE } from '../../app/constants/partnerPage';
import { PAGE_ACTIVITY } from '../../app/constants/pageActivity';
import { useAddUserActivityMutation } from '../../app/services/partner/api/userActivity';

const InitialSortBy: SortType<PartnerProductProviderModel> = {
  id: 'provider_last_name',
  desc: false,
};

const AcoProviderList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState(InitialSortBy);
  const { isSideNavOpen } = useAppSelector(s => s.app);

  const { selectedPartnerProduct, activityList, pageList, userSessionId } = useAppSelector(s => s.user);
  const [postAddUserActivity] = useAddUserActivityMutation();

  const aco_provider_list_page = pageList.find(i => i.page_name === PARTNER_PAGE.ACO_PROVIDER_LIST);
  const aco_provider_list_clicked = activityList.find(i => i.activity_name === PAGE_ACTIVITY.ACO_PROVIDER_LIST_CLICKED);

  useEffect(() => {
    if (aco_provider_list_page && aco_provider_list_clicked && userSessionId) {
      postAddUserActivity({
        user_session_id: userSessionId,
        ref_activity_id: aco_provider_list_clicked.ref_activity_id,
        ref_page_id: aco_provider_list_page.ref_page_id,
        partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      })
        .unwrap()
        .then(res => {});
    }
  }, []);

  const { data, isLoading, isFetching } = useGetPartnerProductProviderListQuery(
    {
      partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      page_number: pageNumber,
      page_size: pageSize,
      search_string: searchParams.get('search') ?? '',
      sort_column: sortBy.id,
      sort_order: sortBy.desc ? 'desc' : 'asc',
    },
    { skip: !selectedPartnerProduct?.partner_product_id },
  );

  const Header: CustomTableHeader<PartnerProductProviderModel> = [
    {
      Header: 'NPI',
      accessor: 'provider_npi',
    },
    {
      Header: 'Last Name',
      accessor: 'provider_last_name',
    },
    {
      Header: 'First Name',
      accessor: 'provider_first_name',
    },
    {
      Header: 'Middle Name',
      accessor: 'provider_middle_name',
    },
    {
      Header: 'Gender',
      accessor: 'provider_gender',
      isSortable: false,
    },
    {
      Header: 'Pecos ID',
      accessor: 'provider_pecos_id',
      isSortable: false,
    },
    {
      Header: 'Enrollment ID',
      accessor: 'provider_enrollment_id',
      isSortable: false,
    },
    {
      Header: 'Provider Type CD',
      accessor: 'provider_provider_type_cd',
      isSortable: false,
    },
    {
      Header: 'Provider Type Desc',
      accessor: 'provider_provider_type_desc',
      isSortable: false,
    },
    {
      Header: 'State CD',
      accessor: 'provider_state_cd',
      isSortable: false,
    },
    {
      Header: 'Partner Name',
      accessor: 'partner_name',
      isSortable: false,
    },
    {
      Header: 'Action',
      Cell: AcoProviderListActionCell,
    },
  ];

  useEffect(() => {
    // needed for search and filters
    setPageNumber(1);
  }, [searchParams]);

  const sideNavWidth = isSideNavOpen ? AppSize.sideNav.width.open : AppSize.sideNav.width.close;

  return (
    <>
      <Heading as="h2" fontSize="xl" fontWeight="semibold" pb="2">
        Lore Health ACO – Participant Providers
      </Heading>
      <Box bg="blackAlpha.100" p="4" rounded="lg" mb="6">
        Click "Add Provider" to import your information from CMS using your NPI. You can use your NPI to look up your
        PECOS ID{' '}
        <Link
          href="https://data.cms.gov/provider-characteristics/medicare-provider-supplier-enrollment/medicare-fee-for-service-public-provider-enrollment/data"
          color="links"
          isExternal
        >
          here
        </Link>
        .
      </Box>

      <Box bg="white" shadow="base" p="4" rounded="md" minW="container.md">
        <CustomTable
          variant="table"
          isLoading={isLoading}
          isFetching={isFetching}
          data={data?.data ?? []}
          pageCount={data?.total_pages || 0}
          pageSize={data?.page_size || pageSize}
          totalRecords={data?.total_records || 0}
          pageIndex={pageNumber - 1}
          headers={Header}
          search={searchParams.get('search') ?? ''}
          initialState={{ sortBy: [InitialSortBy] }}
          manualSortBy
          disableSortRemove
          disableHoverHighlight
          showNoRecords
          onPageChange={index => {
            setPageNumber(index + 1);
          }}
          onPageSizeChange={size => {
            setPageNumber(1);
            setPageSize(size);
          }}
          onPageSearchDebounce={400}
          onPageSearch={search => {
            setPageNumber(1);

            setSearchParams(
              prev => {
                if (search) prev.set('search', search);
                else prev.delete('search');
                return prev;
              },
              { replace: true },
            );
          }}
          onSort={sort => {
            if (sort[0]) {
              setPageNumber(1);
              setSortBy(sort[0]);
            }
          }}
          styles={{
            loadingStyle: 'overlay',
            pagination: { justifyContent: 'start' },
            header: { justifyContent: 'left' },
            tableContainer: {
              sx: {
                maxW: `calc(100vw - 5rem - ${sideNavWidth})`,
                overflow: 'auto',
                table: {
                  borderCollapse: 'separate',
                  borderSpacing: '0',
                  'thead > tr': {
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    zIndex: 2,
                    height: 'auto',
                    bg: 'white',
                  },
                  tr: {
                    'th:last-child': {
                      position: 'sticky',
                      right: '0px',
                      zIndex: 2,
                      bg: 'white',
                      w: '90px',
                      borderLeft: '1px',
                      borderColor: 'gray.100',
                    },
                    'td:last-child': {
                      position: 'sticky',
                      right: '0px',
                      zIndex: 2,
                      bg: 'white',
                      w: '90px',
                      borderLeft: '1px',
                      borderColor: 'gray.100',
                    },
                  },
                },
              },
            },
          }}
          manual={true}
          // onRowClick={(row) => onClickRow(row)}
          extraComponents={{
            afterSearch: (
              <AppAuth requiredAppAccess={AppAccess.ProviderWrite}>
                <AddProviderButton />
              </AppAuth>
            ),
            afterPagination: <ExportAsCsvButton />,
          }}
        />
      </Box>
    </>
  );
};

export default AcoProviderList;
