import { Box, Text, VStack, HStack } from '@chakra-ui/react';
import { MdOutlineWavingHand } from 'react-icons/md';

interface BoxWithTextAndImageProps {
  imageSrc: string;
}

const WelcomeSection = ({ imageSrc }: BoxWithTextAndImageProps) => {
  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <Box
        w="100%"
        height="350px"
        overflow={'hidden'}
        borderRadius={'lg'}
        backgroundImage={imageSrc}
        border={'hidden'}
        sx={{
          position: 'absolute',
          top: '0',
          left: '0',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: '0',
          left: '0',
          pt: '20px',
          pl: '20px',
        }}
      >
        <VStack fontSize={['sm', 'md', 'lg', 'xl', '2xl']}>
          <HStack>
            <MdOutlineWavingHand size={'35'} />
            <Text sx={{ fontWeight: 'bold' }}>A quick note:</Text>
          </HStack>
          <Text sx={{ width: '45%', color: '#585858' }}>
            This portal provides you and our other partners a place to connect with us and find information about how
            Lore users are interacting with the services we offer. To get started, make a selection from the menu on the
            left.
          </Text>
        </VStack>
      </Box>
    </Box>
  );
};

export default WelcomeSection;
