import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Input,
  Skeleton,
  Text,
  useDisclosure,
  useToast,
  VStack,
  Spinner,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { GoPencil } from 'react-icons/go';
import { MdOutlineSave } from 'react-icons/md';
import { RefDemographic, UserNameFormSchema } from '.';
import { AppAccess } from '../../app/constants/appAccesses';
import { useLazyGetUserByEmailQuery } from '../../app/services/partner/api/user';
import { setLogonUser } from '../../app/slices/userSlice';
import { useAppDispatch, useAppSelector } from '../../app/state/hooks';
import AppAuth from '../../features/AppAuth';
import { defaultErrorMessage } from './../../app/constants';
import { getName } from './../../app/helpers/stringHelper';
import { usePutUserDemographicByUserMutation } from './../../app/services/partner/api/userDemographic';
import { useUserContext } from './Context';
import { UserRoleDisplayModel } from '../../app/services/partner/api/types';
import { useLazyGetUserRoleListByRefUserIdQuery } from '../../app/services/partner/api/userRole';

const UserDrawer = () => {
  const { selectedUser, setSelectedUser } = useUserContext();
  const { logonUser } = useAppSelector(s => s.user);
  const [getUserByEmail, { isLoading, isFetching }] = useLazyGetUserByEmailQuery();
  const dispatch = useAppDispatch();

  const [isEdit, setIsEdit] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [putAsync, putDetails] = usePutUserDemographicByUserMutation();
  const [getUserRoles, getUserRolesDetails] = useLazyGetUserRoleListByRefUserIdQuery();

  // const [distinctRolesArray, setDistinctRolesArray] = useState<(UserRoleAppAccessModel | undefined)[]>([]);
  const [userRolesList, setUserRolesList] = useState<(UserRoleDisplayModel | undefined)[]>([]);
  const [isUserRolesLoading, setIsUserRolesLoading] = useState<boolean>(false);

  const { handleSubmit, errors, touched, handleChange, values, resetForm } = useFormik({
    enableReinitialize: true,
    validationSchema: UserNameFormSchema,
    initialValues: {
      first_name: selectedUser?.first_name || '',
      last_name: selectedUser?.last_name || '',
    },
    onSubmit: async (values, form) => {
      selectedUser &&
        putAsync({
          refUserId: selectedUser.ref_user_id,
          body: [
            {
              ref_demographic_id: RefDemographic.first_name,
              demographic_value: values.first_name,
            },
            {
              ref_demographic_id: RefDemographic.last_name,
              demographic_value: values.last_name,
            },
          ],
        })
          .unwrap()
          .then(() => {
            form.resetForm();
            setIsEdit(false);

            toast({
              description: 'Name successfully updated.',
              status: 'success',
            });

            selectedUser.email &&
              getUserByEmail(selectedUser.email)
                .unwrap()
                .then(updatedUser => {
                  setSelectedUser(updatedUser);
                  if (logonUser && logonUser.email === selectedUser.email) {
                    dispatch(setLogonUser(updatedUser));
                  }
                });
          })
          .catch(() => toast({ description: defaultErrorMessage, status: 'error' }));
    },
  });

  useEffect(() => {
    if (selectedUser) {
      console.log(`SELECTED USER in DRAWER: ${JSON.stringify(selectedUser)}`);
      if (!!selectedUser.email) {
        setIsUserRolesLoading(true);
        getUserRoles(selectedUser.ref_user_id)
          .unwrap()
          .then(items => {
            const finalRolesArray = Array.from(new Set(items.map(item => item.role_display_name ?? ''))).map(name => {
              if (!!name) {
                return items.find(item => item.role_display_name === name);
              }
            });
            setUserRolesList(finalRolesArray ?? []);
            console.log(`SELECTED USER's ROLES in DRAWER: ${JSON.stringify(finalRolesArray)}`);
          })
          .catch(error => {
            console.log(`ERROR fetching roles: ${JSON.stringify(error)}`);
          })
          .finally(() => {
            setIsUserRolesLoading(false);
          });
      }
    }
  }, [selectedUser]);

  useEffect(() => {
    if (selectedUser) {
      onOpen();
      setIsEdit(false);
      resetForm();
    } else {
      onClose();
      setIsEdit(false);
      resetForm();
    }
  }, [selectedUser]);

  const handleClose = () => {
    setSelectedUser(null);
  };

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={handleClose}>
        <DrawerOverlay />
        <DrawerContent sx={{ m: '4', rounded: 'md', p: '0' }} w="426px" maxW="426px">
          <DrawerCloseButton mt="1" />

          <DrawerBody px="4" pb="4" pt="12" display="flex" flexDir="column" gap="1">
            <HStack gap="4">
              {isEdit ? (
                <Box w="full">
                  <form onSubmit={handleSubmit}>
                    <HStack gap="4" w="full" alignItems="start">
                      <FormControl isInvalid={!!errors.first_name && touched.first_name} w="120px">
                        <Input
                          id="first_name"
                          name="first_name"
                          value={values.first_name}
                          onChange={handleChange}
                          bg="white"
                          placeholder="First Name"
                          size="sm"
                          isReadOnly={putDetails.isLoading}
                        />
                        <FormErrorMessage>{errors.first_name}</FormErrorMessage>
                      </FormControl>

                      <FormControl isInvalid={!!errors.last_name && touched.last_name} w="120px">
                        <Input
                          id="last_name"
                          name="last_name"
                          value={values.last_name}
                          onChange={handleChange}
                          bg="white"
                          placeholder="Last Name"
                          size="sm"
                          isReadOnly={putDetails.isLoading}
                        />
                        <FormErrorMessage>{errors.last_name}</FormErrorMessage>
                      </FormControl>

                      <Box mt="1">
                        <Button variant="link" colorScheme="brand.main" type="submit" isLoading={putDetails.isLoading}>
                          <HStack gap="1">
                            <Icon as={MdOutlineSave} /> <Text fontWeight="normal">Save</Text>
                          </HStack>
                        </Button>
                      </Box>
                    </HStack>
                  </form>
                </Box>
              ) : (
                <>
                  {isFetching || isLoading ? (
                    <Box pt="1.5">
                      <Skeleton w="164px" height="18px" />
                    </Box>
                  ) : (
                    <Text fontWeight="semibold" fontSize="xl">
                      {getName(selectedUser, { noEmail: true, default: 'N/A' })}
                    </Text>
                  )}

                  <AppAuth requiredAppAccess={AppAccess.UserWrite}>
                    <Button variant="link" colorScheme="brand.main" onClick={() => setIsEdit(true)}>
                      <HStack gap="1">
                        <Icon as={GoPencil} /> <Text fontWeight="normal">Edit</Text>
                      </HStack>
                    </Button>
                  </AppAuth>
                </>
              )}
            </HStack>
            <Text fontSize="md" color="blackAlpha.600">
              {selectedUser?.email || '-'}
            </Text>
            <Text fontSize="md" pb={'5'}>
              <Text as="span" fontWeight="semibold">
                SSO:
              </Text>{' '}
              <Text as="span" color="blackAlpha.600">
                {selectedUser?.sso_object_id || '-'}
              </Text>
            </Text>

            <Text fontSize={'larger'} fontWeight={'bold'}>
              Roles
            </Text>
            <VStack>
              {!isUserRolesLoading ? (
                (userRolesList ?? []).map((item, key) => <Text key={key}>{item?.role_display_name}</Text>)
              ) : (
                <Spinner />
              )}
            </VStack>
          </DrawerBody>

          <DrawerFooter p="0" m="4">
            <Button colorScheme="brand.main" onClick={handleClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default UserDrawer;
