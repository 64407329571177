import { Flex, HStack, Icon, Link, Text, VStack } from '@chakra-ui/react';
import { FC, createElement } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import brandColors from '../../app/theme/brandColors';
import { IconProps } from '../../app/types/appType';
import { NavItemModel } from './navItems';
import AppAuth from '../../features/AppAuth';
// import AppAuth from "../../features/AppAuth";

type IProps = {
  item: NavItemModel;
  isOpen: boolean;
};

const NavItem: FC<IProps> = ({ item, isOpen }) => {
  const location = useLocation();
  const isSelected = (href: string, strict?: boolean) => {
    if (strict) {
      return location.pathname === href;
    }
    return location.pathname === href || location.pathname.indexOf(href) === 0;
  };

  return (
    <VStack
      className={`navItem ${isSelected(item.href, true) ? 'selected' : ''}`}
      spacing={0}
      borderRadius={8}
      bg={isSelected(item.href, item.href === '/' ? true : false) ? 'blackAlpha.100' : undefined}
    >
      <Link
        py={3}
        px={4}
        as={RouterLink}
        to={item.href}
        w="100%"
        fontWeight={isSelected(item.href, true) ? 'medium' : 'normal'}
        whiteSpace="nowrap"
        h="14"
        borderRadius={8}
      >
        <Flex align="stretch" alignItems="center" h="100%" justifyContent={isOpen ? undefined : 'center'}>
          {item.icon ? (
            <Icon
              as={item.icon} //color="sideNavMenu.iconColor"
              w="5"
              h="5"
            />
          ) : (
            createElement<IconProps>(item.iconComponent!, {
              color: isSelected(item.href, true) ? brandColors.primary.teal[400] : 'black',
            })
          )}
          {isOpen && <Text ml={4}>{item.text}</Text>}
        </Flex>
      </Link>
      <VStack align="stretch" hidden={!isSelected(item.href)} overflowX="hidden" w="full" className="subNav" gap="0">
        {(item.subNav || []).map((sm, ii) =>
          sm.requiredAppAccess ? (
            <AppAuth key={sm.text} requiredAppAccess={sm.requiredAppAccess}>
              <SubNav sm={sm} ii={ii} isSelected={isSelected} isOpen={isOpen} />
            </AppAuth>
          ) : (
            <SubNav key={sm.text} sm={sm} ii={ii} isSelected={isSelected} isOpen={isOpen} />
          ),
        )}
      </VStack>
    </VStack>
  );
};

type SubNavProps = {
  sm: NavItemModel;
  ii: number;
  isSelected: (href: string, strict?: boolean) => boolean;
  isOpen: boolean;
};
const SubNav = ({ sm, ii, isSelected, isOpen }: SubNavProps) => {
  return (
    <Link
      key={ii}
      py={2}
      px={3}
      // pr={9}
      as={RouterLink}
      to={sm.href}
      w="100%"
      // whiteSpace="nowrap"
      borderColor="transparent"
      borderLeftWidth="3px"
      borderRadius={8}
      className={`${isSelected(sm.href) ? 'selected' : ''}`}
      h="14"
    >
      <HStack
        pl={isOpen ? 8 : undefined}
        spacing={4}
        alignItems="center"
        h="100%"
        justifyContent={isOpen ? undefined : 'center'}
        color={isSelected(sm.href) ? 'brandColors.primary.teal.400' : 'black'}
      >
        {sm.icon ? (
          <Icon
            as={sm.icon} //color="sideNavMenu.iconColor"
            w="5"
            h="5"
          />
        ) : (
          createElement<IconProps>(sm.iconComponent!, {
            color: isSelected(sm.href, true) ? brandColors.primary.teal[400] : 'black',
          })
        )}
        {isOpen && <Text noOfLines={isOpen ? undefined : 1}>{sm.text}</Text>}
      </HStack>
    </Link>
  );
};

export default NavItem;
