import { partnerApi } from '.';
import { PaginatedApiResult, PaginatedQueryParams } from '../../types';
import {
  AddTestEligibleParticipantModel,
  DeleteTestEligibleParticipantModel,
  TestEligibleParticipantModel,
  UpdateTestEligibleParticipantModel,
} from './types';

const baseUrl = '/eligibleparticipant';

const extendedApi = partnerApi.injectEndpoints({
  endpoints: builder => ({
    postEligibleParticipantTest: builder.mutation<
      { result: { eligible_participant_id: number } },
      AddTestEligibleParticipantModel
    >({
      query: body => ({
        url: baseUrl + '/test',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['EligibleParticipant'],
    }),

    getEligibleParticipantList: builder.query<
      PaginatedApiResult<TestEligibleParticipantModel>,
      PaginatedQueryParams<TestEligibleParticipantModel>
    >({
      query: params => ({
        url: baseUrl + '/test/list',
        params,
      }),
      providesTags: ['EligibleParticipant'],
    }),

    updateEligibleParticipant: builder.mutation<void, UpdateTestEligibleParticipantModel>({
      query: body => ({
        url: baseUrl + '/test',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['EligibleParticipant'],
    }),

    disableEligibleParticipant: builder.mutation<void, DeleteTestEligibleParticipantModel>({
      query: body => ({
        url: baseUrl + '/test',
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['EligibleParticipant'],
    }),
  }),
});

export const {
  usePostEligibleParticipantTestMutation,
  useGetEligibleParticipantListQuery,
  useUpdateEligibleParticipantMutation,
  useDisableEligibleParticipantMutation,
} = extendedApi;
