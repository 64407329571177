import { Box, Button, Grid, GridItem, Text, chakra } from '@chakra-ui/react';
import { FC } from 'react';
import { AddProductTypeStepProp, productTypeFields } from './helper';

import { useAddUserActivityMutation } from '../../../../../../app/services/partner/api/userActivity';
import { useAppSelector } from '../../../../../../app/state/hooks';
import { PARTNER_PAGE } from '../../../../../../app/constants/partnerPage';
import { PAGE_ACTIVITY } from '../../../../../../app/constants/pageActivity';

const Step2: FC<AddProductTypeStepProp> = ({ data, activeStep, setData, setActiveStep, handleSubmit, isLoading }) => {
  const [postAddUserActivity] = useAddUserActivityMutation();
  const { selectedPartnerProduct, activityList, pageList, userSessionId } = useAppSelector(s => s.user);

  const dashboard_page = pageList.find(i => i.page_name === PARTNER_PAGE.DASHBOARD);

  const product_type_created = activityList.find(i => i.activity_name === PAGE_ACTIVITY.PRODUCT_TYPE_CREATED);

  const handleLocalSubmit = () => {
    if (product_type_created && dashboard_page && userSessionId) {
      postAddUserActivity({
        user_session_id: userSessionId,
        ref_activity_id: product_type_created.ref_activity_id,
        ref_page_id: dashboard_page.ref_page_id,
        partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      })
        .unwrap()
        .then(res => {})
        .finally(() => {
          handleSubmit();
        });
    }
  };

  return (
    <>
      <Grid className="da-container" templateColumns="repeat(3, 1fr)" gap="1rem !important">
        <GridItem className="da-c-entry" colSpan={3}>
          <chakra.label htmlFor="product_type_name">{productTypeFields.product_type_name.label}</chakra.label>
          <Text>{data.product_type_name || '-'}</Text>
        </GridItem>
        <GridItem className="da-c-entry" colSpan={3}>
          <chakra.label htmlFor="product_type_desc">{productTypeFields.product_type_desc.label}</chakra.label>
          <Text>{data.product_type_desc || '-'}</Text>
        </GridItem>
      </Grid>

      <Box w="100%" display="flex">
        <Button onClick={() => setActiveStep(i => i - 1)} colorScheme="brand.main" isDisabled={isLoading}>
          Back
        </Button>
        <Button onClick={handleLocalSubmit} colorScheme="brand.main" ml="auto" isLoading={isLoading}>
          Submit
        </Button>
      </Box>
    </>
  );
};

export default Step2;
