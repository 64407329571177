import {
  Box,
  Spinner,
  Input,
  Flex,
  FormLabel,
  HStack,
  VStack,
  FormControl,
  Textarea,
  FormErrorMessage,
  CloseButton,
  Select,
  Button,
  Text,
  IconButton,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import RedAsterisk from '../../../../RedAsterisk';
import { AddRoleAppAccessModel } from '../../../../../app/services/partner/api/types';
import { useGetRefRoleDisplayListQuery } from '../../../../../app/services/partner/api/refRole';
import { useGetRefAppAccessDisplayListQuery } from '../../../../../app/services/partner/api/refAppAccess';
import { useAddRoleAppAccessMutation } from '../../../../../app/services/partner/api/roleAppAccess';
import { RefAppAccessDisplayModel } from '../../../../../app/services/partner/api/types';
import { useToast } from '@chakra-ui/react';

import { useAppSelector } from '../../../../../app/state/hooks';
import { PARTNER_PAGE } from '../../../../../app/constants/partnerPage';
import { PAGE_ACTIVITY } from '../../../../../app/constants/pageActivity';
import { useAddUserActivityMutation } from '../../../../../app/services/partner/api/userActivity';

type RoleAppAccessDetailsProps = {
  rolesUpdatedTime: string;
  appAccessesUpdatedTime: string;
};

const DefaultRoleAppAccess = {
  ref_role_id: 0,
  ref_app_access_list: [],
};

const defaultFormDataErrors = {
  ref_role_id: '',
};

type Option = {
  id: number;
  name: string;
};

const RoleAppAccessDetails = ({ rolesUpdatedTime, appAccessesUpdatedTime }: RoleAppAccessDetailsProps) => {
  const roles = useGetRefRoleDisplayListQuery();
  const appAccesses = useGetRefAppAccessDisplayListQuery();

  const [roleAppAccess, setRoleAppAccess] = useState<AddRoleAppAccessModel>(DefaultRoleAppAccess);
  const [errors, setErrors] = useState(defaultFormDataErrors);
  const [selectedOption, setSelectedOption] = useState<RefAppAccessDisplayModel | null>(null);
  const [selectedRefAppAccessList, setSelectedRefAppAccessList] = useState<RefAppAccessDisplayModel[]>([]);

  const { selectedPartnerProduct, activityList, pageList, userSessionId } = useAppSelector(s => s.user);
  const [postAddUserActivity] = useAddUserActivityMutation();

  const create_role_page = pageList.find(i => i.page_name === PARTNER_PAGE.CREATE_ROLE);
  const role_app_access_created = activityList.find(i => i.activity_name === PAGE_ACTIVITY.ROLE_APP_ACCESS_CREATED);

  const handleLogEvent = () => {
    if (create_role_page && role_app_access_created && userSessionId) {
      postAddUserActivity({
        user_session_id: userSessionId,
        ref_activity_id: role_app_access_created.ref_activity_id,
        ref_page_id: create_role_page.ref_page_id,
        partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      })
        .unwrap()
        .then(res => {});
    }
  };

  const toast = useToast();

  const [postRoleAppAccess, postRoleAppAccessDetails] = useAddRoleAppAccessMutation();

  const validate = () => {
    let newErrors = { ...errors };

    if (roleAppAccess.ref_role_id === 0) {
      newErrors.ref_role_id = 'Role is required';
    } else {
      newErrors.ref_role_id = '';
    }
    setErrors(newErrors);

    return Object.values(newErrors).every(error => error === '');
  };

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validate() && selectedRefAppAccessList.length > 0) {
      //call POST roleAppAccess
      postRoleAppAccess(roleAppAccess)
        .unwrap()
        .then(res => {
          console.log(`ROLE APP ACCESS SUCCESS: ${JSON.stringify(res)}`);
          toast({
            position: 'top-right',
            title: 'Success!',
            description: 'Role App Access saved',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
          onDeleteClick();
        })
        .catch(error => {
          console.log(`ROLE APP ACCESS ERROR: ${JSON.stringify(error)}`);
          toast({
            position: 'top-right',
            title: 'Role App Access Error',
            description: 'There was an error in saving the record',
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        })
        .finally(() => {
          handleLogEvent();
        });
    } else {
      if (selectedRefAppAccessList.length === 0) {
        toast({
          position: 'top-right',
          title: 'Role App Access',
          description: 'You need to select at least one App Access',
          status: 'info',
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  const handleOnStringPropertyChange = (prop_value: string, prop_name: string) => {
    setRoleAppAccess(prevState => ({ ...prevState, [prop_name]: prop_value }));
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = parseInt(event.target.value, 10);
    const selectedObj = (appAccesses.data ?? []).find(option => option.ref_app_access_id === selectedId) || null;

    setSelectedOption(selectedObj);

    if (selectedObj) {
      handleRefAppAccessAdd(selectedObj);
    }
  };

  const onDeleteClick = () => {
    setSelectedRefAppAccessList([]);
    setRoleAppAccess(DefaultRoleAppAccess);
    setSelectedOption(null);
  };

  type AppAccessBlockProps = {
    refAppAccess: RefAppAccessDisplayModel;
  };

  const AppAccessBlock = ({ refAppAccess }: AppAccessBlockProps) => {
    const onClose = () => {
      setSelectedRefAppAccessList(prevItems =>
        prevItems.filter(item => item.ref_app_access_id !== refAppAccess.ref_app_access_id),
      );
      console.log(`ROLE APP ACCESS for SAVING: ${JSON.stringify(roleAppAccess)}`);
    };

    return (
      <>
        <Flex
          justifyContent={'space-between'}
          key={refAppAccess.ref_app_access_id}
          pr={1}
          pl={2}
          py={1}
          mr={1}
          mb={1}
          style={{
            border: '1px solid #e2e8f0',
            backgroundColor: '#e2e8f0',
            fontWeight: 'bolder',
          }}
          rounded="md"
        >
          <Box pr="1">{refAppAccess.app_access_display_name}</Box>
          <Box>
            <CloseButton onClick={onClose} size={'sm'} />
          </Box>
        </Flex>
      </>
    );
  };

  const handleRefAppAccessAdd = (obj: RefAppAccessDisplayModel) => {
    // add selected refappaccess to the list
    setSelectedRefAppAccessList(prevItems => [...prevItems, obj]);
  };

  useEffect(() => {
    //re-load roles list
  }, [rolesUpdatedTime]);

  useEffect(() => {
    //re-load app access list
  }, [appAccessesUpdatedTime]);

  return (
    <>
      <VStack>
        <Box>
          <Text sx={{ fontWeight: 'bold', fontSize: '20' }}>Role App Access</Text>
        </Box>

        <VStack>
          <form onSubmit={handleSave}>
            <Box>
              <FormControl isInvalid={!!errors.ref_role_id}>
                <FormLabel fontSize={'smaller'} mb="-0.2" htmlFor="ref-role-id">
                  Choose Role
                  <RedAsterisk />
                </FormLabel>
                <Select
                  id="ref-role-id"
                  name="ref_role_id"
                  value={roleAppAccess.ref_role_id}
                  placeholder={roles.isLoading || roles.isFetching ? 'Loading...' : ''}
                  disabled={roles.isLoading || roles.isFetching}
                  onChange={e => {
                    handleOnStringPropertyChange(e.target.value, e.target.name);
                  }}
                >
                  <option key="0" value="">
                    Select an option
                  </option>
                  {(roles.data ?? []).map((o, i) => (
                    <option key={i} value={o.ref_role_id}>
                      {o.role_display_name}
                    </option>
                  ))}
                  ;
                </Select>
                <FormErrorMessage>{errors.ref_role_id}</FormErrorMessage>
              </FormControl>
            </Box>

            <Box pt="5">
              <FormControl>
                <FormLabel fontSize={'smaller'} mb="-0.2" htmlFor="ref-app-access-id">
                  Choose App Access
                  <RedAsterisk />
                </FormLabel>
                <Select
                  id="ref-app-access-id"
                  name="ref_app_access_id"
                  placeholder={appAccesses.isLoading || appAccesses.isFetching ? 'Loading...' : ''}
                  disabled={appAccesses.isLoading || appAccesses.isFetching}
                  onChange={handleSelectChange}
                  value={selectedOption?.ref_app_access_id ?? 0}
                >
                  <option key="0" value="">
                    Select an option
                  </option>
                  {(appAccesses.data ?? []).map((o, i) => (
                    <option key={i} value={o.ref_app_access_id}>
                      {o.app_access_display_name}
                    </option>
                  ))}
                  ;
                </Select>
              </FormControl>
            </Box>

            <Box mt="5" sx={{ border: '1px solid #e2e8f0;', minHeight: '100px', borderRadius: '5px' }}>
              <Flex gap="2" wrap="wrap" p="2">
                {(selectedRefAppAccessList || []).map((m, i) => (
                  <AppAccessBlock refAppAccess={m} />
                ))}
              </Flex>
            </Box>

            <Box display="flex" justifyContent={'flex-end'} pt="5">
              <Box display="flex" gap={2}>
                <Button size="sm" onClick={onDeleteClick}>
                  Delete
                </Button>

                <Button size="sm" colorScheme="brand.main" gap="2" px="5" type="submit">
                  {postRoleAppAccessDetails.isLoading ? <Spinner size={'xs'} /> : 'Save'}
                </Button>
              </Box>
            </Box>
          </form>
        </VStack>
      </VStack>
    </>
  );
};

export default RoleAppAccessDetails;
