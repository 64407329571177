import { Box, Heading, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { AppSize } from '../../../../app/constants';
import { AppAccess } from '../../../../app/constants/appAccesses';
import { useGetProductTypeListQuery } from '../../../../app/services/partner/api/refProductType';
import { RefProductTypeModel } from '../../../../app/services/partner/api/types';
import { SortType } from '../../../../app/services/types';
import { useAppSelector } from '../../../../app/state/hooks';
import { CustomTableHeader } from '../../../../app/types/appType';
import CustomTable from '../../../../components/CustomTable';
import AppAuth from '../../../../features/AppAuth';
import DashboardAdminPartnerTypeAfterSearch from './AfterSearch';

const InitialSortBy: SortType<RefProductTypeModel> = {
  id: 'product_type_name',
  desc: false,
};

const Header: CustomTableHeader<RefProductTypeModel> = [
  {
    Header: 'Product Type Name',
    accessor: 'product_type_name',
  },
  {
    Header: 'Product Type Description',
    accessor: 'product_type_desc',
    Cell: ({ row: { original } }: CellProps<RefProductTypeModel>) => {
      return <>{original.product_type_desc || '-'}</>;
    },
  },
  // {
  //   Header: "Action",
  //   Cell: DashboardAdminProductTypeActionCell,
  // },
];

const DashboardAdminPartnerType = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState(InitialSortBy);

  const { isSideNavOpen } = useAppSelector(s => s.app);

  const { data, isLoading, isFetching } = useGetProductTypeListQuery({
    page_number: pageNumber,
    page_size: pageSize,
    search_string: searchParams.get('stype') ?? '',
    sort_column: sortBy.id,
    sort_order: sortBy.desc ? 'desc' : 'asc',
  });

  const sideNavWidth =
    (isSideNavOpen ? AppSize.sideNav.widthNumber.open : AppSize.sideNav.widthNumber.close) / 2 + 'px';

  return (
    <VStack gap="6" h="100%">
      <Heading as="h2" fontSize="xl" fontWeight="semibold">
        Product Type
      </Heading>

      <Box className="dashboard-card">
        <CustomTable
          variant="table"
          isLoading={isLoading}
          isFetching={isFetching}
          data={data?.data ?? []}
          pageCount={data?.total_pages || 0}
          pageSize={data?.page_size || pageSize}
          totalRecords={data?.total_records || 0}
          pageIndex={pageNumber - 1}
          headers={Header}
          search={searchParams.get('stype') ?? ''}
          initialState={{ sortBy: [InitialSortBy] }}
          manualSortBy
          disableSortRemove
          disableHoverHighlight
          showNoRecords
          onPageChange={index => {
            setPageNumber(index + 1);
          }}
          onPageSizeChange={size => {
            setPageNumber(1);
            setPageSize(size);
          }}
          onPageSearchDebounce={400}
          onPageSearch={search => {
            setPageNumber(1);

            setSearchParams(
              prev => {
                if (search) prev.set('stype', search);
                else prev.delete('stype');
                return prev;
              },
              { replace: true },
            );
          }}
          onSort={sort => {
            if (sort[0]) {
              setPageNumber(1);
              setSortBy(sort[0]);
            }
          }}
          styles={{
            loadingStyle: 'overlay',
            pagination: { justifyContent: 'start' },
            header: { justifyContent: 'left' },
            tableContainer: {
              sx: {
                maxW: `calc(50vw - 4.25rem - ${sideNavWidth})`,
                overflow: 'auto',
                table: {
                  borderCollapse: 'separate',
                  borderSpacing: '0',
                  'thead > tr': {
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    zIndex: 2,
                    height: 'auto',
                    bg: 'white',
                  },
                  // tr: {
                  //   "th:last-child": {
                  //     position: "sticky",
                  //     right: "0px",
                  //     zIndex: 2,
                  //     bg: "white",
                  //     w: "90px",
                  //     borderLeft: "1px",
                  //     borderColor: "gray.100",
                  //   },
                  //   "td:last-child": {
                  //     position: "sticky",
                  //     right: "0px",
                  //     zIndex: 2,
                  //     bg: "white",
                  //     w: "90px",
                  //     borderLeft: "1px",
                  //     borderColor: "gray.100",
                  //   },
                  // },
                },
              },
            },
          }}
          manual={true}
          // onRowClick={(row) => onClickRow(row)}
          extraComponents={{
            // TODO
            afterSearch: (
              <AppAuth requiredAppAccess={AppAccess.PartnerWrite}>
                <DashboardAdminPartnerTypeAfterSearch />
              </AppAuth>
            ),
            // afterPagination: <ExportAsCsvButton />,
          }}
        />
      </Box>
    </VStack>
  );
};

export default DashboardAdminPartnerType;
