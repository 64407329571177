import { StyleFunctionProps } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import brandColors from '../brandColors';

const SideNav = (props: StyleFunctionProps) => ({
  '#SideNav': {
    height: '100%',
    bgColor: brandColors.primary.whiteBlack.white,
    '.navItem': {
      // svg: {
      //   color: mode(brandColors.primary.whiteBlack.black, brandColors.primary.whiteBlack[100])(props),
      // },
      a: {
        color: mode(brandColors.primary.whiteBlack.black, brandColors.primary.whiteBlack[100])(props),
        _hover: {
          textDecoration: 'none',
          bgColor: mode('blackAlpha.100', 'whiteAlpha.200')(props),
        },
      },
      '&.selected': {
        '& > a': {
          color: mode(brandColors.primary.teal[400], brandColors.primary.teal[100])(props),
          // bgColor: mode("blackAlpha.200", "whiteAlpha.400")(props),
        },
        '& > .subNav': {
          //bgColor: "red",
          // bgColor: mode("blackAlpha.200", "whiteAlpha.400")(props),
          'a.selected': {
            color: mode(brandColors.primary.teal[400], brandColors.primary.teal[100])(props),
            // bgColor: mode("blackAlpha.50", "whiteAlpha.100")(props),
            // borderColor: mode(brandColors.primary.teal[400], brandColors.primary.teal[100])(props), // TODO discuss
          },
        },
      },
    },
  },
});
export default SideNav;
