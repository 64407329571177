import PartnerContextProvider, { usePartnerContext, PartnerInitialSortBy } from './Context';
import { Box, Heading, Flex, VStack, Text } from '@chakra-ui/react';
import { useAppSelector } from '../../app/state/hooks';
import { useSearchParams } from 'react-router-dom';
import { CustomTableHeader } from '../../app/types/appType';
import { convertUtcToLocal } from '../../app/helpers/dateHelper';
import { PartnerModel } from '../../app/services/partner/api/types';
import { CellProps } from 'react-table';
import CustomTable from '../../components/CustomTable';
import { AppSize } from '../../app/constants';
import ExportAsCsvButton from './Buttons/ExportAsCsvButton';
import UserCountContainer from '../UserCountContainer';
import OptionsButton from './OptionsButton';
import PartnerDetailsButton from './PartnerDetailsModal/PartnerDetailsButton';
import AddPartnerButton from './Buttons/AddPartnerButton';

import { useAddUserActivityMutation } from '../../app/services/partner/api/userActivity';
import { useEffect } from 'react';
import { PARTNER_PAGE } from '../../app/constants/partnerPage';
import { PAGE_ACTIVITY } from '../../app/constants/pageActivity';

const PartnerList = () => {
  return (
    <PartnerContextProvider>
      <PartnersChild />
    </PartnerContextProvider>
  );
};

const Header: CustomTableHeader<PartnerModel> = [
  {
    Header: 'Partner Name',
    accessor: 'partner_name',
    Cell: ({ row: { original } }: CellProps<PartnerModel>) => {
      const { isLoreHealthAdmin } = useAppSelector(s => s.user);

      return (
        <Flex width={'200px'} direction={'column'}>
          {isLoreHealthAdmin ? <PartnerDetailsButton partner={original} /> : <Text>{original.partner_name}</Text>}
        </Flex>
      );
    },
  },
  {
    Header: 'Product Type',
    accessor: 'product_type_name',
    isSortable: false,
  },

  {
    Header: 'Partner TIN',
    accessor: 'partner_tin',
  },
  {
    Header: 'Products',
    isSortable: false,
    Cell: ({ row: { original } }: CellProps<PartnerModel>) => {
      return <UserCountContainer count={original.partner_product_count} />;
    },
  },
  {
    Header: 'Doing Business as',
    accessor: 'doing_business_as',
  },
  {
    Header: 'Contract Start Date',
    Cell: ({ row: { original } }: CellProps<PartnerModel>) => {
      return <>{convertUtcToLocal(original.contract_start_dt, 'YYYY-MM-D') || '-'}</>;
    },
  },
  {
    Header: 'Contract End Date',
    Cell: ({ row: { original } }: CellProps<PartnerModel>) => {
      return <>{convertUtcToLocal(original.contract_end_dt, 'YYYY-MM-D') || '-'}</>;
    },
  },
  {
    Header: 'Action',
    Cell: OptionsButton,
  },
];

const PartnersChild = () => {
  const { pageNumber, pageSize, sortBy, setPageNumber, setPageSize, setSortBy, isLoading, isFetching, data } =
    usePartnerContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const { logonUser } = useAppSelector(s => s.user);
  const { isSideNavOpen } = useAppSelector(s => s.app);

  const [postAddUserActivity] = useAddUserActivityMutation();
  const { selectedPartnerProduct, activityList, pageList, userSessionId } = useAppSelector(s => s.user);

  const sideNavWidth = isSideNavOpen ? AppSize.sideNav.width.open : AppSize.sideNav.width.close;

  const partners_page = pageList.find(i => i.page_name === PARTNER_PAGE.PARTNERS);
  const partners_clicked = activityList.find(i => i.activity_name === PAGE_ACTIVITY.PARTNERS_CLICKED);

  useEffect(() => {
    if (partners_clicked && partners_page && userSessionId) {
      postAddUserActivity({
        user_session_id: userSessionId,
        ref_activity_id: partners_clicked.ref_activity_id,
        ref_page_id: partners_page.ref_page_id,
        partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      })
        .unwrap()
        .then(res => {});
    }
  }, []);

  return (
    <>
      <VStack gap="6">
        <Heading as="h2" fontSize="xl" fontWeight="semibold">
          Partners
        </Heading>

        <Box bg="white" shadow="base" p="4" rounded="md" minW="container.md">
          <CustomTable
            variant="table"
            isLoading={isLoading}
            isFetching={isFetching}
            data={data?.data ?? []}
            pageCount={data?.total_pages || 0}
            pageSize={data?.page_size || pageSize}
            totalRecords={data?.total_records || 0}
            pageIndex={pageNumber - 1}
            headers={Header}
            search={searchParams.get('spartner') ?? ''}
            initialState={{ sortBy: [PartnerInitialSortBy] }}
            manualSortBy
            disableSortRemove
            disableHoverHighlight
            showNoRecords
            onPageChange={index => {
              setPageNumber(index + 1);
            }}
            onPageSizeChange={size => {
              setPageNumber(1);
              setPageSize(size);
            }}
            onPageSearchDebounce={400}
            onPageSearch={search => {
              setPageNumber(1);

              setSearchParams(
                prev => {
                  if (search) prev.set('spartner', search);
                  else prev.delete('spartner');
                  return prev;
                },
                { replace: true },
              );
            }}
            onSort={sort => {
              if (sort[0]) {
                setPageNumber(1);
                setSortBy(sort[0]);
              }
            }}
            styles={{
              loadingStyle: 'overlay',
              pagination: { justifyContent: 'start' },
              header: { justifyContent: 'left' },
              tableContainer: {
                sx: {
                  maxW: `calc(100vw - 5rem - ${sideNavWidth})`,
                  overflow: 'auto',
                  table: {
                    borderCollapse: 'separate',
                    borderSpacing: '0',
                    'thead > tr': {
                      position: 'sticky',
                      left: 0,
                      top: 0,
                      zIndex: 2,
                      height: 'auto',
                      bg: 'white',
                    },
                  },
                },
              },
            }}
            manual={true}
            // onRowClick={(row) => onClickRow(row)}
            extraComponents={{
              // TODO
              afterPagination: <ExportAsCsvButton />,
              afterSearch: <AddPartnerButton />,
            }}
          />
        </Box>
      </VStack>
    </>
  );
};

export default PartnerList;
