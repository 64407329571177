import { AppAuthRequiredAppAccess } from '../constants/appAccesses';
import { UserRoleAppAccessModel } from '../services/partner/api/types';
import { useAppSelector } from '../state/hooks';

export function useIsUserHasRequiredAppAccess(requiredAppAccess: AppAuthRequiredAppAccess): boolean {
  const { currentUserUserRole } = useAppSelector(s => s.userRole);
  return checkUserHasRequiredAppAccess(currentUserUserRole ?? [], requiredAppAccess);
}

type ReturnType = (requiredAppAccess: AppAuthRequiredAppAccess) => boolean;
export function useIsUserHasRequiredAppAccessFunc(): ReturnType {
  const { currentUserUserRole } = useAppSelector(s => s.userRole);
  return (requiredAppAccess: AppAuthRequiredAppAccess) =>
    checkUserHasRequiredAppAccess(currentUserUserRole ?? [], requiredAppAccess);
}

export const checkUserHasRequiredAppAccess = (
  currentUserUserRole: UserRoleAppAccessModel[],
  requiredAppAccess: AppAuthRequiredAppAccess,
) => {
  const inRole =
    (currentUserUserRole.length && requiredAppAccess.toLocaleString() === '*') ||
    (typeof requiredAppAccess === 'string' &&
      currentUserUserRole.some(s => s.app_access_name === requiredAppAccess && s.access_flag)) ||
    (Array.isArray(requiredAppAccess) &&
      currentUserUserRole.some(s => requiredAppAccess.includes(s.app_access_name as any) && s.access_flag));

  return inRole;
};
