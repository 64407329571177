import { partnerApi } from '.';
import { PaginatedApiResult, PaginatedQueryParams } from '../../types';
import {
  RefRoleModel,
  UpdateRefRoleModel,
  RefRoleDisplayModel,
  RefRoleDisplayFilterModel,
  AddRefRoleModel,
} from './types';

const baseUrl = '/refrole';

const extendedApi = partnerApi.injectEndpoints({
  endpoints: build => ({
    getInitialRefRolesUponInvitation: build.query<RefRoleDisplayModel[], void>({
      query: () => ({
        url: baseUrl + '/user-invitation/initial/list',
      }),
      providesTags: ['Role'],
    }),

    addRefRole: build.mutation<void, AddRefRoleModel>({
      query: body => ({
        body,
        url: baseUrl,
        method: 'POST',
      }),
      invalidatesTags: ['Role'],
    }),

    getRefRoleDisplayList: build.query<RefRoleDisplayModel[], void>({
      query: () => ({
        url: baseUrl + '/display/list',
      }),
      providesTags: ['Role'],
    }),
    getRefRoleDisplayFilterList: build.query<RefRoleDisplayFilterModel[], void>({
      query: () => ({
        url: baseUrl + '/display/list',
      }),
      transformResponse: (response: RefRoleDisplayFilterModel[]) =>
        response.map(m => ({ ...m, filter_header: 'Role' })),
      providesTags: ['Role'],
    }),
    getInitialRolesByRolePrefix: build.query<RefRoleDisplayModel[], string>({
      query: prefix => ({
        url: baseUrl + `/role/list/${prefix}`,
      }),
      providesTags: ['Role'],
    }),
    getRefRoles: build.query<
      PaginatedApiResult<RefRoleModel>,
      PaginatedQueryParams<RefRoleModel> & { partner_product_id: number }
    >({
      query: params => ({
        url: baseUrl + `/list`,
        params,
      }),
      providesTags: ['Role'],
    }),

    getRefRoleByPk: build.query<RefRoleModel, number>({
      query: id => baseUrl + `/${id}`,
    }),

    updateRefRoleByPk: build.mutation<void, UpdateRefRoleModel & { id: number }>({
      query: ({ id, ...body }) => ({
        url: baseUrl + `/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Role'],
    }),
  }),
});

export const {
  useGetRefRoleDisplayListQuery,
  useGetRefRoleDisplayFilterListQuery,
  useGetInitialRefRolesUponInvitationQuery,
  useGetInitialRolesByRolePrefixQuery,
  useGetRefRolesQuery,
  useLazyGetRefRoleByPkQuery,
  useUpdateRefRoleByPkMutation,
  useAddRefRoleMutation,
} = extendedApi;
