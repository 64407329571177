import { GoCheck } from 'react-icons/go';
import { CloseButton } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/hooks';
import { useAddUserRoleAccessRequestDetailMutation } from '../../../../app/services/partner/api/userRoleAccessRequest';
import {
  Modal,
  Box,
  Heading,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Button,
  useToast,
  Text,
  Tooltip,
  IconButton,
  Spinner,
  Flex,
} from '@chakra-ui/react';

import { useAppSelector } from '../../../../app/state/hooks';
import { PARTNER_PAGE } from '../../../../app/constants/partnerPage';
import { PAGE_ACTIVITY } from '../../../../app/constants/pageActivity';
import { useAddUserActivityMutation } from '../../../../app/services/partner/api/userActivity';

interface AccessRequestButtonProps {
  id: number;
  user: string;
  role: string;
  onDone: (num: number) => void;
}

const ApproveAccessRequestButton = ({ id, user, role, onDone }: AccessRequestButtonProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [post, postDetails] = useAddUserRoleAccessRequestDetailMutation();
  const toast = useToast();

  const { selectedPartnerProduct, activityList, pageList, userSessionId } = useAppSelector(s => s.user);
  const [postAddUserActivity] = useAddUserActivityMutation();

  const create_role_page = pageList.find(i => i.page_name === PARTNER_PAGE.CREATE_ROLE);
  const role_request_approved = activityList.find(i => i.activity_name === PAGE_ACTIVITY.ROLE_REQUEST_APPROVED);

  const handleLogEvent = () => {
    if (create_role_page && role_request_approved && userSessionId) {
      postAddUserActivity({
        user_session_id: userSessionId,
        ref_activity_id: role_request_approved.ref_activity_id,
        ref_page_id: create_role_page.ref_page_id,
        partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      })
        .unwrap()
        .then(res => {});
    }
  };

  const handleOpen = () => {
    onOpen();
  };

  const handleCancel = () => {
    onClose();
  };

  const handleConfirm = (id: number) => {
    post({
      user_role_access_request_id: id,
      remarks: '',
      status_name: 'approved',
    })
      .unwrap()
      .then(res => {
        handleLogEvent();
        toast({
          position: 'top-right',
          title: 'Approved!',
          description: 'Access request approved!',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      })
      .catch(error => {
        toast({
          position: 'top-right',
          title: 'Request Access Approval Failed',
          description: error.data.error_message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => {
        handleCancel();
        onDone(1);
      });
  };

  return (
    <>
      <Tooltip label="Approve">
        <IconButton
          size={'xs'}
          onClick={handleOpen}
          style={{ backgroundColor: '#3794AA', color: '#fff' }}
          icon={<GoCheck />}
          aria-label="Approve"
        />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />
        <ModalContent position="absolute" top="30%" left="37%">
          <ModalBody p="4" display="flex" flexDir="column" gap="6">
            <Flex justifyContent={'space-between'}>
              <Box pt={'1.5'}>
                <Heading as="h3" size="sm">
                  Approval Confirmation
                </Heading>
              </Box>

              <Box>
                <IconButton
                  variant="link"
                  color="black"
                  size="xxs"
                  aria-label="close modal"
                  onClick={onClose}
                  icon={<CloseButton />}
                />
              </Box>
            </Flex>

            <Box display="flex" gap="4" justifyContent={'center'}>
              <Text>
                You are approving {user} for the role {role}. Is this correct?
              </Text>
            </Box>
            <Box display="flex" justifyContent={'flex-end'}>
              <Box display="flex" gap={2}>
                <Button size="sm" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button size="sm" colorScheme="brand.main" gap="2" px="5" onClick={() => handleConfirm(id)}>
                  {postDetails.isLoading ? <Spinner size={'sm'} /> : 'Confirm'}
                </Button>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ApproveAccessRequestButton;
