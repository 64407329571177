import * as Yup from 'yup';

export const FirstNameSchema = Yup.string()
  .label('First Name')
  .max(100)
  .required()
  .test((str, { createError }) => {
    const regex = /^[a-zA-Z\- ]+$/;
    return str && !regex.test(str)
      ? createError({
          message: 'First Name should only contain letters',
        })
      : true;
  });

export const LastNameSchema = Yup.string()
  .label('Last Name')
  .max(100)
  .required()
  .test((str, { createError }) => {
    const regex = /^[a-zA-Z\- ]+$/;
    return str && !regex.test(str)
      ? createError({
          message: 'Last Name should only contain letters',
        })
      : true;
  });
