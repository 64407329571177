import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { Button } from '@chakra-ui/button';
import { AddIcon } from '@chakra-ui/icons';
import { useDisclosure } from '@chakra-ui/hooks';
import { useState } from 'react';
import { AddReportModel } from '../../../app/services/partner/api/types';

import { useToast } from '@chakra-ui/react';
import ReportDetails from './ReportDetails';
import RolesSelection from './RolesSelection';

const initialReportValues: AddReportModel = {
  report_desc: '',
  report_name: '',
  report_url: '',
  looker_report_guid: '',
  list_ref_role_id: [],
};

const CreateReportButton = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [report, setReport] = useState<AddReportModel>(initialReportValues);
  const [addReportStage, setAddReportStage] = useState<number>(1);
  const toast = useToast();

  const handleOpen = () => {
    onOpen();
  };

  const handleCancel = () => {
    setReport(initialReportValues);
    setAddReportStage(1);
    onClose();
  };

  const handleAddReportStageChange = (stage: number) => {
    setAddReportStage(stage);
  };

  const updateReport = (updatedReport: AddReportModel) => {
    setReport(prev => ({ ...prev, ...updatedReport }));
  };

  let content: JSX.Element | null = null;

  switch (addReportStage) {
    case 1:
      content = (
        <ReportDetails
          report={report}
          updateReport={updateReport}
          handleCancel={handleCancel}
          onClose={onClose}
          handleAddReportStageChange={handleAddReportStageChange}
        />
      );
      break;
    case 2:
      content = <RolesSelection report={report} handleCancel={handleCancel} />;
      break;
  }

  return (
    <>
      <Button size="sm" colorScheme="brand.main" gap="2" px="5" onClick={handleOpen}>
        <AddIcon fontSize="xx-small" />
        Add Report
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="3xl" closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent position="absolute" top="20%" left="30%">
          <ModalBody p="4" display="flex" flexDir="column" gap="3">
            {content}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateReportButton;
