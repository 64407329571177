import { Box, Text } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import MyRolesView from './MyRolesView';
import RoleDetailsView from './RoleDetailsView';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/state/hooks';

import { PARTNER_PAGE } from '../../../app/constants/partnerPage';
import { PAGE_ACTIVITY } from '../../../app/constants/pageActivity';
import { useAddUserActivityMutation } from '../../../app/services/partner/api/userActivity';

const MyRoles = () => {
  const [view, setView] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedRequestId, setSelectedRequestId] = useState<number>(0);

  const { selectedPartnerProduct, activityList, pageList, userSessionId } = useAppSelector(s => s.user);
  const [postAddUserActivity] = useAddUserActivityMutation();

  const my_roles_page = pageList.find(i => i.page_name === PARTNER_PAGE.MY_ROLES);
  const my_roles_clicked = activityList.find(i => i.activity_name === PAGE_ACTIVITY.MY_ROLES_CLICKED);

  useEffect(() => {
    if (my_roles_page && my_roles_clicked && userSessionId) {
      postAddUserActivity({
        user_session_id: userSessionId,
        ref_activity_id: my_roles_clicked.ref_activity_id,
        ref_page_id: my_roles_page.ref_page_id,
        partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      })
        .unwrap()
        .then(res => {});
    }
  }, []);

  useEffect(() => {
    const requestId = searchParams.get('requestId');

    if (requestId) {
      const finalRequestId = parseInt(requestId, 10);

      if (finalRequestId > 0) {
        setSelectedRequestId(finalRequestId);
        setView(1);
      }
    }
  }, [searchParams]);

  const updateView = (newValue: number) => {
    setView(newValue);
  };

  let content: JSX.Element | null = null;

  switch (view) {
    case 0:
      content = <MyRolesView updateView={updateView} />;
      break;
    case 1:
      content = <RoleDetailsView updateView={updateView} selectedRequestId={selectedRequestId} />;
      break;
  }

  return (
    <>
      <Box pb={'5'}>
        <Text sx={{ fontWeight: 'bold', fontSize: '25' }}>My Roles</Text>
      </Box>
      <Box pb={'5'}>
        <Box>{content}</Box>
      </Box>
    </>
  );
};

export default MyRoles;
