import DashboardAdminPartnerProductsAfterSearchAdd from './Add';

const DashboardAdminPartnerProductsAfterSearch = () => {
  return (
    <>
      <DashboardAdminPartnerProductsAfterSearchAdd />
    </>
  );
};

export default DashboardAdminPartnerProductsAfterSearch;
