import { Box, Table, TableContainer, Tbody, Td, Text, Th, Tr } from '@chakra-ui/react';
import { convertUtcToLocal } from '../../app/helpers/dateHelper';
import { PartnerProductProviderModel } from '../../app/services/partner/api/types';

const ProviderDetails = ({ entry }: { entry: Partial<PartnerProductProviderModel> }) => {
  return (
    <Box py="1" borderY="1px" borderColor="blackAlpha.400">
      <TableContainer>
        <Table variant="simple">
          <Tbody
            sx={{
              '& th, td': {
                borderBottom: 'none',
                py: '2',
                px: '2',
              },
              '& th': {
                textTransform: 'none',
                color: 'blackAlpha.700',
                fontSize: 'md',
                lineHeight: '24px',
                letterSpacing: '-1px',
                fontWeight: 'semibold',
              },
              '& td': {
                color: 'blackAlpha.600',
              },
              tr: {
                'th:first-child': {
                  pl: '0',
                },
                'td:first-child': {
                  pl: '0',
                },
                'th:last-child': {
                  pr: '0',
                },
                'td:last-child': {
                  pr: '0',
                },
              },
            }}
          >
            <Tr>
              <Th>
                <Text fontWeight="extrabold">CMS</Text>
              </Th>
              <Th></Th>
              <Th>
                <Text fontWeight="extrabold">NPI</Text>
              </Th>
              <Th></Th>
            </Tr>
            <Tr>
              <Th>First Name</Th>
              <Td>{entry.provider_first_name || '-'}</Td>
              <Th>Address</Th>
              <Td whiteSpace="initial">{entry.npireg_address_1 || '-'}</Td>
            </Tr>
            <Tr>
              <Th>Middle Name</Th>
              <Td>{entry.provider_middle_name || '-'}</Td>
              <Th>City</Th>
              <Td>{entry.npireg_city || '-'}</Td>
            </Tr>
            <Tr>
              <Th>Last Name</Th>
              <Td>{entry.provider_last_name || '-'}</Td>
              <Th>State</Th>
              <Td>{entry.npireg_state || '-'}</Td>
            </Tr>
            <Tr>
              <Th>Gender</Th>
              <Td>{entry.provider_gender || '-'}</Td>
              <Th>Postal Code</Th>
              <Td>{entry.npireg_postal_code || '-'}</Td>
            </Tr>
            <Tr>
              <Th>State</Th>
              <Td>{entry.provider_state_cd || '-'}</Td>
              <Th>Taxonomy (Specialty)</Th>
              <Td whiteSpace="initial">{entry.npireg_taxonomy || '-'}</Td>
            </Tr>
            <Tr>
              <Th>Pecos ID</Th>
              <Td>{entry.provider_pecos_id || '-'}</Td>
              <Th>Enumeration Date (NPI Assigned)</Th>
              <Td>{convertUtcToLocal(entry.npireg_enumeration_date, 'MM-D-YYYY') || '-'}</Td>
            </Tr>
            <Tr>
              <Th>Enrollment ID</Th>
              <Td>{entry.provider_enrollment_id || '-'}</Td>
              <Th>Last Updated</Th>
              <Td>{convertUtcToLocal(entry.npireg_last_updated, 'MM-D-YYYY') || '-'}</Td>
            </Tr>
            <Tr>
              <Th>Organization</Th>
              <Td>{entry.provider_org_name || '-'}</Td>
              <Th>Telephone</Th>
              <Td>{entry.npireg_telephone_number || '-'}</Td>
            </Tr>
            <Tr>
              <Th>Provider Type</Th>
              <Td>{entry.provider_provider_type_cd || '-'}</Td>
              <Th></Th>
              <Td></Td>
            </Tr>
            <Tr>
              <Th>Provider Description</Th>
              <Td whiteSpace="initial">{entry.provider_provider_type_desc || '-'}</Td>
              <Th></Th>
              <Td></Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ProviderDetails;
