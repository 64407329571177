import { partnerApi } from '.';
import { PaginatedApiResult, PaginatedQueryParams } from '../../types';
import { AddRoleAppAccessModel } from './types';

const baseUrl = '/role-app-access';

const extendedApi = partnerApi.injectEndpoints({
  endpoints: build => ({
    addRoleAppAccess: build.mutation<void, AddRoleAppAccessModel>({
      query: body => ({
        body,
        url: baseUrl + '/list',
        method: 'POST',
      }),
      invalidatesTags: ['Role'],
    }),
  }),
});

export const { useAddRoleAppAccessMutation } = extendedApi;
