import { FC, ReactNode, useEffect } from 'react';
import { Navigate, Route, Routes, To, useLocation, useNavigate } from 'react-router-dom';
import { addVisitedUrl } from '../app/slices/appSlice';
import { useAppDispatch, useAppSelector } from '../app/state/hooks';
import AppAuth from '../features/AppAuth';
import UnAuthorized from './UnAuthorized';

import { AppAccess, AppAccessName, AppAuthRequiredAppAccess } from '../app/constants/appAccesses';
import AcoProviderList from './AcoProviderList';
import Dashboard from './Dashboard';
import Roles from './Roles';
import ExploreRoles from './Roles/ExploreRoles';
import AccessApproval from './Roles/AccessApproval';
import MyRoles from './Roles/MyRoles';
import ExploreReports from './Reports/ExploreReports';
import TestEligibleParticipant from './TestEligibleParticipant';
import Users from './Users';
import Home from './Home';
import CreateRole from './Roles/CreateRole';
import PartnerProductList from './PartnerProducts';
import PartnerList from './Partners';

type Props = {};

const Routing = (props: Props) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { hasMultiplePartnerProducts } = useAppSelector(s => s.user);

  const isTestOrDev = process.env.REACT_APP_ENV === 'TEST' || process.env.REACT_APP_ENV === 'DEV';

  useEffect(() => {
    dispatch(addVisitedUrl(location.pathname));
  }, [location.pathname]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        {hasMultiplePartnerProducts && <Route path="/dashboard" element={<Dashboard />} />}

        <Route
          path="/aco-provider-list"
          element={<ProtectedRoute Component={AcoProviderList} requiredAppAccess={AppAccess.Provider} />}
        />
        <Route
          path="/partner-products"
          element={
            <ProtectedRoute
              Component={PartnerProductList}
              requiredAppAccess={AppAccess.LoreHealthAndPartnerAdminAccess}
            />
          }
        />

        <Route
          path="/partners"
          element={
            <ProtectedRoute Component={PartnerList} requiredAppAccess={AppAccess.LoreHealthAndPartnerAdminAccess} />
          }
        />
        <Route path="/roles" element={<Roles />}>
          <Route index element={<Navigate to="explore-roles" replace />} />
          <Route
            path="explore-roles"
            element={<ProtectedRoute Component={ExploreRoles} requiredAppAccess={AppAccess.Roles} />}
          />
          <Route path="my-roles" element={<ProtectedRoute Component={MyRoles} requiredAppAccess={AppAccess.Roles} />} />
          <Route
            path="create-role"
            element={<ProtectedRoute Component={CreateRole} requiredAppAccess={AppAccess.RolesWrite} />}
          />
          <Route
            path="access-approval"
            element={<ProtectedRoute Component={AccessApproval} requiredAppAccess={AppAccess.LoreHealthAdminAccess} />}
          />
        </Route>

        <Route
          path="/users"
          element={
            <ProtectedRoute Component={Users} requiredAppAccess={AppAccess.LoreAdminPartnerAdminAndTeamAccess} />
          }
        />
        <Route path="/unauthorized" element={<UnAuthorized />} />
        <Route
          path="/report-hub"
          element={<ProtectedRoute Component={ExploreReports} requiredAppAccess={AppAccess.ReportHub} />}
        />

        {/* <TestAddEligibleParticipant /> */}
        <Route path="/test-eligible-participant" element={<Navigate to="add" />} />
        <Route path="/test-eligible-participant/:tab" element={<TestEligibleParticipant />} />

        <Route path="*" element={<strong>Page not found</strong>} />
      </Routes>
    </>
  );
};

export default Routing;

type RedirectorProps = {
  navigations: { navigateTo: string; requiredAppAccess: Array<AppAccessName> }[];
  replace?: boolean;
};
const Redirector = ({ navigations, replace }: RedirectorProps) => {
  const navigate = useNavigate();
  const { currentUserUserRole } = useAppSelector(s => s.userRole);

  useEffect(() => {
    if (currentUserUserRole) {
      if (navigations.length < 1) navigate('/unauthorized'); // make sure to add a navigation

      for (let i = 0; i < navigations.length; i++) {
        const { navigateTo, requiredAppAccess } = navigations[i];
        if (currentUserUserRole.some(s => requiredAppAccess.includes(s.app_access_name as any) && s.access_flag)) {
          return navigate(navigateTo, { replace });
        }
      }
      return navigate('/unauthorized');
    }
  }, [navigate, currentUserUserRole, navigations]);

  return <></>;
};

type IProtectedRouteProps = {
  children?: ReactNode;
  Component: React.ComponentType;
  requiredAppAccess: AppAuthRequiredAppAccess;
  navigateTo?: To;
};
const ProtectedRoute: FC<IProtectedRouteProps> = ({ Component, requiredAppAccess, navigateTo }): any => {
  const navigate = useNavigate();

  const onUnAuthorized = () => {
    navigate(navigateTo ?? '/unauthorized');
  };
  return (
    <AppAuth requiredAppAccess={requiredAppAccess} onUnAuthorized={onUnAuthorized}>
      <Component />
    </AppAuth>
  );
};
