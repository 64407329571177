import { Box } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { useLazyGetPartnerProductProviderListQuery } from '../../app/services/partner/api/partnerProductProvider';
import { useAppSelector } from '../../app/state/hooks';
import CsvDownloadButton, { CsvDownloadButtonProps } from '../../components/CsvDownloadButton';

import { useAddUserActivityMutation } from '../../app/services/partner/api/userActivity';
import { PARTNER_PAGE } from '../../app/constants/partnerPage';
import { PAGE_ACTIVITY } from '../../app/constants/pageActivity';

const transform: CsvDownloadButtonProps['transform'] = [
  {
    Header: 'NPI',
    accessor: 'provider_npi',
  },
  {
    Header: 'Last Name',
    accessor: 'provider_last_name',
  },
  {
    Header: 'First Name',
    accessor: 'provider_first_name',
  },
  {
    Header: 'Middle Name',
    accessor: 'provider_middle_name',
  },
  {
    Header: 'Gender',
    accessor: 'provider_gender',
  },
  {
    Header: 'Pecos ID',
    accessor: 'provider_pecos_id',
  },
  {
    Header: 'Enrollment ID',
    accessor: 'provider_enrollment_id',
  },
  {
    Header: 'Provider Type CD',
    accessor: 'provider_provider_type_cd',
  },
  {
    Header: 'Provider Type Desc',
    accessor: 'provider_provider_type_desc',
  },
  {
    Header: 'State CD',
    accessor: 'provider_state_cd',
  },
  {
    Header: 'Partner Name',
    accessor: 'partner_name',
  },
];

const ExportAsCsvButton = () => {
  const [searchParams] = useSearchParams();
  const toast = useToast();
  const [get] = useLazyGetPartnerProductProviderListQuery();

  const [postAddUserActivity] = useAddUserActivityMutation();
  const { selectedPartnerProduct, activityList, pageList, userSessionId } = useAppSelector(s => s.user);

  const aco_provider_list_page = pageList.find(i => i.page_name === PARTNER_PAGE.ACO_PROVIDER_LIST);
  const export_provider = activityList.find(i => i.activity_name === PAGE_ACTIVITY.EXPORT_PROVIDER);

  const handleLogEvent = () => {
    if (export_provider && aco_provider_list_page && userSessionId) {
      postAddUserActivity({
        user_session_id: userSessionId,
        ref_activity_id: export_provider.ref_activity_id,
        ref_page_id: aco_provider_list_page.ref_page_id,
        partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      })
        .unwrap()
        .then(res => {});
    }
  };

  if (!selectedPartnerProduct) return null;

  const exportName =
    selectedPartnerProduct.doing_business_as?.toLowerCase().replace(/ /g, '_') + '_providers_' + Date.now();

  return (
    <Box>
      <CsvDownloadButton
        exportName={exportName}
        transform={transform}
        handleEmptyDownload={() => toast({ description: 'No entries', status: 'info' })}
        styles={{
          button: {
            size: 'sm',
            px: '5',
          },
        }}
        handleDownload={async () => {
          const res = await get({
            partner_product_id: selectedPartnerProduct.partner_product_id,
            page_number: 1,
            page_size: 99999,
            search_string: searchParams.get('search') ?? '',
            sort_column: 'provider_npi',
            sort_order: 'asc',
          })
            .unwrap()
            .finally(() => handleLogEvent());
          return res.data;
        }}
      >
        Export as CSV
      </CsvDownloadButton>
    </Box>
  );
};

export default ExportAsCsvButton;
