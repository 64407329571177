import { AppAccess } from '../constants/appAccesses';
import { useIsUserHasRequiredAppAccess } from './useIsUserHasRequiredRoles';

export function useAppAccess() {
  const isUserAdmin = useIsUserHasRequiredAppAccess(AppAccess.LoreHealthAdminAccess);
  const isUserTeam = useIsUserHasRequiredAppAccess(AppAccess.LoreHealthTeamAccess);
  const isUserAdminOrTeam = isUserAdmin || isUserTeam;

  return { isUserAdmin, isUserTeam, isUserAdminOrTeam };
}
