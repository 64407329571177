import { partnerApi } from '.';
import { RefPageDisplayModel } from './types';

const baseUrl = '/page';

const extendedApi = partnerApi.injectEndpoints({
  endpoints: build => ({
    getRefPageDisplayList: build.query<RefPageDisplayModel[], void>({
      query: () => baseUrl + '/list',
    }),
  }),
});

export const { useLazyGetRefPageDisplayListQuery, useGetRefPageDisplayListQuery } = extendedApi;
