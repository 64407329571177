export enum PAGE_ACTIVITY {
  DASHBOARD_CLICKED = 'dashboard_clicked',
  ACO_PROVIDER_LIST_CLICKED = 'aco_provider_list_clicked',
  EXPLORE_ROLES_CLICKED = 'explore_roles_clicked',
  MY_ROLES_CLICKED = 'my_roles_clicked',
  CREATE_ROLE_CLICKED = 'create_role_clicked',
  ACCESS_APPROVAL_CLICKED = 'access_approval_clicked',
  REPORT_HUB_CLICKED = 'report_hub_clicked',
  REPORT_HUB_FINANCIAL_REPORT_CLICKED = 'report_hub_financial_report_clicked',
  REPORT_HUB_PARTNER_REPORT_CLICKED = 'report_hub_partner_report_clicked',
  USERS_CLICKED = 'users_clicked',
  ROLE_CREATED = 'role_created',
  PARTNER_CREATED = 'partner_created',
  PARTNER_DRAFT_CANCELLED = 'partner_draft_cancelled',
  PARTNER_PRODUCT_CREATED = 'partner_product_created',
  PRODUCT_TYPE_CREATED = 'product_type_created',
  PRODUCT_TYPE_DRAFT_CANCELLED = 'product_type_draft_cancelled',
  PARTNER_PRODUCT_DRAFT_CANCELLED = 'partner_product_draft_cancelled',
  ROLE_ACCESS_REQUEST_SUBMITTED = 'role_access_request_submitted',
  ROLE_ACCESS_REQUEST_DRAFT_CANCELLED = 'role_access_request_draft_cancelled',
  EXPORT_PROVIDER = 'export_provider',
  EXPORT_PARTNER = 'export_partner',
  EXPORT_PARTNER_PRODUCT = 'export_partner_product',
  PARTNER_PRODUCTS_CLICKED = 'partner_products_clicked',
  PARTNERS_CLICKED = 'partners_clicked',
  APP_ACCESS_CREATED = 'app_access_created',
  ROLE_APP_ACCESS_CREATED = 'role_app_access_created',
  ROLE_REQUEST_APPROVED = 'role_request_approved',
  ROLE_REQUEST_REJECTED = 'role_request_rejected',
  REPORT_CREATED = 'report_created',
  REPORT_DRAFT_CANCELLED = 'report_draft_cancelled',
  USER_INVITE_CREATED = 'user_invite_created',
  USER_INVITE_DRAFT_CANCELLED = 'user_invite_draft_cancelled',

  //SELECT_PARTNER = 'select_partner',
  //SEARCH_PARTNER = 'search_partner',
  //SEARCH_PRODUCT_TYPE = 'search_product_type',
  //SEARCH_PARTNER_PRODUCT = 'search_partner_product',
  //PROVIDER_DRAFT_CANCELLED = 'provider_draft_cancelled',
  //PROVIDER_DELETED = 'provider_deleted',
  //VIEW_PROVIDER_DETAILS = 'view_provider_details',
  //SEARCH_PROVIDER = 'search_provider',
  //VIEW_ROLE_DETAILS = 'view_role_details',
  //ROLE_DESCRIPTION_UPDATED = 'role_description_updated',
  //SEARCH_ROLE = 'search_role',
  //FILTER_MY_ROLE = 'filter_my_role',
}
