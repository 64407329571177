import { Box, Button, Heading, Radio, Text, VStack } from '@chakra-ui/react';
import { FC, ReactNode, useState } from 'react';
import { useDispatch } from 'react-redux';
import { defaultErrorMessage } from '../../app/constants';
import { getName } from '../../app/helpers/stringHelper';
import { UserPartnerProductModel } from '../../app/services/partner/api/types';
import { setSelectedPartnerProduct } from '../../app/slices/userSlice';
import { useAppSelector } from '../../app/state/hooks';
import ChoosePartnerProductLogo from '../../assets/svg/ChoosePartnerProductLogo';
import MessagePage from '../../components/MessagePage';

type Props = {
  children: ReactNode;
};

const ChooseProductPartnerWrapper: FC<Props> = ({ children }) => {
  const { userPartnerProducts, selectedPartnerProduct, hasMultiplePartnerProducts } = useAppSelector(s => s.user);

  if (!userPartnerProducts) return null;

  return userPartnerProducts.length < 1 ? (
    <MessagePage title="No partner product found" message="Please contact admin to resolve this issue." />
  ) : !selectedPartnerProduct && hasMultiplePartnerProducts ? (
    <ChooseProductPartner />
  ) : !!selectedPartnerProduct ? (
    <>{children}</>
  ) : (
    // Should not arrive here, but incase let's add this to capture and fix scenario
    <MessagePage title="Partner Product Error" message={defaultErrorMessage} />
  );
};

const ChooseProductPartner = () => {
  const { logonUser, userPartnerProducts } = useAppSelector(s => s.user);
  const [selectedProduct, setSelectedProduct] = useState<UserPartnerProductModel | null>(null);
  const dispatch = useDispatch();

  return (
    <VStack justifyContent="center" alignItems="center" gap="5">
      <Box w="container.lg" maxH="828px" display="flex" justifyContent="space-between" pt="10">
        <VStack>
          <Heading as="h2" fontSize="24px" mb="6">
            Welcome back {getName(logonUser)}
          </Heading>

          <Text color="blackAlpha.700" pb="4">
            Select a{' '}
            <Text as="span" color="#D13745">
              group
            </Text>{' '}
            to manage
          </Text>

          <VStack overflowX="hidden" overflowY="auto" maxH="calc(100vh - 300px)">
            {userPartnerProducts &&
              userPartnerProducts.map(p => (
                <Box
                  key={p.partner_product_id}
                  px="4"
                  py="4"
                  bg="white"
                  rounded="md"
                  shadow="base"
                  display="flex"
                  gap="2"
                  onClick={() => setSelectedProduct(p)}
                  cursor="pointer"
                >
                  <Radio
                    colorScheme="brand.main"
                    isChecked={selectedProduct?.partner_product_id === p.partner_product_id}
                  />
                  <Text>{p.doing_business_as}</Text>
                </Box>
              ))}
          </VStack>
        </VStack>
        <Box alignSelf="end">
          <ChoosePartnerProductLogo width="auto" height="60vh" />
        </Box>
      </Box>

      <Button
        isDisabled={!selectedProduct}
        onClick={() => {
          selectedProduct && dispatch(setSelectedPartnerProduct(selectedProduct));
        }}
      >
        Continue
      </Button>
    </VStack>
  );
};

export default ChooseProductPartnerWrapper;
