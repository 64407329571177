import { AuthenticatedTemplate, useAccount, useIsAuthenticated } from '@azure/msal-react';
import { FC, ReactNode, useEffect, useState } from 'react';
import { defaultErrorMessage } from '../../app/constants';
import { getIdTokenClaims } from '../../app/services/auth/helper';
import { useLazyGetUserByEmailQuery } from '../../app/services/partner/api/user';
import { useLazyGetUserPartnerProductsQuery } from '../../app/services/partner/api/userPartnerProduct';
import { useLazyGetAppAccessByEmailQuery } from '../../app/services/partner/api/userRole';
import { useLazyGetRefActivityDisplayListQuery } from '../../app/services/partner/api/refActivity';
import { useLazyGetRefPageDisplayListQuery } from '../../app/services/partner/api/refPage';
import { setCurrentUserUserRole } from '../../app/slices/userRoleSlice';
import {
  setLogonUser,
  setUserPartnerProducts,
  setUserSessionId,
  setPageList,
  setActivityList,
} from '../../app/slices/userSlice';
import { useAppDispatch, useAppSelector } from '../../app/state/hooks';
import { useAddUserSessionMutation } from '../../app/services/partner/api/userSession';
import MessagePage from '../../components/MessagePage';
import PageLoading from '../../components/PageLoading';
import UnAuthorized from '../../pages/UnAuthorized';

type IProps = {
  children: ReactNode;
  onAuthenticated?: () => void;
};

const AuthenticatedUserWrapper: FC<IProps> = ({ children, onAuthenticated }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [isError, setIsError] = useState(false);
  const [ipAddress, setIpAddress] = useState<string>('');

  const isAuthenticated = useIsAuthenticated();
  const account = useAccount();

  const { logonUser, userPartnerProducts, userSessionId, activityList, pageList } = useAppSelector(s => s.user);
  const { currentUserUserRole } = useAppSelector(s => s.userRole);
  const dispatch = useAppDispatch();

  const [lazyGetUserByEmail] = useLazyGetUserByEmailQuery();
  const [userPartnerAsync] = useLazyGetUserPartnerProductsQuery();
  const [userAppAccessesAsync] = useLazyGetAppAccessByEmailQuery();

  const [getLazyPageList] = useLazyGetRefPageDisplayListQuery();
  const [getLazyActivityList] = useLazyGetRefActivityDisplayListQuery();
  const [postAddUserSession] = useAddUserSessionMutation();

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIpAddress(data.ip))
      .catch(error => console.error(error));
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const { emails = [] } = getIdTokenClaims(account) || {};

      onAuthenticated && onAuthenticated();

      if (emails?.length && !logonUser) {
        setIsLoading(true);
        lazyGetUserByEmail(emails[0])
          .unwrap()
          .then(user => {
            dispatch(setLogonUser(user));
          })
          .catch(() => {
            setIsUnauthorized(true);
          });
      }

      if (logonUser?.disabled_flag) {
        setIsUnauthorized(true);
      }

      if (logonUser) {
        if (!logonUser.email) return setIsError(true);

        if (pageList.length === 0 || !pageList) {
          getLazyPageList()
            .unwrap()
            .then(res => {
              dispatch(setPageList(res));
              console.log(`PAGES: ${JSON.stringify(res)}`);
            });
        }

        if (activityList.length === 0 || !activityList) {
          getLazyActivityList()
            .unwrap()
            .then(res => {
              dispatch(setActivityList(res));
              console.log(`ACTIVITIES: ${JSON.stringify(res)}`);
            });
        }

        if (!userSessionId) {
          postAddUserSession({ user_ip_address: ipAddress })
            .unwrap()
            .then(res => {
              dispatch(setUserSessionId(res.result.user_session_id));
            });
        }

        // ADD new user details here
        if (!userPartnerProducts) {
          console.log('here');
          userPartnerAsync(logonUser.ref_user_id)
            .unwrap()
            .then(userPartnerProducts => {
              dispatch(setUserPartnerProducts(userPartnerProducts));
            })
            .catch(() => {
              setIsError(true);
            });
        }

        if (!currentUserUserRole) {
          userAppAccessesAsync(logonUser.email)
            .unwrap()
            .then(appAccesses => {
              dispatch(setCurrentUserUserRole(appAccesses));
            })
            .catch(() => {
              setIsError(true);
            });
        }
      }
    }
  }, [isAuthenticated, logonUser]);

  useEffect(() => {
    // ADD new user details here
    if (!!logonUser && !!userPartnerProducts && !!currentUserUserRole) {
      setIsLoading(false);
    }
  }, [logonUser, userPartnerProducts, currentUserUserRole]);

  return isUnauthorized ? (
    <UnAuthorized />
  ) : isError ? (
    <MessagePage title="400" message={defaultErrorMessage} />
  ) : !logonUser || isLoading ? (
    <PageLoading />
  ) : (
    <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
  );
};

export default AuthenticatedUserWrapper;
