const HeaderLoreText = () => {
  return (
    <svg width="82" height="25" viewBox="0 0 82 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.0477 24.5001C24.1437 24.4307 20.8909 22.9658 18.4432 19.8443C17.0917 18.1216 16.3292 16.1376 16.0772 13.9519C15.8024 11.5843 16.1525 9.31594 17.2095 7.18645C18.8457 3.89632 21.4636 1.78006 24.988 0.910404C28.9541 -0.0683678 32.6127 0.695471 35.8164 3.29451C38.2183 5.24544 39.6353 7.81141 40.0116 10.9097C40.509 15.0265 39.272 18.5514 36.2418 21.3687C34.406 23.075 32.2036 24.0438 29.733 24.3811C29.1767 24.4571 28.6171 24.5034 28.051 24.4968L28.0477 24.5001ZM28.0902 3.98229C27.5601 4.00875 27.0365 4.02197 26.5195 4.12448C23.6201 4.69322 21.588 6.34655 20.4819 9.11423C19.8732 10.6386 19.7391 12.2291 19.9681 13.8527C20.279 16.0615 21.2182 17.9298 22.9755 19.3186C24.8047 20.7636 26.9089 21.2331 29.1832 20.9554C31.4543 20.6776 33.2901 19.6063 34.6514 17.7479C35.6233 16.422 36.1109 14.9108 36.2418 13.2674C36.3727 11.6372 36.1502 10.0665 35.4302 8.6017C33.9249 5.54965 31.4117 4.09803 28.087 3.98229H28.0902Z"
        fill="white"
      />
      <path
        d="M44.4851 12.51C44.4851 8.78012 44.4851 5.04689 44.4851 1.31698C44.4851 1.04804 44.6193 0.912466 44.8876 0.910262C47.6659 0.910262 50.4441 0.893729 53.2224 0.916875C54.8946 0.930102 56.4621 1.35997 57.8529 2.33543C59.5316 3.5126 60.4348 5.15601 60.6508 7.19622C60.8242 8.84955 60.5231 10.4004 59.6559 11.8222C58.9197 13.0292 57.8725 13.8724 56.5963 14.4378C56.3083 14.5668 56.305 14.5602 56.4948 14.8247C58.6415 17.804 60.7882 20.78 62.9382 23.7593C62.9643 23.7957 62.9938 23.832 63.0167 23.8717C63.0527 23.9312 63.128 23.9874 63.0821 24.0668C63.0462 24.1296 62.9676 24.1098 62.9087 24.1098C61.6423 24.1098 60.3759 24.1098 59.1095 24.1131C58.9229 24.1131 58.8378 24.0073 58.7429 23.8783C57.2278 21.8017 55.7127 19.7285 54.1976 17.6552C53.6445 16.898 53.085 16.144 52.5385 15.3802C52.4239 15.2215 52.3029 15.1587 52.1098 15.162C50.935 15.1686 49.7569 15.1653 48.5789 15.1653C48.3389 15.1653 48.2189 15.2843 48.2189 15.5224C48.2189 18.2471 48.2189 20.9718 48.2189 23.6965C48.2189 23.9698 48.0869 24.1065 47.8229 24.1065C46.8281 24.1065 45.8333 24.0966 44.8352 24.1131C44.5604 24.1164 44.4818 24.0337 44.4818 23.756C44.4884 20.0062 44.4884 16.2565 44.4884 12.5067L44.4851 12.51ZM48.2156 8.0295C48.2156 9.18022 48.2189 10.3342 48.2123 11.485C48.2123 11.6999 48.2582 11.8024 48.4971 11.7991C50.0907 11.7892 51.6844 11.7991 53.2748 11.7759C53.8965 11.7693 54.4888 11.5908 55.0419 11.2998C57.1395 10.2086 57.1722 7.44422 56.3443 6.02897C55.6603 4.85842 54.5739 4.34258 53.2846 4.29628C51.6942 4.24007 50.1005 4.27644 48.5069 4.26652C48.2843 4.26652 48.2058 4.33927 48.2091 4.56743C48.2189 5.71815 48.2123 6.87217 48.2123 8.02289L48.2156 8.0295Z"
        fill="white"
      />
      <path
        d="M66.4265 12.4968C66.4265 8.76689 66.4265 5.03367 66.4265 1.30376C66.4265 0.913574 66.4265 0.913574 66.8159 0.913574C71.6035 0.913574 76.391 0.913574 81.1818 0.913574C81.5581 0.913574 81.5614 0.913574 81.5614 1.28723C81.5614 2.1668 81.5614 3.04306 81.5614 3.92264C81.5614 4.29629 81.5614 4.29629 81.1785 4.29629C77.641 4.29629 74.1003 4.29629 70.5628 4.29629C70.2945 4.29629 70.1603 4.42966 70.1603 4.69639C70.1603 6.41586 70.1669 8.13532 70.1538 9.85478C70.1538 10.1193 70.229 10.1954 70.4908 10.1954C73.1153 10.1855 75.7398 10.1888 78.3642 10.1888C78.7373 10.1888 78.7406 10.1888 78.7406 10.5657C78.7406 11.4155 78.7308 12.2686 78.7471 13.1185C78.7537 13.4028 78.6555 13.4723 78.3904 13.4723C75.7659 13.4624 73.1415 13.4657 70.517 13.4657C70.2814 13.4657 70.1636 13.5869 70.1636 13.8294C70.1636 16.0052 70.1636 18.1843 70.1636 20.36C70.1636 20.6047 70.2901 20.7282 70.5432 20.7304C74.2181 20.7304 77.893 20.7304 81.5679 20.7304C81.8537 20.7304 81.9966 20.8781 81.9966 21.1735C81.9966 22.0332 81.9901 22.8929 81.9999 23.7527C82.0031 24.0172 81.9377 24.1197 81.653 24.1197C76.692 24.1131 71.7343 24.1098 66.7734 24.1197C66.4821 24.1197 66.4331 24.0139 66.4331 23.7527C66.4396 20.0029 66.4363 16.2532 66.4363 12.5034L66.4265 12.4968Z"
        fill="white"
      />
      <path
        d="M0.0065462 12.5101C0.0065462 8.78018 0.0065462 5.05027 0.0065462 1.32035C0.0065462 1.04921 0.139624 0.913635 0.40578 0.913635C1.39077 0.913635 2.37904 0.913635 3.36403 0.913635C3.73381 0.913635 3.73381 0.913635 3.73381 1.2939C3.73381 7.59309 3.73381 13.8923 3.73381 20.1915C3.73381 20.2741 3.74363 20.3568 3.73381 20.4395C3.70436 20.6775 3.81562 20.7503 4.0316 20.7271C4.11341 20.7172 4.19522 20.7271 4.27703 20.7271C7.4709 20.7271 10.6648 20.7271 13.8586 20.7271C14.248 20.7271 14.2513 20.7271 14.2513 21.1239C14.2513 22.0101 14.2448 22.8963 14.2546 23.7858C14.2546 24.0305 14.1793 24.1165 13.9273 24.1165C9.40161 24.1099 4.87915 24.1099 0.35342 24.1165C0.0785383 24.1165 0 24.0371 0 23.7594C0.00654481 20.0096 0.0065462 16.2632 0.0065462 12.5134V12.5101Z"
        fill="white"
      />
    </svg>
  );
};

export default HeaderLoreText;
