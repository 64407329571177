import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { PartnerProductModel } from '../../../../../app/services/partner/api/types';
import { useToggleActivePartnerProductMutation } from '../../../../../app/services/partner/api/partnerProduct';

const DisablePartnerProduct = ({ original }: { original: PartnerProductModel }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [post, postDetails] = useToggleActivePartnerProductMutation();
  const toast = useToast();

  const handleOnClick = () => {
    onOpen();
  };

  const handleDisableClick = () => {
    post({ is_active: false, partner_product_id: original.partner_product_id })
      .unwrap()
      .then(res => {
        toast({ status: 'success', description: `${original.doing_business_as} disabled!` });
        onClose();
      });
  };

  return (
    <>
      <Button
        size="sm"
        _hover={{ textDecoration: 'none' }}
        variant="link"
        fontWeight="normal"
        textAlign="left"
        onClick={handleOnClick}
        color={'red.500'}
      >
        <Box w="100%">Disable</Box>
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="md" closeOnOverlayClick={false}>
        <ModalOverlay />

        <ModalContent position="absolute" top="30%" left="40%">
          <ModalHeader>Disable {original.doing_business_as}?</ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <VStack>
              <Box display="flex" justifyContent={'flex-end'} pt="30px">
                <Box display="flex" gap={2}>
                  <Button color="#3182ce" size="sm" width={'100px'} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    onClick={handleDisableClick}
                    size="sm"
                    colorScheme="brand.main"
                    width={'100px'}
                    gap="2"
                    px="5"
                    type="submit"
                  >
                    {postDetails.isLoading ? <Spinner size={'sm'} /> : 'Submit'}
                  </Button>
                </Box>
              </Box>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DisablePartnerProduct;
