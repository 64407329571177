import { Box, VStack, Text } from '@chakra-ui/react';
import WelcomeSection from './components/WelcomeSection';
import welcomeImage from '../../assets/images/welcome-page.png';

const Home = () => {
  return (
    <>
      <VStack maxHeight={'22xl'}>
        <Box mb="3">
          <Text sx={{ fontSize: ['sm', 'md', 'lg', 'xl', '2xl'] }}>
            <strong>Welcome to the Lore Partner Portal</strong>
          </Text>
        </Box>
        <Box height="100%" width="100%">
          <WelcomeSection imageSrc={welcomeImage} />
        </Box>
      </VStack>
    </>
  );
};

export default Home;
