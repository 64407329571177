import { Link } from '@chakra-ui/react';
import { useState } from 'react';
import { PartnerModel } from '../../../app/services/partner/api/types';
import PartnerDetailsModal from '.';

type PartnerDetailsButtonProps = {
  partner: PartnerModel;
};

const PartnerDetailsButton = ({ partner }: PartnerDetailsButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const SOURCE = 'link';

  const handleLinkClick = () => {
    console.log(`Button clicked;;;;;`);
    setIsModalOpen(true);
  };
  return (
    <>
      <Link onClick={handleLinkClick} style={{ color: '#0c7aff' }} whiteSpace={'normal'} wordBreak={'break-word'}>
        {partner.partner_name}
      </Link>

      <PartnerDetailsModal source={SOURCE} openModal={isModalOpen} partner={partner} setModal={setIsModalOpen} />
    </>
  );
};

export default PartnerDetailsButton;
