import { AddRefProductTypeModel } from '../../../../../../app/services/partner/api/types';

export type AddProductTypeStepProp = {
  data: AddRefProductTypeModel;
  activeStep: number;
  setData: React.Dispatch<React.SetStateAction<AddRefProductTypeModel>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  handleSubmit: () => void;
  isLoading: boolean;
};

export const addProductTypeInitialValues: AddRefProductTypeModel = {
  product_type_name: null,
  product_type_desc: null,
};

export const productTypeFields = {
  product_type_name: {
    max: 500,
    label: 'Product Type Name',
  },
  product_type_desc: {
    max: 1000,
    label: 'Product Type Description',
  },
};
