import {
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  VStack,
} from '@chakra-ui/react';
import { FiMoreVertical } from 'react-icons/fi';
import { CellProps } from 'react-table';
import { PartnerProductProviderModel } from '../../../app/services/partner/api/types';
import AcoProviderListActionCellDelete from './Delete';
import AcoProviderListActionCellView from './View';

const AcoProviderListActionCell = ({ row: { original } }: CellProps<PartnerProductProviderModel>) => {
  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <IconButton aria-label="Options" icon={<FiMoreVertical />} variant="ghost" colorScheme="brand.main" h="5" />
      </PopoverTrigger>
      <Portal>
        <PopoverContent w="fit-content">
          <PopoverArrow />
          <PopoverBody>
            <VStack>
              <AcoProviderListActionCellView original={original} />
              <AcoProviderListActionCellDelete original={original} />
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default AcoProviderListActionCell;
