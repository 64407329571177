import { Box } from '@chakra-ui/react';

type CountProps = {
  count: number;
  isDisabled?: boolean;
};

const UserCountContainer = ({ count, isDisabled }: CountProps) => {
  const numberLength = count.toString().length;
  const xyWidth = numberLength > 1 ? '3px' : '5px';

  const getBackgroundColor = () => {
    return isDisabled ? '#afd4dd' : '#3794aa';
  };
  return (
    <>
      <Box
        style={{
          display: 'flex',
          fontSize: '11px',
          alignItems: 'center',
          paddingLeft: xyWidth,
          paddingRight: xyWidth,
          justifyContent: 'center',
          width: 'min-content',
          fontWeight: 'bold',
          borderRadius: '3px',
          backgroundColor: getBackgroundColor(), //{isDisabled ? '#afd4dd' : '#3794aa' },
          //backgroundColor: '#3794aa',
          color: '#fff',
          userSelect: 'none',
        }}
      >
        {count}
      </Box>
    </>
  );
};

export default UserCountContainer;
