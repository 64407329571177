import { Box, Heading, Text, VStack, Flex } from '@chakra-ui/react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { AppSize } from '../../../../app/constants';
import { useGetPartnerListQuery } from '../../../../app/services/partner/api/partner';
import { PartnerModel } from '../../../../app/services/partner/api/types';
import { SortType } from '../../../../app/services/types';
import { useAppSelector } from '../../../../app/state/hooks';
import { CustomTableHeader } from '../../../../app/types/appType';
import CustomTable from '../../../../components/CustomTable';
import DashboardAdminPartnersAfterSearch from './AfterSearch';
import AppAuth from '../../../../features/AppAuth';
import { AppAccess } from '../../../../app/constants/appAccesses';
import PartnerDetailsButton from '../../../Partners/PartnerDetailsModal/PartnerDetailsButton';
import ViewAllButton from '../../ViewAllButton';

const InitialSortBy: SortType<PartnerModel> = {
  id: 'partner_name',
  desc: false,
};

const Header: CustomTableHeader<PartnerModel> = [
  {
    Header: 'Partner Name',
    accessor: 'partner_name',
    Cell: ({ row: { original } }: CellProps<PartnerModel>) => {
      const { isLoreHealthAdmin } = useAppSelector(s => s.user);

      return (
        <Flex width={'200px'} direction={'column'}>
          {isLoreHealthAdmin ? <PartnerDetailsButton partner={original} /> : <Text>{original.partner_name}</Text>}
        </Flex>
      );
    },
  },
  {
    Header: 'Product Type',
    accessor: 'product_type_name',
    isSortable: false,
  },
  {
    Header: 'Partner Product/Doing Business as',
    accessor: 'doing_business_as',
  },
  {
    Header: 'Partner TIN',
    accessor: 'partner_tin',
  },
  {
    Header: 'Products',
    isSortable: false,
    Cell: ({ row: { original } }: CellProps<PartnerModel>) => {
      return (
        <Box bg="brand.main.default" w="fit-content" px="1" rounded="sm">
          <Text fontSize="xs" fontWeight="bold" color="white">
            {original.partner_product_count}
          </Text>
        </Box>
      );
    },
  },
  // {
  //   Header: "Action",
  //   Cell: DashboardAdminPartnersActionCell,
  // },
];

const DashboardAdminPartners = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState(InitialSortBy);

  const { isSideNavOpen } = useAppSelector(s => s.app);

  const { isLoreHealthAdmin, selectedPartnerProduct } = useAppSelector(s => s.user);

  const partnerProductId = selectedPartnerProduct?.partner_product_id || 0;

  const { data, isLoading, isFetching } = useGetPartnerListQuery({
    page_number: pageNumber,
    page_size: pageSize,
    search_string: searchParams.get('spartner') ?? '',
    sort_column: sortBy.id,
    sort_order: sortBy.desc ? 'desc' : 'asc',
    partner_product_id: isLoreHealthAdmin ? 0 : partnerProductId,
  });

  const sideNavWidth = isSideNavOpen ? AppSize.sideNav.width.open : AppSize.sideNav.width.close;

  // TODO what fields are isSortable:false
  return (
    <VStack gap="6">
      <Heading as="h2" fontSize="xl" fontWeight="semibold">
        Partners
      </Heading>

      <Box className="dashboard-card">
        <CustomTable
          variant="table"
          isLoading={isLoading}
          isFetching={isFetching}
          data={data?.data ?? []}
          pageCount={data?.total_pages || 0}
          pageSize={data?.page_size || pageSize}
          totalRecords={data?.total_records || 0}
          pageIndex={pageNumber - 1}
          headers={Header}
          search={searchParams.get('spartner') ?? ''}
          initialState={{ sortBy: [InitialSortBy] }}
          manualSortBy
          disableSortRemove
          disableHoverHighlight
          showNoRecords
          onPageChange={index => {
            setPageNumber(index + 1);
          }}
          onPageSizeChange={size => {
            setPageNumber(1);
            setPageSize(size);
          }}
          onPageSearchDebounce={400}
          onPageSearch={search => {
            setPageNumber(1);

            setSearchParams(
              prev => {
                if (search) prev.set('spartner', search);
                else prev.delete('spartner');
                return prev;
              },
              { replace: true },
            );
          }}
          onSort={sort => {
            if (sort[0]) {
              setPageNumber(1);
              setSortBy(sort[0]);
            }
          }}
          styles={{
            loadingStyle: 'overlay',
            pagination: { justifyContent: 'start' },
            header: { justifyContent: 'left' },
            tableContainer: {
              sx: {
                maxW: `calc(100vw - 5rem - ${sideNavWidth})`,
                overflow: 'auto',
                table: {
                  borderCollapse: 'separate',
                  borderSpacing: '0',
                  'thead > tr': {
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    zIndex: 2,
                    height: 'auto',
                    bg: 'white',
                  },
                },
              },
            },
          }}
          manual={true}
          // onRowClick={(row) => onClickRow(row)}
          extraComponents={{
            // TODO
            afterSearch: (
              <>
                <AppAuth requiredAppAccess={AppAccess.PartnerWrite}>
                  <DashboardAdminPartnersAfterSearch />
                  <ViewAllButton path={'/partners'} />
                </AppAuth>
              </>
            ),
            // afterPagination: <ExportAsCsvButton />,
          }}
        />
      </Box>
    </VStack>
  );
};

export default DashboardAdminPartners;
