import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Select, chakraComponents } from 'chakra-react-select';
import { useFormik } from 'formik';
import { useMemo } from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import { Row } from 'react-table';
import * as Yup from 'yup';
import { defaultErrorMessage } from '../../../app/constants';
import { getName, validateEmail } from '../../../app/helpers/stringHelper';
import {
  useDisableEligibleParticipantMutation,
  useUpdateEligibleParticipantMutation,
} from '../../../app/services/partner/api/eligibleParticipant';
import { useGetAllPartnerProductsListQuery } from '../../../app/services/partner/api/partnerProduct';
import { TestEligibleParticipantModel } from '../../../app/services/partner/api/types';

const TestEligibleParticipantFormSchema = Yup.object().shape({
  email: Yup.string()
    .label('Email')
    .max(100)
    .required()
    .test((str, { createError }) => {
      if (!str) return true;
      return validateEmail(str)
        ? true
        : createError({
            message: 'Email must be a valid email',
            path: 'email',
          });
    }),
  partner_product_id: Yup.number().required().moreThan(0, 'Partner Product is a required field'),
});

const Update: React.FC<{ original: TestEligibleParticipantModel }> = ({ original }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();

  const getDetails = useGetAllPartnerProductsListQuery();
  const [putAsync, { isLoading }] = useUpdateEligibleParticipantMutation();

  const { handleSubmit, errors, touched, handleChange, values, setValues, resetForm, setFieldValue, setFieldTouched } =
    useFormik({
      enableReinitialize: true,
      validationSchema: TestEligibleParticipantFormSchema,
      initialValues: {
        first_name: original.first_name,
        last_name: original.last_name,
        email: original.email,
        partner_product_id: original.partner_product_id,
      },
      onSubmit: (values, form) => {
        putAsync({
          eligibile_participant_id: original.eligible_participant_id,
          email_address: values.email,
          partner_product_id: values.partner_product_id,
        })
          .unwrap()
          .then(() => {
            toast({ description: `Successfully updated eligible participant "${getName(values)}"`, status: 'success' });
            form.resetForm();
            onClose();
          })
          .catch(() => {
            toast({ description: defaultErrorMessage, status: 'error' });
          });
      },
    });

  const clear = () => {
    resetForm();
    onClose();
  };

  const sortedProductPartners = useMemo(() => {
    const list = [...(getDetails.data ?? [])];
    return list.sort(
      (a, b) =>
        (a.product_type_name ?? '').localeCompare(b.product_type_name ?? '') ||
        (a.doing_business_as ?? '').localeCompare(b.doing_business_as ?? ''),
    );
  }, [getDetails.data]);

  const isDisabled = !!original.end_date;

  return (
    <>
      <Button
        // colorScheme="brand.main"
        // leftIcon={<BsPencil />}
        // variant="link"
        // minW="1"
        // sx={{ span: { marginRight: 1 } }}
        // isLoading={isLoading}
        // isDisabled
        size="sm"
        _hover={{ textDecoration: 'none' }}
        variant="link"
        fontWeight="normal"
        color="brand.main.default"
        isDisabled={isDisabled}
        onClick={onOpen}
        title={isDisabled ? 'Cannot update if account is disabled' : undefined}
      >
        Update
      </Button>

      <Modal isOpen={isOpen} onClose={clear} size="3xl" closeOnOverlayClick={false}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Update Eligible Participant</ModalHeader>

          <ModalCloseButton isDisabled={isLoading} />

          <form onSubmit={handleSubmit}>
            <ModalBody>
              <VStack spacing="5" pt={5}>
                <FormControl isInvalid={!!errors.first_name && touched.first_name} display="flex">
                  <FormLabel minW="150px" htmlFor="first_name">
                    First Name
                  </FormLabel>
                  <Tooltip label="First Name is not editable" placement="right">
                    <Box w="full">
                      <Input
                        size="sm"
                        id="first_name"
                        name="first_name"
                        onChange={handleChange}
                        onBlur={e =>
                          setValues({
                            ...values,
                            first_name: e.target.value.trim(),
                          })
                        }
                        value={values.first_name ?? ''}
                        maxLength={100}
                        readOnly
                      />
                      <FormErrorMessage>{errors.first_name}</FormErrorMessage>
                    </Box>
                  </Tooltip>
                </FormControl>
                <FormControl isInvalid={!!errors.last_name && touched.last_name} display="flex">
                  <FormLabel minW="150px" htmlFor="last_name">
                    Last Name
                  </FormLabel>
                  <Tooltip label="Last Name is not editable" placement="right">
                    <Box w="full">
                      <Input
                        size="sm"
                        id="last_name"
                        name="last_name"
                        onChange={handleChange}
                        onBlur={e =>
                          setValues({
                            ...values,
                            last_name: e.target.value.trim(),
                          })
                        }
                        value={values.last_name ?? ''}
                        maxLength={100}
                        readOnly
                      />
                      <FormErrorMessage>{errors.last_name}</FormErrorMessage>
                    </Box>
                  </Tooltip>
                </FormControl>
                <FormControl isInvalid={!!errors.email && touched.email} display="flex">
                  <FormLabel minW="150px" htmlFor="email">
                    Email
                  </FormLabel>
                  <Tooltip label="Email is not editable" placement="right">
                    <Box w="full">
                      <Input
                        size="sm"
                        id="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={e =>
                          setValues({
                            ...values,
                            email: e.target.value.trim(),
                          })
                        }
                        value={values.email ?? ''}
                        maxLength={100}
                        readOnly
                      />
                      <FormErrorMessage>{errors.email}</FormErrorMessage>
                    </Box>
                  </Tooltip>
                </FormControl>

                <FormControl isInvalid={!!errors.partner_product_id && touched.partner_product_id} display="flex">
                  <FormLabel minW="150px" htmlFor="partner_product_id">
                    Partner Product
                  </FormLabel>
                  <Box w="full">
                    <Select
                      isDisabled={!getDetails.data}
                      id="partner_product_id"
                      name="partner_product_id"
                      useBasicStyles
                      size="sm"
                      placeholder={getDetails.data ? 'Select partner product...' : 'Loading...'}
                      value={(() => {
                        const val = getDetails.data?.find(f => f.partner_product_id === values.partner_product_id);
                        return val ? { label: val.product_type_name, value: val.partner_product_id, data: val } : null;
                      })()}
                      options={sortedProductPartners.map(m => ({
                        label: m.product_type_name,
                        value: m.partner_product_id,
                        data: m,
                      }))}
                      onChange={e => {
                        setValues({
                          ...values,
                          partner_product_id: parseInt(e?.value?.toString() || '0'),
                        });
                      }}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: provided => ({
                          ...provided,
                          zIndex: 1401,
                        }),
                      }}
                      maxMenuHeight={300}
                      components={{
                        Option: ({ children, ...props }) => (
                          <chakraComponents.Option {...props}>
                            <Text>
                              {children}{' '}
                              <Text as="span" color="gray.400">
                                ({props.data.data.doing_business_as})
                              </Text>
                            </Text>
                          </chakraComponents.Option>
                        ),
                        SingleValue: props => {
                          return (
                            <chakraComponents.SingleValue {...props}>
                              <Text>
                                {props.data.label}{' '}
                                <Text as="span" color="gray.400">
                                  ({props.data.data.doing_business_as})
                                </Text>
                              </Text>
                            </chakraComponents.SingleValue>
                          );
                        },
                      }}
                    />
                    <FormErrorMessage>{errors.partner_product_id}</FormErrorMessage>
                  </Box>
                </FormControl>
              </VStack>
            </ModalBody>
            <ModalFooter gap={3}>
              <Button onClick={clear} ml="auto" isDisabled={isLoading}>
                Cancel
              </Button>
              <Button
                type="submit"
                colorScheme="brand.main"
                isLoading={isLoading}
                isDisabled={getDetails.isLoading || getDetails.isFetching}
                // onClick={() => {}}
              >
                Update
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

const Disable: React.FC<{ original: TestEligibleParticipantModel }> = ({ original }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();

  const [disableAsync, { isLoading }] = useDisableEligibleParticipantMutation();

  const isDisabled = !!original.end_date;

  const handleDisable = () => {
    disableAsync({
      eligibile_participant_id: original.eligible_participant_id,
      email_address: original.email,
    })
      .unwrap()
      .then(() => {
        toast({
          description: `Successfully ${isDisabled ? 'enabled' : 'disabled'} eligible participant "${getName(
            original,
          )}"`,
        });
        onClose();
      })
      .catch(() => {
        toast({ description: defaultErrorMessage, status: 'error' });
      });
  };

  return (
    <>
      <Button
        // colorScheme="brand.main"
        // leftIcon={<FaTrashAlt />}
        // variant="link"
        // minW="1"
        // sx={{ span: { marginRight: 1 } }}
        // isLoading={isLoading}
        // isDisabled
        size="sm"
        _hover={{ textDecoration: 'none' }}
        variant="link"
        fontWeight="normal"
        color="brand.main.default"
        onClick={onOpen}
      >
        {isDisabled ? 'Enable' : 'Disable'}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl" closeOnOverlayClick={false}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>{isDisabled ? 'Enable' : 'Disable'} Eligible Participant</ModalHeader>

          <ModalCloseButton isDisabled={isLoading} />

          <ModalBody>
            <Text>
              Are you sure you want to {(isDisabled ? 'enable' : 'disable') + ' '}
              <Text as="span" fontWeight="bold">
                {getName(original)}
              </Text>{' '}
              as eligible participant?
            </Text>
          </ModalBody>
          <ModalFooter gap={3}>
            <Button onClick={onClose} ml="auto" isDisabled={isLoading}>
              Cancel
            </Button>
            <Button colorScheme={isDisabled ? 'brand.main' : 'red'} isLoading={isLoading} onClick={handleDisable}>
              {isDisabled ? 'Enable' : 'Disable'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const TestAddEligibleParticipantActionCell = ({ row: { original } }: { row: Row<TestEligibleParticipantModel> }) => {
  return (
    // <Box>
    //   <Menu>
    //     <MenuButton
    //       as={IconButton}
    //       aria-label="Options"
    //       icon={<FiMoreVertical />}
    //       variant="ghost"
    //       colorScheme="brand.main"
    //       h="5"
    //     />

    //     <MenuList minWidth="fit-content">
    //       <Update original={original} />
    //       <Disable original={original} />
    //     </MenuList>
    //   </Menu>
    // </Box>

    <Popover placement="bottom-end">
      <PopoverTrigger>
        <IconButton aria-label="Options" icon={<FiMoreVertical />} variant="ghost" colorScheme="brand.main" h="5" />
      </PopoverTrigger>
      <Portal>
        <PopoverContent w="fit-content">
          <PopoverArrow />
          <PopoverBody>
            <VStack>
              <Update original={original} />
              <Disable original={original} />
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default TestAddEligibleParticipantActionCell;
