import { GoHome } from 'react-icons/go';
import { BsCardList } from 'react-icons/bs';
import { MdOutlineAddReaction } from 'react-icons/md';
import { IconType } from 'react-icons/lib';
import { BiBox } from 'react-icons/bi';
import {
  MdApproval,
  MdOutlinePersonSearch,
  MdPerson,
  MdPerson2,
  MdPerson3,
  MdPerson4,
  MdOutlinePerson,
  MdOutlinePerson2,
  MdOutlinePerson3,
  MdOutlinePerson4,
  MdPersonOutline,
  MdPersonOff,
  MdOutlineHandshake,
} from 'react-icons/md';
import { PiUsersThreeBold } from 'react-icons/pi';
import { RiFileList3Line } from 'react-icons/ri';
import { AppAccess, AppAuthRequiredAppAccess } from '../../app/constants/appAccesses';
import { RolesIcon } from '../../assets/svg';

export type NavItemModel = {
  icon?: IconType;
  iconComponent?: ({ data }: any) => JSX.Element;
  text: string;
  href: string;
  requiredAppAccess?: AppAuthRequiredAppAccess;
  subNav?: Array<NavItemModel>;
} & (
  | {
      icon: IconType;
    }
  | {
      iconComponent: (props: any) => JSX.Element;
    }
);

const navItems: Array<NavItemModel> = [
  {
    icon: GoHome,
    text: 'Dashboard',
    href: '/dashboard',
  },
  {
    icon: RiFileList3Line,
    text: 'ACO Provider List',
    href: '/aco-provider-list',
    requiredAppAccess: AppAccess.Provider,
  },
  {
    icon: BiBox,
    text: 'Partner Products',
    href: '/partner-products',
    requiredAppAccess: AppAccess.LoreHealthAndPartnerAdminAccess,
  },
  {
    icon: MdOutlineHandshake,
    text: 'Partners',
    href: '/partners',
    requiredAppAccess: AppAccess.LoreHealthAndPartnerAdminAccess,
  },
  {
    iconComponent: RolesIcon,
    text: 'Roles',
    href: '/roles',
    requiredAppAccess: AppAccess.RolesRead,
    subNav: [
      {
        icon: MdOutlinePersonSearch,
        text: 'Explore Roles',
        href: '/roles/explore-roles',
        requiredAppAccess: AppAccess.RolesRead,
      },
      {
        icon: MdOutlinePerson2,
        text: 'My Roles',
        href: '/roles/my-roles',
        requiredAppAccess: AppAccess.RolesRead,
      },
      {
        icon: MdOutlineAddReaction,
        text: 'Create Role',
        href: '/roles/create-role',
        requiredAppAccess: AppAccess.RolesWrite,
      },
      {
        icon: MdApproval,
        text: 'Access Approval',
        href: '/roles/access-approval',
        requiredAppAccess: AppAccess.LoreHealthAdminAccess,
      },
    ],
  },
  {
    icon: BsCardList,
    text: 'Report Hub',
    href: '/report-hub',
    requiredAppAccess: AppAccess.ReportHubRead,
  },
  // {
  //   icon: RiShieldUserLine,
  //   text: "Roles",
  //   href: "/roles",
  //   subNav: [
  //     {
  //       icon: BiGlassesAlt,
  //       text: "Explore Roles",
  //       href: "/roles/explore-roles",
  //     },
  //     {
  //       icon: BsPerson,
  //       text: "My Roles",
  //       href: "/roles/my-roles",
  //     },
  //   ],
  // },
  {
    icon: PiUsersThreeBold,
    text: 'Users',
    href: '/users',
    requiredAppAccess: AppAccess.LoreAdminPartnerAdminAndTeamAccess,
  },
  // {
  //   icon: MdOutlineAdminPanelSettings,
  //   text: "Admin",
  //   href: "/admin",
  //   subNav: [
  //     {
  //       icon: PiUsersThree,
  //       text: "Users",
  //       href: "/admin/users",
  //     },
  //     {
  //       icon: TfiCommentsSmiley,
  //       text: "Review Feedback",
  //       href: "/admin/review-feedback",
  //     },
  //   ],
  // },
];

export default navItems;
