import { Link } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import PartnerProductDetailsModal from '.';

type PartnerProductDetailsButtonProps = {
  name: string | null;
  id: number;
};

const PartnerProductDetailsButton = ({ name, id }: PartnerProductDetailsButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleLinkClick = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    console.log(`Button clicked - ${isModalOpen}`);
  }, [isModalOpen]);
  return (
    <>
      <Link onClick={handleLinkClick} style={{ color: '#0c7aff' }} whiteSpace={'normal'} wordBreak={'break-word'}>
        {name}
      </Link>

      <PartnerProductDetailsModal openModal={isModalOpen} id={id} name={name} setModal={setIsModalOpen} />
    </>
  );
};

export default PartnerProductDetailsButton;
