import { Box, Text, VStack, Switch, Spinner } from '@chakra-ui/react';
import { Button } from '@chakra-ui/button';
import { AddUserInvitationModel } from '../../../../app/services/partner/api/types';
import { useAddUserInvitationMutation } from '../../../../app/services/partner/api/userInvitation';
import { useEffect, useState } from 'react';
import { useGetTemplateRoleMappingByTemplateIdQuery } from '../../../../app/services/partner/api/refEmailTemplate';
import { useAddExistingSsoUserMutation } from '../../../../app/services/partner/api/user';
import { useToast } from '@chakra-ui/react';

import { useAppSelector } from '../../../../app/state/hooks';

import { PARTNER_PAGE } from '../../../../app/constants/partnerPage';
import { PAGE_ACTIVITY } from '../../../../app/constants/pageActivity';
import { useAddUserActivityMutation } from '../../../../app/services/partner/api/userActivity';

interface InvitationStageProps {
  userInvitation: AddUserInvitationModel;
  ssoObjectId: string;
  refProductTypeId: number | undefined;
  handleCancel: () => void;
}

const RolesSelectionPanel = ({ handleCancel, ssoObjectId, userInvitation, refProductTypeId }: InvitationStageProps) => {
  const [localUserInvitation, setLocalUserInvitation] = useState<AddUserInvitationModel>(userInvitation);
  const [selectedRoles, setSelectedRoles] = useState<number[]>([]);
  const [addExistingSsoUser, addExistingSsoUserDetails] = useAddExistingSsoUserMutation();
  const toast = useToast();

  const { selectedPartnerProduct, activityList, pageList, userSessionId } = useAppSelector(s => s.user);
  const [postAddUserActivity] = useAddUserActivityMutation();

  const users_page = pageList.find(i => i.page_name === PARTNER_PAGE.USERS);
  const user_invite_created = activityList.find(i => i.activity_name === PAGE_ACTIVITY.USER_INVITE_CREATED);
  const user_invite_draft_cancelled = activityList.find(
    i => i.activity_name === PAGE_ACTIVITY.USER_INVITE_DRAFT_CANCELLED,
  );

  const [addUserInvitation, addUserInvitationDetails] = useAddUserInvitationMutation();
  const roles = useGetTemplateRoleMappingByTemplateIdQuery(userInvitation.ref_email_template_id);

  const handleCancelClicked = () => {
    handleCancelDraftEventLog();
    handleCancel();
  };

  const handleCancelDraftEventLog = () => {
    if (users_page && user_invite_draft_cancelled && userSessionId) {
      postAddUserActivity({
        user_session_id: userSessionId,
        ref_activity_id: user_invite_draft_cancelled.ref_activity_id,
        ref_page_id: users_page.ref_page_id,
        partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      })
        .unwrap()
        .then(res => {});
    }
  };

  const handleSubmitEventLog = () => {
    if (users_page && user_invite_created && userSessionId) {
      postAddUserActivity({
        user_session_id: userSessionId,
        ref_activity_id: user_invite_created.ref_activity_id,
        ref_page_id: users_page.ref_page_id,
        partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      })
        .unwrap()
        .then(res => {});
    }
  };

  const handleSubmit = () => {
    if (localUserInvitation.user_role_list.length > 0) {
      console.log(`ROLES SELECTED UPON SUBMIT: ${JSON.stringify(localUserInvitation.user_role_list)}`);
      let isException = false;
      let errorMessage = null;
      if (!!ssoObjectId) {
        // create ref_user record and user_role record/s
        // CREATE RefUserController endpoint with params: email_address, sso_object_id, user_role_list
        addExistingSsoUser({
          sso_object_id: ssoObjectId,
          email_address: localUserInvitation.email_address,
          user_role_list: localUserInvitation.user_role_list,
          first_name: localUserInvitation.first_name,
          last_name: localUserInvitation.last_name,
          partner_product_id: localUserInvitation.partner_product_id,
        })
          .unwrap()
          .then(res => {
            console.log(`REF_USER_ID: ${JSON.stringify(res)}`);
          })
          .catch(error => {
            isException = true;
            errorMessage = error;
          })
          .finally(() => {
            if (!isException) {
              toast({
                position: 'top-right',
                title: 'Success!',
                description:
                  'This user will not be receiving an email invite. Please message the user informing them of access to Partner Portal.',
                status: 'success',
                duration: 9000,
                isClosable: true,
              });
            } else {
              toast({
                position: 'top-right',
                title: 'User Creation Failed',
                description: 'There was an error in creating the user record',
                status: 'error',
                duration: 9000,
                isClosable: true,
              });
            }
            handleCancel();
          });
      } else {
        addUserInvitation(localUserInvitation)
          .catch(error => {
            isException = true;
            errorMessage = error;
          })
          .finally(() => {
            if (!isException) {
              handleSubmitEventLog();
              toast({
                position: 'top-right',
                title: 'Invitation Sent',
                description: "We've sent the invitation",
                status: 'success',
                duration: 9000,
                isClosable: true,
              });
            } else {
              toast({
                position: 'top-right',
                title: 'Sending Failed',
                description: 'There was an error in sending the invitation',
                status: 'error',
                duration: 9000,
                isClosable: true,
              });
            }
            handleCancel();
          });
      }
    } else {
      toast({
        position: 'top-right',
        title: 'No Roles Selected!',
        description: 'Please select at least one role',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    console.log(`LOCAL USER INVITATION: ${JSON.stringify(localUserInvitation)} - SSO_OBJECT_ID: ${ssoObjectId}`);
  }, [localUserInvitation]);

  useEffect(() => {
    console.log(`SELECTED ROLES: ${JSON.stringify(selectedRoles)}`);
    setLocalUserInvitation(prev => ({ ...prev, user_role_list: selectedRoles }));
  }, [selectedRoles]);

  const handleOnChange = (isChecked: boolean, id: string) => {
    console.log(`TOGGLED: ${isChecked} - ${id}`);
    const roleId = parseInt(id);

    if (isChecked) {
      setSelectedRoles(prevNumbers => [...prevNumbers, roleId]);
    } else {
      setSelectedRoles(prevNumbers => prevNumbers.filter(i => i !== roleId));
    }
  };

  return (
    <>
      <VStack>
        <Box mb="1">
          <Text fontWeight={'semibold'} fontSize={'smaller'} mb="-1">
            Roles Selection
          </Text>
        </Box>
        <Box mt="-2" sx={{ border: '1px solid #c2c2c2', borderRadius: '5px', height: '300px' }}>
          {roles.isFetching && roles.isLoading ? (
            <Box height={'inherit'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="lg" />
            </Box>
          ) : (
            (roles.data ?? []).map(r => (
              <Box key={r.ref_role_id} display="flex" justifyContent={'space-between'} my="10px" mx="15px">
                <Box>{r.role_display_name}</Box>
                <Box>
                  <Switch value={r.ref_role_id} onChange={e => handleOnChange(e.target.checked, e.target.value)} />
                </Box>
              </Box>
            ))
          )}
        </Box>
      </VStack>
      <Box display="flex" justifyContent={'flex-end'} mt="5">
        <Box display="flex" gap={2}>
          <Button size="sm" onClick={handleCancelClicked}>
            Cancel
          </Button>
          <Button size="sm" colorScheme="brand.main" gap="2" px="5" onClick={handleSubmit}>
            {addUserInvitationDetails.isLoading || addExistingSsoUserDetails.isLoading ? (
              <Spinner size="sm" />
            ) : (
              'Submit'
            )}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default RolesSelectionPanel;
