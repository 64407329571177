import { partnerApi } from '.';
import { RefActivityDisplayModel } from './types';

const baseUrl = '/activity';

const extendedApi = partnerApi.injectEndpoints({
  endpoints: build => ({
    getRefActivityDisplayList: build.query<RefActivityDisplayModel[], void>({
      query: () => baseUrl + '/list',
    }),
  }),
});

export const { useLazyGetRefActivityDisplayListQuery, useGetRefActivityDisplayListQuery } = extendedApi;
