import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { defaultErrorMessage } from '../../../app/constants';
import { useDeletePartnerProductProviderMutation } from '../../../app/services/partner/api/partnerProductProvider';
import { PartnerProductProviderModel } from '../../../app/services/partner/api/types';

const AcoProviderListActionCellDelete = ({ original }: { original: PartnerProductProviderModel }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();

  const [deleteProvider, { isLoading }] = useDeletePartnerProductProviderMutation();

  const handleDelete = () => {
    deleteProvider(original.partner_product_provider_id)
      .unwrap()
      .then(() => {
        toast({
          description: `Successfully deleted provider`,
        });
        onClose();
      })
      .catch(() => {
        toast({ description: defaultErrorMessage, status: 'error' });
      });
  };

  const providerName = original.provider_first_name + ' ' + original.provider_last_name;
  const provider = providerName === ' ' ? original.provider_npi : providerName;

  return (
    <>
      <Button
        size="sm"
        _hover={{ textDecoration: 'none' }}
        variant="link"
        fontWeight="normal"
        color="red.500"
        textAlign="left"
        onClick={onOpen}
      >
        <Box w="100%">Delete</Box>
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl" closeOnOverlayClick={false}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Delete Provider</ModalHeader>

          <ModalCloseButton isDisabled={isLoading} />

          <ModalBody>
            <Text>Are you sure you want to delete provider {provider}?</Text>
          </ModalBody>
          <ModalFooter gap={3}>
            <Button onClick={onClose} ml="auto" isDisabled={isLoading}>
              Cancel
            </Button>
            <Button colorScheme="red" isLoading={isLoading} onClick={handleDelete}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AcoProviderListActionCellDelete;
