import { partnerApi } from '.';
import { PaginatedApiResult, PaginatedQueryParams } from '../../types';
import {
  PartnerProductModel,
  PartnerProductDisplayModel,
  PartnerProductUpdateModel,
  UpdatePartnerProductAndTagsModel,
  PartnerProductAssignableUserModel,
  AssignableUserFilterModel,
  PartnerProductAssignedUserModel,
  ToggleActivePartnerProductModel,
} from './types';

const baseUrl = '/partner-product';

const extendedApi = partnerApi.injectEndpoints({
  endpoints: build => ({
    updatePartnerProductAndTags: build.mutation<void, UpdatePartnerProductAndTagsModel>({
      query: ({ partner_product_id, ...body }) => ({
        url: baseUrl + `/tags/${partner_product_id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['PartnerProduct'],
    }),
    getPartnerProductForUpdate: build.query<PartnerProductUpdateModel, number>({
      query: partner_product_id => baseUrl + `/for-update/${partner_product_id}`,
      providesTags: ['PartnerProduct'],
    }),

    toggleActivePartnerProduct: build.mutation<void, ToggleActivePartnerProductModel>({
      query: ({ partner_product_id, is_active }) => ({
        url: baseUrl + `/active/${is_active}/id/${partner_product_id}`,
        method: 'PUT',
      }),
    }),

    // addUserRoleByAdmin: build.mutation<void, AddUserRoleByAdminModel>({
    //   query: body => ({
    //     url: baseUrl + '/admin',
    //     body: body,
    //     method: 'POST',
    //   }),
    // }),

    getPartnerProductAssignedUsers: build.query<PartnerProductAssignedUserModel[], number>({
      query: partner_product_id => baseUrl + `/assigned-users/${partner_product_id}`,
      providesTags: ['PartnerProduct'],
    }),
    getPartnerProductAssignableUsers: build.query<PartnerProductAssignableUserModel[], AssignableUserFilterModel>({
      query: ({ partner_product_id, search_string }) =>
        baseUrl + `/assignable-users/${partner_product_id}/search/${search_string}`,
      providesTags: ['PartnerProduct'],
    }),
    getAllPartnerProductsDisplayList: build.query<PartnerProductDisplayModel[], number>({
      query: partner_id => baseUrl + `/active/${partner_id}`,
      providesTags: ['PartnerProduct'],
    }),
    getAllPartnerProductsList: build.query<PartnerProductModel[], void>({
      query: () => baseUrl,
      providesTags: ['PartnerProduct'],
    }),
    getPartnerProductPaginatedList: build.query<
      PaginatedApiResult<PartnerProductModel>,
      PaginatedQueryParams<PartnerProductModel> & { partner_product_id: number }
    >({
      query: params => ({
        url: baseUrl + `/list`,
        params,
      }),
      providesTags: ['PartnerProduct'],
    }),
  }),
});

export const {
  useToggleActivePartnerProductMutation,
  useGetAllPartnerProductsListQuery,
  useGetPartnerProductPaginatedListQuery,
  useGetAllPartnerProductsDisplayListQuery,
  useLazyGetAllPartnerProductsDisplayListQuery,
  useGetPartnerProductForUpdateQuery,
  useLazyGetPartnerProductForUpdateQuery,
  useUpdatePartnerProductAndTagsMutation,
  useGetPartnerProductAssignableUsersQuery,
  useLazyGetPartnerProductAssignableUsersQuery,
  useLazyGetPartnerProductPaginatedListQuery,
  useLazyGetPartnerProductAssignedUsersQuery,
} = extendedApi;
