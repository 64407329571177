import { AuthenticatedTemplate, UnauthenticatedTemplate, useAccount } from '@azure/msal-react';
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { headerHeight } from '../../app/constants';
import { getAvatarUrl } from '../../app/helpers/avatarHelper';
import { loginRequest } from '../../app/services/auth/authConfig';
import { useAppSelector } from '../../app/state/hooks';
import { persistor } from '../../app/state/store';
import { appColors } from '../../app/theme';
import { HeaderLogo, HeaderLoreText } from '../../assets/svg';
import { usePurgeUserSessionMutation } from '../../app/services/partner/api/userSession';

type IProps = {};

const Header: FC<IProps> = props => {
  const account = useAccount();
  const instance = globalThis.msalInstance;
  const { isShowMobileTab } = useAppSelector(s => s.app);
  const { userSessionId } = useAppSelector(s => s.user);
  const [userFullName, setUserFullName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [profilePic, setProfilePic] = useState('');

  const { logonUser, selectedPartnerProduct, hasMultiplePartnerProducts } = useAppSelector(s => s.user);
  const isTest = process.env.REACT_APP_ENV === 'TEST';
  const redirect = hasMultiplePartnerProducts ? '/' : window.location.href;
  const [postPurgeUserSession] = usePurgeUserSessionMutation();

  useEffect(() => {
    const { idTokenClaims } = (account as any) ?? {};
    if (idTokenClaims) {
      const upn = account?.username || (idTokenClaims.emails && idTokenClaims.emails[0]) || '';
      setUserEmail(upn);
    }
  }, [account]);

  useEffect(() => {
    if (
      logonUser &&
      logonUser?.first_name &&
      logonUser?.last_name &&
      logonUser?.first_name !== '' &&
      logonUser?.last_name !== ''
    ) {
      setUserFullName(`${logonUser.first_name} ${logonUser.last_name}`);
    } else {
      setUserFullName(logonUser?.email || '');
    }

    if (logonUser && logonUser.email) {
      const avatarUrl = getAvatarUrl(logonUser.sso_object_id || '');
      setProfilePic(avatarUrl);
      setUserEmail(logonUser.email);
    }
  }, [logonUser]);

  const handleSignOut = () => {
    console.log(`Sign out clicked! - ${userSessionId}`);
    // update user_session
    if (userSessionId) {
      postPurgeUserSession({ user_session_id: userSessionId || 0 })
        .unwrap()
        .then(res => {
          console.log('purged!');
        })
        .finally(() => {
          instance.logoutRedirect({
            postLogoutRedirectUri: '/',
            account: instance.getActiveAccount(),
          });
          persistor.purge();
          sessionStorage.clear();
          localStorage.clear();
        });
    }
  };

  return (
    <Flex
      bg="header.bgColor"
      color={useColorModeValue('gray.600', 'white')}
      minH={headerHeight}
      w="100%"
      py={{ base: 2 }}
      px={{ base: 4 }}
      borderBottomWidth={isTest ? 3 : 0}
      borderBottomColor={isTest ? appColors.testEnvironmentIndicator : ''}
      borderStyle={'solid'}
      borderTopColor={useColorModeValue('gray.200', 'gray.900')}
      align={'center'}
      hidden={isShowMobileTab}
      justifyContent="space-between"
      position="fixed"
      zIndex={3}
    >
      <HStack alignItems="center">
        <Link to={redirect}>
          <HeaderLogo style={{ marginTop: '-0.8rem' }} />
        </Link>
        <Link to={redirect}>
          <HStack gap="4">
            <HeaderLoreText />
            <Box h="29px" w="3px" bg="white" />
            <Heading color="white" fontSize="2xl">
              {selectedPartnerProduct?.doing_business_as ?? 'Partner Portal'}
            </Heading>
          </HStack>
        </Link>
      </HStack>

      <Stack justify={'flex-end'} alignItems="center" direction={'row'} gap={4}>
        <Menu>
          <MenuButton as={Button} rounded={'full'} variant={'link'} cursor={'pointer'} minW={0}>
            {profilePic ? (
              <Avatar size={'sm'} bg="gray.300" src={profilePic} />
            ) : (
              <Avatar size={'sm'} bg="gray.300" name={userFullName} />
            )}
          </MenuButton>

          <MenuList>
            <AuthenticatedTemplate>
              <MenuGroup title={userFullName.length > 0 ? userFullName : userEmail}>
                <MenuDivider />

                <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
              </MenuGroup>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <MenuGroup>
                <MenuDivider />
                <MenuItem
                  onClick={() => {
                    instance.loginRedirect(loginRequest);
                  }}
                >
                  Sign In
                </MenuItem>
              </MenuGroup>
            </UnauthenticatedTemplate>
          </MenuList>
        </Menu>
      </Stack>
    </Flex>
  );
};

export default Header;
