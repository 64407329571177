import { Box, VStack, Text, Tabs, TabList, TabPanel, TabPanels, Tab, Spinner } from '@chakra-ui/react';
import RoleStatusList from './Tabs/StatusList';
import RoleDetails from './Tabs/Details';
import { useState, useEffect } from 'react';
import { useAppSelector } from '../../../../app/state/hooks';

import { UserRoleAccessRequestDisplayModel } from '../../../../app/services/partner/api/types';
import { useLazyGetUserRoleAccessRequestQuery } from '../../../../app/services/partner/api/userRoleAccessRequest';
import { useToast } from '@chakra-ui/react';

type IProps = {
  updateView: (newValue: number) => void;
  selectedRequestId: number;
};

const RoleDetailsView = ({ updateView, selectedRequestId }: IProps) => {
  const [latestStatus, setLatestStatus] = useState<string>('');
  const [request, setRequest] = useState<UserRoleAccessRequestDisplayModel | null>();
  const [authorizedViewer, setAuthorizedViewer] = useState<boolean>(true);
  const [getRequest, getRequestDetails] = useLazyGetUserRoleAccessRequestQuery();
  const { logonUser } = useAppSelector(i => i.user);

  const toast = useToast();

  const handleSetLatestStatus = (status: string) => {
    setLatestStatus(status);
  };

  useEffect(() => {
    getRequest(selectedRequestId)
      .unwrap()
      .then(res => {
        setRequest(res);
      })
      .catch(error => {
        toast({
          position: 'top-right',
          title: 'Error',
          description: 'There was an error in fetching the user role access request',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  }, [selectedRequestId]);

  useEffect(() => {
    if (request) {
      if (logonUser?.ref_user_id !== request.requestor_ref_user_id) {
        setRequest(null);
        toast({
          position: 'top-right',
          title: 'Unauthorized',
          description: 'You are not allowed to view this request',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        setAuthorizedViewer(false);
      } else {
        setAuthorizedViewer(true);
      }
    }
  }, [request]);

  const UnAuthorized = () => {
    return (
      <>
        <Box sx={{ width: '100%', border: '2px solid red', py: '3px', pl: '5px' }}>
          <Text sx={{ color: 'red', fontWeight: 'bolder' }}>NOT AUTHORIZED TO VIEW</Text>
        </Box>
      </>
    );
  };

  return (
    <VStack key={0}>
      <Box bg="white" shadow="base" p="4" rounded="md" minH="20vh" minW="container.md">
        <Tabs>
          <TabList width="160px">
            <Tab>Details</Tab>
            <Tab>Status</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Box>
                {getRequestDetails.isFetching ? (
                  <Spinner size="lg" />
                ) : authorizedViewer ? (
                  <RoleDetails
                    request={request}
                    handleSetLatestStatus={handleSetLatestStatus}
                    requestId={selectedRequestId}
                  />
                ) : (
                  <UnAuthorized />
                )}
              </Box>
            </TabPanel>

            <TabPanel>
              <Box>
                {authorizedViewer ? (
                  <RoleStatusList latestStatus={latestStatus} requestId={selectedRequestId} />
                ) : (
                  <UnAuthorized />
                )}
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </VStack>
  );
};

export default RoleDetailsView;
