import { Box, HStack, Heading, Image, Link, Spinner, Text, VStack } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { AppSize } from '../../app/constants';
import { setSelectedPartnerProduct } from '../../app/slices/userSlice';
import { useAppSelector } from '../../app/state/hooks';
import brandColors from '../../app/theme/brandColors';
import NonAdminPic from '../../assets/images/non-admin-pic.png';
import DashboardAdmin from './Admin';
import AppAuth from '../../features/AppAuth';
import { AppAccess } from '../../app/constants/appAccesses';
import { useAddUserActivityMutation } from '../../app/services/partner/api/userActivity';
import { useEffect } from 'react';
import { PARTNER_PAGE } from '../../app/constants/partnerPage';
import { PAGE_ACTIVITY } from '../../app/constants/pageActivity';

const Dashboard = () => {
  const dispatch = useDispatch();
  const [postAddUserActivity] = useAddUserActivityMutation();
  const { selectedPartnerProduct, userPartnerProducts, activityList, pageList, userSessionId } = useAppSelector(
    s => s.user,
  );
  const { isSideNavOpen } = useAppSelector(s => s.app);

  const dashboard_page = pageList.find(i => i.page_name === PARTNER_PAGE.DASHBOARD);
  const dashboard_clicked = activityList.find(i => i.activity_name === PAGE_ACTIVITY.DASHBOARD_CLICKED);

  const sideNavWidth = isSideNavOpen ? AppSize.sideNav.width.open : AppSize.sideNav.width.close;

  useEffect(() => {
    if (dashboard_clicked && dashboard_page && userSessionId) {
      postAddUserActivity({
        user_session_id: userSessionId,
        ref_activity_id: dashboard_clicked.ref_activity_id,
        ref_page_id: dashboard_page.ref_page_id,
        partner_product_id: selectedPartnerProduct?.partner_product_id || 0,
      })
        .unwrap()
        .then(res => {});
    }
  }, []);

  return (
    <Box display="flex" flexDir="column" h="100%">
      <Heading as="h2" fontSize="xl" fontWeight="semibold">
        Select a Partner to Manage
      </Heading>

      <HStack overflowY="hidden" gap="4" w={`calc(100vw - ${sideNavWidth})`} px="6" pt="6" pb="4" mx="-6">
        {userPartnerProducts &&
          userPartnerProducts.map(p => {
            const isSelected = p.partner_product_id === selectedPartnerProduct?.partner_product_id;
            const selectedColor = brandColors.primary.teal[300];

            return (
              <Box
                display="flex"
                gap="2"
                alignItems="start"
                key={p.partner_product_id}
                w="208px"
                minW="208px"
                bg="white"
                h="104px"
                p="4"
                rounded="md"
                shadow="lg"
                cursor="pointer"
                _hover={{ bg: 'blackAlpha.100' }}
                onClick={() => dispatch(setSelectedPartnerProduct(p))}
                border={`2px solid ${isSelected ? selectedColor : 'transparent'}`}
              >
                <Text color={isSelected ? selectedColor : 'black'}>{p.doing_business_as}</Text>
              </Box>
            );
          })}
      </HStack>

      <AppAuth
        requiredAppAccess={AppAccess.Partner}
        UnAuthorizedComponent={<DashboardNoneAdmin />}
        LoadingComponent={<>Loading...</>}
      >
        <DashboardAdmin />
      </AppAuth>
    </Box>
  );
};

const DashboardNoneAdmin = () => {
  return (
    <>
      <HStack gap="6" height="591px" mt="2">
        <Box position="relative">
          <Image
            borderRadius="md"
            height="591px"
            w="1614px"
            src={NonAdminPic}
            alt="Lore Header Logo"
            objectFit="fill"
          />
        </Box>

        {/* <Box w="324px" bg="white" flexShrink="0" rounded="md" shadow="base" p="4">
          <HStack justifyContent="space-between" mb="4">
            <Heading as="h4" size="sm">
              Product Teams
            </Heading>
            <Link color="blue.400" fontSize="xs">
              Detail View
            </Link>
          </HStack>

          <VStack overflowX="hidden" h="calc(416px - 4.2rem)" gap="4">
            {TemporaryUsers.map(u => (
              <HStack gap="4" key={u.id}>
                <Box>
                  <Avatar>
                    <AvatarBadge boxSize="0.90rem" bg={u.badgeColor} />
                  </Avatar>
                </Box>

                <Box display="flex" flexDir="column">
                  <Text lineHeight="20px">{u.name}</Text>
                  <Text lineHeight="20px" color="#0000007a">
                    User Role
                  </Text>
                </Box>
              </HStack>
            ))}
          </VStack>
        </Box> */}
      </HStack>
    </>
  );
};

// TODO tbd
const TemporaryUsers = [
  {
    id: 1,
    name: 'Peter Parker',
    badgeColor: 'green.500',
  },
  {
    id: 2,
    name: 'Wanda Maximoff',
    badgeColor: 'green.500',
  },
  {
    id: 3,
    name: 'Tony Stark',
    badgeColor: 'green.500',
  },
  {
    id: 4,
    name: 'Bruce Wayne',
    badgeColor: 'yellow.300',
  },
  {
    id: 5,
    name: 'Naruto Uzumaki',
    badgeColor: 'red.500',
  },
  {
    id: 6,
    name: 'John Doe',
    badgeColor: 'green.500',
  },
  {
    id: 7,
    name: 'Dan Brown',
    badgeColor: 'red.500',
  },
  {
    id: 8,
    name: 'Nicole Red',
    badgeColor: 'red.500',
  },
  {
    id: 9,
    name: 'Samantha Smith',
    badgeColor: 'yellow.300',
  },
  {
    id: 10,
    name: 'George Vasquez',
    badgeColor: 'yellow.300',
  },
];

export default Dashboard;
