import { partnerApi } from '.';
import { PaginatedApiResult, PaginatedQueryParams } from '../../types';
import { AddUserInvitationModel } from './types';

const baseUrl = '/userinvitation';

const extendedApi = partnerApi.injectEndpoints({
  endpoints: build => ({
    addUserInvitation: build.mutation<
      {
        result: {
          user_invitation_id: number;
        };
        action: 'inserted';
      },
      AddUserInvitationModel
    >({
      query: body => ({
        url: baseUrl,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['UserInvitation'],
    }),
  }),
});

export const { useAddUserInvitationMutation } = extendedApi;
